import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { change, initialize } from 'redux-form'
import { Button, Dropdown, Menu, Spin, Tooltip, Tree, TreeDataNode, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { find, includes, map } from 'lodash'
import cx from 'classnames'

import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { ReactComponent as ProjectIcon } from '../../assets/icons/icon-project.svg'
import { ReactComponent as FolderIcon } from '../../assets/icons/icon-folder.svg'
import { ReactComponent as DotsIcon } from '../../assets/icons/icon-table-actions.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/icon-edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/icon-delete.svg'
import { ReactComponent as MenuProjectIcon } from '../../assets/icons/icon-input-project.svg'

import { history } from '../../utils/history'
import { ACTION_TYPE, DEFAULT_PAGE_SIZE, FORM, LEFT_MENU_ITEM, TREE_NODE_TYPE, CODE_TYPE, TOP_MENU_ITEM } from '../../utils/enums'
import { setLeftMenu, clearProjectSider, setProjectSiderToFolder, setProjectSiderToProject, setProjectSiderToSubFolder } from '../../reducers/menu/menuActions'
import { getProjects, getProjectsSider } from '../../reducers/projects/projectsActions'
import { RootState } from '../../reducers'
import ConfirmModal from '../../components/ConfirmModal'
import RenameFolderModal from '../../pages/HomePage/Folders/components/RenameFolderModal'
import { getFolders } from '../../reducers/folders/foldersActions'
import { deleteReq, getReq } from '../../utils/requests'
import usePermissionCheck from '../../utils/permissionCheck'

const { DirectoryTree } = Tree
const projectItemMaxWidth = { maxWidth: 150 }
const folderItemMaxWidth = { maxWidth: 130 }
const subFolderItemMaxWidth = { maxWidth: 100 }
const minDropdownWidth = { minWidth: 256 }

enum MODAL_TYPE {
	DELETE,
	RENAME
}

type FolderProps = {
	folderID: number
	projectID: number
	folderName: string
	projectName: string
	type: TREE_NODE_TYPE
}

type ProjectProps = {
	projectID: number
	projectName: string
}

const ProjectActionsDropdown = (props: ProjectProps) => {
	const { projectID, projectName } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [modalState, setModalState] = useState<{ visible: boolean; projectID: number | undefined }>({ visible: false, projectID: undefined })

	const menu = useSelector((state: RootState) => state?.menuStore?.path)

	const permissedActions = usePermissionCheck()

	const [, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam
	})

	const confirmDelete = async () => {
		if (modalState.projectID) {
			try {
				await deleteReq('/api/v1/projects/{projectID}', { projectID: modalState.projectID })
				dispatch(getProjectsSider())
				dispatch(getProjects(1, '', DEFAULT_PAGE_SIZE))
				dispatch(clearProjectSider())
				setQuery({ projectID: undefined, projectName: undefined, folderID: undefined, folderName: undefined, subFolderID: undefined, subFolderName: undefined })
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}
		setModalState({ visible: false, projectID: undefined })
	}

	const dropdownMenu = (
		<Menu>
			<Menu.Item
				key={'edit'}
				icon={<EditIcon />}
				onClick={(e) => {
					e.domEvent.stopPropagation()
					history.push(t('paths:projects/{{projectID}}', { projectID }))
				}}
			>
				{permissedActions?.includes(ACTION_TYPE.PROJECT_EDIT) ? t('loc:Edit') : t('loc:Show details')}
			</Menu.Item>
			<Menu.Item
				key={'delete'}
				icon={<DeleteIcon />}
				className={permissedActions?.includes(ACTION_TYPE.PROJECT_DELETE) ? 'text-error-medium' : ''}
				onClick={(e) => {
					e.domEvent.stopPropagation()
					setModalState({ visible: true, projectID })
				}}
				disabled={!permissedActions?.includes(ACTION_TYPE.PROJECT_DELETE)}
			>
				{t('loc:Delete')}
			</Menu.Item>
		</Menu>
	)

	const isDisabled = (): boolean => {
		if (menu.selectedTreeNodeKey === `${TREE_NODE_TYPE.PROJECT}_${projectID}`) return false
		return true
	}

	return (
		<div className={'inline'}>
			<Tooltip placement={'right'} title={projectName} overlayStyle={{ maxWidth: '500px' }}>
				<Typography.Text ellipsis style={projectItemMaxWidth}>
					{projectName}
				</Typography.Text>
			</Tooltip>
			<Dropdown
				overlay={dropdownMenu}
				trigger={['click']}
				disabled={isDisabled()}
				className={cx('absolute right-2 extd-projects-sider-actions', { 'is-disabled': isDisabled() }, { 'is-active': !isDisabled() })}
			>
				<DotsIcon />
			</Dropdown>
			<ConfirmModal
				visible={modalState.visible === true}
				text={t('loc:Delete project?')}
				confirmButtonText={t('loc:Confirm')}
				cancelButtonText={t('loc:Cancel')}
				confirmButtonClassName={'extd-btn-danger'}
				onCancel={() => setModalState({ visible: false, projectID: undefined })}
				onSubmit={confirmDelete}
			/>
		</div>
	)
}

const FolderActionsDropdown = (props: FolderProps) => {
	const { folderID, projectID, folderName, projectName, type } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [modalState, setModalState] = useState<MODAL_TYPE | undefined>()

	const menu = useSelector((state: RootState) => state?.menuStore?.path)
	const folders = useSelector((state: RootState) => state?.foldersStore?.folders)

	const permissedActions = usePermissionCheck()

	const [, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam
	})

	const confirmRename = (newName: string) => {
		dispatch(getProjectsSider())
		if (menu?.selectedTreeNodeKey?.includes('SUB-FOLDER')) {
			dispatch(
				setProjectSiderToSubFolder(menu?.project?.ID || 0, menu?.project?.name || '-', menu?.folder?.ID || 0, menu?.folder?.name || '-', menu?.subFolder?.ID || 0, newName)
			)
			setQuery({
				projectID: menu?.project?.ID,
				projectName: menu?.project?.name,
				folderID: menu?.folder?.ID,
				folderName: menu?.folder?.name,
				subFolderID: menu?.subFolder?.ID,
				subFolderName: newName,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		} else if (menu?.selectedTreeNodeKey?.includes('MAIN-FOLDER')) {
			dispatch(setProjectSiderToFolder(menu?.project?.ID || 0, menu?.project?.name || '-', menu?.folder?.ID || 0, newName))
			setQuery({
				projectID: menu?.project?.ID,
				projectName: menu?.project?.name,
				folderID: menu?.folder?.ID,
				folderName: newName,
				subFolderID: undefined,
				subFolderName: undefined,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		}
		dispatch(getFolders(projectID, 1, folders?.search, DEFAULT_PAGE_SIZE))
		setModalState(undefined)
	}

	const confirmDelete = async () => {
		try {
			await deleteReq('/api/v1/folders/{folderID}', { folderID })
			dispatch(getProjectsSider())
			dispatch(getFolders(projectID, 1, folders?.search, DEFAULT_PAGE_SIZE))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
		setModalState(undefined)
	}

	const openRenameModal = () => {
		dispatch(initialize(FORM.RENAME_FOLDER_FORM, { name: folderName }))
		setModalState(MODAL_TYPE.RENAME)
	}

	const dropdownMenu = (
		<Menu>
			<Menu.Item key={'Rename'} icon={<EditIcon />} onClick={openRenameModal} disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_EDIT)}>
				{t('loc:Rename')}
			</Menu.Item>
			<Menu.Item
				key={'Delete'}
				icon={<DeleteIcon />}
				className={permissedActions?.includes(ACTION_TYPE.FOLDER_DELETE) ? 'text-error-medium' : ''}
				onClick={() => setModalState(MODAL_TYPE.DELETE)}
				disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_DELETE)}
			>
				{t('loc:Delete')}
			</Menu.Item>
		</Menu>
	)

	const isDisabled = (): boolean => {
		if (menu.selectedTreeNodeKey === `${TREE_NODE_TYPE.MAIN_FOLDER}_${folderID}_${projectID}`) return false
		if (menu.selectedTreeNodeKey === `${TREE_NODE_TYPE.SUB_FOLDER}_${folderID}_${menu.folder?.ID}_${projectID}`) return false
		return true
	}

	return (
		<div className={'inline'}>
			<Tooltip placement={'right'} title={folderName} overlayStyle={{ maxWidth: '500px' }}>
				<Typography.Text ellipsis style={type === TREE_NODE_TYPE.SUB_FOLDER ? subFolderItemMaxWidth : folderItemMaxWidth}>
					{folderName}
				</Typography.Text>
			</Tooltip>
			<Dropdown
				overlay={dropdownMenu}
				trigger={['click']}
				disabled={isDisabled()}
				className={cx('absolute right-2 extd-projects-sider-actions', { 'is-disabled': isDisabled() }, { 'is-active': !isDisabled() })}
			>
				<DotsIcon />
			</Dropdown>
			<ConfirmModal
				visible={modalState === MODAL_TYPE.DELETE}
				text={t('loc:Delete folder?')}
				confirmButtonText={t('loc:Confirm')}
				cancelButtonText={t('loc:Cancel')}
				confirmButtonClassName={'extd-btn-danger'}
				onCancel={() => setModalState(undefined)}
				onSubmit={confirmDelete}
			/>
			<RenameFolderModal
				visible={modalState === MODAL_TYPE.RENAME}
				projectName={projectName}
				folderID={folderID}
				onCancel={() => setModalState(undefined)}
				onSubmit={confirmRename}
			/>
		</div>
	)
}

enum CREATE_ITEM {
	CREATE_PROJECT = 'CREATE_PROJECT',
	CREATE_MAIN_FOLDER = 'CREATE_MAIN_FOLDER',
	CREATE_SUB_FOLDER = 'CREATE_SUB_FOLDER',
	CREATE_PLAN = 'CREATE_PLAN'
}

const ProjectsSider = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [query, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam
	})

	const projectsSider = useSelector((state: RootState) => state?.projectsStore?.projectsSider)
	const menu = useSelector((state: RootState) => state?.menuStore)
	const [expandedKeys, setExpandedKeys] = useState<any[]>([])
	const [treeData, setTreeData] = useState<{ projects: TreeDataNode[]; teamMates: TreeDataNode[] }>({ projects: [], teamMates: [] })
	const [BEVersion, setBEVersion] = useState('')

	const selectedLeftMenuKey = menu?.LEFT_MENU?.menuItem || LEFT_MENU_ITEM.PROJECTS
	const selectedTopMenukey = menu?.TOP_MENU?.menuItem

	const permissedActions = usePermissionCheck()

	const { isLoading } = projectsSider

	useEffect(() => {
		const setSider = async () => {
			if (!projectsSider || !projectsSider?.originalData) {
				await dispatch(getProjectsSider())
			} else if (query?.subFolderID && query?.subFolderName && query?.folderID && query?.folderName && query?.projectID && query?.projectName) {
				dispatch(setProjectSiderToSubFolder(query.projectID, query.projectName, query.folderID, query.folderName, query.subFolderID, query.subFolderName))
			} else if (query?.folderID && query?.folderName && query?.projectID && query?.projectName) {
				dispatch(setProjectSiderToFolder(query.projectID, query.projectName, query.folderID, query.folderName))
			} else if (query?.projectID && query?.projectName) {
				dispatch(setProjectSiderToProject(query.projectID, query.projectName))
			} else if (!query?.projectID && menu?.path?.project) {
				setQuery({
					projectID: menu?.path?.project?.ID,
					projectName: menu?.path?.project?.name,
					folderID: menu?.path?.folder?.ID || undefined,
					folderName: menu?.path?.folder?.name || undefined,
					subFolderID: menu?.path?.subFolder?.ID || undefined,
					subFolderName: menu?.path?.subFolder?.name || undefined
				})
			}
		}

		if (
			!menu?.path?.project?.ID ||
			menu?.path?.project?.ID !== query?.projectID ||
			menu?.path?.folder?.ID !== query?.folderID ||
			menu?.path?.subFolder?.ID !== query?.subFolderID ||
			menu?.path?.planStateDocument?.planID !== query?.planID ||
			menu?.path?.planStateDocument?.defaultOption?.key !== query?.planStateID
		) {
			setSider()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query])

	useEffect(() => {
		const newTreeData = map(projectsSider?.originalData, (project) => ({
			key: `${TREE_NODE_TYPE.PROJECT}_${project.id}`,
			title: <ProjectActionsDropdown projectID={project.id} projectName={project.name} />,
			icon: <ProjectIcon />,
			project: { projectName: project.name, projectID: project.id },
			children: map(project.folders, (folder) => ({
				key: `${TREE_NODE_TYPE.MAIN_FOLDER}_${folder.id}_${project.id}`,
				title: <FolderActionsDropdown projectID={project.id} projectName={project.name} folderID={folder.id} folderName={folder.name} type={TREE_NODE_TYPE.MAIN_FOLDER} />,
				icon: <FolderIcon />,
				project: { projectName: project.name, projectID: project.id },
				folder: { folderName: folder.name, folderID: folder.id },
				children: map(folder.folders, (subFolder) => ({
					title: (
						<FolderActionsDropdown
							projectID={project.id}
							projectName={project.name}
							folderID={subFolder.id}
							folderName={subFolder.name}
							type={TREE_NODE_TYPE.SUB_FOLDER}
						/>
					),
					key: `${TREE_NODE_TYPE.SUB_FOLDER}_${subFolder.id}_${folder.id}_${project.id}`,
					icon: <FolderIcon />,
					project: { projectName: project.name, projectID: project.id },
					folder: { folderName: folder.name, folderID: folder.id },
					subFolder: { subFolderName: subFolder.name, subFolderID: subFolder.id }
				}))
			}))
		}))
		const newTeamMatesTreeData = map(projectsSider?.originalData, (project) => ({
			key: `${TREE_NODE_TYPE.PROJECT}_${project.id}`,
			title: <ProjectActionsDropdown projectID={project.id} projectName={project.name} />,
			icon: <ProjectIcon />,
			project: { projectName: project.name, projectID: project.id }
		}))
		setTreeData({ projects: newTreeData, teamMates: newTeamMatesTreeData })
	}, [projectsSider])

	useEffect(() => {
		const loadBEVersion = async () => {
			try {
				const { data } = await getReq('/api/v1/maintenance/version', undefined)
				if (data?.version) {
					setBEVersion(data?.version)
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e)
			}
		}
		loadBEVersion()
	}, [])

	const handleCreateClick = async (info: { key: any }) => {
		const currProject = find(projectsSider?.originalData, (item) => item.id === menu?.path?.project?.ID)

		if (info.key === CREATE_ITEM.CREATE_PROJECT) {
			history.push(t('paths:projects/create'))
		} else if (info.key === CREATE_ITEM.CREATE_MAIN_FOLDER && menu?.path?.project?.ID) {
			dispatch(
				initialize(FORM.MAIN_FOLDER_CREATE_FORM, {
					tabs: [{ id: 1 }]
				})
			)
			dispatch(
				change(FORM.MAIN_FOLDER_CREATE_FORM, 'opened', {
					projectID: menu?.path?.project?.ID,
					projectName: currProject?.name
				})
			)
		} else if (info.key === CREATE_ITEM.CREATE_SUB_FOLDER && menu?.path?.project?.ID && menu?.path?.folder?.ID) {
			dispatch(
				change(FORM.SUB_FOLDER_CREATE_FORM, 'opened', {
					projectID: menu?.path?.project?.ID,
					mainFolderID: menu?.path?.folder?.ID,
					projectName: currProject?.name
				})
			)
		} else if (info.key === CREATE_ITEM.CREATE_PLAN) {
			try {
				const { data } = await getReq(`/api/v1/projects/{projectID}`, { projectID: menu?.path?.project?.ID || 0 })
				dispatch(
					initialize(FORM.PLAN_CREATE_FORM, {
						projectCodeAttributes: data.project.projectCodeAttributes,
						type: CODE_TYPE.STATES
					})
				)
				dispatch(
					change(FORM.PLAN_CREATE_FORM, 'opened', {
						folderID: includes(menu.path?.selectedTreeNodeKey, TREE_NODE_TYPE.SUB_FOLDER) ? menu?.path?.subFolder?.ID : menu?.path?.folder?.ID,
						projectName: currProject?.name
					})
				)
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e)
			}
		}
	}

	const menuItems = []

	if (selectedLeftMenuKey === LEFT_MENU_ITEM.PROJECTS) {
		menuItems.push(
			<Menu.Item key={CREATE_ITEM.CREATE_PROJECT} icon={<ProjectIcon />} disabled={!permissedActions?.includes(ACTION_TYPE.PROJECT_CREATE)}>
				{t('loc:New project')}
			</Menu.Item>
		)
		if (includes(menu.path?.selectedTreeNodeKey, TREE_NODE_TYPE.PROJECT)) {
			menuItems.push(
				<Menu.Item key={CREATE_ITEM.CREATE_MAIN_FOLDER} icon={<FolderIcon />} disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_CREATE)}>
					{t('loc:New folder')}
				</Menu.Item>
			)
		}
		if (includes(menu.path?.selectedTreeNodeKey, TREE_NODE_TYPE.MAIN_FOLDER)) {
			menuItems.push(
				<Menu.Item key={CREATE_ITEM.CREATE_SUB_FOLDER} icon={<FolderIcon />} disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_CREATE)}>
					{t('loc:New sub-folder')}
				</Menu.Item>
			)
		}
		if (includes(menu.path?.selectedTreeNodeKey, TREE_NODE_TYPE.SUB_FOLDER) || includes(menu.path?.selectedTreeNodeKey, TREE_NODE_TYPE.MAIN_FOLDER)) {
			menuItems.push(
				<Menu.Item key={CREATE_ITEM.CREATE_PLAN} icon={<MenuProjectIcon />} disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_CREATE)}>
					{t('loc:New plan')}
				</Menu.Item>
			)
		}
	}

	let createDropdown
	if (menuItems.length > 0) {
		const overlay = <Menu onClick={handleCreateClick}>{menuItems}</Menu>
		createDropdown = (
			<Dropdown overlay={overlay} overlayStyle={minDropdownWidth} className='mt-1 ml-2'>
				<Button type={'primary'} className={'extd-btn'} icon={<PlusOutlined />} />
			</Dropdown>
		)
	}

	const handleProjectsArchiveMenuChange = (key: LEFT_MENU_ITEM) => {
		dispatch(setLeftMenu(key))
		dispatch(clearProjectSider())
	}

	const treeSelectedKeys = menu.path?.selectedTreeNodeKey ? [menu.path?.selectedTreeNodeKey] : []

	const getTeamMatesTreeSelectedKeys = () => {
		const selectedKey = menu?.path?.selectedTreeNodeKey
		if (!selectedKey) {
			return []
		}
		if (selectedKey?.includes('PROJECT')) {
			return [selectedKey]
		}
		return [`PROJECT_${selectedKey.substr(selectedKey.length - 1)}`]
	}

	const onSelectChange = (selectedKeys: any, node: any) => {
		if (selectedKeys[0]?.includes('PROJECT')) {
			const projectID = node?.node?.project?.projectID
			const projectName = node?.node?.project?.projectName
			dispatch(setProjectSiderToProject(projectID, projectName))
			setQuery({
				projectID,
				projectName,
				folderID: undefined,
				folderName: undefined,
				subFolderID: undefined,
				subFolderName: undefined,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		} else if (selectedKeys[0]?.includes('MAIN-FOLDER')) {
			const projectID = node?.node?.project?.projectID
			const projectName = node?.node?.project?.projectName
			const folderID = node?.node?.folder?.folderID
			const folderName = node?.node?.folder?.folderName
			dispatch(setProjectSiderToFolder(projectID, projectName, folderID, folderName))
			setQuery({
				projectID,
				projectName,
				folderID,
				folderName,
				subFolderID: undefined,
				subFolderName: undefined,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		} else if (selectedKeys[0]?.includes('SUB-FOLDER')) {
			const projectID = node?.node?.project?.projectID
			const projectName = node?.node?.project?.projectName
			const folderID = node?.node?.folder?.folderID
			const folderName = node?.node?.folder?.folderName
			const subFolderID = node?.node?.subFolder?.subFolderID
			const subFolderName = node?.node?.subFolder?.subFolderName
			dispatch(setProjectSiderToSubFolder(projectID, projectName, folderID, folderName, subFolderID, subFolderName))
			setQuery({
				projectID,
				projectName,
				folderID,
				folderName,
				subFolderID,
				subFolderName,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		}
	}

	const onExpand = (keys: any[]) => {
		setExpandedKeys(keys)
	}

	useEffect(() => {
		if (!expandedKeys.includes(menu.path?.selectedTreeNodeKey)) {
			if (includes(menu.path?.selectedTreeNodeKey, TREE_NODE_TYPE.PROJECT)) {
				setExpandedKeys([...expandedKeys, `${TREE_NODE_TYPE.PROJECT}_${menu?.path?.project?.ID}`])
			} else if (includes(menu.path?.selectedTreeNodeKey, TREE_NODE_TYPE.MAIN_FOLDER)) {
				setExpandedKeys([
					...expandedKeys,
					`${TREE_NODE_TYPE.PROJECT}_${menu?.path?.project?.ID}`,
					`${TREE_NODE_TYPE.MAIN_FOLDER}_${menu?.path?.folder?.ID}_${menu?.path?.project?.ID}`
				])
			} else if (includes(menu.path?.selectedTreeNodeKey, TREE_NODE_TYPE.SUB_FOLDER)) {
				setExpandedKeys([
					...expandedKeys,
					`${TREE_NODE_TYPE.PROJECT}_${menu?.path?.project?.ID}`,
					`${TREE_NODE_TYPE.MAIN_FOLDER}_${menu?.path?.folder?.ID}_${menu?.path?.project?.ID}`,
					`${TREE_NODE_TYPE.SUB_FOLDER}_${menu?.path.subFolder?.ID}_${menu?.path?.folder?.ID}_${menu?.path?.project?.ID}`
				])
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu.path?.project, menu.path?.subFolder, menu.path?.folder, menu.path?.planStateDocument])

	const getTreeData = useMemo(() => {
		if (selectedTopMenukey === TOP_MENU_ITEM.TEAMMATES) return treeData?.teamMates
		return treeData.projects
	}, [selectedTopMenukey, treeData])

	return (
		<div className={'mt-3'}>
			<div className={'flex ml-2 mr-2'}>
				<Menu
					mode={'horizontal'}
					style={{ width: 'calc(100% - 48px)' }}
					disabledOverflow={true}
					onClick={(info) => {
						handleProjectsArchiveMenuChange(info.key as any)
						setQuery({
							projectID: undefined,
							projectName: undefined,
							folderID: undefined,
							folderName: undefined,
							subFolderID: undefined,
							subFolderName: undefined,
							planID: undefined,
							planName: undefined,
							planStateID: undefined,
							planStateName: undefined
						})
					}}
					selectedKeys={[selectedLeftMenuKey]}
					className={'bg-zinc-100'}
				>
					<Menu.Item style={{ width: '100%' }} className={`${treeSelectedKeys.length > 0 ? '' : 'bg-blue-200'}`} key={LEFT_MENU_ITEM.PROJECTS}>
						{t('loc:Projects')}
					</Menu.Item>
				</Menu>
				{createDropdown}
			</div>
			<Spin spinning={isLoading}>
				<DirectoryTree
					className={'extd-tree mt-4'}
					treeData={getTreeData}
					// treeData={selectedTopMenukey === TOP_MENU_ITEM.TEAMMATES ? treeData?.teamMates : treeData?.projects}
					onExpand={onExpand}
					expandedKeys={expandedKeys}
					selectedKeys={selectedTopMenukey === TOP_MENU_ITEM.TEAMMATES ? getTeamMatesTreeSelectedKeys() : treeSelectedKeys}
					onSelect={onSelectChange}
				/>
			</Spin>
			<div className={'extd-projects-sider-version'}>
				<span className={'text-s-regular'}>{t('loc:Version {{version}}', { version: process.env.REACT_APP_VERSION })}</span>
				<span className={'text-s-regular'}>{t('loc:API {{version}}', { version: BEVersion })}</span>
			</div>
		</div>
	)
}

export default ProjectsSider
