import { Button, Typography } from 'antd'
import { find, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { arrayRemove, change, Field, FieldArray, formValueSelector, getFormError, isDirty, WrappedFieldArrayProps, WrappedFieldProps } from 'redux-form'
import { RootState } from '../../../reducers'
import { ILabelInValue } from '../../../types/interfaces'
import { FORM, UPLOAD_ERROR_TYPE } from '../../../utils/enums'
import { getReq } from '../../../utils/requests'
import SelectField, { selectFieldFilterOption } from '../../../atoms/SelectField'

import { ReactComponent as ArrowIcon } from '../../../assets/icons/icon-breadcrumb-arrow.svg'
import { ReactComponent as DoneIcon } from '../../../assets/icons/icon-notification-done.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/icon-notification-warning-red.svg'
import { ReactComponent as ProjectIcon } from '../../../assets/icons/icon-input-project.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon-delete.svg'

const form = FORM.UPLOAD_DOCUMENTS
const selector = formValueSelector(form)

type FileFieldProps = WrappedFieldProps

const FileField = (param: FileFieldProps) => {
	const fileName = param?.input?.value?.name
	return (
		<div className={'flex col items-center gap-2 mb-1'}>
			<div className={'h-10 mb-1 flex justify-between bg-gray-100 p-2 rounded'} style={{ width: 'calc(30% - 16px)' }}>
				<div className={'flex flex-row'} style={{ maxWidth: '90%' }}>
					<div>
						<ProjectIcon />
					</div>
					<Typography.Text className={'ml-1'} ellipsis>
						{fileName}
					</Typography.Text>
				</div>
			</div>
		</div>
	)
}

type PathFieldProps = WrappedFieldProps

const PathField = (param: PathFieldProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const selectorError = getFormError(FORM.UPLOAD_DOCUMENTS)
	const formErrorMessage: string = useSelector((state: RootState) => selectorError(state)) as string
	const isFormDirty = useSelector((state: RootState) => isDirty(form)(state))

	const name = param?.input?.name

	const getErrorMessage = (): string | undefined => {
		if (!formErrorMessage || !isFormDirty) {
			return undefined
		}
		const errorArray = formErrorMessage.split('-')
		const pathFieldIndex = parseInt(name?.substring(name.indexOf('[') + 1, name.lastIndexOf(']')), 10)

		const errorText = errorArray.find(
			(errorItem) =>
				parseInt(errorItem?.substring(errorItem.indexOf('[') + 1, errorItem.lastIndexOf(']')), 10) === pathFieldIndex &&
				errorItem.includes(UPLOAD_ERROR_TYPE.UNNASSIGNED_PATH)
		)

		if (errorText) {
			return errorText?.substring(errorText.indexOf(' ') + 1)
		}

		return undefined
	}

	const projectsSider = useSelector((state: RootState) => state?.projectsStore?.projectsSider)
	const projectValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.project`))

	const [foldersOptions, setFoldersOptions] = useState<any[]>([])
	const folderValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.folder`))

	const [subFoldersOptions, setSubFoldersOptions] = useState<any[]>([])
	const subFolderValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.subFolder`))

	const [planOptions, setPlanOptions] = useState<any[]>([])
	const planValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.plan`))

	const [planStateOptions, setPlanStateOptions] = useState<any[]>([])
	const planStateValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.planState`))

	const projectOptions = map(projectsSider?.originalData, (project) => ({
		label: project.name,
		key: project.id,
		value: project.id
	}))

	useEffect(() => {
		const currProj = find(projectsSider?.originalData, (project) => project.id === projectValue?.value)
		if (currProj) {
			const newFolderOptions = map(currProj?.folders, (folder) => ({
				label: folder.name,
				key: folder.id,
				value: folder.id
			}))
			setFoldersOptions(newFolderOptions)
			if (folderValue && !find(newFolderOptions, (option) => folderValue.value === option.value)) {
				dispatch(change(form, `${name}.folder`, null))
				dispatch(change(form, `${name}.subFolder`, null))
				dispatch(change(form, `${name}.plan`, null))
				dispatch(change(form, `${name}.planState`, null))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectValue, projectsSider?.originalData])

	useEffect(() => {
		if (!projectValue) {
			dispatch(change(form, `${name}.folder`, null))
			dispatch(change(form, `${name}.subFolder`, null))
			dispatch(change(form, `${name}.plan`, null))
			dispatch(change(form, `${name}.planState`, null))
		} else if (!folderValue) {
			dispatch(change(form, `${name}.subFolder`, null))
			dispatch(change(form, `${name}.plan`, null))
			dispatch(change(form, `${name}.planState`, null))
		} else {
			const currProj = find(projectsSider?.originalData, (project) => project.id === projectValue?.value)
			if (currProj) {
				const currFolder = find(currProj?.folders, (folder) => folder.id === folderValue?.value)
				if (currFolder) {
					const newSubFolderOptions = map(currFolder?.folders, (folder) => ({
						label: folder.name,
						key: folder.id,
						value: folder.id
					}))
					setSubFoldersOptions(newSubFolderOptions)
					if (subFolderValue && !find(newSubFolderOptions, (option) => subFolderValue.value === option.value)) {
						dispatch(change(form, `${name}.subFolder`, null))
						dispatch(change(form, `${name}.plan`, null))
						dispatch(change(form, `${name}.planState`, null))
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectsSider?.originalData, projectValue, folderValue])

	useEffect(() => {
		const getPlanOptions = async (folderID: number) => {
			const data = await getReq('/api/v1/plans/', { folderID, limit: 1000 })
			const newPlanOptions = map(data?.data?.plans, (plan) => ({
				label: plan.name,
				key: plan.id,
				value: plan.id
			}))
			setPlanOptions(newPlanOptions)
			if (planValue && !find(newPlanOptions, (option) => planValue.value === option.value)) {
				dispatch(change(form, `${name}.plan`, null))
				dispatch(change(form, `${name}.planState`, null))
			}
			if (planValue) {
				const currPlan = find(data?.data?.plans, (plan) => plan.id === planValue?.value)
				if (currPlan) {
					const newPlanStateOptions = map(currPlan?.planStates, (planStates) => ({
						label: planStates?.name,
						key: planStates?.id,
						value: planStates?.id
					}))

					setPlanStateOptions(newPlanStateOptions)
				}
			}
		}

		if (subFolderValue) getPlanOptions(subFolderValue?.value)
		else if (folderValue) getPlanOptions(folderValue?.value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folderValue, subFolderValue, planValue])

	const deletePathField = () => {
		const index = parseInt(name.substring(name.indexOf('[') + 1, name.lastIndexOf(']')), 10)
		dispatch(arrayRemove(form, 'unnassignedPlanStates', index))
	}

	return (
		<>
			<div className={'flex flex-row-reverse'}>
				<Button type={'text'} danger className={'extd-btn'} style={{ width: '104px' }} icon={<DeleteIcon />} onClick={deletePathField}>
					{t('loc:Remove')}
				</Button>
			</div>
			<div className={'flex flex-row items-center'} style={{ marginTop: '-36px' }}>
				<div>{planStateValue ? <DoneIcon className={'mr-2 ml-2'} /> : <WarningIcon className={'mr-2 ml-2'} style={{ fill: 'green' }} />}</div>
				<div className={'w-40'}>
					<Field
						name={`${name}.project`}
						component={SelectField}
						allowClear={true}
						placeholder={t('loc:Choose project')}
						label={t('loc:Project')}
						labelInValue
						options={projectOptions}
					/>
				</div>
				{projectValue && (
					<>
						<ArrowIcon />
						<div className={'w-40'}>
							<Field
								name={`${name}.folder`}
								component={SelectField}
								allowClear={true}
								placeholder={t('loc:Choose folder')}
								label={t('loc:Folder')}
								labelInValue
								options={foldersOptions}
							/>
						</div>
					</>
				)}
				{folderValue && subFoldersOptions?.length !== 0 && (
					<>
						<ArrowIcon />
						<div className={'w-40'}>
							<Field
								name={`${name}.subFolder`}
								label={t('loc:Subfolder')}
								placeholder={t('loc:Choose subfolder')}
								component={SelectField}
								allowClear={true}
								labelInValue
								options={subFoldersOptions}
							/>
						</div>
					</>
				)}
				{folderValue && (
					<>
						<ArrowIcon />
						<div className={'w-80'}>
							<Field
								name={`${name}.plan`}
								allowClear={true}
								label={t('loc:Plan')}
								placeholder={t('loc:Choose plan')}
								component={SelectField}
								showSearch
								filterOption={selectFieldFilterOption}
								labelInValue
								options={planOptions}
							/>
						</div>
					</>
				)}
				{planValue && (
					<>
						<ArrowIcon />
						<div className={'w-40'}>
							<Field
								name={`${name}.planState`}
								label={t('loc:PlanState')}
								placeholder={t('loc:Choose plan state')}
								component={SelectField}
								allowClear={true}
								labelInValue
								options={planStateOptions}
							/>
						</div>
					</>
				)}
			</div>
			<span className={'text-error-medium cursor-default'} style={{ position: 'relative', top: '-16px', zIndex: '150' }}>
				{getErrorMessage()}
			</span>
		</>
	)
}

type UnnasignedPlanStateProps = WrappedFieldArrayProps<UnnasignedPlanStateProps>

const UnnasignedPlanStateField = (param: UnnasignedPlanStateProps) => {
	const { t } = useTranslation()

	if (!param?.fields?.length) {
		return <div />
	}

	return (
		<div className={'mb-6 p-4 bg-white'}>
			<div className={'flex flex-row justify-between'}>
				<div className={'flex flex-col cursor-default mb-4'}>
					<span className={'heading-4 mb-2'}>{t('loc:Unnassigned documents')}</span>
					<span>{t('loc:Specify final destination for documents.')}</span>
				</div>
			</div>
			{param?.fields?.map((name, index) => {
				return (
					<div className={'mb-4'} key={index}>
						<Field name={`${name}.file`} component={FileField as any} />
						<Field name={`${name}.route` as string} component={PathField as any} />
					</div>
				)
			})}
		</div>
	)
}

const UnnasignedPlanStateFieldArray = () => {
	return <FieldArray name={'unnassignedPlanStates'} component={UnnasignedPlanStateField as any} />
}

export default UnnasignedPlanStateFieldArray
