import React, { useEffect } from 'react'
import { Row, Col, Layout } from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParams } from 'use-query-params'
import MainFolderCreateModal from './components/Modals/MainFolderCreateModal'
import SubFolderCreateModal from './components/Modals/SubFolderCreateModal'
import BreadCrumbs from '../../components/Breadcrumbs'
import { SIDEBAR_WIDTH, TOP_MENU_ITEM, TREE_NODE_TYPE } from '../../utils/enums'
import ProjectsSider from '../../layouts/components/ProjectsSider'
import ProjectsTable from './Projects/ProjectsTable'
import FoldersTable from './Folders/FoldersTable'
import PlansTable from './Plans/PlansTable'
import { RootState } from '../../reducers'
import UsersTable from './Users/UsersTable'
import PlanCreateModal from './components/Modals/PlanCreateModal'
import PlanEditModal from './components/Modals/PlanEditModal'
import ActivitiesTable from '../ActivitiesPage/ActivitiesTable'
import { setTopMenu } from '../../reducers/menu/menuActions'

const { Sider, Content } = Layout

const HomePage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const menu = useSelector((state: RootState) => state?.menuStore)
	const location = useLocation()

	const [, setQuery] = useQueryParams({
		topMenu: StringParam
	})

	const modals = (
		<>
			<MainFolderCreateModal />
			<SubFolderCreateModal />
			<PlanCreateModal />
			<PlanEditModal />
		</>
	)

	useEffect(() => {
		if (location.pathname === t('paths:activities') || location.pathname === `${t('paths:activities')}/`) {
			dispatch(setTopMenu(TOP_MENU_ITEM.ACTIVITIES))
			setQuery({ topMenu: TOP_MENU_ITEM.ACTIVITIES })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const renderTable = () => {
		if (menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.TEAMMATES) {
			return <UsersTable />
		}
		if (menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.ACTIVITIES) {
			return <ActivitiesTable />
		}
		if (menu?.path?.selectedTreeNodeKey?.includes('PROJECT')) {
			if (menu?.path?.project?.ID) {
				return <FoldersTable />
			}
		}
		if (menu?.path?.selectedTreeNodeKey?.includes('MAIN-FOLDER')) {
			if (menu?.path?.folder?.ID && menu?.path?.project?.ID) {
				return <PlansTable folderID={menu?.path?.folder?.ID} projectID={menu?.path?.project?.ID} type={TREE_NODE_TYPE.MAIN_FOLDER} />
			}
		}
		if (menu?.path?.selectedTreeNodeKey?.includes('SUB-FOLDER')) {
			if (menu?.path?.subFolder?.ID && menu?.path?.project?.ID) {
				return <PlansTable folderID={menu?.path?.subFolder?.ID} projectID={menu?.path?.project?.ID} type={TREE_NODE_TYPE.SUB_FOLDER} />
			}
		}
		return <ProjectsTable />
	}

	return (
		<>
			<Sider width={SIDEBAR_WIDTH}>
				<div className={'extd-projects-sider-scroll'}>
					<ProjectsSider />
				</div>
			</Sider>
			<Content className={'bg-white'}>
				<div className={'content-body px-4'}>
					<Row>
						<Col>{menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.FILES ? <BreadCrumbs /> : null}</Col>
					</Row>
					{renderTable()}
					{modals}
				</div>
			</Content>
		</>
	)
}

export default HomePage
