import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'

import InputField from '../../../atoms/InputField'
import { FORM } from '../../../utils/enums'

// eslint-disable-next-line import/no-cycle
import validate from './validateSubFolderCreateForm'

export interface ISubFolderCreateForm {
	opened?: {
		mainFolderID: number
		projectID: number
		projectName: string
	} | null
	name: string
}

type ComponentProps = {}

type Props = InjectedFormProps<ISubFolderCreateForm, ComponentProps> & ComponentProps

const SubFolderCreateForm = (props: Props) => {
	const { handleSubmit } = props
	const { t } = useTranslation()

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Field name={'name'} component={InputField} label={t('loc:Sub-folder name')} placeholder={t('loc:Enter sub-folder name')} required />
		</Form>
	)
}

const form = reduxForm<ISubFolderCreateForm, ComponentProps>({
	form: FORM.SUB_FOLDER_CREATE_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(SubFolderCreateForm)

export default form
