import React from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import { map } from 'lodash'
import { DownOutlined } from '@ant-design/icons'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { ReactComponent as ArrowIcon } from '../assets/icons/icon-breadcrumb-arrow.svg'
import { RootState } from '../reducers'
import { TOP_MENU_ITEM } from '../utils/enums'
import { clearProjectSider, setProjectSiderToFolder, setProjectSiderToPlanStateDocument, setProjectSiderToProject, setProjectSiderToSubFolder } from '../reducers/menu/menuActions'

const BreadCrumbs = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam
	})

	const menu = useSelector((state: RootState) => state?.menuStore)
	const teammatesTabSelected = menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.TEAMMATES

	const handleClickAll = () => {
		dispatch(clearProjectSider())
		setQuery({ projectID: undefined, projectName: undefined, folderID: undefined, folderName: undefined, subFolderID: undefined, subFolderName: undefined })
	}

	const handleClickProject = () => {
		if (menu.path.project?.ID && menu.path.project?.name) {
			dispatch(setProjectSiderToProject(menu.path.project?.ID, menu.path.project?.name))
			setQuery({
				projectID: menu.path.project?.ID,
				projectName: menu.path.project?.name,
				folderID: undefined,
				folderName: undefined,
				subFolderID: undefined,
				subFolderName: undefined,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		}
	}

	const handleClickFolder = () => {
		if (menu.path.folder?.ID && menu.path.folder.name && menu.path.project?.ID && menu.path.project.name) {
			dispatch(setProjectSiderToFolder(menu.path.project?.ID, menu.path.project.name, menu.path.folder?.ID, menu.path.folder.name))
			setQuery({
				projectID: menu.path.project?.ID,
				projectName: menu.path.project.name,
				folderID: menu.path.folder?.ID,
				folderName: menu.path.folder.name,
				subFolderID: undefined,
				subFolderName: undefined,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		}
	}

	const handleClickSubfolder = () => {
		if (menu?.path.project?.ID && menu?.path.project.name && menu?.path.folder?.ID && menu?.path.folder?.name && menu?.path.subFolder?.ID && menu?.path.subFolder?.name) {
			dispatch(
				setProjectSiderToSubFolder(
					menu?.path.project?.ID,
					menu?.path.project?.name,
					menu?.path.folder?.ID,
					menu?.path.folder?.name,
					menu?.path.subFolder?.ID,
					menu?.path.subFolder?.name
				)
			)
			setQuery({
				projectID: menu?.path.project?.ID,
				projectName: menu?.path.project?.name,
				folderID: menu?.path.folder?.ID,
				folderName: menu?.path.folder?.name,
				subFolderID: menu?.path.subFolder?.ID,
				subFolderName: menu?.path.subFolder?.name,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		}
	}

	const handlePlanStateClick = (option: any) => {
		if (menu?.path.planStateDocument?.planStateOptions) {
			const oldPlanStateOptions = menu?.path.planStateDocument?.planStateOptions
			oldPlanStateOptions?.push(menu?.path.planStateDocument?.defaultOption)

			const defaultOption = {
				key: option?.key,
				label: option?.label
			}

			const planStateOptions = oldPlanStateOptions?.filter((item: any) => item?.key !== option?.key)

			dispatch(
				setProjectSiderToPlanStateDocument({
					planStateOptions,
					defaultOption,
					planName: menu?.path.planStateDocument?.planName,
					planID: menu?.path?.planStateDocument?.planID
				})
			)
			setQuery({
				planID: menu?.path?.planStateDocument?.planID,
				planName: menu?.path.planStateDocument?.planName,
				planStateID: defaultOption?.key,
				planStateName: defaultOption?.label
			})
		}
	}

	const dropdownMenu = (
		<Menu>
			{map(menu?.path.planStateDocument?.planStateOptions, (option) => (
				<Menu.Item key={option?.key} onClick={() => handlePlanStateClick(option)}>
					{option?.label}
				</Menu.Item>
			))}
		</Menu>
	)

	const renderBreadcrumbs = () => {
		return (
			<div className={'flex flex-row items-center mt-0 mb-0'}>
				<Button type={'text'} className={cx('extd-btn', { 'bg-blue-100 text-blue-600': !menu.path?.project && !teammatesTabSelected })} onClick={handleClickAll}>
					{t('loc:All')}
				</Button>
				{teammatesTabSelected && (
					<div className={'flex flex-row items-center mt-0 mb-0'}>
						<ArrowIcon />
						<Button type={'text'} className={'extd-btn bg-blue-100 text-blue-600'} disabled>
							{t('loc:Teammates')}
						</Button>
					</div>
				)}
				{menu.path?.project && !teammatesTabSelected && (
					<div className={'flex flex-row items-center mt-0 mb-0'}>
						<ArrowIcon />
						<Button
							type={'text'}
							className={cx('extd-btn', { 'bg-blue-100 text-blue-600': !menu.path?.folder })}
							disabled={!menu.path?.folder}
							onClick={handleClickProject}
						>
							{menu.path?.project?.name}
						</Button>
					</div>
				)}
				{menu.path?.folder && !teammatesTabSelected && (
					<div className={'flex flex-row items-center mt-0 mb-0'}>
						<ArrowIcon />
						<Button
							type={'text'}
							className={cx('extd-btn', { 'bg-blue-100 text-blue-600': !menu.path?.subFolder && !menu.path?.planStateDocument })}
							disabled={!menu.path?.subFolder && !menu.path?.planStateDocument}
							onClick={handleClickFolder}
						>
							{menu.path?.folder?.name}
						</Button>
					</div>
				)}
				{menu.path?.subFolder && !teammatesTabSelected && (
					<div className={'flex flex-row items-center mt-0 mb-0'}>
						<ArrowIcon />
						<Button
							type={'text'}
							className={cx('extd-btn', { 'bg-blue-100 text-blue-600': !menu.path?.planStateDocument })}
							disabled={!menu.path?.planStateDocument}
							onClick={handleClickSubfolder}
						>
							{menu.path?.subFolder.name}
						</Button>
					</div>
				)}
				{menu.path?.planStateDocument && !teammatesTabSelected && (
					<div className={'flex flex-row items-center mt-0 mb-0'}>
						<ArrowIcon />
						<Dropdown overlay={dropdownMenu} className={'bg-blue-100 text-blue-600 p-2'}>
							<div className={'cursor-pointer'}>
								<span>{`${menu.path?.planStateDocument?.planName}: ${menu.path?.planStateDocument?.defaultOption?.label}`}</span>
								<DownOutlined className='ml-2' />
							</div>
						</Dropdown>
					</div>
				)}
			</div>
		)
	}

	return renderBreadcrumbs()
}

export default BreadCrumbs
