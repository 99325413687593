import React, { Suspense, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Router, Route } from 'react-router'
import { I18nextProvider } from 'react-i18next'
import { PersistGate } from 'redux-persist/es/integration/react'
import { QueryParamProvider, ExtendedStringifyOptions, transformSearchStringJsonSafe } from 'use-query-params'

// instead of antd.css to avoid module warnings from antd. https://github.com/ant-design/ant-design/issues/33327
import 'antd/dist/antd.min.css'
import { ConfigProvider, Spin } from 'antd'

import { Locale } from 'antd/lib/locale-provider'
import dayjs from 'dayjs'
import en_GB from 'antd/lib/locale-provider/en_GB'
import de_DE from 'antd/lib/locale-provider/de_DE'
import Routes from './routes/Routes'

import rootReducer from './reducers'

// utils
import configureStore from './utils/configureStore'
import i18n from './utils/i18n'
import { history } from './utils/history'
import { APP_LANGUAGE } from './utils/enums'

const queryStringifyOptions: ExtendedStringifyOptions = {
	transformSearchString: transformSearchStringJsonSafe
}

const { store, persistor } = configureStore(rootReducer)

const App = () => {
	const [antdLocale, setAntdLocale] = useState<Locale | undefined>(undefined)

	useEffect(() => {
		i18n.on('languageChanged', (language) => {
			document.documentElement.setAttribute('lang', language)
			setAntdLocale(language === APP_LANGUAGE.EN ? en_GB : de_DE)
			dayjs.locale(language)
		})
	}, [])

	return (
		<Suspense
			fallback={
				<div className={'suspense-loading-spinner'}>
					<Spin />
				</div>
			}
		>
			<I18nextProvider i18n={i18n}>
				<PersistGate
					loading={
						<div className={'suspense-loading-spinner'}>
							<Spin />
						</div>
					}
					persistor={persistor}
				>
					<ConfigProvider locale={antdLocale}>
						<Provider store={store}>
							<Router history={history}>
								<QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
									<Routes />
								</QueryParamProvider>
							</Router>
						</Provider>
					</ConfigProvider>
				</PersistGate>
			</I18nextProvider>
		</Suspense>
	)
}

export default App
