// eslint-disable-next-line import/no-cycle
import i18next from 'i18next'
import { FormErrors } from 'redux-form'
// eslint-disable-next-line import/no-cycle
import { IChangePasswordForm } from './ChangePasswordForm'

/**
 * @param {IChangePasswordForm}
 */
export default (values: any) => {
	const errors: FormErrors<IChangePasswordForm> = {}

	if (!values?.actualPassword) {
		errors.actualPassword = i18next.t('loc:This field is required')
	}

	if (!values?.newPassword) {
		errors.newPassword = i18next.t('loc:This field is required')
	}

	if (!values?.repeatedPassword) {
		errors.repeatedPassword = i18next.t('loc:This field is required')
	}

	if (values?.newPassword && values?.repeatedPassword && values.newPassword !== values.repeatedPassword) {
		errors.repeatedPassword = i18next.t('loc:Passwords do not match')
	}

	return errors
}
