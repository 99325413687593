import { getAccessToken } from './auth'

class ViewSDKClient {
	private adobeDCView: any

	private readonly readyPromise: Promise<void>

	constructor() {
		this.readyPromise = new Promise<void>((resolve) => {
			if ((window as any).AdobeDC) {
				resolve()
			} else {
				document.addEventListener('adobe_dc_view_sdk.ready', () => {
					resolve()
				})
			}
		})
		this.adobeDCView = undefined
	}

	ready() {
		return this.readyPromise
	}

	// eslint-disable-next-line class-methods-use-this
	composeFileUrl(fileID: number) {
		return `/api/v1/documents/${fileID}?t=${getAccessToken()}`
	}

	previewFile(url: string, filename: string, viewerConfig: Object) {
		const config = {
			// eslint-disable-next-line no-underscore-dangle
			clientId: window?._env_?.REACT_APP_ADOBE_ID ? window._env_.REACT_APP_ADOBE_ID : process.env.REACT_APP_ADOBE_ID
		}

		this.adobeDCView = new (window as any).AdobeDC.View(config)

		return this.adobeDCView.previewFile(
			{
				content: {
					location: {
						url,
						headers: [
							{
								key: 'Authorization',
								value: `Bearer ${getAccessToken()}`
							}
						]
					}
				},
				metaData: {
					fileName: filename || 'File.pdf'
				}
			},
			viewerConfig
		)
	}
}

export default ViewSDKClient
