import React from 'react'
import { Modal, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { change, formValueSelector, isSubmitting, submit } from 'redux-form'

import { ReactComponent as ProjectIcon } from '../../../../assets/icons/icon-project.svg'
import SubFolderCreateForm, { ISubFolderCreateForm } from '../SubFolderCreateForm'

import { RootState } from '../../../../reducers'
import { getProjectsSider } from '../../../../reducers/projects/projectsActions'
import { FORM } from '../../../../utils/enums'
import { postReq } from '../../../../utils/requests'

const form = FORM.SUB_FOLDER_CREATE_FORM
const selector = formValueSelector(form)

const SubFolderCreateModal = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const opened = useSelector((state: RootState) => selector(state, 'opened'))
	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))

	const handleSubmit = async (values: ISubFolderCreateForm) => {
		try {
			if (values?.opened?.projectID) {
				const reqBody = {
					projectID: values?.opened?.projectID,
					parentID: values?.opened?.mainFolderID,
					name: values.name
				}
				await postReq('/api/v1/folders/', undefined, reqBody)
				dispatch(change(form, 'opened', null))
				dispatch(getProjectsSider())
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	return (
		<Modal
			title={
				<Space className={'cursor-default'}>
					{t('loc:Create sub-folder')}
					<div className='flex items-center text-m-medium text-zinc-600'>
						<ProjectIcon />
						<Typography.Text className='ml-1' ellipsis style={{ maxWidth: 300 }}>
							{opened?.projectName || '-'}
						</Typography.Text>
					</div>
				</Space>
			}
			okText={t('loc:Create sub-folder')}
			onOk={() => dispatch(submit(form))}
			okButtonProps={{
				loading: submitting,
				disabled: submitting
			}}
			cancelText={t('loc:Cancel')}
			onCancel={() => dispatch(change(form, 'opened', null))}
			getContainer={() => document.body}
			destroyOnClose
			visible={!!opened}
		>
			{!!opened && <SubFolderCreateForm onSubmit={handleSubmit} />}
		</Modal>
	)
}

export default SubFolderCreateModal
