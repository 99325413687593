import React, { useEffect, useMemo, useState } from 'react'
import { Button, Dropdown, Menu, Typography, Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import { DownloadOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { renderName } from '../../pages/HomePage/PlanStates/PlanStatesTable'
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-breadcrumb-arrow.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close-red.svg'
// TODO Project icon used as plans icon. not sure if cool or na.
import { ReactComponent as ProjectIcon } from '../../assets/icons/icon-input-project.svg'
import { ReactComponent as ErrorIcon } from '../../assets/icons/icon-notification-error.svg'
import { RootState } from '../../reducers'
import { getAccessToken } from '../../utils/auth'
import showNotifications from '../../utils/tsxHelpers'
import { MSG_TYPE, NOTIFICATION_TYPE, TOP_MENU_ITEM } from '../../utils/enums'
import { clearDownloadList, IDownloadItem, setDownloadList, File } from '../../reducers/dowload/downloadActions'

type PlanState = {
	ID: number
	name: string
}

type Plan = {
	ID: number
	name: string
	planStates: PlanState[]
}

type Path = {
	project: {
		ID: number
		name: string
	}
	folder: {
		ID: number
		name: string
	}
	subFolder?: {
		ID: number
		name: string
	}
	plans: Plan[]
}

type DownloadProps = {
	downloadData:
		| {
				documents: number[]
				withStructure: boolean
		  }
		| undefined
	setDownloadData: (
		value: React.SetStateAction<
			| {
					documents: number[]
					withStructure: boolean
			  }
			| undefined
		>
	) => void
	downloadState: { progress: number; failed: boolean }
	setDownloadState: (value: React.SetStateAction<{ progress: number; failed: boolean }>) => void
}

const filterDownloadList = (downloadList: IDownloadItem[]) => {
	const filteredDownloadList = downloadList?.filter((item) => !!item?.file?.ID)

	return filteredDownloadList
}

const DownloadOverlay = (props: DownloadProps) => {
	const { downloadState, setDownloadState, downloadData, setDownloadData } = props
	const { t } = useTranslation()

	const preparingState = (
		<div className={'download-overlay'}>
			<Progress className={'mb-4'} strokeWidth={15} strokeColor={'#2F54EB'} width={60} type={'circle'} percent={downloadState.progress} />
			<span className={'text-blue-600 mb-4'}>{t('loc:Preparing download...')}</span>
		</div>
	)

	const failedState = (
		<div className={'download-overlay'}>
			<Progress strokeWidth={15} width={60} type={'circle'} percent={0} showInfo={false} />
			<ErrorIcon style={{ position: 'relative', top: '-40px' }} />
			<span className={'text-error-medium mb-4'}>{t('loc:Preparation failed')}</span>
			<Button
				type={'primary'}
				className={'extd-btn mb-4'}
				onClick={() => {
					setDownloadData({ documents: downloadData?.documents || [], withStructure: downloadData?.withStructure || false })
					setDownloadState({ progress: 0, failed: false })
				}}
			>
				{t('loc:Retry download')}
			</Button>
			<Button
				type={'text'}
				className={'extd-btn'}
				onClick={() => {
					setDownloadState({ progress: 0, failed: false })
					setDownloadData(undefined)
				}}
			>
				<span className={'text-blue-600'}>{t('loc:Cancel')}</span>
			</Button>
		</div>
	)

	if (downloadState.failed) {
		return failedState
	}
	return preparingState
}

type CheckedFilesProps = {
	downloadData:
		| {
				documents: number[]
				withStructure: boolean
		  }
		| undefined
	setDownloadData: (
		value: React.SetStateAction<
			| {
					documents: number[]
					withStructure: boolean
			  }
			| undefined
		>
	) => void
	downloadState: { progress: number; failed: boolean }
	setDownloadState: (value: React.SetStateAction<{ progress: number; failed: boolean }>) => void
}

const CheckedFiles = (props: CheckedFilesProps) => {
	const { downloadData, setDownloadData, downloadState, setDownloadState } = props
	const { t } = useTranslation()
	const [opacity, setOpacity] = useState(0)

	const dispatch = useDispatch()
	const downloadList: IDownloadItem[] = filterDownloadList(useSelector((state: RootState) => state?.downloadStore?.download?.data))

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1)
		}, 300)
	}, [])

	// TODO export, will be used to ZIP downloads aswell.
	const handleDownload = (withStructure: boolean) => {
		const fileIds: number[] = []

		downloadList?.forEach((item) => {
			if (item?.file?.ID) {
				fileIds.push(item?.file?.ID)
			}
		})

		setDownloadData({ documents: fileIds, withStructure })
	}

	const downloadMenu = (
		<Menu>
			<Menu.Item key={'FolderDownload'} disabled={!!downloadData?.documents?.length} onClick={() => handleDownload(false)}>
				{t('loc:Download in one folder')}
			</Menu.Item>
			<Menu.Item key={'Download'} disabled={!!downloadData?.documents?.length} onClick={() => handleDownload(true)}>
				{t('loc:Download with structure')}
			</Menu.Item>
		</Menu>
	)

	const deleteFile = (fileID: number | undefined) => {
		const newDownloadList: IDownloadItem[] = downloadList?.filter((item) => item?.file?.ID !== fileID)

		dispatch(setDownloadList(newDownloadList))
	}

	const deletePlanState = (planStateID: number) => {
		const newDownloadList: IDownloadItem[] = downloadList?.filter((item) => item?.planState?.ID !== planStateID)

		dispatch(setDownloadList(newDownloadList))
	}

	const deletePlan = (planID: number) => {
		const newDownloadList: IDownloadItem[] = downloadList?.filter((item) => item?.plan?.ID !== planID)

		dispatch(setDownloadList(newDownloadList))
	}

	const renderFiles = (planStateID: number) => {
		const files: (File | undefined)[] = downloadList
			?.filter((item) => item?.planState?.ID === planStateID)
			?.map((filteredItem) => {
				return filteredItem?.file
			})

		return files?.map((file) => (
			<div className={'flex flex-row justify-between'} style={{ width: '100%', padding: '4px 6px 0px 42px' }}>
				{renderName(file?.name, file?.mimeType, 246)}
				<Button type={'text'} className={'w-6 h-6 p-0'} style={{ right: '-4px' }} onClick={() => deleteFile(file?.ID)}>
					<CloseIcon style={{ position: 'absolute', top: '0px', left: '0px' }} />
				</Button>
			</div>
		))
	}

	const renderPlanStates = (planStates: PlanState[]) => {
		const filteredPlanStates = planStates?.filter((value, index, self) => index === self.findIndex((selfItem) => selfItem?.ID === value?.ID))
		return filteredPlanStates?.map((planState) => (
			<>
				<div className={'flex flex-row justify-between'} style={{ width: '100%', padding: '4px 6px 0px 24px' }}>
					<Typography.Text className={'text-m-bold'} style={{ maxWidth: '280px' }} ellipsis>
						{planState.name}
					</Typography.Text>
					<Button type={'text'} className={'w-6 h-6 p-0'} style={{ right: '-4px' }} onClick={() => deletePlanState(planState?.ID)}>
						<CloseIcon style={{ position: 'absolute', top: '0px', left: '0px' }} />
					</Button>
				</div>
				{renderFiles(planState?.ID)}
			</>
		))
	}

	const renderPlans = (path: Path) => {
		return path?.plans?.map((plan) => (
			<>
				<div className={'flex flex-row justify-between'} style={{ width: '100%', padding: '4px 6px' }}>
					<div className={'flex flex-row items-center'}>
						<ProjectIcon />
						<Typography.Text style={{ maxWidth: '280px' }} ellipsis>
							{plan.name}
						</Typography.Text>
					</div>
					<Button type={'text'} className={'w-6 h-6 p-0'} style={{ right: '-4px' }} onClick={() => deletePlan(plan.ID)}>
						<CloseIcon style={{ position: 'absolute', top: '0px', left: '0px' }} />
					</Button>
				</div>
				{renderPlanStates(plan?.planStates)}
			</>
		))
	}

	const renderPath = () => {
		const renderArray: Path[] = []

		const filterSubFolderPlans = (item: IDownloadItem): Plan[] => {
			const filteredPlans: Plan[] = []

			const filteredDownloadList = downloadList?.filter((filterItem) => filterItem?.subFolder?.ID === item?.subFolder?.ID)

			filteredDownloadList?.forEach((filteredPlanState) => {
				if (!filteredPlans?.find((plan) => plan?.ID === filteredPlanState?.plan?.ID)) {
					filteredPlans.push({
						ID: filteredPlanState?.plan?.ID || 0,
						name: filteredPlanState?.plan?.name || '',
						planStates: downloadList
							?.filter((filterPlan) => filterPlan?.plan?.ID === filteredPlanState?.plan?.ID)
							?.map((filterPlanState) => {
								return filterPlanState?.planState
							}) as any
					})
				}
			})

			return filteredPlans
		}

		const filterFolderPlans = (item: IDownloadItem): Plan[] => {
			const filteredPlans: Plan[] = []

			const filteredDownloadList = downloadList?.filter((filterItem) => filterItem?.folder?.ID === item?.folder?.ID && !filterItem?.subFolder)

			filteredDownloadList?.forEach((filteredPlanState) => {
				if (!filteredPlans?.find((plan) => plan?.ID === filteredPlanState?.plan?.ID)) {
					filteredPlans.push({
						ID: filteredPlanState?.plan?.ID || 0,
						name: filteredPlanState?.plan?.name || '',
						planStates: downloadList
							?.filter((filterPlan) => filterPlan?.plan?.ID === filteredPlanState?.plan?.ID)
							?.map((filterPlanState) => {
								return filterPlanState?.planState
							}) as any
					})
				}
			})

			return filteredPlans
		}

		downloadList?.forEach((item) => {
			if (item?.subFolder?.ID && !renderArray?.find((includedItem) => includedItem?.subFolder?.ID === item?.subFolder?.ID)) {
				const newPath: Path = {
					project: {
						ID: item.project.ID,
						name: item.project.name
					},
					folder: {
						ID: item.folder.ID,
						name: item.folder.name
					},
					subFolder: {
						ID: item?.subFolder?.ID,
						name: item?.subFolder?.name
					},
					plans: filterSubFolderPlans(item)
				}
				renderArray.push(newPath)
			} else if (!renderArray?.find((includedItem) => includedItem?.folder?.ID === item?.folder?.ID)) {
				const newPath: Path = {
					project: {
						ID: item.project.ID,
						name: item.project.name
					},
					folder: {
						ID: item.folder.ID,
						name: item.folder.name
					},
					plans: filterFolderPlans(item)
				}
				renderArray.push(newPath)
			}
		})

		return renderArray?.map((item) => (
			<>
				<div style={{ width: '100%', padding: '4px 6px' }} className={'border-r-4 bg-blue-100 flex flex-row items-center mt-0 mb-0'}>
					<Typography.Text style={{ maxWidth: '100px' }} ellipsis>
						{item?.project?.name}
					</Typography.Text>
					<ArrowIcon />
					<Typography.Text style={{ maxWidth: '100px' }} ellipsis>
						{item?.folder?.name}
					</Typography.Text>
					{item?.subFolder?.name && (
						<>
							<ArrowIcon />
							<Typography.Text style={{ maxWidth: '100px' }} ellipsis>
								{item.subFolder?.name}
							</Typography.Text>
						</>
					)}
				</div>
				{renderPlans(item)}
			</>
		))
	}

	return (
		<>
			<div className={'flex flex-col justify-between p-4'} style={{ height: '100%', overflowX: 'auto' }}>
				<div className={'flex flex-col'} style={{ marginBottom: '60px' }}>
					{renderPath()}
				</div>
				<div className={'flex flex-row justify-between h-14 bg-white'} style={{ position: 'absolute', bottom: '0px', opacity: `${opacity}`, transition: 'opacity 0.3s' }}>
					<Button type={'default'} style={{ width: '169px' }} className={'extd-btn mr-4'} onClick={() => dispatch(clearDownloadList())} disabled={!downloadList.length}>
						{t('loc:Clear selection')}
					</Button>
					<Dropdown overlay={downloadMenu} disabled={!downloadList.length}>
						<Button type={'primary'} style={{ width: '169px' }} className={'extd-btn'} icon={<DownloadOutlined />} disabled={!downloadList.length}>
							{t('loc:Download')}
							<DownOutlined />
						</Button>
					</Dropdown>
				</div>
			</div>
			{(downloadState?.progress !== 0 || downloadState?.failed) && (
				<DownloadOverlay downloadState={downloadState} downloadData={downloadData} setDownloadState={setDownloadState} setDownloadData={setDownloadData} />
			)}
		</>
	)
}

type LoadingProps = {
	progress: number
}

const Loading = (props: LoadingProps) => {
	const { t } = useTranslation()
	const { progress } = props

	return (
		<>
			<Progress className={'mr-2'} strokeWidth={15} strokeColor={'#2F54EB'} type={'circle'} width={20} percent={progress} showInfo={false} />
			<span className={'text-blue-600'}>{t('loc:Preparing download...')}</span>
		</>
	)
}

const Error = () => {
	const { t } = useTranslation()

	return (
		<div className={'flex flex-row items-center'}>
			<ErrorIcon className={'mr-2'} />
			<span className={'text-error-medium'}>{t('loc:Preparation failed')}</span>
		</div>
	)
}

const DownloadWindow = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isOpen, setIsOpen] = useState(false)
	const menu = useSelector((state: RootState) => state?.menuStore)
	const showDownloadWindow = menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.FILES || menu?.TOP_MENU?.menuItem === null || menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.ACTIVITIES
	const downloadList: IDownloadItem[] = filterDownloadList(useSelector((state: RootState) => state.downloadStore.download.data))

	const [downloadData, setDownloadData] = useState<{ documents: number[]; withStructure: boolean } | undefined>(undefined)
	const [downloadState, setDownloadState] = useState<{ progress: number; failed: boolean }>({ progress: 0, failed: false })

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		let isDone = false

		const handleCollectiveDownload = async (urlData: string) => {
			const link = document.createElement('a')
			link.href = `${urlData}?t=${getAccessToken()}`
			link.setAttribute('download', urlData?.substring((urlData?.lastIndexOf('/') || 0) + 1, urlData.length + 1))
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

			dispatch(clearDownloadList())
			setDownloadData(undefined)
			setDownloadState({ progress: 0, failed: false })
		}

		if (downloadData) {
			const documentIds = [downloadData.documents.map((rowKey) => rowKey)].join()
			const documentIdsArg = documentIds.replaceAll(',', '&documentIDs=')
			const eventSourceUrl = `/api/v1/documents/download?withStructure=${downloadData.withStructure}&documentIDs=${documentIdsArg}&t=${getAccessToken()}`
			const sse = new EventSourcePolyfill(eventSourceUrl)
			const getRealTimeData = (data: any) => {
				if (data?.url) {
					if (!isDone) {
						handleCollectiveDownload(data?.url)
						isDone = true
						sse.close()
					}
				}
				if (data?.progress) {
					if (!isDone) {
						setDownloadState({ progress: data?.progress, failed: false })
					}
				}
				if (data?.error) {
					setDownloadState({ progress: 0, failed: true })
				}
				if (data?.warning) {
					showNotifications(
						[
							{
								type: MSG_TYPE.WARNING,
								message: data?.warning
							}
						],
						NOTIFICATION_TYPE.NOTIFICATION
					)
				}
			}

			sse.onmessage = (e: any) => getRealTimeData(JSON.parse(e.data))

			sse.onerror = (e: any) => {
				if (e?.status) {
					showNotifications(
						[
							{
								type: MSG_TYPE.ERROR,
								message: t('loc:Download failed, try again')
							}
						],
						NOTIFICATION_TYPE.NOTIFICATION
					)
					sse.close()
					setDownloadState({ progress: 0, failed: true })
				}
			}

			return () => {
				sse.close()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadData, t])

	const getButtonBottom = useMemo(() => {
		if (isOpen) {
			if (menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.ACTIVITIES) {
				return '406px'
			}
			return '476px'
		}
		if (menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.ACTIVITIES) {
			return '6px'
		}
		return '76px'
	}, [isOpen, menu?.TOP_MENU?.menuItem])

	return (
		<div style={{ display: `${showDownloadWindow ? '' : 'none'}` }}>
			<div
				className={'download-window'}
				style={{
					height: `${isOpen ? '400' : '0'}px`,
					bottom: `${menu?.TOP_MENU?.menuItem === TOP_MENU_ITEM.ACTIVITIES ? '6px' : '76px'}`
				}}
			>
				{isOpen && <CheckedFiles setDownloadData={setDownloadData} downloadData={downloadData} downloadState={downloadState} setDownloadState={setDownloadState} />}
			</div>
			<Button
				className={`extd-btn download  ${downloadList?.length > 0 ? 'bg-blue-300' : ''}`}
				style={{ bottom: getButtonBottom, transition: 'bottom 0.5s' }}
				type={'default'}
				onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
			>
				{/* to be in line with antd table headers */}
				<div className={'flex flex-row justify-between'} style={{ width: '90%' }}>
					<span className={'font-medium'} style={{ color: 'rgba(0,0,0,.85' }}>
						{t('loc:Download')}
					</span>
					{!downloadData && <span style={{ color: 'rgba(0,0,0,.85' }}>{t('loc:Selected files ({{count}})', { count: downloadList?.length })}</span>}
					{!!downloadData && !downloadState?.failed && <Loading progress={downloadState?.progress} />}
					{!!downloadData && downloadState?.failed && <Error />}
				</div>
				{isOpen ? <UpOutlined /> : <DownOutlined />}
			</Button>
		</div>
	)
}

export default DownloadWindow
