import React, { FC, useState } from 'react'
import qs from 'qs'
import i18next from 'i18next'
import { useDispatch } from 'react-redux'
import PasswordForm, { IPasswordForm } from './components/PasswordForm'
import CompanyForm, { ICompanyForm } from './components/CompanyForm'
import ProfileForm, { IProfileForm } from './components/ProfileForm'
import { NOTIFICATION_TYPE, ADMIN_CREATION_STEP } from '../../utils/enums'
import { postReq } from '../../utils/requests'
import { Paths } from '../../types/api'
import { setAccessToken, setRefreshToken } from '../../utils/auth'
import { history } from '../../utils/history'
import CompanyAddressForm, { ICompanyAddressForm } from './components/CompanyAddressForm'
import ContactPersonForm, { IContactPersonForm } from './components/ContactPersonForm'
import CompanyInfoForm, { ICompanyInfoForm } from './components/CompanyInfoForm'
import { reloadUserData } from '../../reducers/authUser/authUserActions'

type Props = {}

const AdminCreationPage: FC<Props> = () => {
	const [step, setStep] = useState<ADMIN_CREATION_STEP | undefined>()
	const dispatch = useDispatch()

	const handleSubmit = async (values: IPasswordForm & IProfileForm & ICompanyForm & ICompanyInfoForm & ICompanyAddressForm & IContactPersonForm) => {
		try {
			const { t: token } = qs.parse(document.location.search, { ignoreQueryPrefix: true })

			const headers = {
				Authorization: `Bearer ${token}`
			}

			const reqBody: Paths.PostApiV1UsersFirstConfirm.RequestBody = {
				password: values.password,
				name: values.name,
				surname: values.surname,
				phone: values.phone,
				company: values.company,
				note: values.note,
				companyRole: values.companyRole,
				companyAddress: {
					address: values.address,
					city: values.city,
					postalCode: values.postalCode,
					country: values.country
				},
				contactPerson: {
					name: values.contactPersonName,
					surname: values.contactPersonSurname,
					email: values.contactPersonEmail,
					phone: values.contactPersonPhone
				},
				companyWeb: values.companyWeb,
				companyNote: values.companyNote
			}

			const { data } = await postReq('/api/v1/users/first-confirm', undefined, reqBody, { headers }, NOTIFICATION_TYPE.NOTIFICATION, true)

			setAccessToken(data.accessToken)
			setRefreshToken(data.refreshToken)

			dispatch(reloadUserData(data?.user?.id))

			history.push(i18next.t('paths:index'))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
	}

	const renderCreationStep = () => {
		switch (step) {
			case ADMIN_CREATION_STEP.PASSWORD:
				return <PasswordForm changeStep={setStep} progress={0} onSubmit={() => setStep(ADMIN_CREATION_STEP.PROFILE)} />

			case ADMIN_CREATION_STEP.PROFILE:
				return <ProfileForm changeStep={setStep} progress={20} onSubmit={() => setStep(ADMIN_CREATION_STEP.COMPANY)} />

			case ADMIN_CREATION_STEP.COMPANY:
				return <CompanyForm changeStep={setStep} progress={40} onSubmit={() => setStep(ADMIN_CREATION_STEP.COMPANY_ADDRESS)} />

			case ADMIN_CREATION_STEP.COMPANY_ADDRESS:
				return <CompanyAddressForm changeStep={setStep} progress={60} onSubmit={() => setStep(ADMIN_CREATION_STEP.CONTACT_PERSON)} />

			case ADMIN_CREATION_STEP.CONTACT_PERSON:
				return <ContactPersonForm changeStep={setStep} progress={80} onSubmit={() => setStep(ADMIN_CREATION_STEP.COMPANY_INFO)} />

			case ADMIN_CREATION_STEP.COMPANY_INFO:
				return <CompanyInfoForm changeStep={setStep} progress={100} onSubmit={handleSubmit as any} />

			default:
				return <PasswordForm changeStep={setStep} progress={0} onSubmit={() => setStep(ADMIN_CREATION_STEP.PROFILE)} />
		}
	}

	return renderCreationStep()
}
export default AdminCreationPage
