/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PureComponent, ReactNode } from 'react'
import { FormAction, WrappedFieldProps } from 'redux-form'
import cx from 'classnames'
import { debounce, get, isArray, isEmpty, isString, map } from 'lodash'
import i18next from 'i18next'

// ant
import { Empty, Form, Select, Spin } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { FormItemProps } from 'antd/lib/form/FormItem'
import { createSlug, formFieldID } from '../utils/helpers'

const { Item } = Form
const { Option } = Select

export type Props = {
	update?: (value: any, ref: any) => FormAction
	allowInfinityScroll?: boolean
	/** Klúč podľa ktorého sa vytiahnu dáta v onSearch */
	dataSourcePath?: string
	/** propa urcena predovsetkym pre filtre, kedy mozeme skopirovat URL na novy TAB
	 *  propa zabezpeci spravne initializovanie z query filtra a formu filtra (forcne dotiahnutie options dat pre select)
	 *  posielat len vtedy ak mame v selecte search a dotahujeme vsetky data (spravidla vtedy ked nie je BE vyhladavanie, alebo neexistuje paginacia)
	 */
	onDidMountSearch?: boolean
	emptyText?: string
	itemRef?: any
	readOnly?: boolean
	onSelect?: (opt: any, option: any, value: any) => any
} & WrappedFieldProps &
	SelectProps<any> &
	FormItemProps

type IPagination = {
	limit: number
	page: number
	totalPages: number
	totalCount: number
}

export const selectFieldFilterOption = (inputValue: any, option: any) => createSlug(option.label.toLowerCase()).indexOf(createSlug(inputValue.toLowerCase())) >= 0

export default class SelectField extends PureComponent<Props> {
	// eslint-disable-next-line react/sort-comp, class-methods-use-this
	onBlur = () => {
		// NOTE: let the function empty
	}

	itemRef: any

	state = {
		data: [],
		fetching: false,
		searchValue: '',
		emptyText: null,
		pagination: null as IPagination | null
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSearchDebounced: (value: string, page?: number) => any

	constructor(props: Props) {
		super(props)
		this.itemRef = this.props.itemRef || React.createRef()

		this.onSearchDebounced = debounce(this.onSearch, 300)
		if (props.onDidMountSearch) {
			this.onSearch('', 1)
		}
	}

	renderDropdown = () => (menu: React.ReactElement) =>
		(
			// eslint-disable-next-line
			<Spin style={{ margin: '10px', justifyContent: 'flex-start' }} className={'flex-center'} tip={i18next.t('loc:Loading...')} spinning={this.state.fetching}>
				{menu}
			</Spin>
		)

	onSearch = async (value: string, page = 1) => {
		const onSearch = this.props.onSearch as any
		const { dataSourcePath = 'data', allowInfinityScroll } = this.props

		const { fetching } = this.state

		if (fetching) {
			return
		}

		if (onSearch) {
			try {
				const collectedData = page === 1 ? [] : this.state.data
				this.setState({ fetching: true, searchValue: value })

				const data: any = await onSearch(value, page)
				const dataOptions = get(data, dataSourcePath)

				if (data.pagination || dataOptions) {
					const mergedData = [...collectedData, ...dataOptions]
					this.setState({ data: mergedData, pagination: data.pagination, fetching: false })
				} else if (!allowInfinityScroll && isArray(data)) {
					// NOTE: Výsledky sa nedoliepajú
					this.setState({ data, fetching: false })
				} else {
					this.setState({
						data: [],
						pagination: null,
						fetching: false,
						searchValue: ''
					})
				}
				if (data.emptyText) {
					this.setState({
						emptyText: data.emptyText
					})
				}
			} catch (e) {
				this.setState({
					data: [],
					pagination: null,
					fetching: false,
					searchValue: ''
				})
			}
		}
	}

	onChange = async (value: any) => {
		const { input, update } = this.props
		if (input.onChange) {
			await input.onChange(value === undefined ? null : value)
			if (update) {
				// NOTE: update prop for onSelect and onDeselect submitting form (eg. setting Tags)
				update(value, this.itemRef.current)
			}
		}
	}

	onSelectWrap = (value: any, option: any) => {
		const { onSelect, input } = this.props
		if (onSelect) {
			onSelect(value, option, input.value)
		}
	}

	onDeselectWrap = (value: any, option: any) => {
		const { onDeselect } = this.props
		if (onDeselect) {
			onDeselect(value, option)
		}
	}

	onScroll = (e: any) => {
		const { fetching, searchValue, pagination } = this.state

		let hasMore = true
		let nextPage = 1
		if (pagination) {
			hasMore = pagination.page < pagination.totalPages
			nextPage = pagination.page + 1
		}
		if (Math.ceil(e.target.scrollTop + e.target.offsetHeight) >= e.target.scrollHeight && !fetching && hasMore) {
			this.onSearch(searchValue, nextPage)
		}
	}

	onDropdownVisibleChange = (isOpen: boolean) => {
		const { onSearch } = this.props
		if (isOpen && onSearch) {
			// NOTE: Po vyhladani, vybrani polozky a znovu otvoreni ostavali vo vysledkoch stare vyhladane vysledky, nie 1. strana zo vsetkych
			this.onSearch('', 1)
		}
	}

	onFocus = (e: any) => {
		const { input } = this.props

		if (input.onFocus) {
			input.onFocus(e)
		}
	}

	renderOption = (option: any) => (
		<Option key={option.key} value={option.key} label={option.label} disabled={option.disabled}>
			{option.label}
		</Option>
	)

	render() {
		const {
			input,
			size,
			placeholder,
			label,
			required,
			meta,
			options,
			loading,
			mode,
			tagRender,
			allowClear,
			style,
			showSearch,
			filterOption,
			labelInValue,
			disabled,
			notFoundContent,
			removeIcon,
			allowInfinityScroll,
			defaultValue,
			clearIcon,
			className,
			optionLabelProp,
			open,
			showArrow,
			dropdownClassName,
			dropdownStyle,
			dropdownMatchSelectWidth = true,
			listHeight,
			emptyText,
			bordered,
			autoClearSearchValue,
			maxTagTextLength,
			showAction,
			getPopupContainer,
			readOnly,
			autoFocus
		} = this.props
		const { fetching, data } = this.state

		const value = input.value === null || input.value === '' ? undefined : input.value

		let opt = options
		if (isEmpty(options) && isEmpty(data)) {
			opt = []
		} else if (isEmpty(options)) {
			opt = data
		}

		const dropdownRender = this.props.dropdownRender || this.renderDropdown()
		const onScroll = allowInfinityScroll ? this.onScroll : undefined

		const contentOpts: ReactNode[] = map(opt, this.renderOption)
		let notFound = notFoundContent
		if (emptyText || this.state.emptyText) {
			notFound = <Empty className={'m-4'} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.emptyText || emptyText} />
		}

		const setGetPopupContainer = () => {
			let popupContainer = (node: any) => node
			// ak je multiple alebo tags tak sa nastavuje pre .ant-select-selector overflow: auto, aby sa scrollovali selectnute multiple option v selecte preto sa nastavuje container na document.body (aby sa to vzdy z hora nemuselo posielat)
			if (mode === 'multiple' || mode === 'tags') {
				popupContainer = (node: any) => node.closest('.ant-drawer-body') || node.closest('.ant-modal-body') || document.body
			} else if (getPopupContainer) {
				// Ak existuje getPopupContainer nastav ho -> vacsinou v editovatelnych tabulkach sa posiela
				popupContainer = getPopupContainer
			}
			return popupContainer
		}

		return (
			<Item
				label={label}
				required={required}
				style={style}
				className={cx(className, { 'form-item-disabled': disabled, 'read-only': readOnly })}
				help={meta?.touched && isString(meta?.error) ? meta?.error : undefined}
				validateStatus={meta?.touched && meta?.error ? 'error' : undefined}
			>
				<Select
					{...input}
					bordered={bordered}
					className={'extd-select'}
					tagRender={tagRender}
					mode={mode}
					id={formFieldID(meta.form, input.name)}
					onFocus={this.onFocus}
					onChange={this.onChange}
					size={size || 'large'}
					value={value}
					onBlur={this.onBlur}
					placeholder={placeholder || ''}
					loading={loading || fetching}
					clearIcon={clearIcon}
					allowClear={allowClear}
					showSearch={showSearch}
					filterOption={filterOption && selectFieldFilterOption}
					onSearch={showSearch ? this.onSearchDebounced : undefined}
					labelInValue={labelInValue}
					dropdownRender={dropdownRender}
					disabled={disabled}
					removeIcon={removeIcon}
					notFoundContent={notFound}
					onPopupScroll={onScroll}
					onDropdownVisibleChange={this.onDropdownVisibleChange}
					ref={this.itemRef as any}
					defaultValue={defaultValue}
					optionLabelProp={optionLabelProp}
					open={open}
					onDeselect={this.onDeselectWrap}
					onSelect={this.onSelectWrap}
					showArrow={showArrow}
					dropdownMatchSelectWidth={dropdownMatchSelectWidth}
					dropdownClassName={cx(`extd-select-dropdown ${dropdownClassName}`, { 'dropdown-match-select-width': dropdownMatchSelectWidth })}
					dropdownStyle={dropdownStyle}
					listHeight={listHeight}
					autoClearSearchValue={autoClearSearchValue}
					maxTagTextLength={maxTagTextLength}
					showAction={showAction}
					getPopupContainer={setGetPopupContainer()}
					autoFocus={autoFocus}
					// NOTE: Do not show chrome suggestions dropdown and do not autofill this field when user picks chrome suggestion for other field
					{...{ autoComplete: 'new-password' }}
				>
					{contentOpts}
				</Select>
			</Item>
		)
	}
}
