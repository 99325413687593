import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IUsersPayload, IUsersActions, IUserPayload } from './usersActions'
import { RESET_STORE } from '../generalTypes'
import { USERS } from './usersTypes'

export const initState = {
	users: {
		originalData: null,
		pagination: null,
		search: null,
		isLoading: false,
		isFailure: false
	} as IUsersPayload & ILoadingAndFailure,
	user: {
		originalData: null,
		isLoading: false,
		isFailure: false
	} as IUserPayload & ILoadingAndFailure
}

export default (state = initState, action: IUsersActions) => {
	switch (action.type) {
		case USERS.USERS_LOAD_START:
			return {
				...state,
				users: {
					...state.users,
					isLoading: true
				}
			}
		case USERS.USERS_LOAD_FAIL:
			return {
				...state,
				users: {
					...initState.users,
					isFailure: true
				}
			}
		case USERS.USERS_LOAD_DONE:
			return {
				...state,
				users: {
					...initState.users,
					originalData: action.payload.originalData,
					options: action.payload.options,
					pagination: action.payload.pagination,
					search: action.payload.search
				}
			}
		case USERS.USER_LOAD_START:
			return {
				...state,
				user: {
					...state.user,
					isLoading: true
				}
			}
		case USERS.USER_LOAD_FAIL:
			return {
				...state,
				user: {
					...initState.user,
					isFailure: true
				}
			}
		case USERS.USER_LOAD_DONE:
			return {
				...state,
				user: {
					...initState.user,
					originalData: action.payload.originalData
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
