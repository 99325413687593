import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { initialize, isPristine, isSubmitting, submit } from 'redux-form'
import { Layout, Button, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'

import DetailHeader from '../../layouts/components/DetailHeader'
import ProjectForm, { IProjectForm } from './components/ProjectForm'

import { RootState } from '../../reducers'
import { getProject } from '../../reducers/projects/projectsActions'
import { history } from '../../utils/history'
import { ACTION_TYPE, CODE_TYPE, FORM, PROJECT_CODE_TYPE } from '../../utils/enums'
import { translateProjectTab } from '../../utils/helpers'
import { deleteReq, patchReq } from '../../utils/requests'
import { Paths } from '../../types/api'
import { IComputedMatch } from '../../types/interfaces'
import usePermissionCheck from '../../utils/permissionCheck'
import ConfirmModal from '../../components/ConfirmModal'
import { clearProjectSider } from '../../reducers/menu/menuActions'

const { Content } = Layout

type Props = {
	computedMatch: IComputedMatch<{
		projectID: string
	}>
}

const ProjectUpdatePage = (props: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const projectID = parseInt(props.computedMatch.params.projectID, 10)
	const projectsStore = useSelector((state: RootState) => state?.projectsStore)

	const pristine = useSelector((state: RootState) => isPristine(FORM.PROJECT_FORM)(state))
	const submitting = useSelector((state: RootState) => isSubmitting(FORM.PROJECT_FORM)(state))

	const [modalState, setModalState] = useState<{ visible: boolean; projectID: number | undefined }>({ visible: false, projectID: undefined })

	const permissedActions = usePermissionCheck()

	const dummyProjectTabsValues = [
		{
			id: 1,
			name: 'Serial Number',
			type: {
				key: PROJECT_CODE_TYPE.SERIAL_NUMBER,
				value: PROJECT_CODE_TYPE.SERIAL_NUMBER,
				label: translateProjectTab(PROJECT_CODE_TYPE.SERIAL_NUMBER)
			},
			items: []
		},
		{
			id: 2,
			name: 'Index',
			type: {
				key: PROJECT_CODE_TYPE.INDEX,
				value: PROJECT_CODE_TYPE.INDEX,
				label: translateProjectTab(PROJECT_CODE_TYPE.INDEX)
			},
			items: []
		}
	]

	const fetchData = async (tabIndex = 0) => {
		const project = await dispatch(getProject(projectID))
		const initData = {
			name: project?.originalData?.name,
			participants: map(project?.originalData?.participants, (participant) => ({
				id: participant.id,
				name: participant.name,
				surname: participant.surname,
				email: participant.email,
				role: participant.role
			})),
			selectedTabKey: project?.originalData?.projectCodeAttributes[tabIndex]?.id,
			isUpdating: true,
			codeType: project?.originalData?.projectCodeAttributes?.length === 0 ? CODE_TYPE.FREE_TEXT : CODE_TYPE.STATES,
			isCodeSet: project?.originalData?.projectCodeAttributes?.length !== 0,
			tabs:
				project?.originalData?.projectCodeAttributes?.length !== 0
					? map(project?.originalData?.projectCodeAttributes, (projectCodeAttribute) => ({
							id: projectCodeAttribute?.id,
							type: {
								key: projectCodeAttribute?.type,
								value: projectCodeAttribute?.type,
								label: translateProjectTab(projectCodeAttribute?.type as PROJECT_CODE_TYPE)
							},
							name: projectCodeAttribute?.name,
							items: projectCodeAttribute?.values?.map((item) => {
								return { ...item, isNew: false }
							})
					  }))
					: dummyProjectTabsValues,
			suffixDelimiter: project?.originalData?.suffixDelimiter,
			prefixDelimiter: project?.originalData?.prefixDelimiter
		}
		dispatch(initialize(FORM.PROJECT_FORM, initData))
	}

	// after 1st render
	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSubmit = async (values: IProjectForm) => {
		try {
			const reqBody: Paths.PatchApiV1ProjectsProjectId.RequestBody = {
				name: values.name,
				participants: map(values.participants, (participant) => ({
					id: participant.id,
					role: participant.role
				})),
				projectCodeAttributes:
					values?.codeType === CODE_TYPE.STATES
						? (map(values.tabs, (tab) => ({
								// pri vytvarani nejdu IDcka, pri uprave idu.
								id: values?.isCodeSet ? tab?.id : undefined,
								type: tab?.type?.value,
								name: tab.name,
								values: tab.items?.map(({ isNew, ...rest }) => {
									return rest
								})
						  })) as any)
						: [],
				suffixDelimiter: values?.suffixDelimiter,
				prefixDelimiter: values?.prefixDelimiter
			}

			await patchReq('/api/v1/projects/{projectID}', { projectID }, reqBody)
			history.push(t('paths:index'))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	const confirmDelete = async () => {
		if (modalState.projectID) {
			try {
				await deleteReq('/api/v1/projects/{projectID}', { projectID: modalState.projectID })
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}
		setModalState({ visible: false, projectID: undefined })
		dispatch(clearProjectSider())
		history.push(t('paths:index'))
	}

	return (
		<Layout className='extd-layout with-footer'>
			<DetailHeader title={`${projectsStore?.project?.originalData?.name || '-'}`} />
			<Content>
				<div className='content-body medium'>
					<Spin spinning={projectsStore?.project?.isLoading}>
						<ProjectForm onSubmit={handleSubmit} />
					</Spin>
				</div>
			</Content>
			{permissedActions?.includes(ACTION_TYPE.PROJECT_EDIT) && (
				<div className={'extd-layout-footer'}>
					<div className='content-footer'>
						<Button
							type={'primary'}
							className={'extd-btn-danger'}
							onClick={() => setModalState({ visible: true, projectID: projectsStore?.project?.originalData?.id })}
						>
							{t('loc:Delete')}
						</Button>
						<Button type={'default'} className={'extd-btn'} onClick={() => history.push(t('paths:index'))}>
							{t('loc:Cancel')}
						</Button>
						<Button type={'primary'} className={'extd-btn'} onClick={() => dispatch(submit(FORM.PROJECT_FORM))} disabled={submitting || pristine} loading={submitting}>
							{t('loc:Save project')}
						</Button>
					</div>
				</div>
			)}
			<ConfirmModal
				visible={modalState.visible === true}
				text={t('loc:Delete project?')}
				confirmButtonText={t('loc:Confirm')}
				cancelButtonText={t('loc:Cancel')}
				confirmButtonClassName={'extd-btn-danger'}
				onCancel={() => setModalState({ visible: false, projectID: undefined })}
				onSubmit={confirmDelete}
			/>
		</Layout>
	)
}

export default ProjectUpdatePage
