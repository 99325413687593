import { FormErrors } from 'redux-form'

import { find } from 'lodash'
import { validationRequired, validationString } from '../../../utils/helpers'
// eslint-disable-next-line import/no-cycle
import { ISubFolderCreateForm } from './SubFolderCreateForm'

/**
 * @param {ISubFolderCreateForm} values
 */
export default (values: any) => {
	const errors: FormErrors<ISubFolderCreateForm> = {}

	const nameError = find([validationRequired(values?.name), validationString(100)(values?.name)], (err) => !!err)
	if (nameError) {
		errors.name = nameError
	}

	return errors
}
