import { FormErrors } from 'redux-form'

import { find } from 'lodash'
import { validationCommaSeparatedEmailList, validationRequired, validationString } from '../../../../utils/helpers'
// eslint-disable-next-line import/no-cycle
import { IInviteUserForm } from './InviteUserForm'

/**
 * @param {IRenameFolderForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IInviteUserForm> = {}

	const emailError = find([validationRequired(values?.email), validationString(255)(values?.email), validationCommaSeparatedEmailList(values?.email)], (err) => !!err)
	if (emailError) {
		errors.email = emailError
	}

	const permissionError = find([validationRequired(values?.permission)], (err) => !!err)
	if (permissionError) {
		errors.permission = permissionError
	}

	return errors
}
