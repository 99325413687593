import React from 'react'
import { Dropdown, Menu, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'
import { ReactComponent as DotsIcon } from '../../../../assets/icons/icon-table-actions.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/icon-edit.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/icon-table-delete-small.svg'
import { ACTION_TYPE, FORM, MODAL_TYPE_FOLDER } from '../../../../utils/enums'
import usePermissionCheck from '../../../../utils/permissionCheck'

const minDropdownWidth = { minWidth: 150 }

type FolderProps = {
	folderID: number
	folderName: string
	setModalState: (value: { visible: MODAL_TYPE_FOLDER | undefined; folderID: undefined | number }) => void
}

const FoldersTableActions = (props: FolderProps) => {
	const { folderID, folderName, setModalState } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const permissedActions = usePermissionCheck()

	const dropdownMenu = (
		<Menu>
			<Menu.Item
				key={'Rename'}
				icon={<EditIcon />}
				onClick={(e) => {
					e.domEvent.stopPropagation()
					dispatch(initialize(FORM.RENAME_FOLDER_FORM, { name: folderName }))
					setModalState({ visible: MODAL_TYPE_FOLDER.RENAME, folderID })
				}}
				disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_EDIT)}
			>
				{t('loc:Rename')}
			</Menu.Item>
			<Menu.Item
				key={'Delete'}
				icon={<DeleteIcon />}
				className={permissedActions?.includes(ACTION_TYPE.FOLDER_DELETE) ? 'text-error-medium' : ''}
				onClick={(e) => {
					e.domEvent.stopPropagation()
					setModalState({ visible: MODAL_TYPE_FOLDER.DELETE, folderID })
				}}
				disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_DELETE)}
			>
				{t('loc:Delete')}
			</Menu.Item>
		</Menu>
	)

	return (
		<div>
			<Dropdown overlay={dropdownMenu} overlayStyle={minDropdownWidth} trigger={['click']}>
				<Button
					type='text'
					className={'extd-table-dropdown flex justify-center'}
					style={{ padding: '0' }}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<DotsIcon />
				</Button>
			</Dropdown>
		</div>
	)
}

export default FoldersTableActions
