import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import InviteForm, { IInviteForm } from './components/InviteForm'
import { inviteAdmin } from '../../reducers/users/usersActions'

type Props = {}

const AdminInvitePage: FC<Props> = () => {
	const dispatch = useDispatch()
	const handleSubmit = (values: IInviteForm) => dispatch(inviteAdmin(values))

	return <InviteForm onSubmit={handleSubmit as any} />
}
export default AdminInvitePage
