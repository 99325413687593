import React, { useCallback } from 'react'
import { Form, Input } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { trimStart, trim } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { formFieldID } from '../utils/helpers'

const { Item } = Form

type Props = WrappedFieldProps &
	InputProps &
	FormItemLabelProps & {
		customOnChange?: (value: string | null) => any
	}

const InputField = (props: Props) => {
	const {
		input,
		size,
		placeholder,
		label,
		required,
		type,
		prefix,
		disabled,
		style,
		customOnChange,
		meta: { form, error, touched },
		maxLength
	} = props
	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? trimStart(e.target.value) : null
			const change = customOnChange || input.onChange
			change(val)
		},
		[input, customOnChange]
	)

	const onBlur = useCallback(
		async (e) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? trim(e.target.value) : null
			// NOTE: wait until redux-form "BLUR" action is finished
			await input.onBlur(val)
		},
		[input]
	)

	const onFocus = useCallback(
		async (e) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? e.target.value : null
			if (input.onFocus) {
				input.onFocus(val)
			}
		},
		[input]
	)

	return (
		<Item label={label} required={required} style={style} help={touched && error} validateStatus={error && touched ? 'error' : undefined}>
			<Input
				{...input}
				id={formFieldID(form, input.name)}
				onChange={onChange}
				onBlur={onBlur}
				size={size || 'large'}
				onFocus={onFocus}
				value={input.value}
				placeholder={placeholder}
				className={'extd-input'}
				type={type || 'text'}
				prefix={prefix}
				disabled={disabled}
				maxLength={maxLength}
			/>
		</Item>
	)
}

export default React.memo(InputField)
