import React, { FC, useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { ReactComponent as Illustration } from '../assets/images/image-authorization.svg'

const AuthenticationLayout: FC<{}> = (props) => {
	const [width, setWidth] = useState(window.innerWidth)

	useEffect(() => {
		const updateWindowDimensions = () => {
			const newWidth = window.innerWidth
			setWidth(newWidth)
		}

		window.addEventListener('resize', updateWindowDimensions)

		return () => window.removeEventListener('resize', updateWindowDimensions)
	}, [])

	return (
		<Row>
			<Col span={width > 786 ? 10 : 24}>{props.children}</Col>
			{width > 786 && (
				<Col span={14}>
					<div className={'h-screen flex justify-center items-center bg-blue-800 overflow-hidden'}>
						<Illustration className={'h-screen w-full'} style={{ objectFit: 'cover' }} />
					</div>
				</Col>
			)}
		</Row>
	)
}

export default AuthenticationLayout
