// eslint-disable-next-line import/prefer-default-export
export enum MENU_TYPE {
	MENU_CHANGE = 'MENU_CHANGE'
}

export enum PATH_TYPE {
	PROJECT_SIDER_CHANGE_TO_PROJECT = 'PROJECT_SIDER_CHANGE_TO_PROJECT',
	PROJECT_SIDER_CHANGE_TO_FOLDER = 'PROJECT_SIDER_CHANGE_TO_FOLDER',
	PROJECT_SIDER_CHANGE_TO_SUBFOLDER = 'PROJECT_SIDER_CHANGE_TO_SUBFOLDER',
	PROJECT_SIDER_CHANGE_TO_PLAN = 'PROJECT_SIDER_CHANGE_TO_PLAN',
	PROJECT_SIDER_CLEAR = 'PROJECT_SIDER_CLEAR'
}
