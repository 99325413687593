/* eslint-disable import/no-cycle */
import { map } from 'lodash'

import { ThunkResult } from '..'
import { USERS } from './usersTypes'
import { IResetStore } from '../generalTypes'
import { Paths } from '../../types/api'
import { ISelectOptionItem } from '../../types/interfaces'
import { getReq, postReq } from '../../utils/requests'

export interface IUsersPayload {
	originalData: Paths.GetApiV1Users.Responses.$200['users'] | null
	options: ISelectOptionItem[]
	pagination: Paths.GetApiV1Users.Responses.$200['pagination'] | null
	search: string | null | undefined
}

export interface IUserPayload {
	originalData: Paths.GetApiV1UsersUserId.Responses.$200['user'] | null
}

interface IGetUsers {
	type: USERS
	payload: IUsersPayload
}

interface IGetUser {
	type: USERS
	payload: IUserPayload
}

export type IUsersActions = IResetStore | (IGetUsers & IGetUser)

export const getUsers =
	(
		limit: Paths.GetApiV1Users.Parameters.Limit,
		page?: Paths.GetApiV1Users.Parameters.Page,
		search?: Paths.GetApiV1Users.Parameters.Search,
		projectID?: Paths.GetApiV1ActivitiesDaily.Parameters.ProjectID
	): ThunkResult<void> =>
	async (dispatch) => {
		let payload = {} as IUsersPayload
		dispatch({ type: USERS.USERS_LOAD_START })

		const params = {
			limit,
			page: page || 1,
			...(search && { search }),
			...(projectID && { projectID })
		}

		try {
			const { data } = await getReq('/api/v1/users/', params)
			payload = {
				originalData: data.users,
				options: map(data.users, (user) => ({
					key: user.id,
					value: user.id,
					label: `${user.name} ${user.surname} (${user.email})`
				})),
				pagination: data.pagination,
				search
			}
			dispatch({ type: USERS.USERS_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: USERS.USERS_LOAD_FAIL })
		}
		return payload
	}

export const getUser =
	(userID: Paths.GetApiV1UsersUserId.Parameters.UserID): ThunkResult<void> =>
	async (dispatch) => {
		let payload = {} as IUserPayload
		dispatch({ type: USERS.USER_LOAD_START })

		try {
			const { data } = await getReq('/api/v1/users/{userID}', { userID })
			payload = {
				originalData: data.user
			}
			dispatch({ type: USERS.USER_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: USERS.USER_LOAD_FAIL })
		}
		return payload.originalData
	}

export const inviteAdmin =
	(input: { email: string }): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: USERS.ADMIN_INVITE_START })
		try {
			await postReq('/api/v1/users/first', null, input)
			dispatch({ type: USERS.ADMIN_INVITE_DONE })
		} catch (e: any) {
			dispatch({ type: USERS.ADMIN_INVITE_FAIL })
		}
	}
