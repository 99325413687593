import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'
import { Button, Typography } from 'antd'
import { map } from 'lodash'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { initialize } from 'redux-form'
import dayjs from 'dayjs'
import { ReactComponent as ProjectIcon } from '../../../../assets/icons/icon-input-project.svg'
import { ReactComponent as EditStatusesIcon } from '../../../../assets/icons/icon-edit-statuses.svg'
import usePermissionCheck from '../../../../utils/permissionCheck'
import { ACTION_TYPE, APP_LANGUAGE, FORM, MODAL_TYPE_PLANS, PROJECT_USER_ROLE, TREE_NODE_TYPE } from '../../../../utils/enums'
import { getReq } from '../../../../utils/requests'
import { translateProjectUserRole } from '../../../../utils/helpers'
import { IDatesFilter } from '../../../../types/interfaces'
import DatesFilter from '../../components/DatesFilter'

type Props = {
	type: TREE_NODE_TYPE
	projectCodeAttributes: any
	folderID: number
	setModalState: (modalType: MODAL_TYPE_PLANS) => void
	setOnlyActual: (value: boolean) => void
	onlyActual: boolean
	dateValues: { dateFrom: dayjs.Dayjs | undefined; dateTo: dayjs.Dayjs | undefined }
	setDateValues: (dateFrom: dayjs.Dayjs | undefined, dateTo: dayjs.Dayjs | undefined) => void
}

const PlansTableFilterRow = ({ projectCodeAttributes, folderID, setModalState, onlyActual, setOnlyActual, dateValues, setDateValues, type }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const permissedActions = usePermissionCheck()

	const maxCodeWidth =
		i18next?.language === APP_LANGUAGE.EN
			? `calc(100vw ${permissedActions?.includes(ACTION_TYPE?.FOLDER_EDIT) ? '- 150px' : ''} - 750px)`
			: `calc(100vw ${permissedActions?.includes(ACTION_TYPE.FOLDER_EDIT) ? '- 160px' : ''} - 774px)`

	const handleEditStatuses = async () => {
		try {
			const folder = await getReq('/api/v1/folders/{folderID}', { folderID })
			const tabs = map(folder.data.folder.states, (state, index) => {
				return {
					id: index,
					// existingID pridane pre identifikaciu uz existujucich status, aby sa nestratili subory v nom.
					existingID: state?.id,
					name: state.name,
					statusView: {
						key: state.projectUserRole,
						value: state.projectUserRole,
						label: translateProjectUserRole(state.projectUserRole as PROJECT_USER_ROLE)
					}
				}
			})

			dispatch(initialize(FORM.MAIN_FOLDER_EDIT_STATUSES_FORM, { tabs, folderID }))

			setModalState(MODAL_TYPE_PLANS.EDIT)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	const handleSubmit = (values: IDatesFilter) => {
		setDateValues(values?.dateFrom, values?.dateTo)
	}

	return (
		<div className={'flex flex-row justify-between'}>
			<div className={'p-2 ml-2 flex items-center'}>
				<ProjectIcon />
				<Typography.Text ellipsis style={{ maxWidth: `${maxCodeWidth}` }}>
					{map(projectCodeAttributes, (attr) => attr?.name).join('_')}
				</Typography.Text>
			</div>
			<div className={'flex flex-row'}>
				<DatesFilter onSubmit={handleSubmit} dateValues={dateValues} />
				<Button
					type={'default'}
					className={'extd-btn'}
					style={{ border: '1px solid white', width: `${i18next.language === APP_LANGUAGE.EN ? '185px' : '220px'}` }}
					onClick={() => setOnlyActual(!onlyActual)}
				>
					<div className={'flex justify-center items-center'}>
						{!onlyActual ? <EyeInvisibleOutlined className={'mr-2'} /> : <EyeOutlined className={'mr-2'} />}
						<span style={{ marginTop: 1 }}>{!onlyActual ? t('loc:Hide older versions') : t('loc:Show all versions')}</span>
					</div>
				</Button>
				{permissedActions?.includes(ACTION_TYPE?.FOLDER_EDIT) && type === TREE_NODE_TYPE.MAIN_FOLDER && (
					<Button
						type={'default'}
						className={'extd-btn'}
						style={{ border: '1px solid white', width: `${i18next.language === APP_LANGUAGE.EN ? '150px' : '160px'}` }}
						onClick={handleEditStatuses}
					>
						<div className={'flex justify-center'}>
							<EditStatusesIcon className={'mr-2'} />
							<span style={{ marginTop: 1 }}>{t('loc:Edit statuses')}</span>
						</div>
					</Button>
				)}
			</div>
		</div>
	)
}

export default PlansTableFilterRow
