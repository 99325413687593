import React, { FC, useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// utils
import { useSelector } from 'react-redux'
import i18next from 'i18next'
import { REFRESH_TOKEN_INTERVAL } from '../utils/enums'
import { getRefreshToken, hasRefreshToken, isLoggedIn, setAccessToken, setRefreshToken } from '../utils/auth'
import { postReq } from '../utils/requests'

// routes
import PublicRoute from './PublicRoute'
import AuthRoute from './AuthRoute'
import CreatePasswordRoute from './CreatePasswordRoute'

// pages
import LoginPage from '../pages/LoginPage/LoginPage'
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage'
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage'
import NewPasswordPage from '../pages/NewPasswordPage/NewPasswordPage'
import HomePage from '../pages/HomePage/HomePage'
import ProjectCreatePage from '../pages/ProjectPage/ProjectCreatePage'
import ProjectUpdatePage from '../pages/ProjectPage/ProjectUpdatePage'
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage'
import UploadDocumentsPage from '../pages/UploadDocumentsPage/UploadDocumentsPage'
import SettingsPage from '../pages/SettingsPage/SettingsPage'

// layouts
import AuthenticationLayout from '../layouts/AuthenticationLayout'
import MainLayout from '../layouts/MainLayout'
import UsersTable from '../pages/HomePage/Users/UsersTable'
import UserCreationPage from '../pages/UserCreationPage/UserCreationPage'
import AdminCreationPage from '../pages/UserCreationPage/AdminCreationPage'
import AdminInvitePage from '../pages/AdminInvitePage/AdminInvitePage'
import { RootState } from '../reducers'

const Routes: FC = (props) => {
	const { t } = useTranslation()

	const authUserLanguage = useSelector((state: RootState) => state?.userStore?.authUser?.originalData?.language)

	useEffect(() => {
		const refreshToken = async () => {
			if (isLoggedIn() && hasRefreshToken()) {
				try {
					const { data } = await postReq('/api/v1/authorization/refresh-token', null, { refreshToken: getRefreshToken() as string })
					setAccessToken(data.accessToken)
					setRefreshToken(data.refreshToken)
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error)
				}
			}
		}

		const refreshInterval = setInterval(refreshToken, REFRESH_TOKEN_INTERVAL)

		return () => {
			if (refreshInterval) {
				clearInterval(refreshInterval)
			}
		}
	}, [])

	useEffect(() => {
		if (authUserLanguage && authUserLanguage !== i18next.language) {
			i18next.changeLanguage(authUserLanguage)
		}
	}, [authUserLanguage])

	return (
		<Switch>
			<PublicRoute {...props} exact path={t('paths:login')} component={LoginPage} layout={AuthenticationLayout} />
			<PublicRoute {...props} exact path={t('paths:forgot-password')} component={ForgotPasswordPage} layout={AuthenticationLayout} />
			<PublicRoute {...props} exact path={t('paths:user-creation')} component={UserCreationPage} layout={AuthenticationLayout} />
			<PublicRoute {...props} exact path={t('paths:admin-invite')} component={AdminInvitePage} layout={AuthenticationLayout} />
			<PublicRoute {...props} exact path={t('paths:admin-creation')} component={AdminCreationPage} layout={AuthenticationLayout} />
			<CreatePasswordRoute exact path={t('paths:reset-password')} component={ResetPasswordPage} layout={AuthenticationLayout} />
			<CreatePasswordRoute exact path={t('paths:new-password')} component={NewPasswordPage} layout={AuthenticationLayout} />
			<AuthRoute {...props} exact path={t('paths:index')} component={HomePage} layout={MainLayout} />
			<AuthRoute {...props} exact path={t('paths:projects/create')} component={ProjectCreatePage} />
			<AuthRoute {...props} exact path={t('paths:projects/{{projectID}}', { projectID: ':projectID' })} component={ProjectUpdatePage} />
			<AuthRoute {...props} exact path={t('paths:users')} component={UsersTable} layout={MainLayout} />
			<AuthRoute {...props} exact path={t('paths:upload-documents')} component={UploadDocumentsPage} />
			<AuthRoute {...props} exact path={t('paths:settings')} component={SettingsPage} />
			<AuthRoute {...props} exact path={t('paths:activities')} component={HomePage} layout={MainLayout} />
			<AuthRoute {...props} component={NotFoundPage} layout={AuthenticationLayout} />
		</Switch>
	)
}

export default Routes
