import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Row, Col, Form, Button } from 'antd'
import { FORM } from '../../../utils/enums'
import InputField from '../../../atoms/InputField'
import { ReactComponent as Planshelf } from '../../../assets/images/image-planshelf.svg'
// eslint-disable-next-line import/no-cycle
import validateInviteForm from './validateInviteForm'

export interface IInviteForm {
	email: string
}

type ComponentProps = {}

type Props = InjectedFormProps<IInviteForm, ComponentProps> & ComponentProps

const InviteForm: FC<Props> = (props) => {
	const { t } = useTranslation()
	const { handleSubmit, submitting } = props

	return (
		<Form layout={'vertical'} className={'mt-20'} onSubmitCapture={handleSubmit}>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Planshelf className={'mb-10'} />
				</Col>
				<Col span={14}>
					<h3 className={'heading-3'}>{t('loc:Invite admin')}</h3>
				</Col>
				<Col span={14}>
					<Field component={InputField} label={t('loc:Email')} placeholder={t('loc:Enter email')} name={'email'} size={'large'} required />
				</Col>
				<Col span={14}>
					<Button type={'primary'} block className={'extd-btn'} size={'large'} htmlType={'submit'} disabled={submitting} loading={submitting}>
						{t('loc:Invite')}
					</Button>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IInviteForm, ComponentProps>({
	form: FORM.ADMIN_INVITE,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateInviteForm
})(InviteForm)

export default form
