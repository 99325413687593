import React from 'react'
import { useDispatch } from 'react-redux'
import { logOutUser } from '../../../../reducers/authUser/authUserActions'
import { patchReq } from '../../../../utils/requests'
import ChangePasswordForm, { IChangePasswordForm } from './ChangePasswordForm'

const ChangePassword = () => {
	const dispatch = useDispatch()

	const handleSubmit = async (values: IChangePasswordForm) => {
		try {
			const reqBody = {
				currentPassword: values.actualPassword,
				newPassword: values.newPassword
			}

			const data = await patchReq('/api/v1/settings/password', {}, reqBody)

			if (data.status === 200) {
				dispatch(logOutUser(false))
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}

	return <ChangePasswordForm onSubmit={handleSubmit as any} />
}

export default ChangePassword
