import React from 'react'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { ISearchFilter } from '../../../../types/interfaces'
import { ACTION_TYPE } from '../../../../utils/enums'
import { history } from '../../../../utils/history'
import usePermissionCheck from '../../../../utils/permissionCheck'
import SearchFilter from '../../components/SearchFilter'

type Props = {
	handleCreateFolder: () => void
	searchFolders: (search: string) => void
}

const FoldersTableButtonRow = ({ handleCreateFolder, searchFolders }: Props) => {
	const { t } = useTranslation()
	const permissedActions = usePermissionCheck()

	const handleSubmit = (values: ISearchFilter) => {
		searchFolders(values?.search)
	}

	return (
		<Row justify={'space-between'} className={'pt-4'}>
			<Col span={10} style={{ minWidth: '144px' }}>
				<SearchFilter onSubmit={handleSubmit} placeholder={t('loc:Search folders')} />
			</Col>
			<Col>
				<Button
					type={'default'}
					className={'extd-btn h-10 w-36 mr-2'}
					icon={<UploadOutlined />}
					onClick={() => history.push(t('paths:upload-documents'))}
					disabled={!permissedActions?.includes(ACTION_TYPE.UPLOAD)}
				>
					{t('loc:Upload files')}
				</Button>
				<Button
					type={'primary'}
					className={'extd-btn h-10'}
					icon={<PlusOutlined />}
					onClick={handleCreateFolder}
					disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_CREATE)}
				>
					{t('loc:Create folder')}
				</Button>
			</Col>
		</Row>
	)
}

export default FoldersTableButtonRow
