import React from 'react'
import { Form, FormItemProps, Upload, UploadProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { WrappedFieldProps } from 'redux-form'
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface'
import { RcFile } from 'antd/es/upload/interface'
import { PlusOutlined } from '@ant-design/icons'
import i18next from 'i18next'
import { getAccessToken } from '../utils/auth'
import showNotifications from '../utils/tsxHelpers'
import { MSG_TYPE, NOTIFICATION_TYPE } from '../utils/enums'

const { Item } = Form
export interface IFileUploadFieldValue {
	id: number
	name: string
	thumbUrl: string
	url: string
}

type UploadAtomProps = {
	isPreview?: boolean
	maxSize?: number
	exludeFileTypes?: string[]
}

type Props = WrappedFieldProps & FormItemProps & UploadProps & UploadAtomProps

const FileUploadField = (props: Props) => {
	const {
		input,
		label,
		required,
		maxCount,
		meta: { error, touched },
		action,
		accept,
		disabled,
		isPreview,
		maxSize,
		exludeFileTypes
	} = props

	const { t } = useTranslation()

	const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
		if (info.file.status === 'error') {
			showNotifications(
				[
					{
						type: MSG_TYPE.ERROR,
						message: t('loc:Upload failed, remove the file and try again.')
					}
				],
				NOTIFICATION_TYPE.NOTIFICATION
			)
			const newFilelist = info.fileList.filter((file) => file.uid !== info.file.uid)
			input.onChange([...newFilelist])
		}
		if (info.file.status === 'success' || info.file.status === 'done') {
			if (isPreview) {
				const newFilelist = info.fileList.filter((file) => file.uid !== info.file.uid)
				input.onChange([
					...newFilelist,
					{
						id: info.file.response?.file?.id,
						name: info.file.response?.file?.id,
						url: info.file.response?.file?.previewUrl,
						thumbUrl: info.file.response?.file?.previewUrl
					}
				])
			} else {
				const newFilelist = info.fileList.filter((file) => file.uid !== info.file.uid)
				input.onChange([
					...newFilelist,
					{
						id: info.file.response?.file?.id,
						name: info.file.response?.file?.name
					}
				])
			}
		} else if (!info.file.status) {
			const newFilelist = info.fileList.filter((file) => file.uid !== info.file.uid)
			input.onChange(newFilelist)
		} else input.onChange(info.fileList)
	}

	const showUploadButton = !maxCount || input?.value?.length < maxCount

	const UploadButton = () => (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: '8px' }}>{i18next.t('loc:Upload')}</div>
		</div>
	)

	return (
		<Item label={label} required={required} help={touched && error} validateStatus={touched && error ? 'error' : undefined}>
			<Upload
				disabled={disabled}
				multiple={false}
				maxCount={maxCount}
				fileList={input?.value || []}
				type={'select'}
				accept={accept}
				onChange={handleChange}
				beforeUpload={(file: RcFile, FileList: RcFile[]) => {
					if (exludeFileTypes && exludeFileTypes?.includes(file.type)) {
						showNotifications(
							[
								{
									type: MSG_TYPE.ERROR,
									message: t(`loc:File type {{fileType}} is not allowed`, { fileType: file.type })
								}
							],
							NOTIFICATION_TYPE.NOTIFICATION
						)
						return false
					}
					if (!!maxSize && file?.size > maxSize) {
						showNotifications(
							[
								{
									type: MSG_TYPE.ERROR,
									message: t(`loc:Max file size here is {{max}}MB`, { max: maxSize / 1000000 })
								}
							],
							NOTIFICATION_TYPE.NOTIFICATION
						)
						return false
					}

					if (input?.value && input.value.length + FileList.length > 10) {
						showNotifications(
							[
								{
									type: MSG_TYPE.ERROR,
									message: t(`loc:Max number of uploaded files is {{max}}`, { max: maxCount })
								}
							],
							NOTIFICATION_TYPE.NOTIFICATION
						)
						return false
					}
					return true
				}}
				headers={{
					Authorization: `Bearer ${getAccessToken()}`
				}}
				action={action}
				listType={'picture-card'}
				showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
			>
				{showUploadButton && <UploadButton />}
			</Upload>
		</Item>
	)
}

export default React.memo(FileUploadField)
