import { FormErrors } from 'redux-form'
import i18next from 'i18next'
import { find } from 'lodash'
import { validationRequired, validationString } from '../../../utils/helpers'
// eslint-disable-next-line import/no-cycle
import { IMainFolderCreateForm } from './MainFolderCreateForm'

/**
 * @param {IMainFolderCreateForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IMainFolderCreateForm> = {}

	const nameError = find([validationRequired(values?.name), validationString(100)(values?.name)], (err) => !!err)
	if (nameError) {
		errors.name = nameError
	}

	let errorIndex: any
	values?.tabs?.forEach((tab: any, tabIndex: any) => {
		if (errorIndex === undefined) {
			if (!tab.name || !tab.statusView) {
				errorIndex = tabIndex
			}
		}
	})

	if (errorIndex !== undefined) {
		// eslint-disable-next-line no-underscore-dangle
		errors._error = i18next.t('loc:All values on {{tabID}}. tab are required', { tabID: errorIndex + 1 })
	}

	return errors
}
