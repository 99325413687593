import i18next from 'i18next'
import { find } from 'lodash'
import { isEmail } from 'lodash-checkit'
import { FormErrors } from 'redux-form'
import { validationRequired, validationString } from '../../../../utils/helpers'
// eslint-disable-next-line import/no-cycle
import { IContactsForm } from './ContactsForm'

/**
 * @param {IContactsForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IContactsForm> = {}

	const nameError = find([validationRequired(values?.name), validationString(100)(values?.name)], (err) => !!err)
	if (nameError) {
		errors.name = nameError
	}

	const surnameError = find([validationRequired(values?.surname), validationString(100)(values?.surname)], (err) => !!err)
	if (surnameError) {
		errors.surname = surnameError
	}

	if (!values?.email) {
		errors.email = i18next.t('loc:This field is required')
	} else if (!isEmail(values.email)) {
		errors.email = i18next.t('loc:Email format is incorrect')
	} else if (values.email?.length > 255) {
		errors.email = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.phone?.length > 100) {
		errors.phone = i18next.t('loc:Max. number of characters is {{max}}', { max: 100 })
	}

	if (values?.company?.length > 255) {
		errors.company = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.position?.length > 255) {
		errors.companyRole = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.note?.length > 255) {
		errors.note = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	return errors
}
