/* eslint-disable import/no-cycle */
import { DOWNLOAD_TYPE } from './downloadTypes'
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'

export interface IDownloadPayload {
	data: IDownloadItem[]
}

export interface File {
	ID: number
	name: string
	mimeType: string
}

export interface IDownloadItem {
	project: {
		ID: number
		name: string
	}
	folder: {
		ID: number
		name: string
	}
	subFolder?: {
		ID: number
		name: string
	}
	plan?: {
		ID: number
		name: string
	}
	planState?: {
		ID: number
		name: string
	}
	file?: File
}

interface IDownloadAction {
	type: DOWNLOAD_TYPE
	payload: IDownloadPayload
}

export type IDownloadActions = IResetStore | IDownloadAction

export const setDownloadList =
	(data: IDownloadItem[]): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: DOWNLOAD_TYPE.DOWNLOAD_CHANGE, payload: { data } })
	}

export const clearDownloadList = (): ThunkResult<void> => async (dispatch) => {
	dispatch({ type: DOWNLOAD_TYPE.DOWNLOAD_CLEAR })
}
