import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Form, Row, Col } from 'antd'
import { FORM } from '../../../../utils/enums'
import { validationRequired } from '../../../../utils/helpers'
import InputPasswordField from '../../../../atoms/InputPasswordField'

export interface IDeleteAccountForm {
	password: string
}

type ComponentProps = {}

type Props = InjectedFormProps<IDeleteAccountForm, ComponentProps> & ComponentProps

const DeleteAccountForm = (props: Props) => {
	const { handleSubmit } = props
	const { t } = useTranslation()

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Row gutter={16}>
				<Col span={24}>
					<Field
						name={'password'}
						component={InputPasswordField}
						label={t('loc:Password')}
						placeholder={t('loc:Enter password')}
						required
						validate={[validationRequired]}
					/>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IDeleteAccountForm, ComponentProps>({
	form: FORM.SETTINGS_DELETE_ACCOUNT_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true
})(DeleteAccountForm)

export default form
