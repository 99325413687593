import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { postReq } from '../../../../utils/requests'
import { RootState } from '../../../../reducers'
import { reloadUserData } from '../../../../reducers/authUser/authUserActions'
import CompanyForm, { ICompanyForm } from './CompanyForm'

const Company = () => {
	const dispatch = useDispatch()

	const authUserID = useSelector((state: RootState) => state?.userStore?.authUser.originalData?.id) || 0

	const handleSubmit = async (values: ICompanyForm) => {
		try {
			const reqBody = {
				company: values?.company,
				logo: values?.logo?.at(0)?.id,
				address: {
					address: values?.address,
					city: values?.city,
					country: values?.country,
					postalCode: values?.postalcode
				},
				contactPerson: {
					name: values?.contactname,
					surname: values?.contactsurname,
					email: values?.contactemail,
					phone: values?.contactphone
				},
				web: values?.web
			}
			await postReq('/api/v1/settings/company', {}, reqBody)
			dispatch(reloadUserData(authUserID))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}

	return <CompanyForm onSubmit={handleSubmit as any} />
}

export default Company
