import { UploadOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ISearchFilter } from '../../../../types/interfaces'
import { ACTION_TYPE } from '../../../../utils/enums'
import usePermissionCheck from '../../../../utils/permissionCheck'
import SearchFilter from '../../components/SearchFilter'

type Props = {
	searchDocuments: (search: string) => void
	handleUploadFiles: () => void
}

const PlanStateButtonRow = ({ searchDocuments, handleUploadFiles }: Props) => {
	const { t } = useTranslation()
	const permissedActions = usePermissionCheck()

	const handleSubmit = (values: ISearchFilter) => {
		searchDocuments(values?.search)
	}

	return (
		<Row justify={'space-between'} className={'pt-4'}>
			<Col span={10} style={{ minWidth: '144px' }}>
				<SearchFilter onSubmit={handleSubmit} placeholder={t('loc:Search documents')} />
			</Col>
			<Col>
				<Button
					type={'primary'}
					className={'w-36 h-10 extd-btn'}
					icon={<UploadOutlined />}
					onClick={handleUploadFiles}
					disabled={!permissedActions?.includes(ACTION_TYPE.UPLOAD)}
				>
					{t('loc:Upload files')}
				</Button>
			</Col>
		</Row>
	)
}

export default PlanStateButtonRow
