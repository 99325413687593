import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { compose } from 'redux'
import qs from 'qs'
import decode from 'jwt-decode'
import { get } from 'lodash'
import { Redirect, RouteProps, Route } from 'react-router-dom'

import BaseRoute from './BaseRoute'

import { TOKEN_AUDIENCE } from '../utils/enums'
import { isLoggedIn } from '../utils/auth'

type Props = WithTranslation &
	RouteProps & {
		layout: any
	}

class CreatePasswordRoute extends Route<Props> {
	render = () => {
		const { t: token } = qs.parse(document.location.search, { ignoreQueryPrefix: true })
		if (isLoggedIn() || !token) {
			return <Redirect to={this.props.t('paths:index')} />
		}

		const payload = decode(token as string)
		const aud = get(payload, 'aud')

		if (aud !== TOKEN_AUDIENCE.FORGOTTEN_PASSWORD && aud !== TOKEN_AUDIENCE.INVITATION) {
			return <Redirect to={this.props.t('paths:index')} />
		}

		return <BaseRoute {...(this.props as any)} />
	}
}

export default compose(withTranslation())(CreatePasswordRoute)
