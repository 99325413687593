import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ACTION_TYPE } from '../../../../utils/enums'
import { history } from '../../../../utils/history'
import usePermissionCheck from '../../../../utils/permissionCheck'

import { ReactComponent as EditIcon } from '../../../../assets/icons/icon-edit.svg'
import { ReactComponent as DotsIcon } from '../../../../assets/icons/icon-table-actions.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/icon-table-delete-small.svg'

const minDropdownWidth = { minWidth: 150 }

type ProjectProps = {
	projectID: number
	setModalState: (value: { visible: boolean; projectID: undefined | number }) => void
}

enum ACTION_KEY {
	EDIT = 'EDIT',
	DELETE = 'DELETE'
}

const ProjectsTableActions = (props: ProjectProps) => {
	const { projectID, setModalState } = props
	const { t } = useTranslation()

	const permissedActions = usePermissionCheck()

	const dropdownMenu = (
		<Menu>
			<Menu.Item
				key={ACTION_KEY.EDIT}
				icon={<EditIcon />}
				onClick={(e) => {
					e.domEvent.stopPropagation()
					history.push(t('paths:projects/{{projectID}}', { projectID }))
				}}
			>
				{permissedActions?.includes(ACTION_TYPE.PROJECT_EDIT) ? t('loc:Edit') : t('loc:Show details')}
			</Menu.Item>
			<Menu.Item
				key={ACTION_KEY.DELETE}
				icon={<DeleteIcon />}
				className={permissedActions?.includes(ACTION_TYPE.PROJECT_DELETE) ? 'text-error-medium' : ''}
				onClick={(e) => {
					e.domEvent.stopPropagation()
					setModalState({ visible: true, projectID })
				}}
				disabled={!permissedActions?.includes(ACTION_TYPE.PROJECT_DELETE)}
			>
				{t('loc:Delete')}
			</Menu.Item>
		</Menu>
	)

	return (
		<Dropdown overlay={dropdownMenu} overlayStyle={minDropdownWidth} trigger={['click']}>
			<Button
				type='text'
				className={'extd-table-dropdown flex justify-center'}
				style={{ padding: '0' }}
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				<DotsIcon />
			</Button>
		</Dropdown>
	)
}

export default ProjectsTableActions
