/* eslint-disable import/no-cycle */
import i18next from 'i18next'

// types
import { ThunkResult } from '../index'
import { AUTH_USER } from './authUserTypes'
import { IResetStore, RESET_STORE } from '../generalTypes'
import { Paths } from '../../types/api'

// utils
import { setAccessToken, clearAccessToken, clearRefreshToken, setRefreshToken } from '../../utils/auth'
import { history } from '../../utils/history'
import { getReq, postReq } from '../../utils/requests'
import { NOTIFICATION_TYPE } from '../../utils/enums'

export interface IAuthUserPayload {
	originalData: Paths.GetApiV1UsersUserId.Responses.$200['user'] | null
	company: Paths.GetApiV1SettingsCompany.Responses.$200['company'] | null
	companyLogo: Paths.GetApiV1SettingsCompany.Responses.$200['logo'] | null
}

interface IGetAuthUser {
	type: AUTH_USER
	payload: IAuthUserPayload
}

export type IUserActions = IResetStore | IGetAuthUser

// eslint-disable-next-line import/prefer-default-export
export const logInUser =
	(input: { email: string; password: string }): ThunkResult<void> =>
	async (dispatch) => {
		let payload = {} as any
		try {
			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_START })

			const { data } = await postReq('/api/v1/authorization/login', null, input)

			setAccessToken(data.accessToken)
			setRefreshToken(data.refreshToken)

			const userData = await getReq('/api/v1/users/{userID}', { userID: data.user.id })

			let companyData
			try {
				companyData = await getReq('/api/v1/settings/company', undefined)
			} catch (e: any) {
				// eslint-disable-next-line
				console.error(e)
			}

			payload = {
				originalData: userData.data.user,
				company: companyData?.data?.company,
				companyLogo: companyData?.data?.logo
			}
			history.push(i18next.t('paths:index'))
			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_FAIL })
			history.push(i18next.t('paths:login'))
			// eslint-disable-next-line no-console
			console.error(e)
		}
		return payload
	}

export const reloadUserData =
	(userID: Paths.GetApiV1UsersUserId.Parameters.UserID): ThunkResult<void> =>
	async (dispatch) => {
		let payload = {}
		try {
			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_START })

			const userData = await getReq('/api/v1/users/{userID}', { userID })
			let companyData
			try {
				companyData = await getReq('/api/v1/settings/company', undefined)
			} catch (e: any) {
				// eslint-disable-next-line
				console.error(e)
			}

			payload = {
				originalData: userData.data.user,
				company: companyData?.data?.company,
				companyLogo: companyData?.data?.logo
			}

			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

export const changeUserLanguage =
	(newData: any): ThunkResult<void> =>
	async (dispatch) => {
		const payload = {
			originalData: newData?.originalData,
			company: newData?.company,
			companyLogo: newData?.companyLogo
		}

		dispatch({ type: AUTH_USER.AUTH_USER_LOAD_DONE, payload })
	}

export const logOutUser =
	(notification: boolean, redirectTo?: string): ThunkResult<void> =>
	async (dispatch) => {
		try {
			if (notification) await postReq('/api/v1/authorization/logout', null, undefined, undefined, NOTIFICATION_TYPE.NOTIFICATION, false)
			else await postReq('/api/v1/authorization/logout', null, undefined, undefined, false)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}

		clearAccessToken()
		clearRefreshToken()

		dispatch({
			type: RESET_STORE
		})

		if (redirectTo) history.push(redirectTo)
		else history.push(i18next.t('paths:login'))
	}
