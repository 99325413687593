import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { Row, Col, Form, Button, Progress, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { FORM } from '../../../utils/enums'
import InputPasswordField from '../../../atoms/InputPasswordField'
import { ReactComponent as Planshelf } from '../../../assets/images/image-planshelf.svg'
// eslint-disable-next-line import/no-cycle
import validateUserForm from './validateUserForm'
import { isStrongEnough } from '../../../utils/helpers'
import { RootState } from '../../../reducers'
import { renderTooltip } from '../../NewPasswordPage/components/NewPasswordForm'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPasswordForm {
	password: string
	password_repeat: string
}

type ComponentProps = {
	changeStep: any
	progress: number
}

type Props = InjectedFormProps<IPasswordForm, ComponentProps> & ComponentProps

const selector = formValueSelector(FORM.USER_CREATION_FORM)

const PasswordForm: FC<Props> = (props) => {
	const { t } = useTranslation()
	const { handleSubmit, submitting, progress } = props

	const formPassword = useSelector((state: RootState) => selector(state, 'password'))
	const canSubmit = isStrongEnough(formPassword) && !submitting
	const showTooltip = formPassword?.length > 0 && !canSubmit

	return (
		<Form layout={'vertical'} className={'mt-20'} onSubmitCapture={handleSubmit}>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Planshelf className={'mb-10'} />
				</Col>
				<Col span={14}>
					<Progress showInfo={false} percent={progress} className={'mb-10'} />
				</Col>
				<Col span={14}>
					<h3 className={'heading-3'}>{t('loc:Welcome.')}</h3>
					<p>{t('loc:Set password for your account.')}</p>
				</Col>
				<Col span={14}>
					<Tooltip visible={showTooltip} placement={'right'} title={renderTooltip(formPassword)}>
						<Field
							component={InputPasswordField}
							label={t('loc:Password')}
							placeholder={t('loc:Enter password')}
							type={'password'}
							size={'large'}
							name={'password'}
							required
						/>
					</Tooltip>
				</Col>
			</Row>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Field
						component={InputPasswordField}
						label={t('loc:Password again')}
						placeholder={t('loc:Enter password again')}
						type={'password'}
						size={'large'}
						name={'password_repeat'}
						required
					/>
				</Col>
				<Col span={14}>
					<Button type={'primary'} block className={'extd-btn'} size={'large'} htmlType={'submit'} disabled={submitting || !canSubmit} loading={submitting}>
						{t('loc:Continue')}
					</Button>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IPasswordForm, ComponentProps>({
	form: FORM.USER_CREATION_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: false,
	validate: validateUserForm
})(PasswordForm)

export default form
