import React from 'react'
import { WrappedFieldProps } from 'redux-form'
import dayjs from 'dayjs'
import cx from 'classnames'
import { FormItemProps } from 'antd/lib/form/FormItem'
import { Form, DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'

// eslint-disable-next-line import/no-cycle
import { formFieldID } from '../utils/helpers'

export const DEFAULT_DATE_INPUT_FORMAT = 'DD.MM.YYYY'
export const DEFAULT_DATE_INIT_FORMAT = 'YYYY-MM-DD'

type Props = WrappedFieldProps &
	FormItemProps &
	DatePickerProps & {
		hideHelp?: boolean
		rounded?: boolean
		readOnly?: boolean
		showToday?: any
	}

const DateField = (props: Props) => {
	const {
		input,
		label,
		required,
		style,
		meta: { form, error, touched },
		format = DEFAULT_DATE_INPUT_FORMAT,
		placeholder,
		disabled,
		allowClear,
		hideHelp,
		getPopupContainer,
		size,
		readOnly,
		showToday = true,
		defaultPickerValue,
		mode,
		open,
		onSelect,
		className
	} = props

	let value

	if (input.value && dayjs(input.value).isValid()) {
		value = dayjs(input.value)
	}

	return (
		<Form.Item
			label={label}
			required={required}
			style={style}
			help={hideHelp ? undefined : touched && error}
			className={cx({ 'form-item-disabled': disabled, readOnly }, className)}
			validateStatus={error && touched ? 'error' : undefined}
		>
			<DatePicker
				id={formFieldID(form, input.name)}
				{...input}
				className={className || 'extd-picker-dropdown'}
				onBlur={() => {}}
				onChange={(val) => {
					if (val) {
						input.onChange(val.format(DEFAULT_DATE_INIT_FORMAT))
					} else {
						input.onChange(null)
					}
				}}
				format={format}
				value={value as any}
				defaultPickerValue={defaultPickerValue}
				size={size}
				placeholder={placeholder}
				disabled={disabled}
				allowClear={allowClear}
				getPopupContainer={getPopupContainer || ((node) => node)}
				showToday={showToday}
				inputReadOnly={true}
				mode={mode}
				open={open}
				onSelect={onSelect}
			/>
		</Form.Item>
	)
}
export default DateField
