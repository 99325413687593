import React from 'react'
import { Button, Col, Row } from 'antd'
import { findIndex, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { arrayPush, change, Field, FieldArray, formValueSelector, WrappedFieldArrayProps } from 'redux-form'
import DraggableTabs from '../../../components/DraggableTabs'
import { RootState } from '../../../reducers'
import { ILabelInValue, ISelectOptionItem } from '../../../types/interfaces'
import { ReactComponent as Delete } from '../../../assets/icons/icon-delete.svg'
import { FORM, MSG_TYPE, NOTIFICATION_TYPE, PROJECT_USER_ROLE, TAB_TYPE } from '../../../utils/enums'
import { translateProjectUserRole, validationRequired, validationString } from '../../../utils/helpers'
import InputField from '../../../atoms/InputField'
import SelectField from '../../../atoms/SelectField'
import showNotifications from '../../../utils/tsxHelpers'

const stringMax100 = validationString(100)

interface TabValue {
	id: number | string
	name: string | null
	statusView: ILabelInValue<PROJECT_USER_ROLE> | null
}

type TabsFieldProps = WrappedFieldArrayProps<TabValue> & {
	selectedTabKey: string | number
	setSelectedTabKey: (tabKey: number | string) => void
	statusViewOptions: ISelectOptionItem[]
}

const TabsField = (param: TabsFieldProps) => {
	const { selectedTabKey, setSelectedTabKey, statusViewOptions } = param
	const { t } = useTranslation()
	const tabsValue = param.fields.getAll()
	const selectedTabIndex = findIndex(tabsValue, (tab) => tab.id === selectedTabKey)
	let tabContent = null

	const deleteTab = () => {
		if (tabsValue.length === 1) {
			showNotifications(
				[
					{
						type: MSG_TYPE.ERROR,
						message: t('loc:Folders has to have atleast one status')
					}
				],
				NOTIFICATION_TYPE.NOTIFICATION
			)
		} else {
			let siblingTabID
			if (selectedTabIndex === 0) {
				siblingTabID = tabsValue[selectedTabIndex + 1].id
			} else {
				siblingTabID = tabsValue[selectedTabIndex - 1].id
			}
			setSelectedTabKey(siblingTabID)
			param?.fields?.remove(selectedTabIndex)
		}
	}

	param?.fields?.forEach((name, index, fields) => {
		const value = fields.get(index)
		if (value.id === selectedTabKey) {
			tabContent = (
				<div className={'w-full bg-zinc-100 pb-4'} style={{ border: '1px solid transparent' }}>
					<div className={'mx-6 mt-6'}>
						<Row justify={'space-between'}>
							<Col span={18}>
								<Row>
									<Col span={15}>
										<Field
											component={InputField}
											size={'large'}
											name={`${name}.name`}
											placeholder={t('loc:Status name')}
											label={t('loc:Status name')}
											required
											validate={[validationRequired, stringMax100]}
										/>
									</Col>
									<Col span={7} className={'ml-4'}>
										<Field
											component={SelectField}
											name={`${name}.statusView`}
											options={statusViewOptions}
											labelInValue
											label={t('loc:Status view')}
											required
											validate={[validationRequired]}
										/>
									</Col>
								</Row>
							</Col>
							<Col>
								<Button type={'text'} danger className={'extd-btn'} icon={<Delete />} style={{ marginTop: 26 }} onClick={deleteTab}>
									{t('loc:Delete')}
								</Button>
							</Col>
						</Row>
					</div>
				</div>
			)
		}
	})
	return tabContent
}

type Props = {
	formName: FORM
}

const FolderTabs = ({ formName }: Props) => {
	const dispatch = useDispatch()
	const selector = formValueSelector(formName)

	const selectedTabKey: number | string | undefined = useSelector((state: RootState) => selector(state, 'selectedTabKey'))
	const tabsValue: TabValue[] = useSelector((state: RootState) => selector(state, 'tabs')) || []
	const selectedTab = selectedTabKey || tabsValue?.[0]?.id

	const tabsList = map(tabsValue, (item) => ({
		...item,
		name: item?.name
	}))

	const setSelectedTabKey = (key: number | string) => {
		dispatch(change(formName, 'selectedTabKey', key))
	}

	const createNewTab = () => {
		const newTab: TabValue = {
			id: `${tabsValue.length + 1}`,
			name: null,
			statusView: null
		}
		dispatch(arrayPush(formName, 'tabs', newTab))
		dispatch(change(formName, 'selectedTabKey', newTab.id))
	}

	const statusViewOptions = map(PROJECT_USER_ROLE, (key) => ({
		key,
		value: key,
		label: translateProjectUserRole(key)
	}))

	return (
		<div>
			<div className={'flex flex-row'}>
				<DraggableTabs
					selectedKey={selectedTab}
					onClick={setSelectedTabKey}
					setList={(reorderedState) => {
						dispatch(change(formName, 'tabs', reorderedState))
					}}
					tabsList={tabsList}
					tabType={TAB_TYPE.FOLDER}
					disabled={false}
					addButtonDisabled={tabsValue?.length >= 10}
					onAddButtonClick={createNewTab}
				/>
			</div>
			<FieldArray
				name='tabs'
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				component={TabsField as any}
				selectedTabKey={selectedTab}
				statusViewOptions={statusViewOptions}
				setSelectedTabKey={setSelectedTabKey}
			/>
		</div>
	)
}

export default FolderTabs
