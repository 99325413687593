import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, FieldArray, formValueSelector, getFormError, change } from 'redux-form'
import { map, filter, some, includes } from 'lodash'
import { Row, Col, Form, Select, Button, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { CODE_TYPE, FORM, PAGE_MAX_SIZE, PROJECT_USER_ROLE } from '../../../utils/enums'
import InputField from '../../../atoms/InputField'
import { getUsers } from '../../../reducers/users/usersActions'
import ProjectTabs from './ProjectTabs'
import ProjectParticipants from './ProjectParticipants'
import { RootState } from '../../../reducers'
import { selectFieldFilterOption } from '../../../atoms/SelectField'
import { ReactComponent as ProjectIcon } from '../../../assets/icons/icon-input-project.svg'
import { validationRequired } from '../../../utils/helpers'
import { ILabelInValue } from '../../../types/interfaces'

// eslint-disable-next-line import/no-cycle
import validate from './validateProjectForm'
import RadioGroupField from '../../../atoms/RadioGroupField'

type Participant = {
	id: number
	name: string
	surname: string
	email: string
	role: PROJECT_USER_ROLE
}

type Tab = {
	id: number
	name: string
	type: ILabelInValue
	/**
	 * constant - is set if tab type is CONSTANT
	 * option and priority - is set if tab type is STATUS
	 * option and isNew - is set if tab type is DROPDOWN
	 */
	items: [
		{
			option?: string | null
			constant?: string | null
			priority?: number | null
			isNew?: boolean | null
		}
	]
}
export interface IProjectForm {
	name: string
	selectedTabKey: number | string
	tabs: Tab[]
	participants: Participant[]
	isUpdating: boolean
	codeType: CODE_TYPE
	isCodeSet: boolean | undefined
	suffixDelimiter: string
	prefixDelimiter: string
}

type ComponentProps = {}

type Props = InjectedFormProps<IProjectForm, ComponentProps> & ComponentProps
const selector = formValueSelector(FORM.PROJECT_FORM)
const selectorError = getFormError(FORM.PROJECT_FORM)

const ProjectForm = (props: Props) => {
	const { handleSubmit } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const users = useSelector((state: RootState) => state.usersStore.users)
	const formParticipants = useSelector((state: RootState) => selector(state, 'participants'))
	const formTabs = useSelector((state: RootState) => selector(state, 'tabs'))
	const formErrorMessage = useSelector((state: RootState) => selectorError(state))
	const isUpdating = useSelector((state: RootState) => selector(state, 'isUpdating'))
	const codeType = useSelector((state: RootState) => selector(state, 'codeType'))
	const isCodeSet = useSelector((state: RootState) => selector(state, 'isCodeSet'))

	const [selectedUsersIDs, setSelectedUsersIDs] = useState<any[]>([])
	const [selectedProjectRole, setSelectedProjectRole] = useState(PROJECT_USER_ROLE.INTERNAL)

	const addParticipants = () => {
		const selectedUsers = filter(users?.originalData, (user) => includes(selectedUsersIDs, user.id))
		const selectedUsersWithRole = selectedUsers.map((item) => ({
			id: item?.id,
			name: item?.name,
			surname: item?.surname,
			email: item?.email,
			role: selectedProjectRole
		}))
		const newParticipants = [...(formParticipants || []), ...selectedUsersWithRole]
		dispatch(change(FORM.PROJECT_FORM, 'participants', newParticipants))
		setSelectedUsersIDs([])
	}

	const participantsOpts = filter(users?.options, (opt) => {
		const isAssigned = some(formParticipants, (tableUser) => tableUser?.id === opt.key)
		return !isAssigned
	})

	const roleOptions = [
		{
			value: PROJECT_USER_ROLE.INTERNAL,
			label: t('loc:Internal')
		},
		{
			value: PROJECT_USER_ROLE.EXTERNAL,
			label: t('loc:External')
		}
	]

	const codeOptions = [
		{ label: t('loc:Use code attributes'), value: CODE_TYPE.STATES },
		{ label: t(`loc:Don't define code attributes`), value: CODE_TYPE.FREE_TEXT }
	]

	const handlePrefixChange = (value: string | undefined) => {
		if (!value) {
			dispatch(change(FORM.PROJECT_FORM, 'prefixDelimiter', value))
		} else if (/^[.&$^%@#*!?><:"}{[()\]]+$/.test(value)) {
			dispatch(change(FORM.PROJECT_FORM, 'prefixDelimiter', value))
		}
	}

	const handleSuffixChange = (value: string | undefined) => {
		if (!value) {
			dispatch(change(FORM.PROJECT_FORM, 'suffixDelimiter', value))
		} else if (/^[&$^%@#*!?><:"}{[()\]]+$/.test(value)) {
			dispatch(change(FORM.PROJECT_FORM, 'suffixDelimiter', value))
		}
	}

	const projectCodePreview = map(formTabs, (tab) => (tab.name ? tab.name : tab.type?.value)).join('_')

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<div className={'bg-white p-4 mb-2 rounded'}>
				<Row gutter={[16, 16]}>
					<Col span={14}>
						<h4 className={'text-l-bold cursor-default'}>{t('loc:Project name')}</h4>
					</Col>
					<Col span={14}>
						<Field component={InputField} name={'name'} placeholder={t('loc:Enter project name')} size={'large'} validate={[validationRequired]} />
					</Col>
				</Row>
			</div>
			<div className={'bg-white p-4 mb-2 rounded'}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<h4 className={'text-l-bold cursor-default'}>{t('loc:Add teammates')}</h4>
					</Col>
					<Col span={11}>
						<Select
							placeholder={t('loc:Search by name or email')}
							size={'large'}
							className={'extd-select'}
							options={participantsOpts}
							value={selectedUsersIDs}
							mode={'multiple'}
							loading={users.isLoading}
							onChange={(value) => setSelectedUsersIDs(value)}
							filterOption={selectFieldFilterOption}
							onFocus={() => dispatch(getUsers(PAGE_MAX_SIZE))}
						/>
					</Col>
					<Col>
						<Select
							size={'large'}
							className={'extd-select w-32'}
							options={roleOptions}
							value={selectedProjectRole}
							onChange={(role: any) => setSelectedProjectRole(role)}
							defaultValue={PROJECT_USER_ROLE.INTERNAL}
						/>
					</Col>
					<Col>
						<Button className={'extd-btn'} type={'primary'} onClick={addParticipants}>
							{t('loc:Add to project')}
						</Button>
					</Col>
					{formParticipants?.length > 0 && (
						<Col span={24}>
							<FieldArray component={ProjectParticipants} name={'participants'} formName={FORM.PROJECT_FORM} />
						</Col>
					)}
				</Row>
			</div>
			<div className={'bg-white p-4 mb-2 rounded'}>
				<Row gutter={16}>
					<Col span={24}>
						<h4 className={'text-l-bold cursor-default'}>{t('loc:Define plans naming code')}</h4>
					</Col>
					{(!isUpdating || (isUpdating && !isCodeSet)) && (
						<Col span={24} className={'mt-4'}>
							<Field component={RadioGroupField} options={codeOptions} name={'codeType'} />
						</Col>
					)}
					{codeType === CODE_TYPE.STATES && (
						<>
							<Col span={11} className={'mt-4'}>
								<Input prefix={<ProjectIcon />} className={'extd-input'} readOnly value={projectCodePreview} />
							</Col>
							<Col span={24}>
								<div className={'h-7 flex items-center mt-0 text-error-medium cursor-default'}>
									{isUpdating && isCodeSet ? t('loc:Cannot update plans naming code except adding new dropdown option!') : formErrorMessage}
								</div>
								<FieldArray component={ProjectTabs} name={'tabs'} formName={FORM.PROJECT_FORM} />
							</Col>
						</>
					)}
				</Row>
			</div>
			<div className={'bg-white p-4 mb-2 rounded'}>
				<Row gutter={16}>
					<Col span={24}>
						<h4 className={'text-l-bold cursor-default'}>{t('loc:Define prefix and suffix for bulk upload')}</h4>
					</Col>
					<Col span={24}>
						<ul>
							<li>{t('loc:You can turn these off by keeping them empty')}</li>
							<li>{t('loc:Suffix and Prefix cannot be the same')}</li>
							<li>{t(`loc:Suffix cannot include '.', but prefix can. Dot cannot be standalone`)}</li>
							<li>{t('loc:Either can be long up to 5 characters')}</li>
							<li>{t('loc:Accepted characters are !?§@#$%^&*(){}[]<>')}</li>
						</ul>
					</Col>
					<Col span={8} className={'mt-4'}>
						<Field
							name={'prefixDelimiter'}
							label={t('loc:Prefix')}
							maxLength={5}
							component={InputField}
							placeholder={t('loc:Choose prefix')}
							customOnChange={handlePrefixChange}
						/>
					</Col>
					<Col span={8} className={'mt-4'}>
						<Field
							name={'suffixDelimiter'}
							label={t('loc:Suffix')}
							maxLength={5}
							component={InputField}
							placeholder={t('loc:Choose suffix')}
							customOnChange={handleSuffixChange}
						/>
					</Col>
				</Row>
			</div>
		</Form>
	)
}

const form = reduxForm<IProjectForm, ComponentProps>({
	form: FORM.PROJECT_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(ProjectForm)

export default form
