import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { reset } from 'redux-form'
import { postReq } from '../../utils/requests'
import ForgotPasswordForm, { IForgotPasswordForm } from './components/ForgotPasswordForm'
import { FORM } from '../../utils/enums'

type Props = {}

const ForgotPasswordPage: FC<Props> = () => {
	const dispatch = useDispatch()
	const handleSubmit = async (values: IForgotPasswordForm) => {
		try {
			await postReq('/api/v1/authorization/forgot-password', null, values)
			dispatch(reset(FORM.FORGOT_PASSWORD))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}

	return <ForgotPasswordForm onSubmit={handleSubmit as any} />
}

export default ForgotPasswordPage
