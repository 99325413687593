/* eslint-disable import/prefer-default-export */

export enum NAMESPACE {
	PATHS = 'paths',
	LOC = 'loc'
}

export enum APP_LANGUAGE {
	EN = 'en',
	DE = 'de'
}

export enum FORM {
	LOGIN = 'LOGIN',
	FORGOT_PASSWORD = 'FORGOT_PASSWORD',
	CREATE_PASSWORD = 'CREATE_PASSWORD',
	PROJECT_FORM = 'CREATE_PROJECT',
	MAIN_FOLDER_CREATE_FORM = 'MAIN_FOLDER_CREATE_FORM',
	RENAME_FOLDER_FORM = 'RENAME_FOLDER_FORM',
	SUB_FOLDER_CREATE_FORM = 'SUB_FOLDER_CREATE_FORM',
	MAIN_FOLDER_EDIT_STATUSES_FORM = 'MAIN_FOLDER_EDIT_STATUSES_FORM',
	PLAN_CREATE_FORM = 'PLAN_CREATE_FORM',
	PLAN_EDIT_FORM = 'PLAN_EDIT_FORM',
	INVITE_USER_FORM = 'INVITE_USER_FORM',
	EDIT_USER_FORM = 'EDIT_USER_FORM',
	USER_CREATION_FORM = 'USER_CREATION_FORM',
	UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
	SETTINGS_CONTACTS_FORM = 'SETTINGS_CONTACTS_FORM',
	SETTINGS_CHANGE_PASSWORD_FORM = 'SETTINGS_CHANGE_PASSWORD_FORM',
	SETTINGS_DELETE_ACCOUNT_FORM = 'SETTINGS_DELETE_ACCOUNT_FORM',
	SETTINGS_EMAIL_NOTIFICATIONS_FORM = 'SETTINGS_EMAIL_NOTIFICATIONS_FORM',
	SETTINGS_COMPANY_FORM = 'SETTINGS_COMPANY_FORM',
	ADMIN_CREATION_FORM = 'ADMIN_CREATION_FORM',
	ADMIN_INVITE = 'ADMIN_INVITE',
	BULK_UPLOAD_FORM = 'BULK_UPLOAD_FORM',
	SEARCH_FILTER = 'SEARCH_FILTER',
	DATES_FILTER = 'DATES_FILTER',
	NOTE_FORM = 'NOTE_FORM',
	INDEX_FORM = 'INDEX_FORM',
	PLANS_SEARCH_FILTER = 'PLANS_SEARCH_FILTER'
}

export enum NOTIFICATION_TYPE {
	MODAL = 'MODAL',
	NOTIFICATION = 'NOTIFICATION'
}

export enum MODAL_TYPE_FOLDER {
	DELETE,
	RENAME
}

export enum MODAL_TYPE_PLANS {
	DELETE,
	EDIT,
	DUPLICATE_RESPONSE
}

export enum MSG_TYPE {
	INFO = 'INFO',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS'
}

export enum TOKEN_AUDIENCE {
	FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD',
	INVITATION = 'INVITATION'
}

export enum MENU_KEYS {
	TOP_MENU = 'TOP_MENU',
	LEFT_MENU = 'LEFT_MENU'
}

export enum TOP_MENU_ITEM {
	FILES = 'FILES',
	ACTIVITIES = 'ACTIVITIES',
	TEAMMATES = 'TEAMMATES'
}

export enum PROJECT_CODE_TYPE {
	INDEX = 'INDEX',
	SERIAL_NUMBER = 'SERIAL_NUMBER',
	DROPDOWN = 'DROPDOWN',
	INPUT = 'INPUT',
	CONSTANT = 'CONSTANT',
	STATUS = 'STATUS'
}

export enum LEFT_MENU_ITEM {
	PROJECTS = 'PROJECTS'
}

export enum PROJECT_USER_ROLE {
	INTERNAL = 'INTERNAL',
	EXTERNAL = 'EXTERNAL'
}

export enum USER_ROLE {
	ADMINISTRATOR = 'ADMINISTRATOR',
	EDITOR = 'EDITOR',
	GUEST = 'GUEST'
}

export enum ACTIVITY {
	CREATE = 'CREATE',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	UPLOAD = 'UPLOAD',
	ARCHIVE = 'ARCHIVE',
	NOTE_UPDATE = 'NOTE_UPDATE'
}

export enum ACTION_TYPE {
	PROJECT_CREATE = 'PROJECT_CREATE',
	FOLDER_CREATE = 'FOLDER_CREATE',
	PLAN_CREATE = 'PLAN_CREATE',
	PROJECT_EDIT = 'PROJECT_EDIT',
	PROJECT_DELETE = 'PROJECT_DELETE',
	FOLDER_DELETE = 'FOLDER_DELETE',
	FOLDER_EDIT = 'FOLDER_EDIT',
	PLAN_DELETE = 'PLAN_DELETE',
	PLAN_DUPLICATE = 'PLAN_DUPLICATE',
	PLAN_EDIT = 'PLAN_EDIT',
	TEAM_MATES = 'TEAM_MATES',
	UPLOAD = 'UPLOAD',
	COMPANY_EDIT = 'COMPANY_EDIT'
}

export enum TAB_TYPE {
	PROJECT = 'PROJECT',
	FOLDER = 'FOLDER'
}

export enum TREE_NODE_TYPE {
	PROJECT = 'PROJECT',
	MAIN_FOLDER = 'MAIN-FOLDER',
	SUB_FOLDER = 'SUB-FOLDER'
}

export enum CODE_TYPE {
	FREE_TEXT = 'FREE_TEXT',
	STATES = 'STATES'
}

export enum UPLOAD_ERROR_TYPE {
	FILE = 'FILE',
	PATH = 'PATH',
	UNNASSIGNED_PATH = 'UNNASSIGNED_PATH',
	MUTLIPLE_PATH = 'MULTIPLE_PATH'
}

export enum SETTINGS_MENU_KEY {
	CONTACT = 'CONTACT',
	PASSWORD = 'PASSWORD',
	NOTIFICATION = 'NOTIFICATION',
	DELETE = 'DELETE',
	COMPANY = 'COMPANY'
}

export const PAGINATION = {
	pageSizeOptions: [25, 50, 100]
}

export const DEFAULT_PAGE_SIZE = 25

export const PAGE_MAX_SIZE = 500

export const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 13 // 13 minutes

export const REFRESH_PAGE_INTERVAL = 1000 * 60 * 60 * 4 // 4 hours

export const SIDEBAR_WIDTH = 240

export const DEFAULT_LANGUAGE = APP_LANGUAGE.EN

export enum USER_CREATION_STEP {
	PASSWORD,
	PROFILE,
	COMPANY
}

export enum ADMIN_CREATION_STEP {
	PASSWORD,
	PROFILE,
	COMPANY,
	COMPANY_ADDRESS,
	CONTACT_PERSON,
	COMPANY_INFO
}
