export enum PROJECTS {
	PROJECTS_LOAD_START = 'PROJECTS_LOAD_START',
	PROJECTS_LOAD_DONE = 'PROJECTS_LOAD_DONE',
	PROJECTS_LOAD_FAIL = 'PROJECTS_LOAD_FAIL'
}

export enum PROJECTS_SIDER {
	PROJECTS_SIDER_LOAD_START = 'PROJECTS_SIDER_LOAD_START',
	PROJECTS_SIDER_LOAD_DONE = 'PROJECTS_SIDER_LOAD_DONE',
	PROJECTS_SIDER_LOAD_FAIL = 'PROJECTS_SIDER_LOAD_FAIL'
}

export enum PROJECT {
	PROJECT_LOAD_START = 'PROJECT_LOAD_START',
	PROJECT_LOAD_DONE = 'PROJECT_LOAD_DONE',
	PROJECT_LOAD_FAIL = 'PROJECT_LOAD_FAIL'
}
