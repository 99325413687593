import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Layout, Menu } from 'antd'
import { ContactsOutlined, DeleteOutlined, HomeOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import i18next from 'i18next'
import DetailHeader from '../../layouts/components/DetailHeader'
import { ACTION_TYPE, APP_LANGUAGE, FORM, SETTINGS_MENU_KEY } from '../../utils/enums'
import Contacts from './components/Contacts/Contacts'
import ChangePassword from './components/ChangePassword/ChangePassword'
import { getReq } from '../../utils/requests'
import { RootState } from '../../reducers'
import DeleteAccountModal from './components/DeleteAccount/DeleteAccountModal'
import EmailNotifications from './components/EmailNotifications/EmailNotifications'
import usePermissionCheck from '../../utils/permissionCheck'
import Company from './components/Company/Company'

const SettingsPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const permissedActions = usePermissionCheck()

	const [modalState, setModalState] = useState(false)
	const [selectedKey, setActiveKey] = useState(SETTINGS_MENU_KEY.CONTACT)
	const userID: number = useSelector((state: RootState) => state?.userStore?.authUser?.originalData?.id) || 0

	const handleSelect = async (e: any) => {
		if (e.key === SETTINGS_MENU_KEY.CONTACT) {
			const data = await getReq('/api/v1/users/{userID}', { userID })

			const initData = {
				name: data.data.user?.name,
				surname: data.data.user?.surname,
				email: data.data.user?.email,
				phone: data.data.user?.phone,
				company: data.data.user?.company,
				companyRole: data.data.user?.companyRole,
				note: data.data.user?.note
			}

			dispatch(initialize(FORM.SETTINGS_CONTACTS_FORM, initData))
		}
		if (e.key === SETTINGS_MENU_KEY.PASSWORD) {
			// aby bolo cisto pri znovu otvoreni
			const initData = {
				actualPassword: null,
				newPassword: null,
				repeatedPassword: null
			}

			dispatch(initialize(FORM.SETTINGS_CHANGE_PASSWORD_FORM, initData))
		}

		if (e.key === SETTINGS_MENU_KEY.NOTIFICATION) {
			const data = await getReq('/api/v1/users/{userID}', { userID })

			const initData = {
				1: data?.data?.user?.notificationDays?.includes(1),
				2: data?.data?.user?.notificationDays?.includes(2),
				3: data?.data?.user?.notificationDays?.includes(3),
				4: data?.data?.user?.notificationDays?.includes(4),
				5: data?.data?.user?.notificationDays?.includes(5),
				6: data?.data?.user?.notificationDays?.includes(6),
				7: data?.data?.user?.notificationDays?.includes(7)
			}

			dispatch(initialize(FORM.SETTINGS_EMAIL_NOTIFICATIONS_FORM, initData))
		}

		if (e.key === SETTINGS_MENU_KEY.COMPANY) {
			const data = await getReq('/api/v1/settings/company', undefined)
			const initData = {
				company: data?.data?.company,
				logo: data?.data?.logo?.id
					? [
							{
								id: data?.data?.logo?.id,
								name: data?.data?.logo?.id,
								url: data?.data?.logo?.previewUrl,
								thumbUrl: data?.data?.logo?.previewUrl
							}
					  ]
					: undefined,
				address: data?.data?.address?.address,
				city: data?.data?.address?.city,
				postalcode: data?.data?.address?.postalCode,
				country: data?.data?.address?.country,
				contactname: data?.data?.contactPerson?.name,
				contactsurname: data?.data?.contactPerson?.surname,
				contactemail: data?.data?.contactPerson?.email,
				contactphone: data?.data?.contactPerson?.phone,
				web: data?.data?.web
			}

			dispatch(initialize(FORM.SETTINGS_COMPANY_FORM, initData))
		}

		setActiveKey(e.key)
	}

	return (
		<Layout className={'extd-layout bg-white'}>
			<DetailHeader title={t('loc:Settings')} />
			<div className={'mt-20 content-body medium flex flex-row'}>
				<div>
					<Menu className={`${i18next.language === APP_LANGUAGE.EN ? 'w-52' : 'w-60'} extd-settings`} selectedKeys={[selectedKey]} onClick={(e) => handleSelect(e)}>
						<Menu.Item key={SETTINGS_MENU_KEY.CONTACT} icon={<ContactsOutlined />}>
							{t('loc:Contact info')}
						</Menu.Item>
						<Menu.Item key={SETTINGS_MENU_KEY.NOTIFICATION} icon={<MailOutlined />}>
							{t('loc:Email notifications')}
						</Menu.Item>
						<Menu.Item key={SETTINGS_MENU_KEY.PASSWORD} icon={<LockOutlined />}>
							{t('loc:Change password')}
						</Menu.Item>
						{permissedActions?.includes(ACTION_TYPE.COMPANY_EDIT) && (
							<Menu.Item key={SETTINGS_MENU_KEY.COMPANY} icon={<HomeOutlined />}>
								{t('loc:Company')}
							</Menu.Item>
						)}
						<Menu.Item key={SETTINGS_MENU_KEY.DELETE} icon={<DeleteOutlined />}>
							{t('loc:Delete my account')}
						</Menu.Item>
					</Menu>
				</div>
				<div className={'flex justify-center'} style={{ width: '100%', marginLeft: '10%', marginRight: 'auto' }}>
					{selectedKey === SETTINGS_MENU_KEY.CONTACT && <Contacts />}
					{selectedKey === SETTINGS_MENU_KEY.PASSWORD && <ChangePassword />}
					{selectedKey === SETTINGS_MENU_KEY.NOTIFICATION && <EmailNotifications />}
					{selectedKey === SETTINGS_MENU_KEY.COMPANY && <Company />}
					{selectedKey === SETTINGS_MENU_KEY.DELETE && (
						<div className={'flex flex-col items-center justify-center'}>
							<span className={'mb-6 cursor-default'}>{t('loc:After this there is no going back. Are you sure?')}</span>
							<Button
								type={'primary'}
								className={'extd-btn-danger'}
								onClick={() => {
									dispatch(initialize(FORM.SETTINGS_DELETE_ACCOUNT_FORM, { password: null }))
									setModalState(true)
								}}
							>
								{t('loc:Yes, delete my account.')}
							</Button>
						</div>
					)}
				</div>
			</div>
			<DeleteAccountModal visible={modalState} onClose={() => setModalState(false)} />
		</Layout>
	)
}

export default SettingsPage
