import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Upload } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { getAccessToken } from '../../../../../utils/auth'
import { FORM, MSG_TYPE, NOTIFICATION_TYPE, TREE_NODE_TYPE } from '../../../../../utils/enums'
import showNotifications from '../../../../../utils/tsxHelpers'
import { IBulkUploadForm } from './BulkUploadForm'
import UploadBulkModal from './UploadBulkModal'
import { history } from '../../../../../utils/history'
import { getReq, postReq } from '../../../../../utils/requests'
import { RootState } from '../../../../../reducers'
import { deselectPlanHeader, isPlanHeaderIndeterminate, isPlanHeaderSelected, selectPlanHeader } from '../PlanDownloadHandler'
import { setDownloadList } from '../../../../../reducers/dowload/downloadActions'

const { Dragger } = Upload

type Props = {
	name: string
	subFolderID?: number
	folderID?: number
	projectCodeAttributes: any
}

const DraggerHeader = ({ name, subFolderID, projectCodeAttributes, folderID }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const menu = useSelector((state: RootState) => state?.menuStore)?.path
	const downloadList = useSelector((state: RootState) => state?.downloadStore?.download?.data)
	const plansRedux = useSelector((state: RootState) => state?.plansStore?.plans)

	const [bulkFileList, setBulkFileList] = useState<any[]>([])
	const [modalVisible, setModalVisible] = useState<boolean>(false)
	const [folderState, setFolderStateID] = useState(0)

	const getFolderStateID = async () => {
		try {
			const { data } = await getReq('/api/v1/folders/{folderID}', { folderID: menu?.folder?.ID || 0 })
			const folderStateID = data?.folder?.states?.find((state) => state?.name === name)?.id || 0
			setFolderStateID(folderStateID)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		if (bulkFileList.length > 0 && !modalVisible) {
			setModalVisible(true)
		}
		getFolderStateID()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bulkFileList.length, modalVisible, menu?.folder?.ID, name])

	const bulkUploadFiles = async (values: IBulkUploadForm) => {
		try {
			const reqBody = {
				projectCodeAttributeIDs: values?.selectStatusAttributes?.filter((item) => !!item.value)?.map((value) => value?.id),
				fileIDs: bulkFileList.map((file) => file?.id),
				...(!!subFolderID && { subFolderID })
			}

			const { data } = await getReq('/api/v1/folders/{folderID}', { folderID: menu?.folder?.ID || 0 })
			const plans = await getReq('/api/v1/plans/', { folderID: menu?.folder?.ID || 0 })
			const folderStateID = data?.folder?.states?.find((state) => state?.name === name)?.id || 0

			const response = await postReq('/api/v1/documents/{folderStateID}', { folderStateID }, reqBody as any)
			const planStates: any[] = []
			const unnassignedPlanStates: any[] = []

			response?.data?.certainSuggestions?.forEach((suggestion) => {
				const planDestination = plans?.data?.plans?.find((plan) => plan?.id === suggestion?.plan?.id)
				const planStateDestination = planDestination?.planStates?.find((planState) => planState?.name === name)
				const newPlanState = {
					files: suggestion?.files?.map((file) => {
						return { id: file?.id, name: file?.name }
					}),
					route: {
						project: { label: menu?.project?.name, key: menu?.project?.ID, value: menu?.project?.ID },
						folder: { label: menu?.folder?.name, key: menu?.folder?.ID, value: menu?.folder?.ID },
						plan: { label: suggestion?.plan?.name, key: suggestion?.plan?.id, value: suggestion?.plan?.id },
						planState: { label: planStateDestination?.name, key: planStateDestination?.id, value: planStateDestination?.id },
						subFolder: menu?.subFolder ? { label: menu?.subFolder?.name, key: menu?.subFolder?.ID, value: menu?.subFolder?.ID } : null
					}
				}
				planStates.push(newPlanState)
			})

			const unnasignedSuggestions = [
				...response.data.noPlansSuggestions,
				...(response.data.alreadyAssignedFiles?.map((file) => {
					return {
						file: {
							id: file?.id,
							name: file?.name
						}
					}
				}) || [])
			]

			const multipleSuggestionPlanStates = response.data.multiplePlansSuggestions?.map((item) => {
				return {
					file: { id: item?.file?.id, name: item?.file?.name },
					routes: item?.plans?.map((route) => {
						const planDestination = plans?.data?.plans?.find((plan) => plan?.id === route?.id)
						const planStateDestination = planDestination?.planStates?.find((planState) => planState?.name === name)
						return {
							project: { label: menu?.project?.name, key: menu?.project?.ID, value: menu?.project?.ID },
							folder: { label: menu?.folder?.name, key: menu?.folder?.ID, value: menu?.folder?.ID },
							subFolder: menu?.subFolder ? { label: menu?.subFolder?.name, key: menu?.subFolder?.ID, value: menu?.subFolder?.ID } : null,
							plan: { label: route?.name, key: route?.id, value: route?.id },
							planState: { label: planStateDestination?.name, key: planStateDestination?.id, value: planStateDestination?.id }
						}
					})
				}
			})

			if (unnasignedSuggestions && unnasignedSuggestions?.length > 0) {
				unnasignedSuggestions?.forEach((suggestion) => {
					const newPlanState = {
						file: { id: suggestion?.file?.id, name: suggestion?.file?.name },
						route: {
							project: { label: menu?.project?.name, key: menu?.project?.ID, value: menu?.project?.ID },
							folder: { label: menu?.folder?.name, key: menu?.folder?.ID, value: menu?.folder?.ID },
							plan: null,
							subFolder: menu?.subFolder ? { label: menu?.subFolder?.name, key: menu?.subFolder?.ID, value: menu?.subFolder?.ID } : null
						}
					}
					unnassignedPlanStates.push(newPlanState)
				})
			}

			const initialData = {
				notify: true,
				planStates,
				unnassignedPlanStates,
				multipleSuggestionPlanStates
			}

			dispatch(initialize(FORM.UPLOAD_DOCUMENTS, initialData))

			history.push(t('paths:upload-documents'))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}

		setBulkFileList([])
		setModalVisible(false)
	}

	const handleBulkUploadChange = async (info: UploadChangeParam<UploadFile<any>>) => {
		if (info.file.status === 'error') {
			showNotifications(
				[
					{
						type: MSG_TYPE.ERROR,
						message: t('loc:Upload failed, try again.')
					}
				],
				NOTIFICATION_TYPE.NOTIFICATION
			)
			setBulkFileList([...info.fileList.filter((file) => file.uid !== info.file.uid)])
		}
		if (info.file.status === 'done' || info.file.status === 'success') {
			const newFileList = info.fileList.filter((file) => file.uid !== info.file.uid)
			setBulkFileList([...newFileList, { id: info.file?.response?.file?.id, name: info.file?.response?.file?.name, isDone: true }])
		} else {
			setBulkFileList(info.fileList)
		}
	}

	const handleCheckboxChange = async (isSelected: boolean) => {
		if (isSelected) {
			dispatch(setDownloadList(deselectPlanHeader(downloadList, plansRedux, folderState)))
		} else {
			const reqBody = {
				planIDs: plansRedux?.plans?.map((item) => item.id)
			}

			const params = {
				fromDate: plansRedux?.fromDate,
				toDate: plansRedux?.toDate
			}

			const data = await postReq('/api/v1/plans/documents', params, reqBody as any)
			const newDownloadList = selectPlanHeader(downloadList, plansRedux, folderState, menu, data?.data)
			dispatch(setDownloadList(newDownloadList))
		}
	}

	return (
		<div className={'extd-dragger-wrapper'}>
			<Checkbox
				onClick={() =>
					handleCheckboxChange(
						isPlanHeaderSelected(
							downloadList,
							plansRedux,
							subFolderID ? TREE_NODE_TYPE.SUB_FOLDER : TREE_NODE_TYPE.MAIN_FOLDER,
							folderState,
							subFolderID || folderID || 0
						)
					)
				}
				indeterminate={isPlanHeaderIndeterminate(
					downloadList,
					plansRedux,
					subFolderID ? TREE_NODE_TYPE.SUB_FOLDER : TREE_NODE_TYPE.MAIN_FOLDER,
					folderState,
					subFolderID || folderID || 0
				)}
				checked={isPlanHeaderSelected(
					downloadList,
					plansRedux,
					subFolderID ? TREE_NODE_TYPE.SUB_FOLDER : TREE_NODE_TYPE.MAIN_FOLDER,
					folderState,
					subFolderID || folderID || 0
				)}
			/>
			<Dragger
				accept={undefined}
				headers={{
					Authorization: `Bearer ${getAccessToken()}`
				}}
				action={`api/v1/documents`}
				maxCount={100}
				multiple
				style={{ padding: '0', margin: '0' }}
				fileList={bulkFileList}
				showUploadList={false}
				onChange={handleBulkUploadChange}
				className={'flex justify-center items-center h-10 overflow-hidden'}
			>
				<Button
					className={`extd-btn border-0 hover:bg-white border-blue-600`}
					style={{ width: '100%' }}
					type={'default'}
					onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
					}}
				>
					<span className={'text-s-regular ml-2 inline'}>{name}</span>
				</Button>
			</Dragger>
			{modalVisible && (
				<UploadBulkModal
					onCancel={() => {
						setModalVisible(false)
						setBulkFileList([])
					}}
					onSubmit={(values: IBulkUploadForm) => {
						bulkUploadFiles(values)
					}}
					fileList={bulkFileList}
					projectCodeAttributes={projectCodeAttributes}
					visible={modalVisible}
				/>
			)}
		</div>
	)
}

export default DraggerHeader
