import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, RouteProps } from 'react-router'
import IdleTimer from 'react-idle-timer'

import BaseRoute from './BaseRoute'

import { isLoggedIn } from '../utils/auth'
import { REFRESH_PAGE_INTERVAL } from '../utils/enums'

type Props = RouteProps & {
	layout?: React.ReactNode
	component: React.ReactNode
}

const onIdle = () => {
	document.location.reload()
}

const AuthRoute: FC<Props> = (props) => {
	const { t } = useTranslation()

	if (!isLoggedIn()) {
		return <Redirect to={t('paths:login')} />
	}

	return (
		<>
			{/* NOTE: prevent to have cached app version */}
			<IdleTimer element={document} onIdle={onIdle} debounce={250} timeout={REFRESH_PAGE_INTERVAL} />
			<BaseRoute {...(props as any)} />
		</>
	)
}

export default AuthRoute
