import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Form, Row, Col, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import InputField from '../../../../atoms/InputField'
import { FORM } from '../../../../utils/enums'
// eslint-disable-next-line import/no-cycle
import validate from './validateContactsForm'

export interface IContactsForm {
	name: string
	surname: string
	email: string
	phone: string
	company: string
	companyRole: string
	note: string
}

type ComponentProps = {}

type Props = InjectedFormProps<IContactsForm, ComponentProps> & ComponentProps

const ContactsForm = (props: Props) => {
	const { handleSubmit, submitting } = props

	const { t } = useTranslation()

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Row className={'mb-4'}>
				<span className={'heading-3'}>{t('loc:Contact info')}</span>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Field name={'name'} component={InputField} label={t('loc:Name')} placeholder={t('loc:Enter name')} required />
				</Col>
				<Col span={12}>
					<Field name={'surname'} component={InputField} label={t('loc:Surname')} placeholder={t('loc:Enter name')} required />
				</Col>
				<Col span={24}>
					<Field name={'email'} component={InputField} label={t('loc:Email')} placeholder={t('loc:Enter email')} required />
				</Col>
				<Col span={24}>
					<Field name={'phone'} component={InputField} label={t('loc:Phone')} placeholder={t('loc:Enter phone')} />
				</Col>
				<Col span={24}>
					<Field name={'company'} component={InputField} label={t('loc:Company/Authority')} placeholder={t('loc:Enter company')} />
				</Col>
				<Col span={24}>
					<Field name={'companyRole'} component={InputField} label={t('loc:Position')} placeholder={t('loc:e.g. an engineer, a quality inspector')} />
				</Col>
				<Col span={24}>
					<Field name={'note'} component={InputField} label={t('loc:Note')} placeholder={t('loc:Additional relevant info about you')} />
				</Col>
			</Row>
			<Button type={'primary'} className={'extd-btn w-52'} htmlType={'submit'} disabled={submitting} loading={submitting}>
				{t('loc:Save')}
			</Button>
		</Form>
	)
}

const form = reduxForm<IContactsForm, ComponentProps>({
	form: FORM.SETTINGS_CONTACTS_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(ContactsForm)

export default form
