import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Row, Col, Form, Button, Progress } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { FORM, USER_CREATION_STEP } from '../../../utils/enums'
import InputField from '../../../atoms/InputField'
import { ReactComponent as Planshelf } from '../../../assets/images/image-planshelf.svg'
// eslint-disable-next-line import/no-cycle
import validateUserForm from './validateUserForm'

export interface ICompanyForm {
	company: string
	companyRole: string
	note: string
}

type ComponentProps = {
	changeStep: any
	progress: number
	isLastStep?: boolean
}

type Props = InjectedFormProps<ICompanyForm, ComponentProps> & ComponentProps

const CompanyForm: FC<Props> = (props) => {
	const { t } = useTranslation()
	const { handleSubmit, submitting, changeStep, progress, isLastStep } = props

	return (
		<Form layout={'vertical'} className={'mt-20'} onSubmitCapture={handleSubmit}>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Planshelf className={'mb-10'} />
				</Col>
				<Col span={14}>
					<Progress showInfo={false} percent={progress} className={'mb-5'} />
				</Col>
				<Col span={14}>
					<div className={'cursor-pointer mb-5'} onKeyPress={() => {}} tabIndex={0} role='button' onClick={() => changeStep(USER_CREATION_STEP.PROFILE)}>
						<ArrowLeftOutlined className={'text-blue-600'} />
						<span className={'ml-2 text-blue-600'}>{t('loc:Back')}</span>
					</div>
				</Col>
				<Col span={14}>
					<h3 className={'heading-3'}>{t('loc:Set info about your job')}</h3>
				</Col>
				<Col span={14}>
					<Field component={InputField} label={t('loc:Company / office')} placeholder={t('loc:Company')} name={'company'} size={'large'} />
				</Col>
			</Row>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Field component={InputField} label={t('loc:Position')} placeholder={t('loc:e.g. an engineer, a quality inspector')} name={'companyRole'} size={'large'} />
				</Col>
			</Row>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Field
						component={InputField}
						label={t('loc:Notes')}
						placeholder={t('loc:Additional info about you relevant for your teammates')}
						name={'note'}
						size={'large'}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Button type={'primary'} block className={'extd-btn'} size={'large'} htmlType={'submit'} disabled={submitting} loading={submitting}>
						{isLastStep ? t('loc:Go to projects') : t('loc:Continue')}
					</Button>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<ICompanyForm, ComponentProps>({
	form: FORM.USER_CREATION_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: false,
	validate: validateUserForm
})(CompanyForm)

export default form
