import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Row, Col, DatePicker, Typography, Tooltip } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import type { DatePickerProps } from 'antd/es/date-picker'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'

// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { map } from 'lodash'
import { RootState } from '../../reducers'
import { getActivities } from '../../reducers/activities/activitiesActions'
import { ReactComponent as ProjectImage } from '../../assets/images/image-activities-empty-state.svg'
import { ReactComponent as ProjectIcon } from '../../assets/icons/icon-project.svg'
import { ReactComponent as FolderIcon } from '../../assets/icons/icon-folder.svg'
import { ReactComponent as ProjectInputIcon } from '../../assets/icons/icon-input-project.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/icon-calendar.svg'
import { formatTime, translateActivitiesEvent } from '../../utils/helpers'
import { ACTIVITY, TOP_MENU_ITEM } from '../../utils/enums'
import { setProjectSiderToFolder, setProjectSiderToPlanStateDocument, setProjectSiderToSubFolder, setTopMenu } from '../../reducers/menu/menuActions'
import { ReactComponent as ImageIcon } from '../../assets/icons/icon-document-image.svg'
import { ReactComponent as PDFIcon } from '../../assets/icons/icon-document-pdf.svg'
import { ReactComponent as OfficeIcon } from '../../assets/icons/icon-document-excel.svg'
import { ReactComponent as TextIcon } from '../../assets/icons/icon-document-text.svg'
import { IDownloadItem, setDownloadList } from '../../reducers/dowload/downloadActions'
import { deselectActivityFiles, getSelectedActivityFiles, selectActivityFiles } from './components/ActivitiesDownloadHandler'

const ActivitiesTable = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const activities = useSelector((state: RootState) => state?.activitiesStore?.activities)
	const selectedProject = useSelector((state: RootState) => state?.menuStore?.path?.project)
	const selectedFolder = useSelector((state: RootState) => state?.menuStore?.path?.folder)
	const selectedSubfolder = useSelector((state: RootState) => state?.menuStore?.path?.subFolder)
	const [initialRowKey, setInitialRowKeys] = useState<number[]>([])

	const downloadList: IDownloadItem[] = useSelector((state: RootState) => state.downloadStore.download.data)

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])

	const [, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam,
		topMenu: StringParam
	})

	const [selectedDate, setSelectedDate] = useState<DatePickerProps['value']>(moment())

	useEffect(() => {
		const folderID = selectedSubfolder?.ID || selectedFolder?.ID || undefined
		const selectedProjectID = folderID ? undefined : selectedProject?.ID || undefined

		dispatch(getActivities(selectedProjectID, folderID, `${selectedDate?.valueOf()}`))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProject, selectedFolder, selectedSubfolder])

	useEffect(() => {
		setSelectedRowKeys(getSelectedActivityFiles(downloadList, activities))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadList, activities])

	useEffect(() => {
		if (activities && activities.originalData && activities?.originalData?.length > 0) {
			const rowKeys = map(activities?.originalData, (item) => item.id)
			setInitialRowKeys(rowKeys)
		}
	}, [activities])

	const onDateChange = (value: DatePickerProps['value']) => {
		setSelectedDate(value)
		const folderID = selectedSubfolder?.ID || selectedFolder?.ID || undefined
		const selectedProjectID = folderID ? undefined : selectedProject?.ID || undefined
		dispatch(getActivities(selectedProjectID, folderID, value ? `${value?.valueOf()}` : undefined))
	}

	const renderFileName = (data: any) => {
		let icon
		const fileType = data?.file?.mimeType?.substring(data.file.mimeType.indexOf('/') + 1)
		const broadType = data?.file?.mimeType?.substring(0, data?.file?.mimeType.indexOf('/'))
		if (broadType === 'image' || broadType === 'video') {
			icon = <ImageIcon className={'mr-2'} style={{ minWidth: '24px' }} />
		} else if (broadType === 'application') {
			switch (fileType) {
				case 'pdf':
					icon = <PDFIcon className={'mr-2'} style={{ minWidth: '24px' }} />
					break
				case 'msword':
				case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
				case 'vnd.oasis.opendocument.presentation':
				case 'vnd.oasis.opendocument.spreadsheet':
				case 'vnd.oasis.opendocument.text':
				case 'vnd.ms-powerpoint':
				case 'vnd.openxmlformats-officedocument.presentationml.presentation':
				case 'vnd.ms-excel':
				case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				case 'vnd.apple.numbers':
				case 'vnd.apple.keynote':
				case 'x-step':
				case 'xml':
					icon = <OfficeIcon className={'mr-2'} style={{ minWidth: '24px' }} />
					break
				default:
					icon = <TextIcon className={'mr-2'} style={{ minWidth: '24px' }} />
			}
		} else {
			icon = <TextIcon className={'mr-2'} style={{ minWidth: '24px' }} />
		}

		return (
			<div className={'flex flex-row items-center'}>
				{icon}
				<Typography.Text className={'text-m-medium'} ellipsis style={{ maxWidth: '200px' }}>
					{data?.subject}
				</Typography.Text>
			</div>
		)
	}

	const getActivitiesTable = (record: any) => {
		const activitiesColumns = [
			{
				title: t('loc:Plan'),
				dataIndex: 'plan',
				key: 'plan',
				width: '20%',
				render: (plan: any) => (
					<div className={'flex items-center'}>
						<ProjectInputIcon className={'mr-1'} />
						{plan?.name}
					</div>
				)
			},
			{
				key: 'description',
				dataIndex: 'plan',
				title: t('loc:Description'),
				ellipsis: true,
				width: '150px',
				render: (plan: any) => (
					<Tooltip placement={'top'} title={plan?.description}>
						<span>{plan?.description}</span>
					</Tooltip>
				)
			},
			{
				title: t('loc:Status'),
				dataIndex: 'plan',
				key: 'plan',
				width: '100px',
				render: (plan: any) => {
					return plan?.planState?.name
				}
			},
			{
				title: t('loc:File/Note'),
				dataIndex: 'subject',
				key: 'subject',
				width: '20%',
				render: (_: string, data: any) => renderFileName(data)
			},
			{
				title: t('loc:Event'),
				dataIndex: 'event',
				key: 'event',
				elipsis: true,
				width: '140px',
				render: (event: string) => translateActivitiesEvent(event as ACTIVITY)
			},
			{
				title: t('loc:Updated by'),
				dataIndex: 'creator',
				key: 'creator',
				elipsis: true,
				width: '240px',
				render: (lastUpdate: any) => `${lastUpdate?.name} ${lastUpdate?.surname}`
			},
			{ title: t('loc:Time'), width: '60px', dataIndex: 'date', key: 'date', render: (date: any) => formatTime(date) }
		]

		const onSelectChange = (newSelectedRowKeys: any) => {
			const deselectedFileIDs = selectedRowKeys?.filter((key: number) => !newSelectedRowKeys.includes(key))
			const newlySelectedFileIDs = newSelectedRowKeys?.filter((key: number) => !selectedRowKeys.includes(key))

			let newDownloadList: IDownloadItem[] = downloadList
			if (newlySelectedFileIDs?.length > 0) {
				newDownloadList = selectActivityFiles(downloadList, activities, newlySelectedFileIDs, record?.id)
			}
			if (deselectedFileIDs?.length > 0) {
				newDownloadList = deselectActivityFiles(newDownloadList, deselectedFileIDs, record?.id)
			}

			dispatch(setDownloadList(newDownloadList))
		}

		const rowSelection = {
			selectedRowKeys,
			onChange: onSelectChange
		}

		const onRow = (rowRecord: any) => ({
			onClick: () => {
				const planStateOptions = rowRecord.plan.allPlanStates.map((item: any) => {
					return { key: item?.id, label: item?.name }
				})
				const defaultOption = {
					key: rowRecord?.plan?.planState?.id,
					label: rowRecord?.plan?.planState?.name
				}

				const newQuery = {} as any

				if (!rowRecord?.plan?.folder?.parent) {
					dispatch(
						setProjectSiderToFolder(
							rowRecord?.plan?.folder?.project?.id,
							rowRecord?.plan?.folder?.project?.name,
							rowRecord?.plan?.folder?.id,
							rowRecord?.plan?.folder?.name
						)
					)
					newQuery.projectID = rowRecord?.plan?.folder?.project?.id
					newQuery.projectName = rowRecord?.plan?.folder?.project?.name
					newQuery.folderID = rowRecord?.plan?.folder?.id
					newQuery.folderName = rowRecord?.plan?.folder?.name
				} else {
					dispatch(
						setProjectSiderToSubFolder(
							rowRecord?.plan?.folder?.project?.id,
							rowRecord?.plan?.folder?.project?.name,
							rowRecord?.plan?.folder?.parent?.id,
							rowRecord?.plan?.folder?.parent?.name,
							rowRecord?.plan?.folder?.id,
							rowRecord?.plan?.folder?.name
						)
					)
					newQuery.projectID = rowRecord?.plan?.folder?.project?.id
					newQuery.projectName = rowRecord?.plan?.folder?.project?.name
					newQuery.folderID = rowRecord?.plan?.folder?.parent?.id
					newQuery.folderName = rowRecord?.plan?.folder?.parent?.name
					newQuery.subFolderID = rowRecord?.plan?.folder?.id
					newQuery.subFolderName = rowRecord?.plan?.folder?.name
				}

				dispatch(setProjectSiderToPlanStateDocument({ planStateOptions, defaultOption, planName: rowRecord?.plan?.name, planID: rowRecord?.plan?.id }))
				newQuery.planID = rowRecord?.plan?.id
				newQuery.planName = rowRecord?.plan?.name
				newQuery.planStateID = defaultOption?.key
				newQuery.planStateName = defaultOption?.label
				newQuery.topMenu = TOP_MENU_ITEM.ACTIVITIES
				dispatch(setTopMenu(TOP_MENU_ITEM.FILES))
				setQuery(newQuery)
			}
		})

		return (
			<Table
				onRow={onRow}
				rowKey={'id'}
				rowSelection={rowSelection}
				columns={activitiesColumns}
				dataSource={record?.activities}
				rowClassName={'cursor-pointer'}
				pagination={false}
			/>
		)
	}

	const nestedTableRenderer = (record: any) => {
		if (record.folders && record.folders.length > 0) {
			const foldersRowKeys = map(record?.folders, (item) => item.id)
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			const foldersColumns = [
				{
					title: t('loc:Name'),
					dataIndex: 'name',
					key: 'name',
					render: (name: string) => (
						<div className={'flex items-center'}>
							<FolderIcon className={'mr-1'} />
							{name}
						</div>
					)
				}
			]
			const outerFolderHasActivites = record?.activities?.length > 0 && record?.folders
			return (
				<>
					{outerFolderHasActivites && getActivitiesTable(record)}
					<Table
						rowKey={'id'}
						columns={foldersColumns}
						dataSource={record?.folders}
						pagination={false}
						style={{ marginTop: `${outerFolderHasActivites ? '16px' : ''}`, marginLeft: `${outerFolderHasActivites ? '32px' : ''}` }}
						showHeader={false}
						expandable={{ expandedRowRender: nestedTableRenderer, defaultExpandAllRows: true, showExpandColumn: false, expandedRowKeys: foldersRowKeys }}
					/>
				</>
			)
		}
		return getActivitiesTable(record)
	}

	const columns = [
		{
			key: 'name',
			dataIndex: 'name',
			title: t('loc:Folders'),
			sorter: false,
			ellipsis: true,
			render: (name: string) => (
				<div className={'flex items-center'}>
					<ProjectIcon className={'mr-1'} />
					{name}
				</div>
			)
		}
	]

	const renderEmptyState = (
		<div className={'pb-2 pt-28 flex flex-col items-center'}>
			<ProjectImage />
		</div>
	)

	const activitiesTable = (
		<Table
			size={'small'}
			className={'extd-table mt-4'}
			columns={columns}
			rowKey={'id'}
			rowClassName={'bg-zinc-100'}
			loading={activities?.isLoading}
			dataSource={activities?.originalData || []}
			pagination={false}
			expandable={{ defaultExpandAllRows: true, expandedRowRender: nestedTableRenderer, showExpandColumn: false, expandedRowKeys: initialRowKey }}
			locale={{
				emptyText: t('loc:No activities found')
			}}
			showHeader={false}
		/>
	)
	return (
		<div>
			<Row justify={'space-between'}>
				<Col span={10} className={'flex items-center'}>
					<Button type={'primary'} className={'extd-btn h-10'} onClick={() => onDateChange(selectedDate?.add(-1, 'd'))}>
						<LeftOutlined />
					</Button>
					<DatePicker
						defaultValue={moment()}
						allowClear={false}
						value={selectedDate}
						suffixIcon={<CalendarIcon />}
						className={'extd-picker ml-1 mr-1'}
						onChange={onDateChange}
						format={'DD.MM.YYYY'}
					/>
					<Button type={'primary'} className={'extd-btn h-10'} onClick={() => onDateChange(selectedDate?.add(1, 'd'))}>
						<RightOutlined />
					</Button>
					<button type='button' className={'text-button ml-2 text-blue-600'} onClick={() => onDateChange(moment())}>
						{t('loc:Today')}
					</button>
				</Col>
			</Row>
			{!activities?.isLoading && activities?.originalData?.length === 0 ? renderEmptyState : activitiesTable}
		</div>
	)
}

export default ActivitiesTable
