import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IPlanStateDocumentsPayload, IPlanStateDocumentsActions } from './planStateDocumentsActions'
import { RESET_STORE } from '../generalTypes'
import { PLAN_STATE_DOCUMENTS } from './planStateDocumentsTypes'

export const initState = {
	planStateDocuments: {
		documents: null,
		search: null,
		note: null,
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IPlanStateDocumentsPayload & ILoadingAndFailure
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (state = initState, action: IPlanStateDocumentsActions) => {
	switch (action.type) {
		case PLAN_STATE_DOCUMENTS.PLAN_STATE_DOCUMENTS_LOAD_START:
			return {
				...state,
				planStateDocuments: {
					...state.planStateDocuments,
					isLoading: true
				}
			}
		case PLAN_STATE_DOCUMENTS.PLAN_STATE_DOCUMENTS_LOAD_FAIL:
			return {
				...state,
				planStateDocuments: {
					...initState.planStateDocuments,
					isFailure: true
				}
			}
		case PLAN_STATE_DOCUMENTS.PLAN_STATE_DOCUMENTS_LOAD_DONE:
			return {
				...state,
				planStateDocuments: {
					...initState.planStateDocuments,
					documents: action.payload.documents,
					pagination: action.payload.pagination,
					note: action.payload.note,
					search: action.payload.search
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
