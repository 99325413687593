import React, { useEffect, useState } from 'react'
import { Form, Button, Divider } from 'antd'
import { WrappedFieldArrayProps, InjectedFormProps, reduxForm, FieldArray, Field, formValueSelector, change } from 'redux-form'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FORM } from '../../../../../utils/enums'
import SwitchField from '../../../../../atoms/SwitchField'

// eslint-disable-next-line import/no-cycle
import validateBulkUploadForm from './validateBulkUploadForm'
import { RootState } from '../../../../../reducers'

export interface IBulkUploadForm {
	selectStatusAttributes: { value: boolean; name: string; id: number }[]
}

type BulkUploadFieldsProps = WrappedFieldArrayProps<any> & {
	formName: FORM
}

const SelectStatusesFields = (param: BulkUploadFieldsProps) => {
	const selectStatusAttributes = param.fields.getAll()

	const renderFields = () => {
		return selectStatusAttributes?.map((attr, index) => {
			return (
				<div className={'w-52 flex flex-row justify-between items-center'}>
					<span>{attr?.name}</span>
					<Field key={`selectStatusAttributes.${index}.value`} name={`selectStatusAttributes.${index}.value`} component={SwitchField} />
				</div>
			)
		})
	}
	return <div>{renderFields()}</div>
}

type ComponentProps = {}

type Props = InjectedFormProps<IBulkUploadForm, ComponentProps> & ComponentProps

const BulkUploadForm = (props: Props) => {
	const { handleSubmit } = props
	const dispatch = useDispatch()

	const selector = formValueSelector(FORM.BULK_UPLOAD_FORM)
	const statusAttrs = useSelector((state: RootState) => selector(state, 'selectStatusAttributes'))
	const [allSelected, setAllSelected] = useState<boolean>(false)

	const changeSelection = () => {
		const newStatusAttrs = statusAttrs?.map((attr: any) => {
			return { value: true, name: attr?.name, id: attr?.id }
		})

		dispatch(change(FORM.BULK_UPLOAD_FORM, 'selectStatusAttributes', newStatusAttrs))
	}

	useEffect(() => {
		if (statusAttrs?.find((value: any) => !value?.value)) {
			if (allSelected) setAllSelected(false)
		} else if (!allSelected) setAllSelected(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statusAttrs])

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<span className={'heading-2 mb-10'}>{t('loc:Select project codes attributes based on which files will be assigned')}</span>
			<div className={'flex flex-col justify-center items-center'}>
				<FieldArray component={SelectStatusesFields} name={'selectStatusAttributes'} formName={FORM.BULK_UPLOAD_FORM} />
			</div>
			<Button className={'extd-btn'} type={'primary'} disabled={allSelected} onClick={changeSelection}>
				{t('loc:Select All')}
			</Button>
			<Divider />
		</Form>
	)
}

const form = reduxForm<IBulkUploadForm, ComponentProps>({
	form: FORM.BULK_UPLOAD_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateBulkUploadForm
})(BulkUploadForm)

export default form
