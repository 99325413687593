// eslint-disable-next-line import/prefer-default-export
export enum USERS {
	USERS_LOAD_START = 'USERS_LOAD_START',
	USERS_LOAD_DONE = 'USERS_LOAD_DONE',
	USERS_LOAD_FAIL = 'USERS_LOAD_FAIL',
	USER_LOAD_START = 'USER_LOAD_START',
	USER_LOAD_DONE = 'USER_LOAD_DONE',
	USER_LOAD_FAIL = 'USER_LOAD_FAIL',
	ADMIN_INVITE_START = 'ADMIN_INVITE_START',
	ADMIN_INVITE_DONE = 'ADMIN_INVITE_DONE',
	ADMIN_INVITE_FAIL = 'ADMIN_INVITE_FAIL'
}
