import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'

import InputField from '../../../../atoms/InputField'
import { FORM } from '../../../../utils/enums'

// eslint-disable-next-line import/no-cycle
import validate from './validateRenameFolderForm'

export interface IRenameFolderForm {
	folderID: number
	name: string
}

type ComponentProps = {}

type Props = InjectedFormProps<IRenameFolderForm, ComponentProps> & ComponentProps

const RenameFolderForm = (props: Props) => {
	const { handleSubmit } = props
	const { t } = useTranslation()

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Field name={'name'} component={InputField} label={t('loc:Folder name')} placeholder={t('loc:Enter folder name')} required />
		</Form>
	)
}

const form = reduxForm<IRenameFolderForm, ComponentProps>({
	form: FORM.RENAME_FOLDER_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(RenameFolderForm)

export default form
