import { IDownloadItem, File } from '../../../../reducers/dowload/downloadActions'
import { IPathPayload } from '../../../../reducers/menu/menuActions'
import { IPlanStateDocumentsPayload } from '../../../../reducers/planStateDocuments/planStateDocumentsActions'

export const getSelectedDocuments = (downloadList: IDownloadItem[], planStateDocuments: IPlanStateDocumentsPayload): number[] => {
	const selectedRows: number[] = []

	planStateDocuments?.documents?.forEach((documents) => {
		const foundItem = downloadList?.find((item) => item?.file?.ID === documents?.id)
		if (foundItem) {
			selectedRows.push(documents?.id)
		}
	})

	return selectedRows
}

export const deselectFiles = (downloadList: IDownloadItem[], menu: IPathPayload): IDownloadItem[] => {
	return downloadList?.filter((item) => item?.planState?.ID !== menu?.planStateDocument?.defaultOption?.key)
}

export const addSelectedFiles = (downloadList: IDownloadItem[], files: File[], menu: IPathPayload): IDownloadItem[] => {
	const newDownloadList: IDownloadItem[] = []

	files?.forEach((file) => {
		if (menu?.project?.ID && menu?.project?.name && menu?.folder?.ID && menu?.folder?.name && menu?.planStateDocument?.planID && menu?.planStateDocument?.planName) {
			const newDownloadItem: IDownloadItem = {
				project: {
					ID: menu.project.ID,
					name: menu?.project?.name
				},
				folder: {
					ID: menu?.folder?.ID,
					name: menu?.folder?.name
				},
				subFolder: menu?.subFolder?.ID
					? {
							ID: menu?.subFolder?.ID,
							name: menu?.subFolder?.name
					  }
					: undefined,
				plan: {
					ID: menu?.planStateDocument?.planID,
					name: menu?.planStateDocument?.planName
				},
				planState: {
					ID: menu?.planStateDocument?.defaultOption?.key,
					name: menu?.planStateDocument?.defaultOption?.label
				},
				file: {
					ID: file?.ID,
					name: file?.name,
					mimeType: file?.mimeType
				}
			}

			newDownloadList.push(newDownloadItem)
		}
	})

	return [...downloadList, ...newDownloadList]
}
