import React from 'react'
import { Button, Col, Modal, Row, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { change, formValueSelector, isSubmitting, submit } from 'redux-form'

import { parseInt, split } from 'lodash'
import { ReactComponent as ProjectIcon } from '../../../../assets/icons/icon-project.svg'
import { ReactComponent as InfoIcon } from '../../../../assets/icons/icon-info.svg'
import PlanCreateForm, { IPlanCreateForm } from '../PlanCreateForm'

import { RootState } from '../../../../reducers'
import { CODE_TYPE, DEFAULT_PAGE_SIZE, FORM, PROJECT_CODE_TYPE } from '../../../../utils/enums'
import { postReq } from '../../../../utils/requests'
import { getPlans } from '../../../../reducers/plans/plansActions'

const form = FORM.PLAN_CREATE_FORM
const selector = formValueSelector(form)

const PlanCreateModal = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const opened = useSelector((state: RootState) => selector(state, 'opened'))
	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))
	const plans = useSelector((state: RootState) => state?.plansStore?.plans)

	const handleSubmit = async (values: IPlanCreateForm) => {
		try {
			const type = values?.type
			if (values?.opened?.folderID) {
				const reqBody = {
					folderID: values?.opened?.folderID,
					description: values?.description ? values.description : null,
					name: type === CODE_TYPE.FREE_TEXT ? values?.planName : undefined,
					projectCodeAttributes:
						type === CODE_TYPE.STATES
							? (Object.keys(values.planAttributes).map((key) => {
									const attrType = split(key, '_')[1]
									let value = null
									if (attrType === PROJECT_CODE_TYPE.INDEX) {
										if (!values.planAttributes[key]) {
											value = '-'
										}
									}
									if (!value) {
										value = values.planAttributes[key]?.label ? values.planAttributes[key]?.label : values.planAttributes[key]
									}
									return {
										id: parseInt(split(key, '_')[0]),
										value
									}
							  }) as any)
							: undefined
				}

				await postReq('/api/v1/plans/', undefined, reqBody)
				dispatch(change(form, 'opened', null))
				dispatch(
					getPlans(
						values?.opened.folderID,
						plans?.pagination?.page || 1,
						plans?.search,
						plans?.onlyActual,
						plans?.fromDate,
						plans?.toDate,
						plans?.pagination?.limit || DEFAULT_PAGE_SIZE
					)
				)
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	const handleOK = () => {
		dispatch(submit(form))
	}

	const handleCancel = () => {
		dispatch(change(form, 'opened', null))
	}

	return (
		<Modal
			title={
				<Space className={'cursor-default'}>
					{t('loc:Create plan')}
					<div className={'flex items-center text-m-medium text-zinc-600'}>
						<ProjectIcon />
						<Typography.Text className={'ml-1'} ellipsis style={{ maxWidth: 300 }}>
							{opened?.projectName || '-'}
						</Typography.Text>
					</div>
				</Space>
			}
			onOk={handleOK}
			okButtonProps={{
				loading: submitting,
				disabled: submitting
			}}
			onCancel={handleCancel}
			getContainer={() => document.body}
			destroyOnClose
			visible={!!opened}
			className={'modal-w-1100'}
			footer={
				<Row justify={'space-between'}>
					<Col>
						<div className={'flex flex-row items-center mt-5 cursor-default'}>
							<InfoIcon className={'mr-2'} />
							<span className={'text-m-regular text-zinc-500'}>{t('loc:Input fields represent elements of plan name separated by underscores.')}</span>
						</div>
					</Col>
					<Col>
						<div className={'flex flex-row'}>
							<Button type={'default'} className={'extd-btn'} onClick={handleCancel}>
								{t('loc:Cancel')}
							</Button>
							<Button type={'primary'} className={'extd-btn'} onClick={handleOK}>
								{t('loc:Create plan')}
							</Button>
						</div>
					</Col>
				</Row>
			}
		>
			{!!opened && <PlanCreateForm onSubmit={handleSubmit} />}
		</Modal>
	)
}

export default PlanCreateModal
