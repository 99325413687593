import { IDownloadItem } from '../../../../reducers/dowload/downloadActions'
import { Paths } from '../../../../types/api'
import { IPathPayload } from '../../../../reducers/menu/menuActions'
import { IPlansPayload } from '../../../../reducers/plans/plansActions'
import { TREE_NODE_TYPE } from '../../../../utils/enums'

export const getSelectedPlans = (downloadList: IDownloadItem[], plans: IPlansPayload, type: TREE_NODE_TYPE, folderID: number): number[] => {
	const selectedRows: number[] = []

	if (type === TREE_NODE_TYPE.MAIN_FOLDER) {
		plans?.plans?.forEach((plan) => {
			const foundItem = downloadList?.find((item) => item?.folder?.ID === folderID && plan?.id === item?.plan?.ID)
			if (foundItem && !selectedRows?.includes(plan?.id)) {
				selectedRows.push(plan?.id)
			}
		})
	} else if (type === TREE_NODE_TYPE.SUB_FOLDER) {
		plans?.plans?.forEach((plan) => {
			const foundItem = downloadList?.find((item) => item?.subFolder?.ID === folderID && plan?.id === item?.plan?.ID)
			if (foundItem && !selectedRows?.includes(plan?.id)) {
				selectedRows.push(plan?.id)
			}
		})
	}

	return selectedRows
}

export const getPartlySelectedPlans = (downloadList: IDownloadItem[], plans: IPlansPayload, type: TREE_NODE_TYPE, folderID: number): number[] => {
	const partlySelectedRows: number[] = []

	if (type === TREE_NODE_TYPE.MAIN_FOLDER) {
		plans?.plans?.forEach((plan) => {
			let filesCount = 0
			plan?.planStates.forEach((item) => {
				filesCount += item.documentCount
			})
			const filteredItems = downloadList?.filter((item) => item?.folder?.ID === folderID && plan?.id === item?.plan?.ID)
			if (filteredItems?.length > 0 && filesCount !== filteredItems?.length && !partlySelectedRows?.includes(plan?.id) && filesCount !== 0) {
				partlySelectedRows.push(plan?.id)
			}
		})
	} else if (type === TREE_NODE_TYPE.SUB_FOLDER) {
		plans?.plans?.forEach((plan) => {
			let filesCount = 0
			plan?.planStates.forEach((item) => {
				filesCount += item.documentCount
			})
			const filteredItems = downloadList?.filter((item) => item?.subFolder?.ID === folderID && plan?.id === item?.plan?.ID)
			if (filteredItems?.length > 0 && filteredItems?.length !== filesCount && !partlySelectedRows?.includes(plan?.id) && filesCount !== 0) {
				partlySelectedRows.push(plan?.id)
			}
		})
	}

	return partlySelectedRows
}

export const getSelectedPlanStates = (downloadList: IDownloadItem[], plans: IPlansPayload, type: TREE_NODE_TYPE, folderID: number): number[] => {
	const selectedPlanStatesIDs: number[] = []

	if (type === TREE_NODE_TYPE.MAIN_FOLDER) {
		plans?.plans?.forEach((plan) => {
			const foundItems: IDownloadItem[] = downloadList?.filter((item) => item?.folder?.ID === folderID && plan?.id === item?.plan?.ID)
			foundItems?.forEach((foundItem) => {
				if (foundItem?.planState?.ID && !selectedPlanStatesIDs.includes(foundItem?.planState?.ID)) {
					selectedPlanStatesIDs.push(foundItem?.planState?.ID)
				}
			})
		})
	} else if (type === TREE_NODE_TYPE.SUB_FOLDER) {
		plans?.plans?.forEach((plan) => {
			const foundItems: IDownloadItem[] = downloadList?.filter((item) => item?.subFolder?.ID === folderID && plan?.id === item?.plan?.ID)
			foundItems?.forEach((foundItem) => {
				if (foundItem?.planState?.ID && !selectedPlanStatesIDs.includes(foundItem?.planState?.ID)) {
					selectedPlanStatesIDs.push(foundItem?.planState?.ID)
				}
			})
		})
	}
	return selectedPlanStatesIDs
}

export const isPlanStateSelected = (downloadList: IDownloadItem[], planStateID: number): boolean => {
	const foundItem = downloadList?.find((item) => item?.planState?.ID === planStateID)

	if (foundItem) {
		return true
	}
	return false
}

export const deselectPlanStateFiles = (downloadList: IDownloadItem[], planStateID: number): IDownloadItem[] => {
	const filteredDownloadList = downloadList?.filter((item) => item?.planState?.ID !== planStateID)

	return filteredDownloadList
}

export const deselectPlanFiles = (downloadList: IDownloadItem[], deselectedIds: number[]): IDownloadItem[] => {
	const filteredDownloadList = downloadList?.filter((item) => !deselectedIds?.includes(item?.plan?.ID || 0))

	return filteredDownloadList
}

export const selectPlanStateFiles = (
	downloadList: IDownloadItem[],
	planStateID: number,
	planID: number,
	menu: IPathPayload,
	data: Paths.PostApiV1PlansDocuments.Responses.$200
): IDownloadItem[] => {
	const newDownloadList: IDownloadItem[] = []
	if (menu?.project?.ID && menu?.project?.name && menu?.folder?.ID && menu?.folder?.name) {
		// lebo ts ku nestaci ta podmienka tu, a tvrdi mi ze by mohli byt undefined.
		const projectID = menu.project.ID
		const projectName = menu.project.name
		const folderID = menu.folder.ID
		const folderName = menu.folder.name
		if (menu?.subFolder?.ID && menu?.subFolder?.name) {
			const subFolderID = menu.subFolder.ID
			const subFolderName = menu.subFolder.name

			data?.plans?.forEach((plan) => {
				const filteredPlanState = plan?.planStates?.filter((planState) => planStateID === planState?.id)
				filteredPlanState?.forEach((planState) => {
					planState?.documents?.forEach((document) => {
						const newDownloadItem: IDownloadItem = {
							project: {
								ID: projectID,
								name: projectName
							},
							folder: {
								ID: folderID,
								name: folderName
							},
							subFolder: {
								ID: subFolderID,
								name: subFolderName
							},
							plan: {
								ID: planID,
								name: plan?.name
							},
							planState: {
								ID: planStateID,
								name: planState?.name
							},
							file: {
								ID: document.id,
								name: document.name,
								mimeType: document.mimeType
							}
						}
						newDownloadList.push(newDownloadItem)
					})
				})
			})
		} else {
			data?.plans?.forEach((plan) => {
				const filteredPlanState = plan?.planStates?.filter((planState) => planStateID === planState?.id)
				filteredPlanState?.forEach((planState) => {
					planState?.documents?.forEach((document) => {
						const newDownloadItem: IDownloadItem = {
							project: {
								ID: projectID,
								name: projectName
							},
							folder: {
								ID: folderID,
								name: folderName
							},
							plan: {
								ID: planID,
								name: plan?.name
							},
							planState: {
								ID: planStateID,
								name: planState?.name
							},
							file: {
								ID: document.id,
								name: document.name,
								mimeType: document.mimeType
							}
						}
						newDownloadList.push(newDownloadItem)
					})
				})
			})
		}
	}

	return [...downloadList, ...newDownloadList]
}

export const selectPlanFiles = (downloadList: IDownloadItem[], menu: IPathPayload, data: Paths.PostApiV1PlansDocuments.Responses.$200): IDownloadItem[] => {
	const newDownloadList: IDownloadItem[] = []

	if (menu?.project?.ID && menu?.project?.name && menu?.folder?.ID && menu?.folder?.name) {
		// lebo ts ku nestaci ta podmienka tu, a tvrdi mi ze by mohli byt undefined.
		const projectID = menu.project.ID
		const projectName = menu.project.name
		const folderID = menu.folder.ID
		const folderName = menu.folder.name
		if (menu?.subFolder?.ID && menu?.subFolder?.name) {
			const subFolderID = menu.subFolder.ID
			const subFolderName = menu.subFolder.name

			data?.plans?.forEach((plan) => {
				plan?.planStates?.forEach((planState) => {
					planState?.documents?.forEach((document) => {
						const newDownloadItem: IDownloadItem = {
							project: {
								ID: projectID,
								name: projectName
							},
							folder: {
								ID: folderID,
								name: folderName
							},
							subFolder: {
								ID: subFolderID,
								name: subFolderName
							},
							plan: {
								ID: plan?.id,
								name: plan?.name
							},
							planState: {
								ID: planState?.id,
								name: planState?.name
							},
							file: {
								ID: document.id,
								name: document.name,
								mimeType: document.mimeType
							}
						}
						if (!downloadList?.find((item) => item.file?.ID === newDownloadItem?.file?.ID)) {
							newDownloadList.push(newDownloadItem)
						}
					})
				})
			})

			newDownloadList?.concat(downloadList)

			data?.plans?.forEach((plan) => {
				if (!newDownloadList?.find((item) => item?.plan?.ID === plan?.id)) {
					const newDownloadItem: IDownloadItem = {
						project: {
							ID: projectID,
							name: projectName
						},
						folder: {
							ID: folderID,
							name: folderName
						},
						subFolder: {
							ID: subFolderID,
							name: subFolderName
						},
						plan: {
							ID: plan?.id,
							name: plan?.name
						}
					}
					newDownloadList.push(newDownloadItem)
				}
			})
		} else {
			data?.plans?.forEach((plan) => {
				plan?.planStates?.forEach((planState) => {
					planState?.documents?.forEach((document) => {
						const newDownloadItem: IDownloadItem = {
							project: {
								ID: projectID,
								name: projectName
							},
							folder: {
								ID: folderID,
								name: folderName
							},
							plan: {
								ID: plan?.id,
								name: plan?.name
							},
							planState: {
								ID: planState?.id,
								name: planState?.name
							},
							file: {
								ID: document.id,
								name: document.name,
								mimeType: document.mimeType
							}
						}
						if (!downloadList?.find((item) => item?.file?.ID === newDownloadItem?.file?.ID)) {
							newDownloadList.push(newDownloadItem)
						}
					})
				})
			})

			newDownloadList?.concat(downloadList)

			data?.plans?.forEach((plan) => {
				if (!newDownloadList?.find((item) => item?.plan?.ID === plan?.id)) {
					const newDownloadItem: IDownloadItem = {
						project: {
							ID: projectID,
							name: projectName
						},
						folder: {
							ID: folderID,
							name: folderName
						},
						plan: {
							ID: plan?.id,
							name: plan?.name
						}
					}
					newDownloadList.push(newDownloadItem)
				}
			})
		}
	}

	return [...downloadList, ...newDownloadList]
}

const getUsedFolderStates = (plans: IPlansPayload, folderStateID: number) => {
	const usedFolderStates: number[] = []
	plans?.plans?.forEach((plan) => {
		plan?.planStates?.forEach((planState) => {
			if (planState?.folderState?.id === folderStateID) {
				if (planState.documentCount) {
					usedFolderStates?.push(planState?.id)
				}
			}
		})
	})

	return usedFolderStates
}

export const isPlanHeaderIndeterminate = (downloadList: IDownloadItem[], plans: IPlansPayload, type: TREE_NODE_TYPE, folderStateID: number, folderID: number): boolean => {
	const usedFolderStates = getUsedFolderStates(plans, folderStateID)

	const selectedPlanStates = getSelectedPlanStates(downloadList, plans, type, folderID)

	return !usedFolderStates.every((item) => selectedPlanStates.includes(item)) && usedFolderStates.some((item) => selectedPlanStates.includes(item))
}

export const isPlanHeaderSelected = (downloadList: IDownloadItem[], plans: IPlansPayload, type: TREE_NODE_TYPE, folderStateID: number, folderID: number): boolean => {
	const usedFolderStates = getUsedFolderStates(plans, folderStateID)

	const selectedPlanStates = getSelectedPlanStates(downloadList, plans, type, folderID)

	if (usedFolderStates?.length === 0) {
		return false
	}

	return usedFolderStates.every((item) => selectedPlanStates.includes(item))
}

export const selectPlanHeader = (
	downloadList: IDownloadItem[],
	plans: IPlansPayload,
	folderStateID: number,
	menu: IPathPayload,
	data: Paths.PostApiV1PlansDocuments.Responses.$200
): IDownloadItem[] => {
	const usedFolderStates = getUsedFolderStates(plans, folderStateID)
	const newDownloadList: IDownloadItem[] = []

	if (menu?.project?.ID && menu?.project?.name && menu?.folder?.ID && menu?.folder?.name) {
		const projectID = menu.project.ID
		const projectName = menu.project.name
		const folderID = menu.folder.ID
		const folderName = menu.folder.name
		if (menu?.subFolder?.ID && menu?.subFolder?.name) {
			const subFolderID = menu.subFolder.ID
			const subFolderName = menu.subFolder.name

			data?.plans?.forEach((dataPlan) => {
				dataPlan?.planStates?.forEach((planState) => {
					if (usedFolderStates.includes(planState?.id)) {
						planState?.documents?.forEach((document) => {
							const newDownloadItem: IDownloadItem = {
								project: {
									ID: projectID,
									name: projectName
								},
								folder: {
									ID: folderID,
									name: folderName
								},
								subFolder: {
									ID: subFolderID,
									name: subFolderName
								},
								plan: {
									ID: dataPlan.id,
									name: dataPlan?.name
								},
								planState: {
									ID: planState.id,
									name: planState?.name
								},
								file: {
									ID: document.id,
									name: document.name,
									mimeType: document.mimeType
								}
							}
							newDownloadList.push(newDownloadItem)
						})
					}
				})
			})
		} else {
			data?.plans?.forEach((dataPlan) => {
				dataPlan?.planStates?.forEach((planState) => {
					if (usedFolderStates.includes(planState?.id)) {
						planState?.documents?.forEach((document) => {
							const newDownloadItem: IDownloadItem = {
								project: {
									ID: projectID,
									name: projectName
								},
								folder: {
									ID: folderID,
									name: folderName
								},
								plan: {
									ID: dataPlan.id,
									name: dataPlan?.name
								},
								planState: {
									ID: planState.id,
									name: planState?.name
								},
								file: {
									ID: document.id,
									name: document.name,
									mimeType: document.mimeType
								}
							}
							newDownloadList.push(newDownloadItem)
						})
					}
				})
			})
		}
	}

	downloadList?.forEach((item) => {
		if (!newDownloadList?.find((newItem) => newItem.file?.ID === item?.file?.ID)) {
			newDownloadList?.push(item)
		}
	})

	return newDownloadList
}

export const deselectPlanHeader = (downloadList: IDownloadItem[], plans: IPlansPayload, folderStateID: number): IDownloadItem[] => {
	const usedFolderStates = getUsedFolderStates(plans, folderStateID)
	const filteredDownloadList = downloadList?.filter((item) => !usedFolderStates?.includes(item?.planState?.ID || 0))

	return filteredDownloadList
}
