import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Row, Col, Form, Button, Progress } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { FORM, USER_CREATION_STEP } from '../../../utils/enums'
import InputField from '../../../atoms/InputField'
import { ReactComponent as Planshelf } from '../../../assets/images/image-planshelf.svg'
// eslint-disable-next-line import/no-cycle
import validateUserForm from './validateUserForm'

export interface IProfileForm {
	name: string
	surname: string
	phone: string
}

type ComponentProps = {
	changeStep: any
	progress: number
}

type Props = InjectedFormProps<IProfileForm, ComponentProps> & ComponentProps

const ProfileForm: FC<Props> = (props) => {
	const { t } = useTranslation()
	const { handleSubmit, submitting, changeStep, progress } = props

	return (
		<Form layout={'vertical'} className={'mt-20'} onSubmitCapture={handleSubmit}>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Planshelf className={'mb-10'} />
				</Col>
				<Col span={14}>
					<Progress showInfo={false} percent={progress} className={'mb-5'} />
				</Col>
				<Col span={14}>
					<div className={'cursor-pointer mb-5'} onKeyPress={() => {}} tabIndex={0} role='button' onClick={() => changeStep(USER_CREATION_STEP.PASSWORD)}>
						<ArrowLeftOutlined className={'text-blue-600'} />
						<span className={'ml-2 text-blue-600'}>{t('loc:Back')}</span>
					</div>
				</Col>
				<Col span={14}>
					<h3 className={'heading-3'}>{t('loc:Set your profile')}</h3>
				</Col>
				<Col span={14}>
					<Field component={InputField} label={t('loc:Name')} placeholder={t('loc:Enter your name')} name={'name'} size={'large'} required />
				</Col>
			</Row>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Field component={InputField} label={t('loc:Surname')} placeholder={t('loc:Enter your surname')} name={'surname'} size={'large'} required />
				</Col>
			</Row>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Field component={InputField} label={t('loc:Phone')} placeholder={t('loc:00XX...')} name={'phone'} size={'large'} />
				</Col>
			</Row>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Button type={'primary'} block className={'extd-btn'} size={'large'} htmlType={'submit'} disabled={submitting} loading={submitting}>
						{t('loc:Continue')}
					</Button>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IProfileForm, ComponentProps>({
	form: FORM.USER_CREATION_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: false,
	validate: validateUserForm
})(ProfileForm)

export default form
