import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button, Tag, Divider } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { find } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getFormError, initialize, isSubmitting, submit } from 'redux-form'
import BulkUploadForm, { IBulkUploadForm } from './BulkUploadForm'
import { FORM } from '../../../../../utils/enums'
import { RootState } from '../../../../../reducers'

type Props = {
	visible: boolean
	onCancel: () => void
	onSubmit: (values: IBulkUploadForm) => void
	fileList: any
	projectCodeAttributes: any
}

const form = FORM.BULK_UPLOAD_FORM

const UploadBulkModal = ({ visible, onCancel, onSubmit, fileList, projectCodeAttributes }: Props) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [isDone, setIsDone] = useState(false)
	const [hasError, setHasError] = useState(false)

	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))
	const selectorError = getFormError(form)
	const formErrorMessage = useSelector((state: RootState) => selectorError(state))

	useEffect(() => {
		if (find(fileList, (file) => file?.status === 'error')) {
			setHasError(true)
		} else {
			setHasError(false)
		}
		setIsDone(fileList?.every((file: any) => file?.isDone === true))
	}, [fileList])

	useEffect(() => {
		if (isDone) {
			const initData = {
				selectStatusAttributes: projectCodeAttributes?.map((attr: any) => {
					return { value: true, name: attr?.name, id: attr?.id }
				})
			}
			setTimeout(() => {
				dispatch(initialize(form, initData))
			}, 200)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDone])

	const renderFileList = () => {
		return (
			<div>
				{fileList?.map((file: any) => {
					if (file?.isDone) {
						return (
							<div className={'flex flex-row justify-between items-center p-2'}>
								<span className={'mr-2'}>{file?.name}</span>
								<Tag color={'#15803D'} icon={<CheckCircleOutlined />}>
									{t('loc:Done')}
								</Tag>
							</div>
						)
					}
					if (file?.status === 'error') {
						return (
							<div className={'flex flex-row justify-between items-center p-2'}>
								<span className={'mr-2'}>{file?.name}</span>
								<Tag color={'#C82929'} icon={<ExclamationCircleOutlined />}>
									{t('loc:Error!')}
								</Tag>
							</div>
						)
					}

					return (
						<div className={'flex flex-row justify-between items-center p-2'}>
							<span className={'mr-2'}>{file?.name}</span>
							<Tag color={'#2F54EB'} icon={<LoadingOutlined />}>
								{t('loc:Uploading {{percent}}', { percent: Math.round(file?.percent) })}
							</Tag>
						</div>
					)
				})}
			</div>
		)
	}

	const handleSubmit = (values: IBulkUploadForm) => {
		onSubmit(values)
	}

	return (
		<Modal width={600} visible={visible} onCancel={onCancel} footer={null}>
			<div className={'flex flex-col justify-between items-center pt-5 text-center'}>
				<span className={'heading-2 mb-10'}>{t('loc:Upload')}</span>
				{!isDone && renderFileList()}
				{hasError && <span className={'text-error-medium mt-10'}>{t('loc:Uploading some file failed. Close and try again')}</span>}
				{isDone && (
					<div>
						<Divider />
						<BulkUploadForm onSubmit={handleSubmit} />
					</div>
				)}
				<div className={'flex items-center mt-10'}>
					<Button type={'default'} className={'extd-btn mr-2'} onClick={() => onCancel()}>
						{t('loc:Cancel')}
					</Button>
					<Button type={'primary'} className={'extd-btn'} disabled={!isDone || submitting || !!formErrorMessage} onClick={() => dispatch(submit(form))}>
						{t('loc:Upload')}
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default UploadBulkModal
