import i18next from 'i18next'
import { FormErrors } from 'redux-form'
import { UPLOAD_ERROR_TYPE } from '../../../utils/enums'

// eslint-disable-next-line import/no-cycle
import { IUploadDocumentsForm } from './UploadDocumentsForm'

/**
 * @param {IUploadDocumentsForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IUploadDocumentsForm> = {}

	const errorsArray: string[] = []

	values?.planStates?.forEach((planState: any, index: any) => {
		if (!planState?.route?.planState) {
			const newError = i18next.t('loc:[{{errorIndex}}]{{errorType}} Must choose full path!', { errorIndex: index, errorType: UPLOAD_ERROR_TYPE.PATH })
			errorsArray.push(newError)
		} else if (!planState?.files || planState?.files?.length === 0) {
			const newError = i18next.t('loc:[{{errorIndex}}]{{errorType}} Every path needs atleast one file to upload!', { errorIndex: index, errorType: UPLOAD_ERROR_TYPE.FILE })
			errorsArray.push(newError)
		}
	})

	values?.unnassignedPlanStates?.forEach((planState: any, index: any) => {
		if (!planState?.route?.planState) {
			const newError = i18next.t('loc:[{{errorIndex}}]{{errorType}} Must choose full path!', { errorIndex: index, errorType: UPLOAD_ERROR_TYPE.UNNASSIGNED_PATH })
			errorsArray.push(newError)
		}
	})

	values?.multipleSuggestionPlanStates?.forEach((planState: any, index: any) => {
		if (!planState?.chosenStatus) {
			const newError = i18next.t('loc:[{{errorIndex}}]{{errorType}} Must choose one of the options!', { errorIndex: index, errorType: UPLOAD_ERROR_TYPE.MUTLIPLE_PATH })
			errorsArray.push(newError)
		}
	})

	// eslint-disable-next-line no-underscore-dangle
	if (errorsArray.length !== 0) errors._error = errorsArray.join('-')

	return errors
}
