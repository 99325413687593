import i18next from 'i18next'
import { find } from 'lodash'
import { FormErrors } from 'redux-form'
import { validationRequired, validationString } from '../../../../utils/helpers'
// eslint-disable-next-line import/no-cycle
import { ICompanyForm } from './CompanyForm'

/**
 * @param {ICompanyForm} values
 */

export default (values: any) => {
	const errors: FormErrors<ICompanyForm> = {}

	const companyError = find([validationRequired(values?.company), validationString(100)(values?.company)], (err) => !!err)
	if (companyError) {
		errors.company = companyError
	}

	if (values?.address && values?.address?.length > 255) {
		errors.address = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.city && values?.city?.length > 100) {
		errors.city = i18next.t('loc:Max. number of characters is {{max}}', { max: 100 })
	}

	if (values?.country && values?.country?.length > 100) {
		errors.country = i18next.t('loc:Max. number of characters is {{max}}', { max: 100 })
	}

	if (values?.postalcode && values?.postalCode?.length > 20) {
		errors.postalcode = i18next.t('loc:Max. number of characters is {{max}}', { max: 20 })
	}

	if (values?.web && values?.web?.length > 255) {
		errors.web = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.contactname && values?.contactname?.length > 255) {
		errors.contactname = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.contactsurname && values?.contactsurname?.length > 255) {
		errors.contactsurname = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.contactemail && values?.contactemail?.length > 255) {
		errors.contactemail = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.contactphone && values?.contactphone?.length > 16) {
		errors.contactphone = i18next.t('loc:Max. number of characters is {{max}}', { max: 16 })
	}

	return errors
}
