import { Button, Layout, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { initialize, isSubmitting, submit, getFormError, change, formValueSelector } from 'redux-form'
import DetailHeader from '../../layouts/components/DetailHeader'
import UploadDocumentsForm, { IUploadDocumentsForm } from './components/UploadDocumentsForm'
import { RootState } from '../../reducers'
import { FORM } from '../../utils/enums'
import { history } from '../../utils/history'
import { patchReq } from '../../utils/requests'

const form = FORM.UPLOAD_DOCUMENTS

const UploadDocumentsPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const selector = formValueSelector(form)

	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))
	const menu = useSelector((state: RootState) => state?.menuStore?.path)
	const selectorError = getFormError(FORM.UPLOAD_DOCUMENTS)
	const formErrorMessage = useSelector((state: RootState) => selectorError(state))
	const planStates = useSelector((state: RootState) => selector(state, 'planStates'))

	const [switchValue, setSwitchValue] = useState(true)

	const handleSubmit = async (values: IUploadDocumentsForm) => {
		try {
			const reqArray: any[] = []

			values?.planStates?.forEach((planState) => {
				planState?.files?.forEach((state) => {
					reqArray.push({ fileID: state.id, planStateID: planState?.route?.planState?.key })
				})
			})

			values?.unnassignedPlanStates?.forEach((planState) => {
				reqArray.push({ fileID: planState?.file?.id, planStateID: planState?.route?.planState?.key })
			})

			values?.multipleSuggestionPlanStates?.forEach((planState) => {
				reqArray.push({ fileID: planState.file?.id, planStateID: planState?.chosenStatus })
			})

			const reqBody = {
				files: reqArray.map((data) => ({
					fileID: data?.fileID,
					planStateID: data?.planStateID
				})),
				notify: values?.notify
			}

			await patchReq('/api/v1/documents/', {}, reqBody)
			history.push(t('paths:index'))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	useEffect(() => {
		// aby sa neinitovalo ked ho initnem z bulk uploadu.
		if (!planStates) {
			const initData = {
				planStates: [
					{
						route: {
							project: menu.project
								? {
										label: menu.project?.name,
										key: menu?.project?.ID,
										value: menu?.project?.ID
								  }
								: null,
							folder: menu?.folder
								? {
										label: menu.folder?.name,
										key: menu.folder?.ID,
										value: menu.folder?.ID
								  }
								: null,
							subFolder: menu?.subFolder
								? {
										label: menu?.subFolder?.name,
										key: menu?.subFolder?.ID,
										value: menu?.subFolder?.ID
								  }
								: null,
							plan: menu?.planStateDocument
								? {
										label: menu?.planStateDocument?.planName,
										key: menu?.planStateDocument?.planID,
										value: menu?.planStateDocument?.planID
								  }
								: null,
							planState: menu?.planStateDocument?.defaultOption ? menu?.planStateDocument?.defaultOption : null
						},
						files: []
					}
				],
				notify: true
			}
			dispatch(initialize(form, initData))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSwitchChange = (value: boolean) => {
		setSwitchValue(value)
		dispatch(change(form, 'notify', value))
	}

	return (
		<>
			<Layout className={'extd-layout with-footer'}>
				<DetailHeader title={t('loc:Upload documents')} />
				<UploadDocumentsForm onSubmit={handleSubmit} />
			</Layout>
			<div className={'extd-layout-footer'}>
				<div className={'content-footer'}>
					<div className={'flex flex-row'}>
						<span>{t('loc:Notify others?')}</span>
						<Switch checked={switchValue} onChange={handleSwitchChange} />
					</div>
					<Button type={'default'} className={'extd-btn'} onClick={() => history.push(t('paths:index'))}>
						{t('loc:Cancel')}
					</Button>
					<Button type={'primary'} className={'extd-btn'} loading={submitting} disabled={submitting || !!formErrorMessage} onClick={() => dispatch(submit(form))}>
						{t('loc:Upload')}
					</Button>
				</div>
			</div>
		</>
	)
}

export default UploadDocumentsPage
