import React from 'react'
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Form, Row, Col } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import InputField from '../../../../atoms/InputField'
import { FORM } from '../../../../utils/enums'
import { validationRequired } from '../../../../utils/helpers'

export interface IIndexForm {
	index: string | number
}

type ComponentProps = {}

type Props = InjectedFormProps<IIndexForm, ComponentProps> & ComponentProps

const ChooseFirstIndexForm = (props: Props) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { handleSubmit } = props

	const onInputChange = (value: any) => {
		if (!value) dispatch(change(FORM.INDEX_FORM, 'index', value))
		if (value?.length === 1) {
			if (/^[a-z0-9]+$/i.test(value) || value === '-') {
				dispatch(change(FORM.INDEX_FORM, 'index', value))
			}
		}
		if (value?.length >= 1 && /^[0-9]+$/i.test(value)) {
			dispatch(change(FORM.INDEX_FORM, 'index', value))
		}
	}

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Row justify={'center'}>
				<Col span={8}>
					<Field name={'index'} validate={[validationRequired]} component={InputField} maxLength={3} label={t('loc:Index')} customOnChange={onInputChange} required />
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IIndexForm, ComponentProps>({
	form: FORM.INDEX_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true
})(ChooseFirstIndexForm)

export default form
