import React from 'react'
import { Modal, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { isSubmitting, submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'
import { DEFAULT_PAGE_SIZE, FORM } from '../../../../utils/enums'
import { ReactComponent as ProjectIcon } from '../../../../assets/icons/icon-project.svg'
import { RootState } from '../../../../reducers'
import EditMainFolderStatusesForm, { IEditMainFolderStatusesForm } from './EditMainFolderStatusesForm'
import { patchReq } from '../../../../utils/requests'
import { getPlans } from '../../../../reducers/plans/plansActions'

const form = FORM.MAIN_FOLDER_EDIT_STATUSES_FORM

type Props = {
	visible: boolean
	projectName: string
	onClose: () => void
}

const EditMainFolderStatusesModal = ({ visible, projectName, onClose }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))
	const plans = useSelector((state: RootState) => state?.plansStore?.plans)

	const handleSubmit = async (values: IEditMainFolderStatusesForm) => {
		try {
			const reqBody = {
				states: map(values.tabs, (tab) => ({
					id: tab?.existingID || null,
					name: tab.name,
					projectUserRole: tab.statusView.key as any
				}))
			}
			await patchReq('/api/v1/folders/{folderID}', { folderID: values.folderID }, reqBody)
			dispatch(
				getPlans(
					values.folderID,
					plans?.pagination?.page || 1,
					plans?.search,
					plans?.onlyActual,
					plans?.fromDate,
					plans?.toDate,
					plans?.pagination?.limit || DEFAULT_PAGE_SIZE
				)
			)
			onClose()
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	return (
		<Modal
			title={
				<Space className={'cursor-default'}>
					{t('loc:Edit status timeline')}
					<div className={'flex items-center text-m-medium text-zinc-600'}>
						<ProjectIcon />
						<span className={'ml-1'}>{projectName}</span>
					</div>
				</Space>
			}
			okText={t('loc:Save')}
			onOk={() => dispatch(submit(form))}
			okButtonProps={{
				loading: submitting,
				disabled: submitting
			}}
			cancelText={t('loc:Cancel')}
			onCancel={onClose}
			getContainer={() => document.body}
			destroyOnClose
			visible={visible}
			className={'modal-w-1100'}
		>
			{visible && <EditMainFolderStatusesForm onSubmit={handleSubmit} />}
		</Modal>
	)
}

export default EditMainFolderStatusesModal
