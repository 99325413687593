/* eslint-disable import/no-cycle */
import { ThunkResult } from '../index'
import { PLAN_STATE_DOCUMENTS } from './planStateDocumentsTypes'
import { IResetStore } from '../generalTypes'
import { Paths } from '../../types/api'
import { IResponsePagination } from '../../types/interfaces'
import { getReq } from '../../utils/requests'

export interface IPlanStateDocumentsPayload {
	documents: Paths.GetApiV1PlanStatesPlanStateIdDocuments.Responses.$200['documents'] | null
	note: Paths.GetApiV1PlanStatesPlanStateIdDocuments.Responses.$200['note'] | null
	pagination: IResponsePagination | null
	search: string | null
}

interface IGetPlanStateDocuments {
	type: PLAN_STATE_DOCUMENTS
	payload: IPlanStateDocumentsPayload
}

export type IPlanStateDocumentsActions = IResetStore | IGetPlanStateDocuments

export const getPlanStateDocuments =
	(planStateID: number, page: number | null | undefined, search: string | null, limit: Paths.GetApiV1PlanStatesPlanStateIdDocuments.Parameters.Limit): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: PLAN_STATE_DOCUMENTS.PLAN_STATE_DOCUMENTS_LOAD_START })
		try {
			const { data } = await getReq('/api/v1/plan-states/{planStateID}/documents', { planStateID, search, limit, page: page || 0 }, undefined, undefined, undefined, true)

			dispatch({ type: PLAN_STATE_DOCUMENTS.PLAN_STATE_DOCUMENTS_LOAD_DONE, payload: { search, ...data } })
		} catch (e: any) {
			dispatch({ type: PLAN_STATE_DOCUMENTS.PLAN_STATE_DOCUMENTS_LOAD_FAIL })
		}
	}
