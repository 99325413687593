import { IDownloadItem } from '../../../../reducers/dowload/downloadActions'
import { IFoldersPayload } from '../../../../reducers/folders/foldersActions'
import { Paths } from '../../../../types/api'

export const getSelectedFolders = (downloadList: IDownloadItem[], folders: IFoldersPayload): number[] => {
	const selectedRows: number[] = []

	folders?.folders?.forEach((folder) => {
		const foundItem = downloadList?.find((item) => item?.folder?.ID === folder?.id)
		if (foundItem) {
			selectedRows.push(folder?.id)
		}
	})

	return selectedRows
}

export const selectFiles = (projectID: number, projectName: string, downloadList: IDownloadItem[], data: Paths.PostApiV1FoldersDocuments.Responses.$200) => {
	// filter out those that do not have any files in them.
	const newDownloadList: IDownloadItem[] = []

	data?.folders?.forEach((item) => {
		item?.plans?.forEach((plan) => {
			plan?.planStates?.forEach((planState) => {
				planState?.documents?.forEach((document) => {
					const newDownloadItem: IDownloadItem = {
						project: {
							ID: projectID,
							name: projectName
						},
						folder: {
							ID: item?.id,
							name: item?.name
						},
						plan: {
							ID: plan?.id,
							name: plan?.name
						},
						planState: {
							ID: planState?.id,
							name: planState?.name
						},
						file: {
							ID: document?.id,
							name: document?.name,
							mimeType: document?.mimeType
						}
					}
					newDownloadList.push(newDownloadItem)
				})
			})
		})
		item?.children?.forEach((subFolder) => {
			subFolder?.plans?.forEach((plan) => {
				plan?.planStates?.forEach((planState) => {
					planState?.documents?.forEach((document) => {
						const newDownloadItem: IDownloadItem = {
							project: {
								ID: projectID,
								name: projectName
							},
							folder: {
								ID: item?.id,
								name: item?.name
							},
							subFolder: {
								ID: subFolder?.id,
								name: subFolder?.name
							},
							plan: {
								ID: plan?.id,
								name: plan?.name
							},
							planState: {
								ID: planState?.id,
								name: planState?.name
							},
							file: {
								ID: document?.id,
								name: document?.name,
								mimeType: document?.mimeType
							}
						}
						newDownloadList.push(newDownloadItem)
					})
				})
			})
		})
	})

	const mergedDownloadList = [...downloadList, ...newDownloadList]

	data?.folders?.forEach((folder) => {
		if (!mergedDownloadList?.find((item) => item?.folder?.ID === folder?.id)) {
			const newDownloadItem: IDownloadItem = {
				project: {
					ID: projectID,
					name: projectName
				},
				folder: {
					ID: folder?.id,
					name: folder?.name
				}
			}
			mergedDownloadList.push(newDownloadItem)
		}
	})

	return mergedDownloadList
}

export const deselectFolders = (downloadList: IDownloadItem[], deselectedRowKeys: number[]): IDownloadItem[] => {
	const filteredDownloadList: IDownloadItem[] = downloadList?.filter((item) => !deselectedRowKeys?.includes(item?.folder?.ID))

	return filteredDownloadList
}
