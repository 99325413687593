import React from 'react'
import { CloseOutlined, CopyOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ACTION_TYPE, TREE_NODE_TYPE } from '../../../../utils/enums'
import { history } from '../../../../utils/history'
import usePermissionCheck from '../../../../utils/permissionCheck'
import { handleCreatePlan, handleCreateSubFolder } from '../../../../utils/helpers'
import PlansSearchFilter from './PlansSearchFilter'
import { ISearchFilter } from '../../../../types/interfaces'

type Props = {
	type: TREE_NODE_TYPE
	handleBulkDuplicate: (increaseStatus: boolean) => void
	searchPlans: (search: string) => void
	duplicateDisabled: boolean
	projectID: number | undefined
	folderID: number
	projectName: string | undefined
	projectCodeAttributes: any
	resetFilters: () => void
	isClearShown: boolean
}

const PlansTableButtonRow = ({
	type,
	handleBulkDuplicate,
	searchPlans,
	duplicateDisabled,
	projectID,
	folderID,
	projectName,
	projectCodeAttributes,
	resetFilters,
	isClearShown
}: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const permissedActions = usePermissionCheck()

	const duplicateMenu = (
		<Menu>
			<Menu.Item key={'Duplicate_index'} onClick={() => handleBulkDuplicate(false)}>
				{t('loc:Duplicate to next Index')}
			</Menu.Item>
			<Menu.Item key={'Duplicate_index_increase_status'} onClick={() => handleBulkDuplicate(true)}>
				{t('loc:Duplicate to next Index and increase Status')}
			</Menu.Item>
		</Menu>
	)

	const handleSubmit = (values: ISearchFilter) => {
		searchPlans(values?.search)
	}

	return (
		<div className={'flex flex-row justify-between pt-4'}>
			<div className={'flex flex-row'}>
				<PlansSearchFilter onSubmit={handleSubmit} placeholder={t('loc:Search plans')} />
				{isClearShown && (
					<Button type={'primary'} className={'extd-btn-danger h-10 ml-2'} onClick={() => resetFilters()} icon={<CloseOutlined />}>
						{t('loc:Clear filter')}
					</Button>
				)}
			</div>
			<div className={type === TREE_NODE_TYPE.MAIN_FOLDER ? 'mainfolder-plans-button-row' : 'subfolder-plans-button-row'}>
				<div>
					<Button
						type={'default'}
						className={'extd-btn h-10 mr-2'}
						icon={<UploadOutlined />}
						onClick={() => history.push(t('paths:upload-documents'))}
						disabled={!permissedActions?.includes(ACTION_TYPE.UPLOAD)}
					>
						{t('loc:Upload files')}
					</Button>
				</div>
				<div>
					<Dropdown overlay={duplicateMenu} disabled={duplicateDisabled}>
						<Button type={'default'} className={'extd-btn h-10 mr-2 flex100'} icon={<CopyOutlined />} disabled={duplicateDisabled}>
							{t('loc:Bulk duplicate')}
						</Button>
					</Dropdown>
				</div>

				{type === TREE_NODE_TYPE.MAIN_FOLDER && (
					<div>
						<Button
							type={'default'}
							className={'extd-btn h-10 mr-2'}
							icon={<PlusOutlined />}
							onClick={() => handleCreateSubFolder(projectID, folderID, projectName, dispatch)}
							disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_CREATE)}
						>
							{t('loc:Create sub-folder')}
						</Button>
					</div>
				)}
				<div>
					<Button
						type={'primary'}
						className={'extd-btn h-10 w-36'}
						icon={<PlusOutlined />}
						style={{ width: '100%' }}
						onClick={() => handleCreatePlan(folderID, projectCodeAttributes, projectName, dispatch)}
						disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_CREATE)}
					>
						{t('loc:Create plan')}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default PlansTableButtonRow
