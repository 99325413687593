import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { change, initialize } from 'redux-form'
import { Divider, Dropdown, Menu } from 'antd'
import { getPlans } from '../../../../reducers/plans/plansActions'
import { Paths } from '../../../../types/api'
import { ReactComponent as EditIcon } from '../../../../assets/icons/icon-edit.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/icon-delete.svg'
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/icon-duplicate.svg'
import { ReactComponent as DotsIcon } from '../../../../assets/icons/icon-table-actions.svg'
import { ACTION_TYPE, CODE_TYPE, FORM, PROJECT_CODE_TYPE } from '../../../../utils/enums'
import usePermissionCheck from '../../../../utils/permissionCheck'
import { deleteReq, getReq, postReq } from '../../../../utils/requests'
import ConfirmModal from '../../../../components/ConfirmModal'
import ChooseFirstIndexModal from './ChooseFirstIndexModal'

const minDropdownWidth = { minWidth: 150 }

type PlansProps = {
	folderID: number
	planID: number
	planName: string
	projectName: string
	projectCodeAttributes: any
	page: number
	limit: number
	search: string
	onlyActual: boolean
	dateFromValue: string | undefined
	dateToValue: string | undefined
}

const PlansTableActions = (props: PlansProps) => {
	const { folderID, planID, projectName, projectCodeAttributes, page, search, onlyActual, dateFromValue, dateToValue, limit, planName } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [modalState, setModalState] = useState(false)
	const [indexModal, setIndexModal] = useState<{ visible: boolean; increaseStatus: boolean }>({ visible: false, increaseStatus: false })

	const permissedActions = usePermissionCheck()

	const confirmDelete = async () => {
		try {
			await deleteReq('/api/v1/plans/{planID}', { planID })
			dispatch(getPlans(folderID, page, search, onlyActual, dateFromValue, dateToValue, limit as any))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
		setModalState(false)
	}

	const handleDuplicateNextIndex = async () => {
		const splitPlanName = planName.split('_')
		let isMissingIndex = false

		projectCodeAttributes.forEach((code: any, index: number) => {
			if (code?.type === PROJECT_CODE_TYPE.INDEX && splitPlanName?.at(index) === '-') {
				isMissingIndex = true
			}
		})

		if (!isMissingIndex) {
			try {
				const reqBody: Paths.PostApiV1PlansPlanIdDuplicate.RequestBody = {
					type: 'INDEX',
					increaseStatus: false
				}

				await postReq('/api/v1/plans/{planID}/duplicate', { planID }, reqBody)
				dispatch(getPlans(folderID, page, search, onlyActual, dateFromValue, dateToValue, limit as any))
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e)
			}
		} else {
			setIndexModal({ visible: true, increaseStatus: false })
		}
	}

	const handleDuplicateNextIndexAndIncreseStatus = async () => {
		const splitPlanName = planName.split('_')
		let isMissingIndex = false

		projectCodeAttributes.forEach((code: any, index: number) => {
			if (code?.type === PROJECT_CODE_TYPE.INDEX && splitPlanName?.at(index) === '-') {
				isMissingIndex = true
			}
		})

		if (!isMissingIndex) {
			try {
				const reqBody: Paths.PostApiV1PlansPlanIdDuplicate.RequestBody = {
					type: 'INDEX',
					increaseStatus: true
				}

				await postReq('/api/v1/plans/{planID}/duplicate', { planID }, reqBody)
				dispatch(getPlans(folderID, page, search, onlyActual, dateFromValue, dateToValue, limit as any))
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e)
			}
		} else {
			setIndexModal({ visible: true, increaseStatus: true })
		}
	}

	const handleDuplicateNextSerialNumber = async () => {
		try {
			const reqBody: Paths.PostApiV1PlansPlanIdDuplicate.RequestBody = {
				type: 'SERIAL_NUMBER'
			}

			await postReq('/api/v1/plans/{planID}/duplicate', { planID }, reqBody)
			dispatch(getPlans(folderID, page, search, onlyActual, dateFromValue, dateToValue, limit as any))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
	}

	const openEditModal = async () => {
		try {
			const planData = await getReq('/api/v1/plans/{planID}', { planID })
			if (projectCodeAttributes?.length !== 0) {
				dispatch(
					initialize(FORM.PLAN_EDIT_FORM, {
						projectCodeAttributes,
						description: planData?.data?.description,
						hasGeneratedName: planData?.data?.hasGeneratedName ? CODE_TYPE.STATES : CODE_TYPE.FREE_TEXT,
						isCodeSet: true
					})
				)
			} else {
				dispatch(
					initialize(FORM.PLAN_EDIT_FORM, {
						projectCodeAttributes: [],
						description: planData?.data?.description,
						hasGeneratedName: CODE_TYPE.FREE_TEXT,
						isCodeSet: false
					})
				)
			}
			dispatch(
				change(FORM.PLAN_EDIT_FORM, 'opened', {
					projectName,
					planID,
					folderID,
					generatedPlanName: planData?.data?.hasGeneratedName ? planData?.data?.name : null,
					freeTextPlanName: planData?.data.name
				})
			)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
	}

	const refetchPlans = () => {
		dispatch(getPlans(folderID, page, search, onlyActual, dateFromValue, dateToValue, limit as any))
	}

	const dropdownMenu = (
		<Menu>
			<Menu.Item key={'Edit'} icon={<EditIcon />} onClick={openEditModal} disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_EDIT)}>
				{t('loc:Edit')}
			</Menu.Item>
			<Menu.Item
				key={'Duplicate_plan_number'}
				icon={<DuplicateIcon />}
				onClick={handleDuplicateNextSerialNumber}
				disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_DUPLICATE)}
			>
				{t('loc:Duplicate to next Plan_number')}
			</Menu.Item>
			<Menu.Item key={'Duplicate_index'} icon={<DuplicateIcon />} onClick={handleDuplicateNextIndex} disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_DUPLICATE)}>
				{t('loc:Duplicate to next Index')}
			</Menu.Item>
			<Menu.Item
				key={'Duplicate_index_increase_status'}
				icon={<DuplicateIcon />}
				onClick={handleDuplicateNextIndexAndIncreseStatus}
				disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_DUPLICATE)}
			>
				{t('loc:Duplicate to next Index and increase Status')}
			</Menu.Item>
			<Divider className={'mt-0 mb-0'} />
			<Menu.Item
				key={'Delete'}
				icon={<DeleteIcon />}
				className={permissedActions?.includes(ACTION_TYPE.FOLDER_DELETE) ? 'text-error-medium' : ''}
				onClick={() => setModalState(true)}
				disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_DELETE)}
			>
				{t('loc:Delete')}
			</Menu.Item>
		</Menu>
	)

	return (
		<div>
			<Dropdown overlay={dropdownMenu} overlayStyle={minDropdownWidth} trigger={['click']}>
				<div className={'extd-table-dropdown flex justify-center'}>
					<DotsIcon />
				</div>
			</Dropdown>
			<ChooseFirstIndexModal
				onClose={() => setIndexModal({ visible: false, increaseStatus: false })}
				visible={indexModal?.visible}
				increaseStatus={indexModal?.increaseStatus}
				refetchPlans={refetchPlans}
				planID={planID}
			/>
			<ConfirmModal
				visible={modalState}
				text={t('loc:Delete plan?')}
				confirmButtonText={t('loc:Confirm')}
				cancelButtonText={t('loc:Cancel')}
				confirmButtonClassName={'extd-btn-danger'}
				onCancel={() => setModalState(false)}
				onSubmit={confirmDelete}
			/>
		</div>
	)
}

export default PlansTableActions
