import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { arrayPush, arrayRemove, change, Field, FieldArray, formValueSelector, getFormError, isDirty, WrappedFieldArrayProps, WrappedFieldProps } from 'redux-form'
import { Button, Col, Row, Typography } from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/icon-breadcrumb-arrow.svg'
import { ReactComponent as DoneIcon } from '../../../assets/icons/icon-notification-done.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/icon-notification-warning-red.svg'
import { ReactComponent as ProjectIcon } from '../../../assets/icons/icon-input-project.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon-delete.svg'
import { PathFieldValue, PlanStateFieldValue } from './PlanStateFieldArray'
import { FORM, UPLOAD_ERROR_TYPE } from '../../../utils/enums'
import { RootState } from '../../../reducers'

const form = FORM.UPLOAD_DOCUMENTS
const selector = formValueSelector(form)

type PathsFieldProps = WrappedFieldProps

const PathsField = (param: PathsFieldProps) => {
	const dispatch = useDispatch()
	const routes = param.input?.value
	const chosenStatusName = `${param?.input?.name.split('.')[0]}.chosenStatus`
	const chosenStatusValue = useSelector((state: RootState) => selector(state, `${param?.input?.name.split('.')[0]}.chosenStatus`))

	const routeText = (route: PathFieldValue) => {
		let path = ''
		path += `.../${route.plan?.label}` || ''
		path += `/${route?.planState?.label}` || ''
		return path
	}

	const chooseRoute = (route: PathFieldValue) => {
		if (route?.planState?.key === chosenStatusValue) {
			dispatch(change(form, chosenStatusName, undefined))
		} else {
			dispatch(change(form, chosenStatusName, route.planState?.key))
		}
	}
	return (
		<div className={'flex flex-row items-center'}>
			<div>{chosenStatusValue ? <DoneIcon className={'mr-2 ml-2'} /> : <WarningIcon className={'mr-2 ml-2'} />} </div>
			<Row gutter={[16, 16]} style={{ width: '100%' }}>
				{routes?.map((route: PathFieldValue) => {
					return (
						<Col span={8}>
							<Button
								style={{ width: '89%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
								type={route?.planState?.key === chosenStatusValue ? 'primary' : 'default'}
								className={'extd-btn'}
								onClick={() => chooseRoute(route)}
							>
								{routeText(route)}
							</Button>
						</Col>
					)
				})}
			</Row>
		</div>
	)
}

type FileFieldProps = WrappedFieldProps

const FileField = (param: FileFieldProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const fileName = param?.input?.value?.name
	const fieldName = param?.input?.name

	const routePath = useSelector((state: RootState) => selector(state, `${param?.input?.name.split('.')[0]}.routes`))?.at(0)
	const selectorError = getFormError(FORM.UPLOAD_DOCUMENTS)
	const formErrorMessage: string = useSelector((state: RootState) => selectorError(state)) as string
	const isFormDirty = useSelector((state: RootState) => isDirty(form)(state))

	const getErrorMessage = (): string | undefined => {
		if (!formErrorMessage || !isFormDirty) {
			return undefined
		}
		const errorArray = formErrorMessage.split('-')
		const pathFieldIndex = parseInt(fieldName?.substring(fieldName.indexOf('[') + 1, fieldName.lastIndexOf(']')), 10)

		const errorText = errorArray.find(
			(errorItem) =>
				parseInt(errorItem?.substring(errorItem.indexOf('[') + 1, errorItem.lastIndexOf(']')), 10) === pathFieldIndex && errorItem.includes(UPLOAD_ERROR_TYPE.MUTLIPLE_PATH)
		)

		if (errorText) {
			return errorText?.substring(errorText.indexOf(' ') + 1)
		}

		return undefined
	}

	const deleteField = () => {
		const index = parseInt(fieldName.substring(fieldName.indexOf('[') + 1, fieldName.lastIndexOf(']')), 10)
		dispatch(arrayRemove(form, 'multipleSuggestionPlanStates', index))
	}

	const moveToPlanStateField = () => {
		const newPlanStateField: PlanStateFieldValue = {
			route: {
				project: { label: routePath?.project?.label, value: routePath?.project?.value, key: routePath?.project?.key },
				folder: { label: routePath?.folder?.label, value: routePath?.folder?.value, key: routePath?.folder?.key },
				subFolder: null,
				plan: null,
				planState: null
			},
			files: [{ id: param?.input?.value?.id, name: fileName }]
		}
		dispatch(arrayPush(form, 'planStates', newPlanStateField))
		deleteField()
	}

	return (
		<Row style={{ width: '100%', alignItems: 'center' }} className={'mb-2'} gutter={[0, 16]}>
			<Col span={8}>
				<div className={'h-10 mb-1 flex justify-between bg-gray-100 p-2 rounded'}>
					<div className={'flex flex-row'}>
						<div>
							<ProjectIcon />
						</div>
						<Typography.Text className={'ml-1'} ellipsis>
							{fileName}
						</Typography.Text>
					</div>
				</div>
			</Col>
			<Col span={4}>
				<Button type={'primary'} className={'extd-btn ml-4'} onClick={moveToPlanStateField}>
					{t('loc:Choose different path')}
				</Button>
			</Col>
			<Col span={4}>
				<span className={'text-error-medium cursor-default'}>{getErrorMessage()}</span>
			</Col>
			<Col span={8}>
				<div className={'flex flex-row-reverse'}>
					<Button type={'text'} danger className={'extd-btn'} style={{ width: '104px' }} icon={<DeleteIcon />} onClick={deleteField}>
						{t('loc:Remove')}
					</Button>
				</div>
			</Col>
		</Row>
	)
}

type MultipleSuggestionPlanStateProps = WrappedFieldArrayProps<MultipleSuggestionPlanStateProps>

const MultipleSuggestionPlanStateField = (param: MultipleSuggestionPlanStateProps) => {
	const { t } = useTranslation()

	if (!param?.fields?.length) {
		return <div />
	}

	return (
		<div className={'mb-6 p-4 bg-white'}>
			<div className={'flex flex-row justify-between'}>
				<div className={'flex flex-col cursor-default mb-4'}>
					<span className={'heading-4 mb-2'}>{t('loc:Documents with multiple suggestions')}</span>
					<span>{t('loc:Specify final destination for documents.')}</span>
				</div>
			</div>
			{param?.fields?.map((name, index) => {
				return (
					<div className={'mb-4'} key={index}>
						<Field name={`${name}.file`} component={FileField as any} />
						<Field name={`${name}.routes` as string} component={PathsField as any} />
					</div>
				)
			})}
		</div>
	)
}

const MultipleSuggestionPlanStateFieldArray = () => {
	return <FieldArray name={'multipleSuggestionPlanStates'} component={MultipleSuggestionPlanStateField as any} />
}

export default MultipleSuggestionPlanStateFieldArray
