import React from 'react'
import { Form } from 'antd'
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { ISearchFilter } from '../../../../types/interfaces'
import InputField from '../../../../atoms/InputField'
import { FORM } from '../../../../utils/enums'

type ComponentProps = {
	submit?: any
	placeholder: string
}

type Props = InjectedFormProps<ISearchFilter, ComponentProps> & ComponentProps

const PlansSearchFilter = (props: Props) => {
	const { handleSubmit, placeholder } = props

	return (
		<Form onSubmitCapture={handleSubmit} style={{ width: '30vw' }}>
			<Field
				component={InputField}
				name={'search'}
				allowClear
				placeholder={placeholder}
				className={'extd-input'}
				type={'text'}
				size={'large'}
				suffix={<SearchOutlined />}
				maxLength={100}
			/>
		</Form>
	)
}

const form = reduxForm<ISearchFilter, ComponentProps>({
	form: FORM.PLANS_SEARCH_FILTER,
	forceUnregisterOnUnmount: false,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, 300),
	destroyOnUnmount: false
})(PlansSearchFilter)

export default form
