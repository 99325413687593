import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form } from 'antd'
import { InjectedFormProps, reduxForm, Field } from 'redux-form'
import { FORM } from '../../../../utils/enums'
import SwitchField from '../../../../atoms/SwitchField'

export interface IEmailNotificationsForm {
	1: boolean
	2: boolean
	3: boolean
	4: boolean
	5: boolean
	6: boolean
	7: boolean
}

type ComponentProps = {}

type Props = InjectedFormProps<IEmailNotificationsForm, ComponentProps> & ComponentProps

const EmailNotificationsForm = (props: Props) => {
	const { handleSubmit, submitting } = props

	const { t } = useTranslation()

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<div className={'w-52 flex flex-row justify-between items-center'}>
				<span>{t('loc:Monday')}</span>
				<Field name={'1'} component={SwitchField} />
			</div>
			<div className={'w-52 flex flex-row justify-between items-center'}>
				<span>{t('loc:Tuesday')}</span>
				<Field name={'2'} component={SwitchField} />
			</div>
			<div className={'w-52 flex flex-row justify-between items-center'}>
				<span>{t('loc:Wednesday')}</span>
				<Field name={'3'} component={SwitchField} />
			</div>
			<div className={'w-52 flex flex-row justify-between items-center'}>
				<span>{t('loc:Thursday')}</span>
				<Field name={'4'} component={SwitchField} />
			</div>
			<div className={'w-52 flex flex-row justify-between items-center'}>
				<span>{t('loc:Friday')}</span>
				<Field name={'5'} component={SwitchField} />
			</div>
			<div className={'w-52 flex flex-row justify-between items-center'}>
				<span>{t('loc:Saturday')}</span>
				<Field name={'6'} component={SwitchField} />
			</div>
			<div className={'w-52 flex flex-row justify-between items-center'}>
				<span>{t('loc:Sunday')}</span>
				<Field name={'7'} component={SwitchField} />
			</div>
			<Button type={'primary'} className={'extd-btn w-52 mt-4'} htmlType={'submit'} disabled={submitting} loading={submitting}>
				{t('loc:Save')}
			</Button>
		</Form>
	)
}

const form = reduxForm<IEmailNotificationsForm, ComponentProps>({
	form: FORM.SETTINGS_EMAIL_NOTIFICATIONS_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true
})(EmailNotificationsForm)

export default form
