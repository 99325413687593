import React from 'react'
import { Row, Col } from 'antd'
import { WrappedFieldArrayProps, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { validationRequired, validationString } from '../../../../utils/helpers'
import InputField from '../../../../atoms/InputField'

const stringMax100 = validationString(100)

type Props = WrappedFieldArrayProps<any>

const ConstantArrayField = (props: Props) => {
	const { t } = useTranslation()

	return props?.fields?.map((option, index) => {
		return (
			<Row key={index} gutter={[16, 16]} className={'mt-6'}>
				<Col span={11} className={'ml-6'}>
					<Field
						component={InputField}
						name={`${option}.constant`}
						placeholder={t('loc:Constant')}
						label={t('loc:Constant')}
						size={'large'}
						required
						validate={[validationRequired, stringMax100]}
					/>
				</Col>
			</Row>
		)
	})
}

export default ConstantArrayField
