import { FormErrors } from 'redux-form'
import i18next from 'i18next'
// eslint-disable-next-line import/no-cycle
import { IEditMainFolderStatusesForm } from './EditMainFolderStatusesForm'

/**
 * @param {IEditMainFolderStatusesForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IEditMainFolderStatusesForm> = {}

	let errorIndex: any
	values?.tabs?.forEach((tab: any, tabIndex: any) => {
		if (errorIndex === undefined) {
			if (!tab.name || !tab.statusView) {
				errorIndex = tabIndex
			}
		}
	})

	if (errorIndex !== undefined) {
		// eslint-disable-next-line no-underscore-dangle
		errors._error = i18next.t('loc:All values on {{tabID}}. tab are required', { tabID: errorIndex + 1 })
	}

	return errors
}
