import React from 'react'
import { Modal, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { isSubmitting, submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { FORM } from '../../../../utils/enums'
import { RootState } from '../../../../reducers'
import EditUserForm, { IEditUserForm } from './EditUserForm'

const form = FORM.EDIT_USER_FORM

type Props = {
	visible: boolean
	onSubmit: (values: IEditUserForm) => void
	onClose: () => void
}

const EditUserModal = ({ visible, onClose, onSubmit }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))

	const handleSubmit = async (values: IEditUserForm) => {
		onSubmit(values)
	}

	return (
		<Modal
			title={<Space className={'cursor-default'}>{t('loc:Edit user')}</Space>}
			okText={t('loc:Save')}
			onOk={() => dispatch(submit(form))}
			okButtonProps={{
				loading: submitting,
				disabled: submitting
			}}
			cancelText={t('loc:Cancel')}
			onCancel={onClose}
			destroyOnClose
			visible={visible}
		>
			{visible && <EditUserForm onSubmit={handleSubmit} />}
		</Modal>
	)
}

export default EditUserModal
