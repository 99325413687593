import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { change, initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TablePaginationConfig, Table, Pagination, Tooltip, Typography } from 'antd'
import { FolderOutlined, PlusOutlined } from '@ant-design/icons'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { RootState } from '../../../reducers'
import { getFolders } from '../../../reducers/folders/foldersActions'
import { getProjectsSider } from '../../../reducers/projects/projectsActions'
import { ReactComponent as FoldersImage } from '../../../assets/images/image-folder.svg'
import { DEFAULT_PAGE_SIZE, PAGINATION, FORM, ACTION_TYPE, MODAL_TYPE_FOLDER } from '../../../utils/enums'
import { Paths } from '../../../types/api'
import { formatDateTime } from '../../../utils/helpers'
import ConfirmModal from '../../../components/ConfirmModal'
import RenameFolderModal from './components/RenameFolderModal'
import { deleteReq, postReq } from '../../../utils/requests'
import usePermissionCheck from '../../../utils/permissionCheck'
import { setProjectSiderToFolder } from '../../../reducers/menu/menuActions'
import { deselectFolders, getSelectedFolders, selectFiles } from './components/FolderDownloadHandler'
import { IDownloadItem, setDownloadList } from '../../../reducers/dowload/downloadActions'
import FoldersTableActions from './components/FolderTableActions'
import FoldersTableButtonRow from './components/FoldersTableButtonRow'

const FoldersTable = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const folders = useSelector((state: RootState) => state?.foldersStore?.folders)
	const projectID = useSelector((state: RootState) => state?.menuStore?.path?.project?.ID) || 0
	const projectName = useSelector((state: RootState) => state?.menuStore?.path?.project?.name) || '-'
	const downloadList = useSelector((state: RootState) => state?.downloadStore?.download?.data)

	const [modalState, setModalState] = useState<{ visible: MODAL_TYPE_FOLDER | undefined; folderID: number | undefined }>({ visible: undefined, folderID: undefined })

	const [search, setSearch] = useState('')

	const permissedActions = usePermissionCheck()

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])

	const [, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam
	})

	useEffect(() => {
		if (folders?.folders?.length) setSelectedRowKeys(getSelectedFolders(downloadList, folders))
		// Toto je super shitty practice, neviem ale ako to fixnut.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectID, downloadList, folders])

	useEffect(() => {
		dispatch(getFolders(projectID || 0, 1, '', folders?.pagination?.limit || DEFAULT_PAGE_SIZE))
		dispatch(change(FORM.SEARCH_FILTER, 'search', null))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectID])

	const searchFolders = (searchTerm: string) => {
		dispatch(getFolders(projectID, 1, searchTerm, folders?.pagination?.limit || DEFAULT_PAGE_SIZE))
		setSearch(searchTerm)
	}

	const handleCreateFolder = () => {
		dispatch(
			initialize(FORM.MAIN_FOLDER_CREATE_FORM, {
				tabs: [{ id: 1 }]
			})
		)
		dispatch(
			change(FORM.MAIN_FOLDER_CREATE_FORM, 'opened', {
				projectID,
				projectName
			})
		)
	}

	const pagination = {
		current: folders?.pagination?.page,
		total: folders?.pagination?.totalCount,
		pageSize: folders?.pagination?.limit,
		showSizeChanger: true,
		pageSizeOptions: PAGINATION.pageSizeOptions,
		className: 'extd-pagination'
	}

	const columns = [
		{
			key: 'name',
			dataIndex: 'name',
			title: t('loc:Folder name'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (name: any) => (
				<div>
					<Tooltip placement={'topLeft'} title={name} overlayStyle={{ maxWidth: '500px' }}>
						<FolderOutlined className={'text-blue-600 mr-2'} />
						<Typography.Text ellipsis className={'text-m-medium'} style={{ width: 'calc(100% - 25px)' }}>
							{name}
						</Typography.Text>
					</Tooltip>
				</div>
			)
		},
		{
			key: 'plansCount',
			dataIndex: 'plansCount',
			title: t('loc:Plans count'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true
		},
		{
			key: 'updatedby',
			dataIndex: 'lastUpdate',
			title: t('loc:Updated By'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (lastUpdate: any) => `${lastUpdate?.user?.name} ${lastUpdate?.user?.surname}`
		},
		{
			key: 'lastModified',
			dataIndex: 'lastUpdate',
			title: t('loc:Last modified'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (lastUpdate: any) => formatDateTime(lastUpdate?.datetime)
		},
		{
			key: 'actions',
			width: 50,
			render: (data: any) => <FoldersTableActions setModalState={setModalState} folderID={data?.id} folderName={data?.name} />
		}
	]

	const handleTableChange = useCallback(
		(paginationConfig: TablePaginationConfig) => {
			dispatch(getFolders(projectID, paginationConfig?.current, search, paginationConfig?.pageSize as Paths.GetApiV1Folders.Parameters.Limit))
		},
		[dispatch, search, projectID]
	)

	const handlePaginationChange = (page: number, pageSize: number) => {
		dispatch(getFolders(projectID, page, search, pageSize as Paths.GetApiV1Folders.Parameters.Limit))
	}

	const onSelectChange = async (newSelectedRowKeys: any) => {
		// aby bol select plynuly a necakal na BE response.
		setSelectedRowKeys(newSelectedRowKeys)

		const deselectedIds = selectedRowKeys?.filter((id: number) => !newSelectedRowKeys?.includes(id))
		const newlySelectedIds = newSelectedRowKeys?.filter((id: number) => !selectedRowKeys?.includes(id))
		let newDownloadList: IDownloadItem[] = downloadList

		if (newlySelectedIds?.length) {
			try {
				const reqBody = {
					folderIDs: newlySelectedIds
				}
				const data = await postReq('/api/v1/folders/documents', undefined, reqBody)
				newDownloadList = selectFiles(projectID, projectName, newDownloadList, data?.data)
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}

		if (deselectedIds?.length) {
			newDownloadList = deselectFolders(newDownloadList, deselectedIds)
		}

		dispatch(setDownloadList(newDownloadList))
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange
	}

	const onRow = (record: Paths.GetApiV1FoldersFolderId.Responses.$200['folder']) => ({
		onClick: () => {
			dispatch(setProjectSiderToFolder(projectID, projectName, record?.id, record?.name))
			setQuery({
				projectID,
				projectName,
				folderID: record?.id,
				folderName: record?.name,
				subFolderID: undefined,
				subFolderName: undefined,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		}
	})

	const confirmDelete = async () => {
		if (modalState.folderID) {
			try {
				await deleteReq('/api/v1/folders/{folderID}', { folderID: modalState.folderID })
				dispatch(getProjectsSider())
				dispatch(getFolders(projectID, pagination?.current, search, pagination?.pageSize || DEFAULT_PAGE_SIZE))
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e)
			}
		}
		setModalState({ visible: undefined, folderID: undefined })
	}

	const confirmRename = () => {
		dispatch(getProjectsSider())
		dispatch(getFolders(projectID, pagination?.current, search, pagination?.pageSize || DEFAULT_PAGE_SIZE))
		setModalState({ visible: undefined, folderID: undefined })
	}

	const renderEmptyState = (
		<div className={'pb-2 pt-28 flex flex-col items-center'}>
			<FoldersImage />
			<Button
				type={'primary'}
				className={'extd-btn h-10 w-56 mt-4'}
				icon={<PlusOutlined />}
				onClick={handleCreateFolder}
				disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_CREATE)}
			>
				{t('loc:Create new folder')}
			</Button>
		</div>
	)

	const foldersTable = (
		<Table
			size={'small'}
			className={'extd-table mt-4 mb-28'}
			style={{ cursor: 'pointer' }}
			columns={columns}
			rowKey={'id'}
			rowSelection={rowSelection}
			onRow={onRow}
			loading={folders?.isLoading}
			dataSource={(folders?.folders as any) || []}
			pagination={false}
			onChange={handleTableChange}
			locale={{
				emptyText: t('loc:No folders found')
			}}
		/>
	)

	return (
		<div>
			<FoldersTableButtonRow handleCreateFolder={handleCreateFolder} searchFolders={searchFolders} />
			{!folders?.isLoading && folders?.folders?.length === 0 && !search ? renderEmptyState : foldersTable}
			<div className={'extd-layout-footer'}>
				<Pagination
					size={'small'}
					className={'extd-pagination'}
					showSizeChanger={true}
					pageSizeOptions={PAGINATION.pageSizeOptions}
					current={pagination?.current}
					total={pagination?.total}
					showTotal={(total, range) => `${range[0]}-${range[1]} ${t('loc:of')} ${total} ${t('loc:items')}`}
					pageSize={pagination?.pageSize || 0}
					onChange={handlePaginationChange}
				/>
			</div>
			<ConfirmModal
				visible={modalState.visible === MODAL_TYPE_FOLDER.DELETE}
				text={t('loc:Delete folder?')}
				confirmButtonText={t('loc:Confirm')}
				cancelButtonText={t('loc:Cancel')}
				confirmButtonClassName={'extd-btn-danger'}
				onCancel={() => setModalState({ visible: undefined, folderID: undefined })}
				onSubmit={confirmDelete}
			/>
			<RenameFolderModal
				visible={modalState.visible === MODAL_TYPE_FOLDER.RENAME}
				projectName={projectName}
				folderID={modalState.folderID || 0}
				onCancel={() => setModalState({ visible: undefined, folderID: undefined })}
				onSubmit={confirmRename}
			/>
		</div>
	)
}

export default FoldersTable
