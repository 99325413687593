import { RESET_STORE } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { IDownloadActions, IDownloadPayload } from './downloadActions'
import { DOWNLOAD_TYPE } from './downloadTypes'

export const initState = {
	download: {
		data: []
	} as IDownloadPayload
}

export default (state = initState, action: IDownloadActions) => {
	switch (action.type) {
		case DOWNLOAD_TYPE.DOWNLOAD_CHANGE:
			return {
				...state,
				download: {
					...initState.download,
					data: action.payload.data
				}
			}
		case DOWNLOAD_TYPE.DOWNLOAD_CLEAR:
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
