import React from 'react'
import { Radio, Form } from 'antd'
import { InputProps } from 'antd/lib/input'
import { WrappedFieldProps } from 'redux-form'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { map } from 'lodash'

const { Item } = Form

type Props = WrappedFieldProps &
	InputProps &
	FormItemLabelProps & {
		options: any
	}

const RadioGroupField = (props: Props) => {
	const {
		input,
		label,
		required,
		options,
		meta: { error, touched },
		style
	} = props

	const radioOptions = map(options, (option) => {
		return (
			<Radio.Button key={option?.value} className={'w-full text-center'} value={option?.value}>
				{option?.label}
			</Radio.Button>
		)
	})

	return (
		<Item required={required} label={label} style={style} validateStatus={error && touched ? 'error' : undefined}>
			<Radio.Group buttonStyle={'solid'} className={'extd-radio'} value={input.value || []} onChange={input.onChange} size={'large'}>
				{radioOptions}
			</Radio.Group>
		</Item>
	)
}

export default RadioGroupField
