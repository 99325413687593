import { MENU_KEYS, TREE_NODE_TYPE } from '../../utils/enums'
import { RESET_STORE } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { IMenuActions, IMenuPayload, IPathPayload } from './menuActions'
import { MENU_TYPE, PATH_TYPE } from './menuTypes'

export const initState = {
	[MENU_KEYS.LEFT_MENU]: {
		menuItem: null
	} as IMenuPayload,
	[MENU_KEYS.TOP_MENU]: {
		menuItem: null
	} as IMenuPayload,
	path: {
		project: null,
		folder: null,
		subFolder: null,
		planStateDocument: null,
		selectedTreeNodeKey: null
	} as IPathPayload
}

export default (state = initState, action: IMenuActions) => {
	switch (action.type) {
		case MENU_TYPE.MENU_CHANGE:
			return {
				...state,
				[action.menuKey]: {
					...initState[action.menuKey],
					menuItem: action.payload.menuItem
				}
			}
		case PATH_TYPE.PROJECT_SIDER_CLEAR:
			return {
				...state,
				path: {
					project: null,
					folder: null,
					subFolder: null,
					planStateDocument: null,
					selectedTreeNodeKey: null,
					expandedTreeNodeKeys: null
				}
			}
		case PATH_TYPE.PROJECT_SIDER_CHANGE_TO_PROJECT:
			return {
				...state,
				path: {
					project: action.payload.project,
					folder: null,
					subFolder: null,
					planStateDocument: null,
					selectedTreeNodeKey: `${TREE_NODE_TYPE.PROJECT}_${action.payload.project?.ID}`
				}
			}
		case PATH_TYPE.PROJECT_SIDER_CHANGE_TO_FOLDER:
			return {
				...state,
				path: {
					project: action.payload.project,
					folder: action.payload.folder,
					subFolder: null,
					planStateDocument: null,
					selectedTreeNodeKey: `${TREE_NODE_TYPE.MAIN_FOLDER}_${action.payload.folder?.ID}_${action.payload.project?.ID}`
				}
			}
		case PATH_TYPE.PROJECT_SIDER_CHANGE_TO_SUBFOLDER:
			return {
				...state,
				path: {
					project: action.payload.project,
					folder: action.payload.folder,
					subFolder: action.payload.subFolder,
					planStateDocument: null,
					selectedTreeNodeKey: `${TREE_NODE_TYPE.SUB_FOLDER}_${action.payload.subFolder?.ID}_${action.payload.folder?.ID}_${action.payload.project?.ID}`
				}
			}
		case PATH_TYPE.PROJECT_SIDER_CHANGE_TO_PLAN:
			return {
				...state,
				path: {
					...state.path,
					planStateDocument: action.payload.planStateDocument
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
