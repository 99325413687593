import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { initialize, isPristine, isSubmitting, submit } from 'redux-form'
import { Layout, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'

import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import ProjectForm, { IProjectForm } from './components/ProjectForm'
import { history } from '../../utils/history'
import { CODE_TYPE, FORM, PROJECT_CODE_TYPE } from '../../utils/enums'
import { translateProjectTab } from '../../utils/helpers'
import DetailHeader from '../../layouts/components/DetailHeader'
import { RootState } from '../../reducers'
import { postReq } from '../../utils/requests'
import { setProjectSiderToProject } from '../../reducers/menu/menuActions'
import { getProjectsSider } from '../../reducers/projects/projectsActions'

const { Content } = Layout

const ProjectCreatePage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const pristine = useSelector((state: RootState) => isPristine(FORM.PROJECT_FORM)(state))
	const submitting = useSelector((state: RootState) => isSubmitting(FORM.PROJECT_FORM)(state))

	const [, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam
	})

	const dummyProjectTabsValues = [
		{
			id: 1,
			name: t('loc:Serial Number'),
			type: {
				key: PROJECT_CODE_TYPE.SERIAL_NUMBER,
				value: PROJECT_CODE_TYPE.SERIAL_NUMBER,
				label: translateProjectTab(PROJECT_CODE_TYPE.SERIAL_NUMBER)
			},
			items: []
		},
		{
			id: 2,
			name: 'Index',
			type: {
				key: PROJECT_CODE_TYPE.INDEX,
				value: PROJECT_CODE_TYPE.INDEX,
				label: translateProjectTab(PROJECT_CODE_TYPE.INDEX)
			},
			items: []
		}
	]

	const handleSubmit = async (values: IProjectForm) => {
		try {
			const submitData = {
				name: values.name,
				participants: map(values.participants, (participant) => ({
					id: participant.id,
					role: participant.role
				})),
				projectCodeAttributes:
					values?.codeType === CODE_TYPE.STATES
						? (map(values.tabs, (tab) => ({
								type: tab?.type?.value,
								name: tab.name,
								values: tab.items?.map(({ isNew, ...rest }) => {
									return rest
								})
						  })) as any)
						: [],
				suffixDelimiter: values?.suffixDelimiter,
				prefixDelimiter: values?.prefixDelimiter
			}
			const { data } = await postReq('/api/v1/projects/', undefined, submitData)
			await dispatch(getProjectsSider())
			await dispatch(setProjectSiderToProject(data.project.id, values.name))
			setQuery({
				projectID: data?.project?.id,
				projectName: values?.name,
				folderID: undefined,
				folderName: undefined,
				subFolderID: undefined,
				subFolderName: undefined,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
			history.push(t('paths:index'))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	// after 1st render
	useEffect(() => {
		dispatch(
			initialize(FORM.PROJECT_FORM, {
				tabs: dummyProjectTabsValues,
				isUpdating: false,
				codeType: CODE_TYPE.STATES,
				prefixDelimiter: '...',
				suffixDelimiter: '^'
			})
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Layout className='extd-layout with-footer'>
			<DetailHeader title={t('loc:New project')} />
			<Content>
				<div className='content-body medium'>
					<ProjectForm onSubmit={handleSubmit} />
				</div>
			</Content>
			<div className={'extd-layout-footer'}>
				<div className='content-footer'>
					<Button type={'default'} className={'extd-btn'} onClick={() => history.push(t('paths:index'))}>
						{t('loc:Cancel')}
					</Button>
					<Button type={'primary'} className={'extd-btn'} onClick={() => dispatch(submit(FORM.PROJECT_FORM))} disabled={submitting || pristine} loading={submitting}>
						{t('loc:Create')}
					</Button>
				</div>
			</div>
		</Layout>
	)
}

export default ProjectCreatePage
