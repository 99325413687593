import React, { FC, useState } from 'react'
import qs from 'qs'
import i18next from 'i18next'
import { useDispatch } from 'react-redux'
import PasswordForm, { IPasswordForm } from './components/PasswordForm'
import CompanyForm, { ICompanyForm } from './components/CompanyForm'
import ProfileForm, { IProfileForm } from './components/ProfileForm'
import { NOTIFICATION_TYPE, USER_CREATION_STEP } from '../../utils/enums'
import { postReq } from '../../utils/requests'
import { Paths } from '../../types/api'
import { setAccessToken, setRefreshToken } from '../../utils/auth'
import { history } from '../../utils/history'
import { reloadUserData } from '../../reducers/authUser/authUserActions'

type Props = {}

const UserCreationPage: FC<Props> = () => {
	const [step, setStep] = useState<USER_CREATION_STEP | undefined>()
	const dispatch = useDispatch()

	const handleSubmit = async (values: IPasswordForm & IProfileForm & ICompanyForm) => {
		try {
			const { t: token } = qs.parse(document.location.search, { ignoreQueryPrefix: true })

			const headers = {
				Authorization: `Bearer ${token}`
			}

			const reqBody: Paths.PostApiV1UsersConfirm.RequestBody = {
				password: values.password,
				name: values.name,
				surname: values.surname,
				phone: values.phone,
				company: values.company,
				note: values.note,
				companyRole: values.companyRole
			}

			const { data } = await postReq('/api/v1/users/confirm', undefined, reqBody, { headers }, NOTIFICATION_TYPE.NOTIFICATION, true)

			setAccessToken(data.accessToken)
			setRefreshToken(data.refreshToken)

			dispatch(reloadUserData(data?.user?.id))

			history.push(i18next.t('paths:index'))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
	}

	const renderCreationStep = () => {
		switch (step) {
			case USER_CREATION_STEP.PASSWORD:
				return <PasswordForm changeStep={setStep} progress={0} onSubmit={() => setStep(USER_CREATION_STEP.PROFILE)} />
			case USER_CREATION_STEP.PROFILE:
				return <ProfileForm changeStep={setStep} progress={50} onSubmit={() => setStep(USER_CREATION_STEP.COMPANY)} />
			case USER_CREATION_STEP.COMPANY:
				return <CompanyForm isLastStep progress={100} changeStep={setStep} onSubmit={handleSubmit as any} />
			default:
				return <PasswordForm changeStep={setStep} progress={0} onSubmit={() => setStep(USER_CREATION_STEP.PROFILE)} />
		}
	}

	return renderCreationStep()
}
export default UserCreationPage
