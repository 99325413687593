import React from 'react'
import { Menu } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { APP_LANGUAGE } from '../../utils/enums'
import { RootState } from '../../reducers'
import { putReq } from '../../utils/requests'
import { changeUserLanguage } from '../../reducers/authUser/authUserActions'

const languageOptions = Object.values(APP_LANGUAGE).map((language) => ({ key: language, text: language.toUpperCase() }))

const LanguagePicker = () => {
	const dispatch = useDispatch()
	const authUser = useSelector((state: RootState) => state?.userStore?.authUser)

	const getMenuWidth = () => {
		const width = 50 * languageOptions.length
		return { width: `${width}px` }
	}

	const setLanguage = async (language: any) => {
		try {
			const reqBody = {
				language
			}
			putReq('/api/v1/settings/language', undefined, reqBody)
			const newData = authUser
			if (newData?.originalData?.language) {
				newData.originalData.language = language as any
			}
			dispatch(changeUserLanguage(newData))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	const handleSelectLanguage = async (e: any) => {
		if (authUser?.originalData?.language !== e.key) {
			setLanguage(e.key)
		}
	}

	return (
		<Menu mode={'horizontal'} style={getMenuWidth()} onClick={(e) => handleSelectLanguage(e)} selectedKeys={[authUser?.originalData?.language || APP_LANGUAGE.EN]}>
			{languageOptions.map((option) => (
				<Menu.Item key={option.key}>{option.text}</Menu.Item>
			))}
		</Menu>
	)
}

export default LanguagePicker
