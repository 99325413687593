import React from 'react'
import { Button, Modal } from 'antd'
import { ReactComponent as WarningIcon } from '../assets/icons/icon-notification-warning-red.svg'

type Props = {
	visible: boolean
	description?: string
	text: string
	confirmButtonText: string
	cancelButtonText: string
	confirmButtonClassName?: string
	onCancel: () => void
	onSubmit: () => void
}

const ConfirmModal = ({ text, visible, description, confirmButtonText, cancelButtonText, confirmButtonClassName, onSubmit, onCancel }: Props) => {
	const confirmClassName = confirmButtonClassName || 'extd-btn'

	return (
		<Modal width={600} visible={visible} onCancel={onCancel} footer={null}>
			<div className={'flex flex-col justify-between items-center pt-5 text-center'}>
				<WarningIcon className={'w-16 h-16'} />
				<span className={'heading-2 mt-10'}>{text}</span>
				{description && <span className={'text-m-regular text-zinc-400'}>{description}</span>}
				<div className={'flex mt-10'}>
					<Button type={'default'} className={'extd-btn'} onClick={() => onCancel()}>
						{cancelButtonText}
					</Button>
					<Button type={'primary'} className={`ml-2 ${confirmClassName}`} onClick={() => onSubmit()}>
						{confirmButtonText}
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmModal
