// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '..'
import { ACTIVITIES } from './activitiesTypes'
import { IResetStore } from '../generalTypes'
import { Paths } from '../../types/api'
// eslint-disable-next-line import/no-cycle
import { getReq } from '../../utils/requests'

export interface IActivitiesPayload {
	originalData: Paths.GetApiV1ActivitiesDaily.Responses.$200['projects'] | null
}

interface IGetActivities {
	type: ACTIVITIES
	payload: IActivitiesPayload
}

export type IUsersActions = IResetStore | IGetActivities

export const getActivities =
	(
		projectID?: Paths.GetApiV1ActivitiesDaily.Parameters.ProjectID,
		folderID?: Paths.GetApiV1ActivitiesDaily.Parameters.FolderID,
		date?: Paths.GetApiV1ActivitiesDaily.Parameters.Date
	): ThunkResult<void> =>
	async (dispatch) => {
		let payload = {} as IActivitiesPayload
		dispatch({ type: ACTIVITIES.ACTIVITIES_LOAD_START })

		const params = {
			projectID,
			folderID,
			date
		}

		try {
			const { data } = await getReq('/api/v1/activities/daily', params)

			const fixData: any[] = []

			data?.projects?.forEach((project) => {
				const newProject: any = {
					id: project?.id,
					name: project?.name,
					folders: []
				}
				project?.folders?.forEach((folder) => {
					const newFolder: any = {
						id: folder?.id,
						name: folder?.name,
						activities: [],
						folders: []
					}
					folder?.activities?.forEach((activity) => {
						if (activity?.file?.documentID) {
							const newActivity = activity
							newActivity.id = activity?.file?.documentID
							newFolder.activities.push(newActivity)
							// fixedActivities.push(newActivity)
						}
					})
					folder?.folders?.forEach((subFolder) => {
						const newSubfolder: any = {
							id: subFolder?.id,
							name: subFolder?.name,
							activities: []
						}
						subFolder?.activities?.forEach((activity) => {
							if (activity?.file?.documentID) {
								const newActivity = activity
								newActivity.id = activity?.file?.documentID
								// fixedActivities.push(newActivity)
								newSubfolder.activities.push(newActivity)
							}
						})
						newFolder.folders.push(newSubfolder)
					})
					newProject.folders.push(newFolder)
				})
				fixData.push(newProject)
			})

			data.projects = fixData

			payload = {
				originalData: data.projects
			}

			dispatch({ type: ACTIVITIES.ACTIVITIES_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: ACTIVITIES.ACTIVITIES_LOAD_FAIL })
		}
		return payload
	}
