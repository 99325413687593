import React, { useEffect } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { forEach, map, split } from 'lodash'
import { FieldArray, Field, InjectedFormProps, reduxForm, WrappedFieldArrayProps, formValueSelector, change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isNotNumeric } from 'lodash-checkit'
import { CODE_TYPE, FORM, PROJECT_CODE_TYPE } from '../../../utils/enums'
import { ReactComponent as ProjectIcon } from '../../../assets/icons/icon-project.svg'
import InputField from '../../../atoms/InputField'
import SelectField from '../../../atoms/SelectField'
import { validationRequired, validationString } from '../../../utils/helpers'
import { RootState } from '../../../reducers'
import RadioGroupField from '../../../atoms/RadioGroupField'

const stringMax100 = validationString(100)
const stringMax150 = validationString(150)

export interface IPlanEditForm {
	opened?: {
		planID: number
		projectName: string
		generatedPlanName: string | null
		freeTextPlanName: string
		folderID: number
	} | null
	description: string
	projectCodeAttributes: any
	planAttributes: any
	hasGeneratedName: any
	isCodeSet: boolean
}

const selector = formValueSelector(FORM.PLAN_EDIT_FORM)

type EditPlanFieldsProps = WrappedFieldArrayProps<any> & {
	formName: FORM
}

const EditPlanFields = (param: EditPlanFieldsProps) => {
	const dispatch = useDispatch()
	const newProjectCodeAttributes = param.fields.getAll()

	const opened = useSelector((state: RootState) => selector(state, 'opened'))

	useEffect(() => {
		if (opened?.generatedPlanName) {
			forEach(newProjectCodeAttributes, (attr, index) => {
				if (attr?.type === PROJECT_CODE_TYPE.CONSTANT) {
					dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_CONSTANT`, split(opened?.generatedPlanName, '_')?.at(index) as string))
				}
				if (attr?.type === PROJECT_CODE_TYPE.DROPDOWN) {
					dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_DROPDOWN`, split(opened?.generatedPlanName, '_')?.at(index) as string))
				}
				if (attr?.type === PROJECT_CODE_TYPE.INDEX) {
					dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_INDEX`, split(opened?.generatedPlanName, '_')?.at(index) as string))
				}
				if (attr?.type === PROJECT_CODE_TYPE.INPUT) {
					dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_INPUT`, split(opened?.generatedPlanName, '_')?.at(index) as string))
				}
				if (attr?.type === PROJECT_CODE_TYPE.SERIAL_NUMBER) {
					dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_SERIAL_NUMBER`, split(opened?.generatedPlanName, '_')?.at(index) as string))
				}
				if (attr?.type === PROJECT_CODE_TYPE.STATUS) {
					dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_STATUS`, split(opened?.generatedPlanName, '_')?.at(index) as string))
				}
			})
		} else {
			forEach(newProjectCodeAttributes, (attr) => {
				if (attr?.type === PROJECT_CODE_TYPE.CONSTANT) {
					dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_CONSTANT`, attr?.values?.at(0)?.constant))
				}
				if (attr?.type === PROJECT_CODE_TYPE.SERIAL_NUMBER) {
					dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_SERIAL_NUMBER`, '000'))
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newProjectCodeAttributes])

	const getConstantField = (attr: any) => {
		return (
			<Col span={5} key={`planAttributes.${attr?.id}_CONSTANT`}>
				<Field name={`planAttributes.${attr?.id}_CONSTANT`} component={InputField} label={attr?.name} required disabled />
			</Col>
		)
	}

	const getIndexField = (attr: any) => {
		const onInputChange = (value: any) => {
			if (/^[a-z0-9]+$/i.test(value) || value === '-') {
				dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_INDEX`, value))
			}
		}

		return (
			<Col span={5} key={`planAttributes.${attr?.id}_INDEX`}>
				<Field name={`planAttributes.${attr?.id}_INDEX`} component={InputField} maxLength={1} label={attr?.name} customOnChange={onInputChange} />
			</Col>
		)
	}

	const getSerialNumberField = (attr: any) => {
		const onSerialNumberChange = (value: any) => {
			if (value === null || !isNotNumeric(value)) {
				dispatch(change(FORM.PLAN_EDIT_FORM, `planAttributes.${attr?.id}_SERIAL_NUMBER`, value))
			}
		}

		return (
			<Col span={5} key={`planAttributes.${attr?.id}_SERIAL_NUMBER`}>
				<Field
					name={`planAttributes.${attr?.id}_SERIAL_NUMBER`}
					maxLength={3}
					component={InputField}
					label={attr?.name}
					required
					customOnChange={onSerialNumberChange}
					validate={[validationRequired]}
				/>
			</Col>
		)
	}

	const getDropdownField = (attr: any) => {
		const options = map(attr?.values, (option, i) => ({
			key: i,
			value: option?.option,
			label: option?.option
		}))
		return (
			<Col span={5} key={`planAttributes.${attr?.id}_DROPDOWN`}>
				<Field
					name={`planAttributes.${attr?.id}_DROPDOWN`}
					component={SelectField}
					options={options}
					placeholder={attr?.name}
					labelInValue
					label={attr?.name}
					validate={[validationRequired]}
					required
				/>
			</Col>
		)
	}

	const getInputField = (attr: any) => {
		return (
			<Col span={5} key={`planAttributes.${attr?.id}_INPUT`}>
				<Field
					name={`planAttributes.${attr?.id}_INPUT`}
					component={InputField}
					label={attr?.name}
					required
					validate={[validationRequired, stringMax100]}
					placeholder={attr?.name}
				/>
			</Col>
		)
	}

	const getStatusField = (attr: any) => {
		const options = map(attr?.values, (option, i) => ({
			key: i,
			value: option?.option,
			label: option?.option
		}))
		return (
			<Col span={5} key={`planAttributes.${attr?.id}_STATUS`}>
				<Field
					name={`planAttributes.${attr?.id}_STATUS`}
					component={SelectField}
					options={options}
					placeholder={attr?.name}
					labelInValue
					label={attr?.name}
					validate={[validationRequired]}
					required
				/>
			</Col>
		)
	}

	const renderFields = () => {
		return map(newProjectCodeAttributes, (attr) => {
			if (attr?.type === PROJECT_CODE_TYPE.CONSTANT) {
				return getConstantField(attr)
			}
			if (attr?.type === PROJECT_CODE_TYPE.DROPDOWN) {
				return getDropdownField(attr)
			}
			if (attr?.type === PROJECT_CODE_TYPE.INDEX) {
				return getIndexField(attr)
			}
			if (attr?.type === PROJECT_CODE_TYPE.INPUT) {
				return getInputField(attr)
			}
			if (attr?.type === PROJECT_CODE_TYPE.SERIAL_NUMBER) {
				return getSerialNumberField(attr)
			}
			if (attr?.type === PROJECT_CODE_TYPE.STATUS) {
				return getStatusField(attr)
			}
			return <> </>
		})
	}

	return <Row gutter={[16, 16]}>{renderFields()}</Row>
}

type ComponentProps = {}

type Props = InjectedFormProps<IPlanEditForm, ComponentProps> & ComponentProps

const PlanEditForm = (props: Props) => {
	const { handleSubmit } = props
	const { t } = useTranslation()

	const projectCodeAttributes = useSelector((state: RootState) => selector(state, 'projectCodeAttributes'))
	const type = useSelector((state: RootState) => selector(state, 'hasGeneratedName'))
	const isCodeSet = useSelector((state: RootState) => selector(state, 'isCodeSet'))

	const options = [
		{ label: t('loc:Use code attributes'), value: CODE_TYPE.STATES },
		{ label: t('loc:Use text'), value: CODE_TYPE.FREE_TEXT }
	]

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Row justify={'space-around'}>
				{isCodeSet && (
					<Col className={'mb-4'} span={24}>
						<Input prefix={<ProjectIcon />} className={'extd-input'} readOnly value={map(projectCodeAttributes, (attr) => attr?.name).join('_')} />
					</Col>
				)}
				<Col span={24}>
					<Field component={InputField} name={'description'} label={t('loc:Description')} placeholder={t('loc:Description')} size={'large'} validate={[stringMax150]} />
				</Col>
			</Row>
			{isCodeSet && <Field component={RadioGroupField} options={options} defaultValue={type} required name={'hasGeneratedName'} label={t('loc:Type')} />}
			{type === CODE_TYPE.STATES ? (
				<FieldArray component={EditPlanFields} name={'projectCodeAttributes'} formName={FORM.PLAN_EDIT_FORM} />
			) : (
				<Field
					component={InputField}
					name={'opened.freeTextPlanName'}
					label={t('loc:Plan name')}
					placeholder={t('loc:Plan name')}
					size={'large'}
					required
					validate={[validationRequired]}
				/>
			)}
		</Form>
	)
}

const form = reduxForm<IPlanEditForm, ComponentProps>({
	form: FORM.PLAN_EDIT_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true
})(PlanEditForm)

export default form
