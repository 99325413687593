import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button, Switch, Tag } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { find } from 'lodash'

type Props = {
	visible: boolean
	onCancel: () => void
	onSubmit: (notify: boolean) => void
	fileList: any
}

const UploadModal = ({ visible, onCancel, onSubmit, fileList }: Props) => {
	const { t } = useTranslation()

	const [switchValue, setSwitchValue] = useState(true)

	const renderFileList = () => {
		return (
			<div>
				{fileList?.map((file: any) => {
					if (file?.isDone) {
						return (
							<div className={'flex flex-row justify-between items-center p-2'}>
								<span className={'mr-2'}>{file?.name}</span>
								<Tag color={'#15803D'} icon={<CheckCircleOutlined />}>
									{t('loc:Done')}
								</Tag>
							</div>
						)
					}
					if (file?.status === 'error') {
						return (
							<div className={'flex flex-row justify-between items-center p-2'}>
								<span className={'mr-2'}>{file?.name}</span>
								<Tag color={'#C82929'} icon={<ExclamationCircleOutlined />}>
									{t('loc:Error!')}
								</Tag>
							</div>
						)
					}

					return (
						<div className={'flex flex-row justify-between items-center p-2'}>
							<span className={'mr-2'}>{file?.name}</span>
							<Tag color={'#2F54EB'} icon={<LoadingOutlined />}>
								{t('loc:Uploading {{percent}}', { percent: Math.round(file?.percent) })}
							</Tag>
						</div>
					)
				})}
			</div>
		)
	}

	const hasError = () => {
		if (find(fileList, (file) => file?.status === 'error')) return true
		return false
	}

	const isDone = () => {
		return fileList?.every((file: any) => file?.isDone === true)
	}

	return (
		<Modal width={600} visible={visible} onCancel={onCancel} footer={null}>
			<div className={'flex flex-col justify-between items-center pt-5 text-center'}>
				<span className={'heading-2 mb-10'}>{t('loc:Upload')}</span>
				{renderFileList()}
				{hasError() && <span className={'text-error-medium mt-10'}>{t('loc:Uploading some file failed. Close and try again')}</span>}
				<div className={'flex items-center mt-10'}>
					<span className={'mr-2'}>{t('loc:Notify others?')}</span>
					<Switch className={'mr-2'} checked={switchValue} onChange={(value: boolean) => setSwitchValue(value)} />
					<Button type={'default'} className={'extd-btn mr-2'} onClick={() => onCancel()}>
						{t('loc:Cancel')}
					</Button>
					<Button type={'primary'} className={'extd-btn'} disabled={!isDone()} onClick={() => onSubmit(switchValue)}>
						{t('loc:Upload')}
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default UploadModal
