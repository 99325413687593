import React, { useCallback } from 'react'
import { Form, Switch, SwitchProps } from 'antd'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { WrappedFieldProps } from 'redux-form'

const { Item } = Form

type Props = WrappedFieldProps & SwitchProps & FormItemLabelProps

const SwitchField = (props: Props) => {
	const {
		input,
		disabled,
		label,
		meta: { error, touched },
		size,
		defaultChecked,
		className
	} = props

	const checkedState = input.value === 'true' || input.value === true

	const onChange = useCallback(
		(check: boolean) => {
			input.onChange(check)
		},
		[input]
	)

	return (
		<Item validateStatus={error && touched ? 'error' : undefined} className={'mt-2 mb-2'}>
			<Switch
				onChange={onChange}
				checkedChildren={label}
				unCheckedChildren={label}
				className={className}
				disabled={disabled}
				checked={checkedState}
				size={size || 'default'}
				defaultChecked={defaultChecked}
			/>
		</Item>
	)
}

export default SwitchField
