import React from 'react'
import { render } from 'react-dom'
import { ConfigProvider } from 'antd'
import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

// dayjs
import dayjs from 'dayjs'
import EN from 'antd/es/locale/en_GB'
import 'dayjs/locale/en-gb'

import App from './App'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// eslint-disable-next-line import/no-unresolved
import './styles/main.css'
import './styles/global.sass'

import ErrorBoundary from './components/ErrorBoundary'

dayjs.locale('en-gb')

Sentry.init({
	release: process.env.REACT_APP_VERSION,
	enabled: process.env.NODE_ENV !== 'development',
	// eslint-disable-next-line no-underscore-dangle
	environment: window?._env_?.REACT_APP_SENTRY_ENV ? window._env_.REACT_APP_SENTRY_ENV : process.env.REACT_APP_SENTRY_ENV,
	dsn: 'https://d79ba5c2a2cb474d9e71ea80c3027549@sentry.goodrequest.dev/36',
	integrations: [new TracingIntegrations.BrowserTracing()] as any,
	tracesSampleRate: 0.05
})

const app = (
	<ConfigProvider locale={EN}>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</ConfigProvider>
)
render(app, document.getElementById('root'))
