import { FormErrors } from 'redux-form'
import i18next from 'i18next'
import { find } from 'lodash'
import { validationRequired, validationString } from '../../../utils/helpers'
// eslint-disable-next-line import/no-cycle
import { IProjectForm } from './ProjectForm'
import { CODE_TYPE, PROJECT_CODE_TYPE } from '../../../utils/enums'

/**
 * @param {IProjectForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IProjectForm> = {}

	const nameError = find([validationRequired(values?.name), validationString(100)(values?.name)], (err) => !!err)
	if (nameError) {
		errors.name = nameError
	}

	if (values?.codeType === CODE_TYPE.STATES) {
		let errorIndex: any
		values?.tabs?.forEach((tab: any, tabIndex: any) => {
			if (errorIndex === undefined) {
				if (!tab.type) {
					errorIndex = tabIndex
				} else if (!tab.name) {
					errorIndex = tabIndex
				} else if (tab?.type?.value === PROJECT_CODE_TYPE.CONSTANT && !tab?.items[0]?.constant) {
					errorIndex = tabIndex
				} else if (tab?.type?.value === PROJECT_CODE_TYPE.DROPDOWN) {
					const errorItem = find(tab?.items, (item) => !item.option)
					if (errorItem) {
						errorIndex = tabIndex
					}
				} else if (tab?.type?.value === PROJECT_CODE_TYPE.STATUS) {
					const errorItem = find(tab?.items, (item) => !item.option || !item.priority)
					if (errorItem) {
						errorIndex = tabIndex
					}
				}
			}
		})

		if (errorIndex !== undefined) {
			// eslint-disable-next-line no-underscore-dangle
			errors._error = i18next.t('loc:All values on {{tabID}}. tab are required!', { tabID: errorIndex + 1 })
		}
	}

	if (values?.prefixDelimiter && values?.suffixDelimiter && values.prefixDelimiter === values?.suffixDelimiter) {
		errors.suffixDelimiter = i18next.t('loc:Suffix and Prefix cannot be the same')
		errors.prefixDelimiter = i18next.t('loc:Suffix and Prefix cannot be the same')
	}

	return errors
}
