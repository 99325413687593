import React from 'react'
import { Modal, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isSubmitting, submit } from 'redux-form'
import { FORM } from '../../../../utils/enums'
import { RootState } from '../../../../reducers'
import { Paths } from '../../../../types/api'
import { postReq } from '../../../../utils/requests'
import ChooseFirstIndexForm, { IIndexForm } from './ChooseFirstIndexForm'

const form = FORM.INDEX_FORM

type Props = {
	visible: boolean
	increaseStatus: boolean
	planID?: number
	refetchPlans: (newIndex?: string | number) => void
	onClose: () => void
}

const ChooseFirstIndexModal = ({ visible, onClose, refetchPlans, increaseStatus, planID }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))

	const handleSubmit = async (values: IIndexForm) => {
		if (!planID) {
			refetchPlans(values?.index)
			onClose()
			return
		}

		try {
			if (increaseStatus) {
				const reqBody: Paths.PostApiV1PlansPlanIdDuplicate.RequestBody = {
					type: 'INDEX',
					increaseStatus: true,
					index: values?.index
				}

				await postReq('/api/v1/plans/{planID}/duplicate', { planID: planID || 0 }, reqBody)
			} else {
				const reqBody: Paths.PostApiV1PlansPlanIdDuplicate.RequestBody = {
					type: 'INDEX',
					increaseStatus: false,
					index: values?.index
				}

				await postReq('/api/v1/plans/{planID}/duplicate', { planID: planID || 0 }, reqBody)
			}
			refetchPlans()
			onClose()
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	return (
		<Modal
			title={<Space className={'cursor-default'}>{increaseStatus ? t('loc:Duplicate to next Index and increase Status') : t('loc:Duplicate to next Index')}</Space>}
			okText={t('loc:Save')}
			onOk={() => dispatch(submit(form))}
			okButtonProps={{
				loading: submitting,
				disabled: submitting
			}}
			cancelText={t('loc:Cancel')}
			onCancel={onClose}
			getContainer={() => document.body}
			destroyOnClose
			visible={visible}
		>
			{visible && <ChooseFirstIndexForm onSubmit={handleSubmit} />}
		</Modal>
	)
}

export default ChooseFirstIndexModal
