import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Menu } from 'antd'
import { FolderOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { MENU_KEYS, TOP_MENU_ITEM } from '../../utils/enums'
import { setTopMenu } from '../../reducers/menu/menuActions'
import { RootState } from '../../reducers'

const HeaderMenu = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const topMenuSelected = useSelector((state: RootState) => state?.menuStore?.[MENU_KEYS.TOP_MENU])

	const [query, setQuery] = useQueryParams({
		topMenu: withDefault(StringParam, topMenuSelected?.menuItem)
	})

	const handleSelect = (e: any) => {
		dispatch(setTopMenu(e.key as TOP_MENU_ITEM))
		setQuery({ topMenu: e.key as TOP_MENU_ITEM })
	}

	useEffect(() => {
		if (!topMenuSelected?.menuItem && !query?.topMenu) {
			handleSelect({ key: TOP_MENU_ITEM.FILES })
		} else if (topMenuSelected?.menuItem && !query?.topMenu) {
			setQuery({ topMenu: topMenuSelected?.menuItem })
		} else if (query?.topMenu) {
			dispatch(setTopMenu(query?.topMenu as TOP_MENU_ITEM))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Menu mode={'horizontal'} onClick={(e) => handleSelect(e)} selectedKeys={[topMenuSelected?.menuItem || TOP_MENU_ITEM.FILES]}>
			<Menu.Item key={TOP_MENU_ITEM.FILES} className={'w-48'} icon={<FolderOutlined />}>
				{t('loc:Files')}
			</Menu.Item>
			<Menu.Item key={TOP_MENU_ITEM.ACTIVITIES} className={'w-48'} icon={<UnorderedListOutlined />}>
				{t('loc:Activities')}
			</Menu.Item>
			<Menu.Item key={TOP_MENU_ITEM.TEAMMATES} className={'w-48'} icon={<UserOutlined />}>
				{t('loc:Teammates')}
			</Menu.Item>
		</Menu>
	)
}

export default HeaderMenu
