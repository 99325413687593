import React, { useCallback, useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { TablePaginationConfig, Table, Row, Col, Button, Pagination, Typography, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { change } from 'redux-form'
import { RootState } from '../../../reducers'
import { getPlanStateDocuments } from '../../../reducers/planStateDocuments/planStateDocumentsActions'
import { Paths } from '../../../types/api'
import { ACTION_TYPE, DEFAULT_PAGE_SIZE, FORM, PAGINATION } from '../../../utils/enums'
import { formatDateTime } from '../../../utils/helpers'
import { ReactComponent as ImageIcon } from '../../../assets/icons/icon-document-image.svg'
import { ReactComponent as PDFIcon } from '../../../assets/icons/icon-document-pdf.svg'
import { ReactComponent as OfficeIcon } from '../../../assets/icons/icon-document-excel.svg'
import { ReactComponent as TextIcon } from '../../../assets/icons/icon-document-text.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon-table-delete-small.svg'
import { ReactComponent as DocumentImage } from '../../../assets/images/image-document.svg'
import { deleteReq, putReq } from '../../../utils/requests'
import { history } from '../../../utils/history'
import ConfirmModal from '../../../components/ConfirmModal'
import usePermissionCheck from '../../../utils/permissionCheck'
import usePDFViewer from '../../../utils/usePDFViewer'
import { File, IDownloadItem, setDownloadList } from '../../../reducers/dowload/downloadActions'
import { getSelectedDocuments, addSelectedFiles, deselectFiles } from './components/PlanStateDownloadHandler'
import PlanStateButtonRow from './components/PlanStateButtonRow'
import NoteForm from './components/NoteForm'
import { INoteForm } from '../../../types/interfaces'
import { getAccessToken } from '../../../utils/auth'

export const renderName = (name: string | undefined, mimeType: string | undefined, maxWidth?: number) => {
	if (!name || !mimeType) {
		return <div />
	}

	let icon
	const fileType = mimeType?.substring(mimeType.indexOf('/') + 1)
	const broadType = mimeType?.substring(0, mimeType.indexOf('/'))
	if (broadType === 'image' || broadType === 'video') {
		icon = <ImageIcon className={'mr-2'} style={{ minWidth: '24px' }} />
	} else if (broadType === 'application') {
		switch (fileType) {
			case 'pdf':
				icon = <PDFIcon className={'mr-2'} style={{ minWidth: '24px' }} />
				break
			case 'msword':
			case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
			case 'vnd.oasis.opendocument.presentation':
			case 'vnd.oasis.opendocument.spreadsheet':
			case 'vnd.oasis.opendocument.text':
			case 'vnd.ms-powerpoint':
			case 'vnd.openxmlformats-officedocument.presentationml.presentation':
			case 'vnd.ms-excel':
			case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			case 'vnd.apple.numbers':
			case 'vnd.apple.keynote':
			case 'x-step':
			case 'xml':
				icon = <OfficeIcon className={'mr-2'} style={{ minWidth: '24px' }} />
				break
			default:
				icon = <TextIcon className={'mr-2'} style={{ minWidth: '24px' }} />
		}
	} else {
		icon = <TextIcon className={'mr-2'} style={{ minWidth: '24px' }} />
	}

	return (
		<div className={'flex flex-row items-center'}>
			{icon}
			<Typography.Text className={'text-m-medium'} style={{ maxWidth: `${maxWidth}px` }} ellipsis>
				{name}
			</Typography.Text>
		</div>
	)
}

const DocumentsTable = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const planStateDocuments = useSelector((state: RootState) => state?.planStateDocumentsStore?.planStateDocuments)
	const menu = useSelector((state: RootState) => state?.menuStore.path)
	const permissedActions = usePermissionCheck()
	const PDFViewer = usePDFViewer()

	const [search, setSearch] = useState('')
	const downloadList = useSelector((state: RootState) => state?.downloadStore?.download?.data)

	const [selectedRowKeys, setSelectedRowKeys] = useState(getSelectedDocuments(downloadList, planStateDocuments))

	const [modalData, setModalData] = useState<{ visible: boolean; planStateDocumentID: number }>({ visible: false, planStateDocumentID: 0 })
	const [downloadModalData, setDownloadModalData] = useState<{ visible: boolean; planStateDocumentID: number; fileName: string }>({
		visible: false,
		planStateDocumentID: 0,
		fileName: ''
	})

	useEffect(() => {
		setSelectedRowKeys(getSelectedDocuments(downloadList, planStateDocuments))
		// Toto je super shitty practice, neviem ale ako to fixnut.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadList, planStateDocuments])

	useEffect(() => {
		if (menu?.planStateDocument?.defaultOption?.key) {
			dispatch(getPlanStateDocuments(menu?.planStateDocument?.defaultOption?.key, 1, '', planStateDocuments?.pagination?.limit || DEFAULT_PAGE_SIZE))
			dispatch(change(FORM.SEARCH_FILTER, 'search', null))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu?.planStateDocument?.defaultOption?.key])

	const searchDocuments = (searchTerm: string) => {
		dispatch(getPlanStateDocuments(menu?.planStateDocument?.defaultOption?.key || 0, 1, searchTerm, planStateDocuments?.pagination?.limit || DEFAULT_PAGE_SIZE))
		setSearch(searchTerm)
	}

	const showPDF = (data: any) => {
		PDFViewer.ready().then(() => {
			const url = PDFViewer.composeFileUrl(data?.id)
			PDFViewer.previewFile(url, data?.name, {
				embedMode: 'LIGHT_BOX'
			})
		})
	}

	const downloadFile = (data: any) => {
		setDownloadModalData({ visible: true, planStateDocumentID: data?.id, fileName: data?.name })
	}

	const pagination = {
		current: planStateDocuments?.pagination?.page,
		total: planStateDocuments?.pagination?.totalCount,
		pageSize: planStateDocuments?.pagination?.limit,
		showSizeChanger: true,
		pageSizeOptions: PAGINATION.pageSizeOptions,
		className: 'extd-pagination'
	}

	const columns = [
		{
			key: 'fileName',
			title: t('loc:File name'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			width: '30%',
			render: (data: any) => (
				<div>
					<Tooltip placement={'topLeft'} title={data?.name} overlayStyle={{ maxWidth: '500px' }}>
						<Typography.Text ellipsis className={'text-m-medium'}>
							{renderName(data?.name, data?.mimeType)}
						</Typography.Text>
					</Tooltip>
				</div>
			)
		},
		{
			key: 'size',
			dataIndex: 'size',
			title: t('loc:Size'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			width: 150,
			render: (size: number) => {
				return `${Math.trunc(size / 10000) / 100} ${t('loc:MB')}`
			}
		},
		{
			key: 'format',
			dataIndex: 'name',
			title: t('loc:Format'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			width: 75,
			render: (name: any) => name?.substring(name.lastIndexOf('.') + 1).toUpperCase()
		},
		{
			key: 'creator',
			dataIndex: 'creator',
			title: t('loc:Creator'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (creator: any) => `${creator?.name} ${creator?.surname}`
		},
		{
			key: 'uploadedAt',
			dataIndex: 'createdAt',
			title: t('loc:Uploaded at'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (createdAt: any) => formatDateTime(createdAt)
		},
		{
			key: 'delete',
			width: 50,
			render: (data: any) => (
				<Button
					type={'text'}
					className={'extd-btn w-9 h-9'}
					style={{ padding: '6px' }}
					onClick={(event) => {
						event.stopPropagation()
						setModalData({
							visible: true,
							planStateDocumentID: data?.id
						})
					}}
				>
					<DeleteIcon className={'w-6 h-6'} />
				</Button>
			)
		}
	]

	const handleTableChange = useCallback(
		(paginationConfig: TablePaginationConfig) => {
			dispatch(
				getPlanStateDocuments(
					menu?.planStateDocument?.defaultOption?.key || 0,
					paginationConfig?.current,
					search,
					paginationConfig?.pageSize as Paths.GetApiV1PlanStatesPlanStateIdDocuments.Parameters.Limit
				)
			)
		},
		[dispatch, search, menu?.planStateDocument?.defaultOption?.key]
	)

	const handlePaginationChange = (page: number, pageSize: number) => {
		dispatch(getPlanStateDocuments(menu?.planStateDocument?.defaultOption?.key || 0, page, search, pageSize as Paths.GetApiV1PlanStatesPlanStateIdDocuments.Parameters.Limit))
	}

	const onSelectChange = (newSelectedRowKeys: any) => {
		const deselectedIds = selectedRowKeys?.filter((id: number) => !newSelectedRowKeys?.includes(id))
		const newlySelectedIds = newSelectedRowKeys?.filter((id: number) => !selectedRowKeys?.includes(id))
		let newDownloadList: IDownloadItem[] = downloadList

		if (newlySelectedIds?.length) {
			const selectedFiles = planStateDocuments.documents?.filter((item) => newlySelectedIds?.includes(item.id))

			const files: File[] | undefined = selectedFiles?.map((item) => ({
				ID: item.id,
				name: item.name,
				mimeType: item.mimeType
			}))

			if (files) {
				newDownloadList = addSelectedFiles(newDownloadList, files, menu)
			}
		}
		if (deselectedIds?.length) {
			newDownloadList = deselectFiles(newDownloadList, menu)
		}

		dispatch(setDownloadList(newDownloadList))
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange
	}

	const handleUploadFiles = () => {
		history.push(t('paths:upload-documents'))
	}

	const confirmDelete = async () => {
		try {
			await deleteReq('/api/v1/plan-states/documents/{documentID}', { documentID: modalData.planStateDocumentID })
			dispatch(
				getPlanStateDocuments(
					menu?.planStateDocument?.defaultOption?.key || 0,
					pagination.current,
					search,
					pagination.pageSize as Paths.GetApiV1PlanStatesPlanStateIdDocuments.Parameters.Limit
				)
			)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
		setModalData({ visible: false, planStateDocumentID: 0 })
	}

	const confirmDownload = async () => {
		try {
			const link = document.createElement('a')
			link.href = `/api/v1/documents/${downloadModalData.planStateDocumentID}?t=${getAccessToken()}`
			link.setAttribute('download', downloadModalData.fileName)
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
		setDownloadModalData({ visible: false, planStateDocumentID: 0, fileName: '' })
	}

	const onRow = (record: any) => ({
		onClick: () => {
			if (record?.dataType === 'PDF') {
				showPDF(record)
			} else {
				downloadFile(record)
			}
		}
	})

	const handleNoteSubmit = (values: INoteForm) => {
		try {
			const reqBody = {
				note: values?.note
			}
			putReq('/api/v1/plan-states/{planStateID}/note', { planStateID: menu?.planStateDocument?.defaultOption?.key || 0 }, reqBody)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	const renderEmptyState = (
		<div className={'pb-2 pt-28 flex flex-col items-center'}>
			<DocumentImage />
			<Button
				type={'primary'}
				className={'extd-btn h-10 w-56 mt-4'}
				icon={<UploadOutlined />}
				onClick={handleUploadFiles}
				disabled={!permissedActions?.includes(ACTION_TYPE.UPLOAD)}
			>
				{t('loc:Upload files')}
			</Button>
		</div>
	)

	const planStateDocumentsTable = (
		<Table
			size={'small'}
			className={'extd-table mb-8'}
			rowClassName={'cursor-pointer'}
			columns={columns}
			rowSelection={rowSelection}
			rowKey={'id'}
			onRow={onRow}
			loading={planStateDocuments?.isLoading}
			dataSource={planStateDocuments?.documents || []}
			pagination={false}
			onChange={handleTableChange}
			locale={{
				emptyText: t('loc:No documents found')
			}}
		/>
	)

	return (
		<div>
			<PlanStateButtonRow searchDocuments={searchDocuments} handleUploadFiles={handleUploadFiles} />
			<Row gutter={[16, 16]} className={'mt-4'}>
				<Col span={18}>
					{!planStateDocuments?.isLoading && planStateDocuments?.documents?.length === 0 && !search ? renderEmptyState : planStateDocumentsTable}
					<div className={'extd-layout-footer'}>
						<Pagination
							size={'small'}
							className={'extd-pagination'}
							showSizeChanger={true}
							pageSizeOptions={PAGINATION.pageSizeOptions}
							current={pagination?.current}
							total={pagination?.total}
							showTotal={(total, range) => `${range[0]}-${range[1]} ${t('loc:of')} ${total} ${t('loc:items')}`}
							pageSize={pagination?.pageSize || 0}
							onChange={handlePaginationChange}
						/>
					</div>
				</Col>
				<Col span={6}>
					<Table
						size={'small'}
						className={'extd-table'}
						columns={[
							{
								key: 'note',
								dataIndex: 'note',
								title: t('loc:Notes'),
								sorter: false,
								showSorterTooltip: false,
								ellipsis: true,
								width: 200,
								render: () => <NoteForm onSubmit={handleNoteSubmit} initNote={planStateDocuments?.note} />
							}
						]}
						loading={planStateDocuments?.isLoading}
						dataSource={[{ note: planStateDocuments?.note }]}
						pagination={false}
					/>
				</Col>
			</Row>
			<ConfirmModal
				visible={modalData.visible}
				text={t('loc:Delete file?')}
				confirmButtonText={t('loc:Confirm')}
				cancelButtonText={t('loc:Cancel')}
				confirmButtonClassName={'extd-btn-danger'}
				onCancel={() => setModalData({ visible: false, planStateDocumentID: modalData.planStateDocumentID })}
				onSubmit={confirmDelete}
			/>
			<ConfirmModal
				visible={downloadModalData.visible}
				text={''}
				description={t('loc:No preview available. Do you want to download the file?')}
				confirmButtonText={t('loc:Download')}
				cancelButtonText={t('loc:Cancel')}
				onCancel={() => setDownloadModalData({ visible: false, planStateDocumentID: 0, fileName: '' })}
				onSubmit={confirmDownload}
			/>
		</div>
	)
}

export default DocumentsTable
