import { Form } from 'antd'
import React, { FC } from 'react'
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form'
import { FORM } from '../../../utils/enums'
import MultipleSuggestionPlanStateFieldArray from './MultipleSuggestionPlanStateFieldArray'
import PlanStateFieldArray, { FileFieldValue, PathFieldValue, PlanStateFieldValue } from './PlanStateFieldArray'
import UnnasignedPlanStateFieldArray from './UnnasignedPlanStateFieldArray'
// eslint-disable-next-line import/no-cycle
import validateUploadDocumentsForm from './validateUploadDocumentsForm'

export interface IUploadDocumentsForm {
	planStates: PlanStateFieldValue[]
	unnassignedPlanStates?: {
		file: FileFieldValue
		route: PathFieldValue
	}[]
	multipleSuggestionPlanStates?: {
		file: FileFieldValue
		routes: PathFieldValue[]
		chosenStatus?: number
	}[]
	notify: boolean
}

type ComponentProps = {}

type Props = InjectedFormProps<IUploadDocumentsForm, ComponentProps> & ComponentProps

const UploadDocumentsForm: FC<Props> = (props) => {
	const { handleSubmit } = props

	return (
		<Form onSubmitCapture={handleSubmit} layout={'vertical'}>
			<div className={'content-body medium mt-24 rounded'}>
				<FieldArray component={UnnasignedPlanStateFieldArray} name={'unnassignedPlanStates'} />
				<FieldArray component={MultipleSuggestionPlanStateFieldArray} name={'multipleSuggestionPlanStates'} />
				<FieldArray component={PlanStateFieldArray} name={'planStates'} />
			</div>
		</Form>
	)
}

const form = reduxForm<IUploadDocumentsForm, ComponentProps>({
	form: FORM.UPLOAD_DOCUMENTS,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateUploadDocumentsForm
})(UploadDocumentsForm)

export default form
