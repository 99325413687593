import { FormErrors } from 'redux-form'
import i18next from 'i18next'

// eslint-disable-next-line import/no-cycle
import { find } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { IProfileForm } from './ProfileForm'
// eslint-disable-next-line import/no-cycle
import { IPasswordForm } from './PasswordForm'
// eslint-disable-next-line import/no-cycle
import { ICompanyForm } from './CompanyForm'
import { validationRequired, validationString } from '../../../utils/helpers'

/**
 * @param {ILoginForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IProfileForm & IPasswordForm & ICompanyForm> = {}

	if (!values?.password) {
		errors.password = i18next.t('loc:This field is required')
	} else if (values.password?.length > 255) {
		errors.password = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (!values?.password_repeat) {
		errors.password_repeat = i18next.t('loc:This field is required')
	} else if (values.password_repeat?.length > 255) {
		errors.password_repeat = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.password_repeat && values?.password) {
		if (values?.password_repeat !== values?.password) {
			errors.password = i18next.t('loc:Passwords do not match')
			errors.password_repeat = i18next.t('loc:Passwords do not match')
		}
	}

	const nameError = find([validationRequired(values?.name), validationString(100)(values?.name)], (err) => !!err)
	if (nameError) {
		errors.name = nameError
	}

	const surnameError = find([validationRequired(values?.surname), validationString(100)(values?.surname)], (err) => !!err)
	if (surnameError) {
		errors.surname = surnameError
	}

	return errors
}
