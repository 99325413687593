import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Row, Form, Button, Col } from 'antd'
import { Link } from 'react-router-dom'
import { FORM } from '../../../utils/enums'
import InputField from '../../../atoms/InputField'
import { ReactComponent as ArrowLeft } from '../../../assets/icons/icon-arrow-left.svg'
import { ReactComponent as Planshelf } from '../../../assets/images/image-planshelf.svg'
// eslint-disable-next-line import/no-cycle
import validateForgotPasswordForm from './validateForgotPasswordForm'

export interface IForgotPasswordForm {
	email: string
}

type ComponentProps = {}

type Props = InjectedFormProps<IForgotPasswordForm, ComponentProps> & ComponentProps

const ForgotPasswordForm: FC<Props> = (props) => {
	const { t } = useTranslation()
	const { handleSubmit, submitting } = props

	return (
		<Form layout={'vertical'} className={'mt-20'} onSubmitCapture={handleSubmit}>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col span={14}>
					<Planshelf className={'mb-10'} />
				</Col>
				<Col span={14}>
					<Link to={t('paths:login') as string}>
						<span className={'mr-1'}>
							<ArrowLeft />
						</span>
						<span className={'text-m-regular text-blue-600'}>{t('loc:Back')}</span>
					</Link>
				</Col>
				<Col span={14}>
					<h3 className={'heading-3'}>{t('loc:Forgot password')}</h3>
				</Col>
				<Col span={14}>
					<Field component={InputField} label={t('loc:Email')} placeholder={t('loc:Enter email')} name={'email'} size={'large'} required />
				</Col>
				<Col span={14}>
					<Button type={'primary'} block className={'extd-btn'} size={'large'} htmlType={'submit'} disabled={submitting} loading={submitting}>
						{t('loc:Send me recovery link')}
					</Button>
				</Col>
			</Row>
		</Form>
	)
}
const form = reduxForm<IForgotPasswordForm, ComponentProps>({
	form: FORM.FORGOT_PASSWORD,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForgotPasswordForm
})(ForgotPasswordForm)

export default form
