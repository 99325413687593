/* eslint-disable import/no-cycle */
import { ThunkResult } from '../index'
import { PLANS } from './plansTypes'
import { IResetStore } from '../generalTypes'
import { Paths } from '../../types/api'
import { IResponsePagination } from '../../types/interfaces'
import { getReq } from '../../utils/requests'

export interface IPlansPayload {
	plans?: Paths.GetApiV1Plans.Responses.$200['plans'] | null
	pagination: IResponsePagination | null
	search: string | null
	onlyActual: boolean
	fromDate: string | undefined
	toDate: string | undefined
}

interface IGetPlans {
	type: PLANS
	payload: IPlansPayload
}

export type IPlansActions = IResetStore | IGetPlans

export const getPlans =
	(
		folderID: number,
		page: number | null | undefined,
		search: string | null,
		onlyActual: boolean,
		fromDate: string | undefined,
		toDate: string | undefined,
		limit: Paths.GetApiV1Plans.Parameters.Limit
	): ThunkResult<Promise<IPlansPayload>> =>
	async (dispatch) => {
		dispatch({ type: PLANS.PLANS_LOAD_START })
		let payload = {} as IPlansPayload
		try {
			const { data } = await getReq('/api/v1/plans/', { folderID, limit, page: page || 1, search, onlyActual, fromDate, toDate }, undefined, undefined, undefined, true)

			payload = { fromDate, toDate, onlyActual, search, ...data }
			dispatch({ type: PLANS.PLANS_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: PLANS.PLANS_LOAD_FAIL })
		}
		return payload
	}
