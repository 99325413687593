import React, { useState } from 'react'
import { Modal, Menu, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AlignLeftOutlined, HomeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { ReactComponent as ProfileIllustration } from '../../../../assets/images/image-profile-illustration.svg'
import { ReactComponent as Position } from '../../../../assets/icons/icon-position.svg'
import { ReactComponent as ProjectIcon } from '../../../../assets/icons/icon-project.svg'
import { RootState } from '../../../../reducers'
import usePermissionCheck from '../../../../utils/permissionCheck'
import { ACTION_TYPE } from '../../../../utils/enums'

enum MENU_TABS {
	CONTACT = 'CONTACT',
	PROJECTS = 'PROJECT'
}

type Props = {
	visible: boolean
	onCancel: () => void
	openEdit: () => void
}

const UserDetailModal = ({ visible, onCancel, openEdit }: Props) => {
	const { t } = useTranslation()

	const user = useSelector((state: RootState) => state?.usersStore?.user?.originalData)
	const permissedActions = usePermissionCheck()

	const [selectedKey, setSelectedKey] = useState(MENU_TABS.CONTACT)

	const contacts = (
		<>
			<div className={'flex flex-col justify-start mt-4'} style={{ width: '100%' }}>
				<div className={'flex flex-row items-center'}>
					<MailOutlined className={'mr-2 text-gray-400'} />
					<span className={'text-gray-400'}>{t('loc:Email')}</span>
				</div>
				<span style={{ marginLeft: '22px' }}>{user?.email}</span>
			</div>
			<div className={'flex flex-col mt-4'} style={{ width: '100%' }}>
				<div className={'flex flex-row items-center'}>
					<PhoneOutlined className={'mr-2 text-gray-400'} />
					<span className={'text-gray-400'}>{t('loc:Phone')}</span>
				</div>
				<span style={{ marginLeft: '22px' }}>{user?.phone}</span>
			</div>
			<div className={'flex flex-col mt-4'} style={{ width: '100%' }}>
				<div className={'flex flex-row items-center'}>
					<Position className={'mr-2'} />
					<span className={'text-gray-400'}>{t('loc:Position')}</span>
				</div>
				<span style={{ marginLeft: '22px' }}>{user?.companyRole}</span>
			</div>
			<div className={'flex flex-col mt-4'} style={{ width: '100%' }}>
				<div className={'flex flex-row items-center'}>
					<HomeOutlined className={'mr-2 text-gray-400'} />
					<span className={'text-gray-400'}>{t('loc:Company')}</span>
				</div>
				<span style={{ marginLeft: '22px' }}>{user?.company}</span>
			</div>
			<div className={'flex flex-col mt-4'} style={{ width: '100%' }}>
				<div className={'flex flex-row items-center'}>
					<AlignLeftOutlined className={'mr-2 text-gray-400'} />
					<span className={'text-gray-400'}>{t('loc:Notes')}</span>
				</div>
				<span style={{ marginLeft: '22px' }}>{user?.note}</span>
			</div>
		</>
	)

	const projects = (
		<div className={'flex flex-col items-center mt-4'}>
			{!user?.projects || (user?.projects.length === 0 && <span>{t('loc:User is not included in any projects')}</span>)}
			{user?.projects.map((project) => (
				<div className={'flex flex-row justify-center items-center mt-2'}>
					<ProjectIcon className={'mr-2'} />
					<span>{project.name}</span>
				</div>
			))}
		</div>
	)

	return (
		<Modal
			visible={visible}
			onCancel={() => {
				if (selectedKey !== MENU_TABS.CONTACT) setSelectedKey(MENU_TABS.CONTACT)
				onCancel()
			}}
			footer={null}
		>
			{permissedActions.includes(ACTION_TYPE.TEAM_MATES) && selectedKey === MENU_TABS.CONTACT && (
				<Button type={'text'} className={'text-blue-600 hover:bg-white'} style={{ position: 'absolute', top: '16px', left: '16px' }} onClick={() => openEdit()}>
					<span>{t('loc:Edit')}</span>
				</Button>
			)}
			<div className={'flex flex-col justify-center items-center mt-5 text-center'}>
				<ProfileIllustration />
				<span className={'heading-4 mt-4'}>{`${user?.name} ${user?.surname}`}</span>
				<span className={'text-m-regular mt-2'}>{`${user?.permission}`}</span>
				<Menu mode={'horizontal'} className={'mt-8'} onClick={(e) => setSelectedKey(e.key as MENU_TABS)} selectedKeys={[selectedKey]} style={{ width: '100%' }}>
					<Menu.Item key={MENU_TABS.CONTACT} className={'flex justify-center'} style={{ width: '49%' }}>
						{t('loc:Contact')}
					</Menu.Item>
					<Menu.Item key={MENU_TABS.PROJECTS} className={'flex justify-center'} style={{ width: '49%' }}>
						{t('loc:Projects')}
					</Menu.Item>
				</Menu>
			</div>
			{selectedKey === MENU_TABS.CONTACT ? contacts : projects}
		</Modal>
	)
}

export default UserDetailModal
