import React from 'react'
import { Button, Divider, Row, Col } from 'antd'
import { filter, findIndex, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { arrayPush, change, Field, FieldArray, formValueSelector, WrappedFieldArrayProps } from 'redux-form'

import { FORM, MSG_TYPE, NOTIFICATION_TYPE, PROJECT_CODE_TYPE, TAB_TYPE } from '../../../utils/enums'
import { RootState } from '../../../reducers'
import { translateProjectTab, validationRequired, validationString } from '../../../utils/helpers'
import showNotifications from '../../../utils/tsxHelpers'
import { ILabelInValue, ISelectOptionItem } from '../../../types/interfaces'
import DraggableTabs from '../../../components/DraggableTabs'
import SelectField from '../../../atoms/SelectField'
import InputField from '../../../atoms/InputField'
import { ReactComponent as Delete } from '../../../assets/icons/icon-delete.svg'

// tab arrayfields
import DropdownArrayField from './TabArrayFields/DropdownArrayField'
import ConstantArrayField from './TabArrayFields/ConstantArrayField'
import StatusArrayField from './TabArrayFields/StatusArrayField'

const selector = formValueSelector(FORM.PROJECT_FORM)
const stringMax100 = validationString(100)

interface TabValue {
	id: number | string
	type: ILabelInValue<PROJECT_CODE_TYPE> | null
	/** Rozny format dat pre rozne typy atributov */
	items: {
		option?: string | null
		priority?: number | null
		constant?: string | null
	}[]
	name: string | null
}

type TabsFieldProps = WrappedFieldArrayProps<TabValue> & {
	selectedTabKey: string | number
	setSelectedTabKey: (tabKey: number | string) => void
	typeOptions: ISelectOptionItem[]
}

const TabsField = (param: TabsFieldProps) => {
	const { selectedTabKey, setSelectedTabKey, typeOptions } = param
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const tabsValue = param.fields.getAll()
	const selectedTabIndex = findIndex(tabsValue, (tab) => tab.id === selectedTabKey)
	const isUpdating: boolean = useSelector((state: RootState) => selector(state, 'isUpdating'))
	const isCodeSet = useSelector((state: RootState) => selector(state, 'isCodeSet'))
	let tabContent = null

	const deleteTab = () => {
		const projectType = tabsValue[selectedTabIndex].type?.value as PROJECT_CODE_TYPE
		const projectTypeCount = filter(tabsValue, (tab) => tab.type?.value === projectType)?.length || 0

		if ((projectType === PROJECT_CODE_TYPE.SERIAL_NUMBER || projectType === PROJECT_CODE_TYPE.INDEX) && projectTypeCount === 1) {
			showNotifications(
				[
					{
						type: MSG_TYPE.ERROR,
						message: t('loc:Serial Number and Index has to be in project naming code at least once')
					}
				],
				NOTIFICATION_TYPE.NOTIFICATION
			)
		} else {
			let siblingTabID
			if (selectedTabIndex === 0) {
				siblingTabID = tabsValue[selectedTabIndex + 1].id
			} else {
				siblingTabID = tabsValue[selectedTabIndex - 1].id
			}
			setSelectedTabKey(siblingTabID)
			param?.fields?.remove(selectedTabIndex)
		}
	}

	param?.fields?.forEach((name, index, fields) => {
		const value = fields.get(index)
		const options =
			filter(tabsValue, (tab) => tab.type?.value === PROJECT_CODE_TYPE.STATUS)?.length === 0
				? typeOptions
				: typeOptions.filter((item) => item?.key !== PROJECT_CODE_TYPE.STATUS)
		if (value.id === selectedTabKey) {
			let component: any = null
			let fieldArray
			if (value.type?.key === PROJECT_CODE_TYPE.DROPDOWN) {
				component = DropdownArrayField
			} else if (value.type?.key === PROJECT_CODE_TYPE.CONSTANT) {
				component = ConstantArrayField
			} else if (value.type?.key === PROJECT_CODE_TYPE.STATUS) {
				component = StatusArrayField
			}
			if (value.type?.key === PROJECT_CODE_TYPE.DROPDOWN || value.type?.key === PROJECT_CODE_TYPE.CONSTANT || value.type?.key === PROJECT_CODE_TYPE.STATUS) {
				fieldArray = <FieldArray name={`${name}.items` as any} component={component} />
			}

			const handleSelect = (selectedValue: any) => {
				const projectType = value.type?.value
				const projectTypeCount = filter(tabsValue, (tab) => tab.type?.value === projectType)?.length

				let newTab: any
				let errorIndexAndSerialNumber = false

				if ((projectType === PROJECT_CODE_TYPE.SERIAL_NUMBER || projectType === PROJECT_CODE_TYPE.INDEX) && projectTypeCount === 1) {
					errorIndexAndSerialNumber = true
					newTab = {
						type: value.type,
						name: value.name,
						items: value.items
					}
				} else {
					switch (selectedValue.value) {
						case PROJECT_CODE_TYPE.CONSTANT:
							newTab = {
								type: {
									key: PROJECT_CODE_TYPE.CONSTANT,
									value: PROJECT_CODE_TYPE.CONSTANT,
									label: translateProjectTab(PROJECT_CODE_TYPE.CONSTANT)
								},
								name: translateProjectTab(PROJECT_CODE_TYPE.CONSTANT),
								items: [{ constant: '' }]
							}
							break
						case PROJECT_CODE_TYPE.STATUS:
							newTab = {
								type: {
									key: PROJECT_CODE_TYPE.STATUS,
									value: PROJECT_CODE_TYPE.STATUS,
									label: translateProjectTab(PROJECT_CODE_TYPE.STATUS)
								},
								name: translateProjectTab(PROJECT_CODE_TYPE.STATUS),
								items: [{ option: '', priority: null }]
							}
							break
						case PROJECT_CODE_TYPE.DROPDOWN:
							newTab = {
								type: {
									key: PROJECT_CODE_TYPE.DROPDOWN,
									value: PROJECT_CODE_TYPE.DROPDOWN,
									label: translateProjectTab(PROJECT_CODE_TYPE.DROPDOWN)
								},
								name: translateProjectTab(PROJECT_CODE_TYPE.DROPDOWN),
								items: [{ option: '' }]
							}
							break
						case PROJECT_CODE_TYPE.INDEX:
							newTab = {
								type: {
									key: PROJECT_CODE_TYPE.INDEX,
									value: PROJECT_CODE_TYPE.INDEX,
									label: translateProjectTab(PROJECT_CODE_TYPE.INDEX)
								},
								name: translateProjectTab(PROJECT_CODE_TYPE.INDEX),
								items: []
							}
							break
						case PROJECT_CODE_TYPE.SERIAL_NUMBER:
							newTab = {
								type: {
									key: PROJECT_CODE_TYPE.SERIAL_NUMBER,
									value: PROJECT_CODE_TYPE.SERIAL_NUMBER,
									label: translateProjectTab(PROJECT_CODE_TYPE.SERIAL_NUMBER)
								},
								name: translateProjectTab(PROJECT_CODE_TYPE.SERIAL_NUMBER),
								items: []
							}
							break
						case PROJECT_CODE_TYPE.INPUT:
							newTab = {
								type: {
									key: PROJECT_CODE_TYPE.INPUT,
									value: PROJECT_CODE_TYPE.INPUT,
									label: translateProjectTab(PROJECT_CODE_TYPE.INPUT)
								},
								name: translateProjectTab(PROJECT_CODE_TYPE.INPUT),
								items: []
							}
							break
						default:
							newTab = {}
					}
				}
				const updatedTabs = tabsValue.map((tab, tabIndex) => {
					return tabIndex === index ? { ...newTab, id: value.id } : tab
				})
				dispatch(change(FORM.PROJECT_FORM, 'tabs', updatedTabs))

				if (errorIndexAndSerialNumber) {
					showNotifications(
						[
							{
								type: MSG_TYPE.ERROR,
								message: t('loc:Serial Number and Index has to be in project naming code at least once')
							}
						],
						NOTIFICATION_TYPE.NOTIFICATION
					)
				}
			}

			tabContent = (
				<div className={isUpdating && isCodeSet ? 'w-full bg-zinc-100 pb-4 pointer-events-none' : 'w-full bg-zinc-100 pb-4'} style={{ border: '1px solid transparent' }}>
					<div className={'mx-6 mt-6'}>
						<Row justify={'space-between'}>
							<Col span={18}>
								<Row>
									<Col span={15}>
										<Field
											component={SelectField}
											name={`${name}.type`}
											placeholder={t('loc:Select type')}
											options={options}
											labelInValue
											label={t('loc:Type')}
											required
											validate={[validationRequired]}
											onSelect={handleSelect}
										/>
									</Col>
									<Col span={8} className={'ml-4'}>
										<Field
											component={InputField}
											size={'large'}
											name={`${name}.name`}
											placeholder={t('loc:Name')}
											label={t('loc:Name')}
											required
											validate={[validationRequired, stringMax100]}
										/>
									</Col>
								</Row>
							</Col>
							<Col>
								<Button type={'text'} danger className={'extd-btn'} icon={<Delete />} style={{ marginTop: 26 }} onClick={deleteTab}>
									{t('loc:Delete')}
								</Button>
							</Col>
						</Row>
					</div>
					<Divider className='m-0' />
					{fieldArray}
				</div>
			)
		}
	})
	return tabContent
}

const ProjectTabs = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const selectedTabKey: number | string | undefined = useSelector((state: RootState) => selector(state, 'selectedTabKey'))
	const tabsValue: TabValue[] = useSelector((state: RootState) => selector(state, 'tabs')) || []
	const isUpdating: boolean = useSelector((state: RootState) => selector(state, 'isUpdating'))
	const isCodeSet = useSelector((state: RootState) => selector(state, 'isCodeSet'))
	const selectedTab = selectedTabKey || tabsValue?.[0]?.id

	const tabsList = map(tabsValue, (item) => ({
		...item,
		title: item?.type?.label ?? t('loc:Not selected'),
		name: item?.name
	}))

	const setSelectedTabKey = (key: number | string) => {
		dispatch(change(FORM.PROJECT_FORM, 'selectedTabKey', key))
	}

	const createNewTab = () => {
		const newTab: TabValue = {
			id: `new_${tabsValue.length}`,
			type: null,
			items: [],
			name: null
		}
		dispatch(arrayPush(FORM.PROJECT_FORM, 'tabs', newTab))
		dispatch(change(FORM.PROJECT_FORM, 'selectedTabKey', newTab.id))
	}

	const typeOptions = map(PROJECT_CODE_TYPE, (key) => ({
		key,
		value: key,
		label: translateProjectTab(key)
	}))
		.filter((item) => item?.key !== PROJECT_CODE_TYPE.INDEX)
		.filter((item) => item?.key !== PROJECT_CODE_TYPE.SERIAL_NUMBER)

	return (
		<div>
			<div className='flex flex-row'>
				<DraggableTabs
					selectedKey={selectedTab}
					onClick={setSelectedTabKey}
					setList={(reorderedState) => {
						dispatch(change(FORM.PROJECT_FORM, 'tabs', reorderedState))
					}}
					tabsList={tabsList}
					tabType={TAB_TYPE.PROJECT}
					disabled={isUpdating && isCodeSet}
					addButtonDisabled={tabsValue?.length >= 10 || (isUpdating && isCodeSet)}
					onAddButtonClick={createNewTab}
				/>
			</div>
			<FieldArray
				name='tabs'
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				component={TabsField as any}
				selectedTabKey={selectedTab}
				typeOptions={typeOptions}
				setSelectedTabKey={setSelectedTabKey}
			/>
		</div>
	)
}

export default ProjectTabs
