import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Tag, Upload } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../../../../reducers'
import { getAccessToken } from '../../../../../utils/auth'
import UploadModal from './UploadModal'
import { getReq, patchReq, postReq } from '../../../../../utils/requests'
import { deselectPlanStateFiles, getSelectedPlanStates, isPlanStateSelected, selectPlanStateFiles } from '../PlanDownloadHandler'
import { formatDateTime } from '../../../../../utils/helpers'
import showNotifications from '../../../../../utils/tsxHelpers'
import { ACTION_TYPE, MSG_TYPE, NOTIFICATION_TYPE, TREE_NODE_TYPE } from '../../../../../utils/enums'
import usePermissionCheck from '../../../../../utils/permissionCheck'
import { setDownloadList } from '../../../../../reducers/dowload/downloadActions'

const { Dragger } = Upload

type Props = {
	index: number
	refreshPlans: () => void
	planData: any
	openDocuments: (data: any, index: number) => void
	type: TREE_NODE_TYPE
	folderID: number
	draggingPlanStateID: number | undefined
	setDraggingPlanStateID: (planStateID: number | undefined) => void
}

const DraggerCell = ({ index, refreshPlans, planData, openDocuments, type, folderID, draggingPlanStateID, setDraggingPlanStateID }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [modalVisible, setModalVisible] = useState<boolean>(false)
	const [planStateDestination, setPlanStateDestination] = useState<number | undefined>(undefined)
	const [fileList, setFileList] = useState<any[]>([])

	const downloadList = useSelector((state: RootState) => state?.downloadStore?.download?.data)
	const plans = useSelector((state: RootState) => state?.plansStore?.plans)
	const menu = useSelector((state: RootState) => state?.menuStore)
	const permissedActions = usePermissionCheck()

	useEffect(() => {
		if (fileList.length > 0 && !modalVisible) {
			setModalVisible(true)
		}
	}, [fileList.length, modalVisible])

	const handleDrop = async (targetPlanStateID: number) => {
		if (draggingPlanStateID && targetPlanStateID !== draggingPlanStateID) {
			try {
				const { data } = await getReq('/api/v1/plan-states/{planStateID}/documents', { planStateID: draggingPlanStateID })

				const array: any[] = []
				data?.documents?.forEach((document) => {
					array.push({ id: document.fileID, name: document.name, isDone: true })
				})
				setPlanStateDestination(targetPlanStateID)
				setFileList(array)
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}
		setDraggingPlanStateID(undefined)
	}

	const uploadFiles = async (notify: boolean) => {
		if (planStateDestination) {
			try {
				const reqBody = {
					files: fileList.map((file) => ({
						fileID: file?.id,
						planStateID: planStateDestination
					})),
					notify
				}

				await patchReq('/api/v1/documents/', {}, reqBody)
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}

		setFileList([])
		refreshPlans()
		setModalVisible(false)
	}

	const handleUploadChange = async (info: UploadChangeParam<UploadFile<any>>) => {
		if (info.file.status === 'error') {
			showNotifications(
				[
					{
						type: MSG_TYPE.ERROR,
						message: t('loc:Upload failed, try again.')
					}
				],
				NOTIFICATION_TYPE.NOTIFICATION
			)
			setFileList([...info.fileList.filter((file) => file.uid !== info.file.uid)])
		}
		if (info.file.status === 'done' || info.file.status === 'success') {
			const newFileList = info.fileList.filter((file) => file.uid !== info.file.uid)
			setFileList([...newFileList, { id: info.file?.response?.file?.id, name: info.file?.response?.file?.name, isDone: true }])
		} else {
			setFileList(info.fileList)
		}
	}

	const handleCheckboxClick = async (planState: any, planID: number) => {
		const selectedPlanStates = getSelectedPlanStates(downloadList, plans, type, folderID)
		if (selectedPlanStates?.includes(planState?.id)) {
			dispatch(setDownloadList(deselectPlanStateFiles(downloadList, planState?.id)))
		} else {
			try {
				const reqBody = {
					planIDs: [planID]
				}
				const params = {
					fromDate: plans?.fromDate,
					toDate: plans?.toDate
				}
				const data = await postReq('/api/v1/plans/documents', params, reqBody as any)
				dispatch(setDownloadList(selectPlanStateFiles(downloadList, planState?.id, planID, menu?.path, data?.data)))
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}
	}

	return !permissedActions?.includes(ACTION_TYPE.UPLOAD) ? (
		<Button
			className={`extd-btn hover:bg-white border border-blue-600 ${planData?.planStates?.at(index)?.documentCount === 0 && 'bg-gray-200'}`}
			style={{ width: '100%' }}
			type={'default'}
			onClick={(e) => {
				e.stopPropagation()
				e.preventDefault()
				openDocuments(planData, index)
			}}
		>
			<Tag className={`pl-2 pr-2 ${planData?.planStates?.at(index)?.documentCount === 0 && 'bg-gray-300'}`}>{`${planData?.planStates?.at(index)?.documentCount}`}</Tag>
			<span className={'text-s-regular ml-2 inline'}>{formatDateTime(planData?.planStates?.at(index)?.lastUpdate?.datetime)}</span>
		</Button>
	) : (
		<>
			<div className={'flex flex-row '}>
				<div className={'flex justify-center items-center mr-1'}>
					<Checkbox
						disabled={planData?.planStates?.at(index)?.documentCount === 0}
						onClick={() => handleCheckboxClick(planData?.planStates?.at(index), planData?.id)}
						checked={isPlanStateSelected(downloadList, planData?.planStates?.at(index)?.id)}
					/>
				</div>
				<div
					draggable={planData?.planStates?.at(index)?.documentCount !== 0}
					onDragStart={() => setDraggingPlanStateID(planData?.planStates?.at(index)?.id)}
					onDrop={() => handleDrop(planData?.planStates?.at(index)?.id)}
				>
					<Dragger
						accept={undefined}
						headers={{
							Authorization: `Bearer ${getAccessToken()}`
						}}
						action={`api/v1/documents`}
						maxCount={10}
						multiple
						fileList={fileList}
						showUploadList={false}
						onChange={handleUploadChange}
						beforeUpload={() => setPlanStateDestination(planData?.planStates?.at(index)?.id)}
						className={'flex justify-center items-center h-10 overflow-hidden'}
					>
						<Button
							className={`extd-btn border-0 hover:bg-white ${planData?.planStates?.at(index)?.documentCount === 0 && 'bg-gray-200'}`}
							style={{ width: '100%' }}
							type={'default'}
							onClick={(e) => {
								e.stopPropagation()
								e.preventDefault()
								openDocuments(planData, index)
							}}
						>
							<Tag className={`pl-2 pr-2 ${planData?.planStates?.at(index)?.documentCount === 0 && 'bg-gray-300'}`}>{`${
								planData?.planStates?.at(index)?.documentCount
							}`}</Tag>
							<span className={'text-s-regular ml-2 inline'}>{formatDateTime(planData?.planStates?.at(index)?.lastUpdate?.datetime)}</span>
						</Button>
					</Dragger>
				</div>
			</div>
			{modalVisible && (
				<UploadModal
					onCancel={() => {
						setModalVisible(false)
						setFileList([])
					}}
					onSubmit={(notify: boolean) => {
						uploadFiles(notify)
					}}
					fileList={fileList}
					visible={modalVisible}
				/>
			)}
		</>
	)
}

export default DraggerCell
