import React, { useEffect } from 'react'
import { Form } from 'antd'
import { InjectedFormProps, Field, reduxForm, change } from 'redux-form'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { INoteForm } from '../../../../types/interfaces'
import { FORM } from '../../../../utils/enums'
import TextAreaField from '../../../../atoms/TextAreaField'

type ComponentProps = {
	initNote: string | null
}

type Props = InjectedFormProps<INoteForm, ComponentProps> & ComponentProps

const NoteForm = (props: Props) => {
	const dispatch = useDispatch()

	const { handleSubmit, initNote } = props

	useEffect(() => {
		dispatch(change(FORM.NOTE_FORM, 'note', initNote))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initNote])

	return (
		<Form onSubmitCapture={handleSubmit}>
			<Field component={TextAreaField} name={'note'} maxLength={5000} autoSize={{ minRows: 10, maxRows: 18 }} />
		</Form>
	)
}

const form = reduxForm<INoteForm, ComponentProps>({
	form: FORM.NOTE_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, 1000),
	destroyOnUnmount: true
})(NoteForm)

export default form
