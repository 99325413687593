import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { compose } from 'redux'
import { RouteProps } from 'react-router-dom'
import qs from 'qs'

import NewPasswordForm, { INewPasswordForm } from './components/NewPasswordForm'

import { history } from '../../utils/history'
import { postReq } from '../../utils/requests'
import { NOTIFICATION_TYPE } from '../../utils/enums'

type Props = WithTranslation & RouteProps

class NewPasswordPage extends Component<Props> {
	handleSubmit = async (values: INewPasswordForm) => {
		try {
			const data = {
				password: values.password
			}
			const { t: token } = qs.parse(document.location.search, { ignoreQueryPrefix: true })

			const headers = {
				Authorization: `Bearer ${token}`
			}

			await postReq('/api/v1/authorization/reset-password', undefined, data, { headers }, NOTIFICATION_TYPE.NOTIFICATION, true)

			history.push(this.props.t('paths:login'))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}

	render() {
		return <NewPasswordForm onSubmit={this.handleSubmit} />
	}
}

export default compose(withTranslation())(NewPasswordPage)
