import React from 'react'
import * as Sentry from '@sentry/react'
import { Result, Button } from 'antd'

class ErrorBoundary extends React.Component {
	state = {
		error: null,
		eventId: null as any,
		isOpen: false
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error: any, errorInfo: any) {
		this.setState({ error })
		Sentry.withScope((scope: any) => {
			scope.setExtras(errorInfo)
			const eventId = Sentry.captureException(error)
			this.setState({ eventId })
		})
	}

	render() {
		if (this.state.error) {
			// TODO: texty + preklady
			return (
				<div className={'error-page-wrapper'}>
					<Result
						status='500'
						title='500'
						subTitle='Unexpected error occurred and your request could not be completed. Please contact us about the error.'
						extra={
							<Button onClick={() => this.setState({ isOpen: true })} type='primary'>
								Kontaktovať o chybe
							</Button>
						}
					/>
					{this.state.isOpen &&
						Sentry.showReportDialog({
							eventId: this.state.eventId,
							successMessage: 'Your feedback has been sent. Thank you!',
							title: 'Unexpected error occurred',
							subtitle: 'Please contact us',
							subtitle2: '',
							labelName: 'Name',
							labelComments: 'Error description',
							labelClose: 'Close',
							labelSubmit: 'Send error report',
							lang: 'en'
						})}
				</div>
			)
		}
		return this.props.children
	}
}

export default ErrorBoundary
