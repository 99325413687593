import { IActivitiesPayload } from '../../../reducers/activities/activitiesActions'
import { File, IDownloadItem } from '../../../reducers/dowload/downloadActions'

export const getSelectedActivityFiles = (downloadList: IDownloadItem[], activities: IActivitiesPayload): number[] => {
	const selectedRows: number[] = []

	const activityFiles: File[] = []
	activities?.originalData?.forEach((activityProject) => {
		activityProject?.folders?.forEach((activityFolder) => {
			activityFolder?.activities?.forEach((activity) => {
				activityFiles.push({
					ID: activity?.file?.documentID || 0,
					name: activity?.subject,
					mimeType: activity?.file?.mimeType || ''
				})
			})
			activityFolder?.folders?.forEach((activitySubFolder) => {
				activitySubFolder?.activities?.forEach((activity) => {
					activityFiles.push({
						ID: activity?.file?.documentID || 0,
						name: activity?.subject,
						mimeType: activity?.file?.mimeType || ''
					})
				})
			})
		})
	})

	activityFiles?.forEach((file) => {
		downloadList?.forEach((item) => {
			if (item?.file?.ID === file?.ID) {
				if (item?.file) {
					selectedRows?.push(item.file.ID)
				}
			}
		})
	})

	return selectedRows
}

export const selectActivityFiles = (downloadList: IDownloadItem[], activities: IActivitiesPayload, newlySelectedFilesIDs: number[], recordID: number): IDownloadItem[] => {
	const newDownloadList: IDownloadItem[] = []

	activities?.originalData?.forEach((project) => {
		const foundFolder = project?.folders?.find((folder) => folder?.id === recordID)
		if (foundFolder) {
			const newActivities = foundFolder?.activities?.filter((activity) => newlySelectedFilesIDs?.includes(activity?.id))

			newActivities?.forEach((activity) => {
				const newDownloadItem: IDownloadItem = {
					project: {
						ID: project?.id,
						name: project?.name
					},
					folder: {
						ID: foundFolder?.id,
						name: foundFolder?.name
					},
					plan: {
						ID: activity?.plan?.id,
						name: activity?.plan?.name || ''
					},
					planState: {
						ID: activity?.plan?.planState?.id || 0,
						name: activity?.plan?.planState?.name || ''
					},
					file: {
						ID: activity?.id,
						name: activity?.subject,
						mimeType: activity?.file?.mimeType || ''
					}
				}
				newDownloadList.push(newDownloadItem)
			})
		} else {
			project?.folders?.forEach((folder) => {
				const foundSubfolder = folder?.folders?.find((subFolder) => subFolder?.id === recordID)
				if (foundSubfolder) {
					const newActivities = foundSubfolder?.activities?.filter((activity) => newlySelectedFilesIDs?.includes(activity?.id))

					newActivities?.forEach((activity) => {
						const newDownloadItem: IDownloadItem = {
							project: {
								ID: project?.id,
								name: project?.name
							},
							folder: {
								ID: folder?.id,
								name: folder?.name
							},
							subFolder: {
								ID: foundSubfolder?.id,
								name: foundSubfolder?.name
							},
							plan: {
								ID: activity?.plan?.id,
								name: activity?.plan?.name || ''
							},
							planState: {
								ID: activity?.plan?.planState?.id || 0,
								name: activity?.plan?.planState?.name || ''
							},
							file: {
								ID: activity?.id,
								name: activity?.subject,
								mimeType: activity?.file?.mimeType || ''
							}
						}
						newDownloadList.push(newDownloadItem)
					})
				}
			})
			// TODO ak nenajde ani subfolder pravdepodobne folder/subfolder bol zmazany. notifikacia ze to suckuje
		}
	})

	return [...downloadList, ...newDownloadList]
}

export const deselectActivityFiles = (downloadList: IDownloadItem[], deselectedFilesIDs: number[], recordID: number): IDownloadItem[] => {
	const shouldBeRemoved = (item: IDownloadItem): boolean => {
		if (item?.folder?.ID === recordID && !item?.subFolder?.ID) {
			return !deselectedFilesIDs?.includes(item?.file?.ID || 0)
		}
		if (item?.subFolder?.ID === recordID) {
			return !deselectedFilesIDs?.includes(item?.file?.ID || 0)
		}
		return true
	}

	return downloadList?.filter((item) => shouldBeRemoved(item))
}
