import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { reloadUserData } from '../../../../reducers/authUser/authUserActions'
import { postReq } from '../../../../utils/requests'
import EmailNotificationsForm, { IEmailNotificationsForm } from './EmailNotificationsForm'

const EmailNotifications = () => {
	const dispatch = useDispatch()

	const authUserID = useSelector((state: RootState) => state?.userStore?.authUser.originalData?.id) || 0

	const handleSubmit = async (values: IEmailNotificationsForm) => {
		try {
			const reqBody = {
				days: [values[1] && 1, values[2] && 2, values[3] && 3, values[4] && 4, values[5] && 5, values[6] && 6, values[7] && 7].filter((item) => item !== false) as number[],
				// max 7 dni back, otherwise email is barely readable
				maxDays: 7
			}

			await postReq('/api/v1/settings/notifications', null, reqBody)

			dispatch(reloadUserData(authUserID))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}

	return <EmailNotificationsForm onSubmit={handleSubmit as any} />
}

export default EmailNotifications
