import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { submit, isSubmitting } from 'redux-form'
import { Modal, Space, Typography } from 'antd'
import { ReactComponent as ProjectIcon } from '../../../../assets/icons/icon-project.svg'
import RenameFolderForm, { IRenameFolderForm } from './RenameFolderForm'
import { FORM } from '../../../../utils/enums'
import { patchReq } from '../../../../utils/requests'
import { Paths } from '../../../../types/api'
import { RootState } from '../../../../reducers'

const form = FORM.RENAME_FOLDER_FORM

type Props = {
	visible: boolean
	projectName: string
	folderID: number
	onCancel: () => void
	onSubmit: (newName: string) => void
}

const RenameFolderModal = ({ visible, projectName, folderID, onCancel, onSubmit }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))

	const handleSubmit = async (values: IRenameFolderForm) => {
		try {
			const reqBody: Paths.PatchApiV1FoldersFolderId.RequestBody = {
				name: values.name
			}
			await patchReq('/api/v1/folders/{folderID}', { folderID }, reqBody)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}

		onSubmit(values?.name)
	}

	return (
		<Modal
			title={
				<Space className={'cursor-default'}>
					{t('loc:Rename folder')}
					<div className='flex items-center text-m-medium text-zinc-600'>
						<ProjectIcon />
						<Typography.Text className='ml-1' ellipsis style={{ maxWidth: 300 }}>
							{projectName || '-'}
						</Typography.Text>
					</div>
				</Space>
			}
			onCancel={() => onCancel()}
			onOk={() => dispatch(submit(form))}
			cancelText={t('loc:Cancel')}
			okText={t('loc:Confirm')}
			okButtonProps={{
				loading: submitting,
				disabled: submitting
			}}
			visible={visible}
		>
			<RenameFolderForm onSubmit={handleSubmit} />
		</Modal>
	)
}

export default RenameFolderModal
