import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IFoldersPayload, IFoldersActions } from './foldersActions'
import { RESET_STORE } from '../generalTypes'
import { FOLDERS } from './foldersTypes'

export const initState = {
	folders: {
		folders: null,
		pagination: null,
		search: null,
		isLoading: false,
		isFailure: false
	} as IFoldersPayload & ILoadingAndFailure
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (state = initState, action: IFoldersActions) => {
	switch (action.type) {
		case FOLDERS.FOLDERS_LOAD_START:
			return {
				...state,
				folders: {
					...state.folders,
					isLoading: true
				}
			}
		case FOLDERS.FOLDERS_LOAD_FAIL:
			return {
				...state,
				folders: {
					...initState.folders,
					isFailure: true
				}
			}
		case FOLDERS.FOLDERS_LOAD_DONE:
			return {
				...state,
				folders: {
					...initState.folders,
					folders: action.payload.folders,
					pagination: action.payload.pagination,
					search: action.payload.search
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
