import React from 'react'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Row, Col } from 'antd'
import { WrappedFieldArrayProps, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { validationRequired, validationString } from '../../../../utils/helpers'
import InputField from '../../../../atoms/InputField'

const stringMax100 = validationString(100)
const labelHeight = { marginTop: 26 }
type Props = WrappedFieldArrayProps<any>

const DropdownArrayField = (props: Props) => {
	const { t } = useTranslation()

	const fieldValues = props?.fields.getAll()

	return props?.fields?.map((option, index, fields) => {
		let action
		if (index === 0) {
			action = (
				<Button
					type={'primary'}
					className={'extd-btn pointer-events-auto'}
					style={labelHeight}
					disabled={props?.fields?.length === 20}
					icon={<PlusOutlined />}
					onClick={() => fields.push({ option: null, isNew: true })}
				/>
			)
		} else {
			action = (
				<Button
					type={'default'}
					className={fieldValues?.at(index)?.isNew ? 'extd-btn pointer-events-auto' : 'extd-btn'}
					disabled={!fieldValues?.at(index)?.isNew}
					icon={<CloseOutlined />}
					onClick={() => fields.remove(index)}
				/>
			)
		}

		return (
			<Row key={index} gutter={[16, 16]} className={'mt-6'}>
				<Col span={11} className={fieldValues?.at(index)?.isNew ? 'ml-6 pointer-events-auto' : 'ml-6'}>
					<Field
						component={InputField}
						name={`${option}.option`}
						placeholder={t('loc:Option name')}
						size={'large'}
						label={index === 0 ? t('loc:Option name') : null}
						required
						validate={[validationRequired, stringMax100]}
					/>
				</Col>
				<Col>{action}</Col>
			</Row>
		)
	})
}

export default DropdownArrayField
