/* eslint-disable import/no-cycle */
import { ThunkResult } from '../index'
import { PROJECTS, PROJECTS_SIDER, PROJECT } from './projectsTypes'
import { IResetStore } from '../generalTypes'
import { Paths } from '../../types/api'
import { IResponsePagination } from '../../types/interfaces'
import { getReq } from '../../utils/requests'
import { PAGE_MAX_SIZE } from '../../utils/enums'

export interface IProjectsPayload {
	originalData: Paths.GetApiV1Projects.Responses.$200['projects'] | null
	pagination: IResponsePagination | null
}

export interface IProjectPayload {
	originalData: Paths.GetApiV1ProjectsProjectId.Responses.$200['project'] | null
}

interface IGetProjectsAction {
	type: PROJECTS
	payload: IProjectsPayload
}

interface IGetProjectsSiderAction {
	type: PROJECTS_SIDER
	payload: IProjectsPayload
}

interface IGetProjectAction {
	type: PROJECT
	payload: IProjectPayload
}

export type IProjectsActions = IResetStore | IGetProjectsAction | IGetProjectsSiderAction | IGetProjectAction

export const getProjects =
	(page: number | null | undefined, search: string | null, limit: Paths.GetApiV1Projects.Parameters.Limit): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: PROJECTS.PROJECTS_LOAD_START })
		try {
			const { data } = await getReq('/api/v1/projects/', { limit, page: page || 1, search }, undefined, undefined, undefined, true)
			const payload: IProjectsPayload = {
				originalData: data.projects,
				pagination: data.pagination
			}
			dispatch({ type: PROJECTS.PROJECTS_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: PROJECTS.PROJECTS_LOAD_FAIL })
		}
	}

export const getProjectsSider =
	(search?: string | null): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: PROJECTS_SIDER.PROJECTS_SIDER_LOAD_START })
		try {
			const { data } = await getReq('/api/v1/projects/', { limit: PAGE_MAX_SIZE, page: 1, search })
			const payload: IProjectsPayload = {
				originalData: data.projects,
				pagination: null
			}
			dispatch({ type: PROJECTS_SIDER.PROJECTS_SIDER_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: PROJECTS_SIDER.PROJECTS_SIDER_LOAD_FAIL })
		}
	}

export const getProject =
	(projectID: number): ThunkResult<Promise<IProjectPayload>> =>
	async (dispatch) => {
		let payload = {} as IProjectPayload
		dispatch({ type: PROJECT.PROJECT_LOAD_START })
		try {
			const { data } = await getReq(`/api/v1/projects/{projectID}`, { projectID })

			payload = {
				originalData: data.project
			}

			dispatch({ type: PROJECT.PROJECT_LOAD_DONE, payload })
		} catch (e: any) {
			dispatch({ type: PROJECT.PROJECT_LOAD_FAIL })
		}
		return payload
	}
