/* eslint-disable import/no-cycle */
import { persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storageSession from 'redux-persist/es/storage/session'
import localStorage from 'redux-persist/es/storage'
import { reducer as formReducer } from 'redux-form'
import { ThunkAction } from 'redux-thunk'

import authUserReducer from './authUser/authUserReducer'
import menuReducer from './menu/menuReducer'
import projectsReducer from './projects/projectsReducer'
import usersReduces from './users/usersReducer'
import foldersReducer from './folders/foldersReducer'
import plansReducer from './plans/plansReducer'
import planStateDocumentsReducer from './planStateDocuments/planStateDocumentsReducer'
import activitiesReducer from './activities/activitiesReducer'
import downloadReducer from './dowload/downloadReducer'

export const REDUCER_KEYS = {
	USER: 'user',
	USERS: 'users',
	FORMS: 'form',
	MENU: 'menu',
	PROJECTS: 'projects',
	FOLDERS: 'folders',
	PLANS: 'plans',
	PROJECTSIDER: 'projectsider',
	PLANSTATEDOCUMENTS: 'planstatedocuments',
	ACTIVITIES: 'activities',
	DOWNLOAD: 'download'
}

const rootReducer = combineReducers({
	userStore: persistReducer(
		{
			key: REDUCER_KEYS.USER,
			storage: localStorage
		},
		authUserReducer
	),
	usersStore: persistReducer(
		{
			key: REDUCER_KEYS.USERS,
			storage: storageSession
		},
		usersReduces
	),
	form: persistReducer(
		{
			key: REDUCER_KEYS.FORMS,
			storage: storageSession
		},
		formReducer
	),
	menuStore: persistReducer(
		{
			key: REDUCER_KEYS.MENU,
			storage: storageSession
		},
		menuReducer
	),
	projectsStore: persistReducer(
		{
			key: REDUCER_KEYS.PROJECTS,
			storage: storageSession
		},
		projectsReducer
	),
	foldersStore: persistReducer(
		{
			key: REDUCER_KEYS.FOLDERS,
			storage: storageSession
		},
		foldersReducer
	),
	plansStore: persistReducer(
		{
			key: REDUCER_KEYS.PLANS,
			storage: storageSession
		},
		plansReducer
	),
	planStateDocumentsStore: persistReducer(
		{
			key: REDUCER_KEYS.PLANSTATEDOCUMENTS,
			storage: storageSession
		},
		planStateDocumentsReducer
	),
	activitiesStore: persistReducer(
		{
			key: REDUCER_KEYS.ACTIVITIES,
			storage: storageSession
		},
		activitiesReducer
	),
	downloadStore: persistReducer(
		{
			key: REDUCER_KEYS.DOWNLOAD,
			storage: storageSession
		},
		downloadReducer
	)
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>
