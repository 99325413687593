import { FormErrors } from 'redux-form'
// eslint-disable-next-line import/no-cycle
import { find } from 'lodash'
import i18next from 'i18next'
import { validationRequired, validationString } from '../../../../utils/helpers'
// eslint-disable-next-line import/no-cycle
import { IEditUserForm } from './EditUserForm'

/**
 * @param {IEditUserForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IEditUserForm> = {}

	const nameError = find([validationRequired(values?.name), validationString(100)(values?.name)], (err) => !!err)
	if (nameError) {
		errors.name = nameError
	}

	const surnameError = find([validationRequired(values?.surname), validationString(100)(values?.surname)], (err) => !!err)
	if (surnameError) {
		errors.surname = surnameError
	}

	const permissionError = find([validationRequired(values?.permission)], (err) => !!err)
	if (permissionError) {
		errors.permission = permissionError
	}

	if (values?.phone?.length > 100) {
		errors.phone = i18next.t('loc:Max. number of characters is {{max}}', { max: 100 })
	}

	if (values?.company?.length > 255) {
		errors.company = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.companyRole?.length > 255) {
		errors.companyRole = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	if (values?.note?.length > 255) {
		errors.note = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	return errors
}
