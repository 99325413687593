import React from 'react'
import { Modal, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { change, formValueSelector, isSubmitting, submit } from 'redux-form'

import { map } from 'lodash'
import { ReactComponent as ProjectIcon } from '../../../../assets/icons/icon-project.svg'
import MainFolderCreateForm, { IMainFolderCreateForm } from '../MainFolderCreateForm'

import { RootState } from '../../../../reducers'
import { getProjectsSider } from '../../../../reducers/projects/projectsActions'
import { DEFAULT_PAGE_SIZE, FORM } from '../../../../utils/enums'
import { postReq } from '../../../../utils/requests'
import { getFolders } from '../../../../reducers/folders/foldersActions'

const form = FORM.MAIN_FOLDER_CREATE_FORM
const selector = formValueSelector(form)

const MainFolderCreateModal = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const opened = useSelector((state: RootState) => selector(state, 'opened'))
	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))
	const folders = useSelector((state: RootState) => state?.foldersStore?.folders)

	const handleSubmit = async (values: IMainFolderCreateForm) => {
		try {
			if (values?.opened?.projectID) {
				const reqBody = {
					name: values.name,
					projectID: values?.opened?.projectID,
					states: map(values.tabs, (tab) => ({
						name: tab.name,
						projectUserRole: tab.statusView.key as any
					}))
				}
				await postReq('/api/v1/folders/', undefined, reqBody)
				dispatch(change(form, 'opened', null))
				dispatch(getProjectsSider())
				dispatch(getFolders(values?.opened?.projectID, folders?.pagination?.page || 1, folders?.search, folders?.pagination?.limit || DEFAULT_PAGE_SIZE))
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	return (
		<Modal
			title={
				<Space className={'cursor-default'}>
					{t('loc:Create folder')}
					<div className='flex items-center text-m-medium text-zinc-600'>
						<ProjectIcon />
						<Typography.Text className='ml-1' ellipsis style={{ maxWidth: 300 }}>
							{opened?.projectName || '-'}
						</Typography.Text>
					</div>
				</Space>
			}
			okText={t('loc:Create folder')}
			onOk={() => dispatch(submit(form))}
			okButtonProps={{
				loading: submitting,
				disabled: submitting
			}}
			cancelText={t('loc:Cancel')}
			onCancel={() => dispatch(change(form, 'opened', null))}
			getContainer={() => document.body}
			destroyOnClose
			visible={!!opened}
			className={'modal-w-1100'}
		>
			{!!opened && <MainFolderCreateForm onSubmit={handleSubmit} />}
		</Modal>
	)
}

export default MainFolderCreateModal
