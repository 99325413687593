import React, { PropsWithChildren } from 'react'
import { Col, Layout, Row } from 'antd'
import { useSelector } from 'react-redux'
import ProfileDropdown from './components/ProfileDropdown'
import HeaderMenu from './components/HeaderMenu'
import { RootState } from '../reducers'
import LanguagePicker from './components/LanguagePicker'
import DownloadWindow from './components/DownloadWindow'

const { Header } = Layout

const MainLayout = (props: PropsWithChildren<{}>) => {
	const authUser = useSelector((state: RootState) => state?.userStore?.authUser)

	return (
		<Layout className='extd-layout with-footer' hasSider>
			<Header className='extd-layout-header'>
				<Row>
					{authUser?.companyLogo?.previewUrl ? (
						<Col span={24}>
							<div className={'extd-sider-header flex flex-row '}>
								<img
									src={authUser?.companyLogo?.previewUrl}
									className={'ml-4'}
									style={{ maxHeight: '50px', maxWidth: '50px', objectFit: 'cover' }}
									alt={'companyLogo'}
								/>
								<h4 className={'text-l-bold text-blue-600 ml-4 overflow-hidden text-ellipsis'} style={{ maxWidth: '138px', maxHeight: '50px' }}>
									{authUser.company || ''}
								</h4>
							</div>
						</Col>
					) : (
						<Col span={24}>
							<div className={'extd-sider-header'}>
								<h4 className={'text-l-bold text-blue-600 ml-4 overflow-hidden text-ellipsis'} style={{ maxWidth: '236px', maxHeight: '50px' }}>
									{authUser.company || ''}
								</h4>
							</div>
						</Col>
					)}
				</Row>
				<div className='content-header with-sidebar'>
					<HeaderMenu />
					<div className={'flex flex-row'}>
						<LanguagePicker />
						<ProfileDropdown />
					</div>
				</div>
			</Header>
			<DownloadWindow />
			{props.children}
		</Layout>
	)
}

export default MainLayout
