import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import LoginForm, { ILoginForm } from './components/LoginForm'
import { logInUser } from '../../reducers/authUser/authUserActions'

type Props = {}

const LoginPage: FC<Props> = () => {
	const dispatch = useDispatch()
	const handleSubmit = (values: ILoginForm) => dispatch(logInUser(values))

	return <LoginForm onSubmit={handleSubmit as any} />
}
export default LoginPage
