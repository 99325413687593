import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { reloadUserData } from '../../../../reducers/authUser/authUserActions'
import { patchReq } from '../../../../utils/requests'
import ContactsForm, { IContactsForm } from './ContactsForm'

const Contacts = () => {
	const dispatch = useDispatch()

	const authUserID = useSelector((state: RootState) => state?.userStore?.authUser.originalData?.id) || 0

	const handleSubmit = async (values: IContactsForm) => {
		try {
			const reqBody = {
				name: values?.name,
				surname: values?.surname,
				email: values?.email,
				phone: values?.phone,
				company: values?.company,
				companyRole: values?.companyRole,
				note: values?.note
			}

			await patchReq('/api/v1/settings/contact', undefined, reqBody)

			dispatch(reloadUserData(authUserID))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	return <ContactsForm onSubmit={handleSubmit as any} />
}

export default Contacts
