import { useSelector } from 'react-redux'
import { RootState } from '../reducers'
import { ACTION_TYPE, USER_ROLE } from './enums'

const usePermissionCheck = () => {
	const userPermission = useSelector((state: RootState) => state?.userStore?.authUser?.originalData?.permission)

	const permissedActions: ACTION_TYPE[] = []

	if (userPermission === USER_ROLE.ADMINISTRATOR) {
		const allActions = Object.values(ACTION_TYPE)
		return allActions
	}

	if (userPermission === USER_ROLE.EDITOR) {
		permissedActions.push(ACTION_TYPE.PLAN_CREATE)
		permissedActions.push(ACTION_TYPE.PLAN_DELETE)
		permissedActions.push(ACTION_TYPE.PLAN_DUPLICATE)
		permissedActions.push(ACTION_TYPE.PLAN_EDIT)
		permissedActions.push(ACTION_TYPE.UPLOAD)
	}

	return permissedActions
}
export default usePermissionCheck
