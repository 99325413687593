import React from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'
import { reset } from 'redux-form'
import ArrowLeft from '../../assets/icons/icon-arrow-left-black.svg'

const { Header } = Layout

type Props = {
	title: string
	resetForm?: string
}

const DetailHeader = ({ title, resetForm }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	return (
		<Header className='extd-layout-header'>
			<div className='content-header medium'>
				<Link
					to={t('paths:index') as string}
					onClick={() => {
						if (resetForm) dispatch(reset(resetForm))
					}}
				>
					<span>
						<img className={'pb-1'} src={ArrowLeft} alt={'back'} />
					</span>
					<span className={'heading-4 ml-3'}>{title}</span>
				</Link>
			</div>
		</Header>
	)
}

export default DetailHeader
