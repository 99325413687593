import React, { useState } from 'react'
import { Form, Dropdown, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Field, InjectedFormProps, reduxForm, isPristine, getFormError, submit, reset } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { IDatesFilter } from '../../../types/interfaces'
import { FORM } from '../../../utils/enums'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/icon-calendar-blue.svg'
import DateField from '../../../atoms/DateField'
import { RootState } from '../../../reducers'
import validateDatesFilter from './validateDatesFilter'

type ComponentProps = {
	dateValues: { dateFrom: dayjs.Dayjs | undefined; dateTo: dayjs.Dayjs | undefined }
}

type Props = InjectedFormProps<IDatesFilter, ComponentProps> & ComponentProps
const selectorError = getFormError(FORM.DATES_FILTER)

const DatesFilter = (props: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { dateValues } = props

	const [isDropdownVisible, setIsDropdownVisible] = useState(false)

	const formErrorMessage = useSelector((state: RootState) => selectorError(state))
	const isPristineForm: boolean = useSelector((state: RootState) => isPristine(FORM.DATES_FILTER)(state))

	const getFilterText = () => {
		if (!dateValues?.dateTo && !dateValues?.dateFrom) {
			return t('loc:Filter by date')
		}
		return `${dateValues?.dateFrom ? dateValues.dateFrom : ''} - ${dateValues?.dateTo ? dateValues.dateTo : ''}`
	}

	const handleApply = () => {
		dispatch(submit(FORM.DATES_FILTER))
		setIsDropdownVisible(false)
	}

	const handleClear = async () => {
		await dispatch(reset(FORM.DATES_FILTER))
		dispatch(submit(FORM.DATES_FILTER))
		setIsDropdownVisible(false)
	}

	const filterOverlay = (
		<div className={'bg-white p-4'} style={{ borderRadius: '4px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15' }}>
			<Form layout={'vertical'}>
				<div className={'flex flex-row'}>
					<Field
						component={DateField}
						allowClear={false}
						label={t('loc:From')}
						name={'dateFrom'}
						className={'mr-4 extd-picker-dropdown'}
						size={'large'}
						placeholder={t('loc:Pick date')}
					/>
					<Field
						component={DateField}
						allowClear={false}
						label={t('loc:To')}
						name={'dateTo'}
						className={'mr-4 extd-picker-dropdown'}
						size={'large'}
						placeholder={t('loc:Pick date')}
					/>
				</div>
				{formErrorMessage && (
					<span style={{ top: '-12px', position: 'relative' }} className={'text-s-regular text-error-medium'}>
						{formErrorMessage}
					</span>
				)}
				<div className={'flex flex-row-reverse'}>
					<Button type={'text'} className={'extd-btn'} onClick={handleApply} disabled={isPristineForm || !!formErrorMessage}>
						<span className={'text-blue-600'} style={{ opacity: isPristineForm || !!formErrorMessage ? 0.5 : 1 }}>
							{t('loc:Apply')}
						</span>
					</Button>
					<Button type={'text'} className={'extd-btn'} onClick={handleClear} disabled={isPristineForm}>
						<span className={'text-error-medium'} style={{ opacity: isPristineForm ? 0.5 : 1 }}>
							{t('loc:Clear')}
						</span>
					</Button>
				</div>
			</Form>
		</div>
	)

	return (
		<Dropdown overlay={filterOverlay} visible={isDropdownVisible} onVisibleChange={(e) => setIsDropdownVisible(e)} trigger={['click']}>
			<Button
				type={'default'}
				className={'extd-btn'}
				style={{ border: '1px solid white', width: '225px' }}
				onClick={(e) => {
					e?.preventDefault()
					setIsDropdownVisible(true)
				}}
			>
				<div className={'flex justify-center items-center'}>
					<CalendarIcon className={'mr-2'} />
					<span style={{ marginTop: 1 }}>{getFilterText()}</span>
				</div>
			</Button>
		</Dropdown>
	)
}

const form = reduxForm<IDatesFilter, ComponentProps>({
	form: FORM.DATES_FILTER,
	forceUnregisterOnUnmount: false,
	touchOnChange: true,
	destroyOnUnmount: false,
	validate: validateDatesFilter
})(DatesFilter)

export default form
