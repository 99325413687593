import { Button, Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isSubmitting, submit } from 'redux-form'
import { RootState } from '../../../../reducers'
import { logOutUser } from '../../../../reducers/authUser/authUserActions'
import { FORM } from '../../../../utils/enums'
import { postReq } from '../../../../utils/requests'
import DeleteAccountForm, { IDeleteAccountForm } from './DeleteAccountForm'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/icon-notification-warning-red.svg'

const form = FORM.SETTINGS_DELETE_ACCOUNT_FORM

type Props = {
	visible: boolean
	onClose: () => void
}

const DeleteAccountModal = ({ visible, onClose }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const submitting = useSelector((state: RootState) => isSubmitting(FORM.SETTINGS_DELETE_ACCOUNT_FORM)(state))

	const handleSubmit = async (values: IDeleteAccountForm) => {
		try {
			const data = await postReq('/api/v1/settings/self', null, { password: values.password })

			if (data.status === 200) {
				dispatch(logOutUser(false))
			}
			onClose()
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}

	return (
		<Modal width={600} visible={visible} onCancel={onClose} footer={null}>
			<div className={'flex flex-col justify-between items-center pt-5 text-center'}>
				<WarningIcon className={'w-16 h-16'} />
				<span className={'heading-2 mt-10'}>{t('loc:Delete Account')}</span>
				<span className={'text-m-regular text-zinc-400 mb-6'}>{t('loc:This action cannot be reverted')}</span>
				{visible && <DeleteAccountForm onSubmit={handleSubmit} />}
				<div className={'flex mt-10'}>
					<Button type={'default'} className={'extd-btn'} onClick={() => onClose()}>
						{t('loc:Cancel')}
					</Button>
					<Button type={'primary'} onClick={() => dispatch(submit(form))} loading={submitting} disabled={submitting} className={'ml-2 extd-btn-danger'}>
						{t('loc:Delete')}
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteAccountModal
