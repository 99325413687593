import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IActivitiesPayload, IUsersActions } from './activitiesActions'
import { RESET_STORE } from '../generalTypes'
import { ACTIVITIES } from './activitiesTypes'

export const initState = {
	activities: {
		originalData: null,
		isLoading: false,
		isFailure: false
	} as IActivitiesPayload & ILoadingAndFailure
}

export default (state = initState, action: IUsersActions) => {
	switch (action.type) {
		case ACTIVITIES.ACTIVITIES_LOAD_START:
			return {
				...state,
				activities: {
					...state.activities,
					isLoading: true
				}
			}
		case ACTIVITIES.ACTIVITIES_LOAD_FAIL:
			return {
				...state,
				activities: {
					...initState.activities,
					isFailure: true
				}
			}
		case ACTIVITIES.ACTIVITIES_LOAD_DONE:
			return {
				...state,
				activities: {
					...initState.activities,
					originalData: action.payload.originalData
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
