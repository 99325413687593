import React from 'react'
import { Modal, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isSubmitting, submit } from 'redux-form'

import InviteUserForm, { IInviteUserForm } from './InviteUserForm'

import { RootState } from '../../../../reducers'
import { FORM } from '../../../../utils/enums'
import { postReq } from '../../../../utils/requests'

type Props = {
	visible: boolean
	onCancel: () => void
	onSubmit: () => void
}

const form = FORM.INVITE_USER_FORM

const InviteUserModal = ({ visible, onCancel, onSubmit }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const submitting = useSelector((state: RootState) => isSubmitting(form)(state))

	const handleSubmit = async (values: IInviteUserForm) => {
		try {
			const reqBody = {
				emails: values?.email.replace(/\s/g, '').split(','),
				permission: values.permission
			}

			await postReq('/api/v1/users/', undefined, reqBody)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}

		onSubmit()
	}

	return (
		<Modal
			title={<Space className={'cursor-default'}>{t('loc:Invite')}</Space>}
			okText={t('loc:Invite')}
			onOk={() => dispatch(submit(form))}
			onCancel={() => onCancel()}
			okButtonProps={{
				loading: submitting,
				disabled: submitting
			}}
			cancelText={t('loc:Cancel')}
			visible={visible}
		>
			<InviteUserForm onSubmit={handleSubmit} />
		</Modal>
	)
}

export default InviteUserModal
