import React from 'react'
import { Button, Divider, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/icon-notification-warning-red.svg'
import { IFailedPlans } from '../../../../types/interfaces'

type Props = {
	visible: boolean
	onCancel: () => void
	failedDuplicatedPlans: IFailedPlans | undefined
}

const DuplicateResponseModal = ({ visible, onCancel, failedDuplicatedPlans }: Props) => {
	const { t } = useTranslation()

	return (
		<Modal width={1000} visible={visible} onCancel={onCancel} footer={null}>
			<div className={'flex flex-col justify-center items-center pt-5 text-center'}>
				<WarningIcon className={'w-16 h-16'} />
				<span className={'heading-2'}>{t('loc:Duplicating some plans has failed!')}</span>
				{failedDuplicatedPlans?.alreadyExistingPlans && failedDuplicatedPlans?.alreadyExistingPlans?.length > 0 && (
					<>
						<Divider />
						<span className={'heading-4 mb-1'}>{t('loc:Plan with provided name already exists in folder')}</span>
						{failedDuplicatedPlans?.alreadyExistingPlans?.map((plan) => (
							<span>{plan?.name}</span>
						))}
					</>
				)}
				{failedDuplicatedPlans?.maxIndexPlans && failedDuplicatedPlans?.maxIndexPlans?.length > 0 && (
					<>
						<Divider />
						<span className={'heading-4 mb-1'}>{t('loc:Plan already has max index')}</span>
						{failedDuplicatedPlans?.maxIndexPlans?.map((plan) => (
							<span>{plan?.name}</span>
						))}
					</>
				)}
				{failedDuplicatedPlans?.maxStatusPlans && failedDuplicatedPlans?.maxStatusPlans?.length > 0 && (
					<>
						<Divider />
						<span className={'heading-4 mb-1'}>{t('loc:Plan already has max status')}</span>
						{failedDuplicatedPlans?.maxStatusPlans?.map((plan) => (
							<span>{plan?.name}</span>
						))}
					</>
				)}
				{failedDuplicatedPlans?.noStatusPlans && failedDuplicatedPlans?.noStatusPlans?.length > 0 && (
					<>
						<Divider />
						<span className={'heading-4 mb-1'}>{t('loc:Plan has no status')}</span>
						{failedDuplicatedPlans?.noStatusPlans?.map((plan) => (
							<span>{plan?.name}</span>
						))}
					</>
				)}
				{failedDuplicatedPlans?.plansWithoutGeneratedName && failedDuplicatedPlans?.plansWithoutGeneratedName?.length > 0 && (
					<>
						<Divider />
						<span className={'heading-4 mb-1'}>{t('loc:Plan has no code attributes')}</span>
						{failedDuplicatedPlans?.plansWithoutGeneratedName?.map((plan) => (
							<span>{plan?.name}</span>
						))}
					</>
				)}
				<Button type={'primary'} className={'extd-btn mt-2'} onClick={() => onCancel()}>
					{t('loc:Close')}
				</Button>
			</div>
		</Modal>
	)
}

export default DuplicateResponseModal
