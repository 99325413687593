import dayjs, { Dayjs } from 'dayjs'
import { chain, floor, get, isFinite, isNumber, isString, replace, round } from 'lodash'
import i18next from 'i18next'
import slugify from 'slugify'
import { Dispatch } from 'redux'
import { change, initialize } from 'redux-form'
import { ACTIVITY, CODE_TYPE, FORM, MSG_TYPE, PROJECT_CODE_TYPE, PROJECT_USER_ROLE, USER_ROLE } from './enums'

// eslint-disable-next-line import/prefer-default-export
export const formFieldID = (form?: FORM | string, name?: string) => {
	let id
	if (form && name) {
		// NOTE: element can't be queried if id contains dots
		const fieldSelector = chain(name)
			.filter((char) => char !== ']')
			.map((char) => (char === '[' || char === '.' ? '-' : char))
			.value()
			.join('')
		id = `${form}-${fieldSelector}`
	}
	return id
}

export const translateMessageType = (msgType: MSG_TYPE) => {
	switch (msgType) {
		case MSG_TYPE.ERROR:
			return i18next.t('loc:Error')
		case MSG_TYPE.WARNING:
			return i18next.t('loc:Warning')
		case MSG_TYPE.SUCCESS:
			return i18next.t('loc:Success')
		case MSG_TYPE.INFO:
			return i18next.t('loc:Info')
		default:
			return ''
	}
}

export const translateProjectTab = (type: PROJECT_CODE_TYPE) => {
	let title = '-'
	switch (type) {
		case PROJECT_CODE_TYPE.SERIAL_NUMBER:
			title = i18next.t('loc:Serial')
			break
		case PROJECT_CODE_TYPE.INDEX:
			title = i18next.t('loc:Index')
			break
		case PROJECT_CODE_TYPE.DROPDOWN:
			title = i18next.t('loc:Dropdown')
			break
		case PROJECT_CODE_TYPE.INPUT:
			title = i18next.t('loc:Input')
			break
		case PROJECT_CODE_TYPE.CONSTANT:
			title = i18next.t('loc:Constant')
			break
		case PROJECT_CODE_TYPE.STATUS:
			title = i18next.t('loc:Status')
			break
		default:
			break
	}
	return title
}

export const translateProjectUserRole = (type: PROJECT_USER_ROLE) => {
	let title = '-'
	switch (type) {
		case PROJECT_USER_ROLE.EXTERNAL:
			title = i18next.t('loc:External')
			break
		case PROJECT_USER_ROLE.INTERNAL:
			title = i18next.t('loc:Internal')
			break
		default:
			break
	}
	return title
}

export const translateUserRole = (type: USER_ROLE) => {
	let role = '-'
	switch (type) {
		case USER_ROLE.ADMINISTRATOR:
			role = i18next.t('loc:Admin')
			break
		case USER_ROLE.EDITOR:
			role = i18next.t('loc:Editor')
			break
		case USER_ROLE.GUEST:
			role = i18next.t('loc:Guest')
			break
		default:
			break
	}
	return role
}

export const translateActivitiesEvent = (activity: ACTIVITY) => {
	let event = '-'
	switch (activity) {
		case ACTIVITY.CREATE:
			event = i18next.t('loc:Created')
			break
		case ACTIVITY.DELETE:
			event = i18next.t('loc:Deleted')
			break
		case ACTIVITY.NOTE_UPDATE:
			event = i18next.t('loc:Note was updated')
			break
		case ACTIVITY.UPLOAD:
			event = i18next.t('loc:Uploaded')
			break
		case ACTIVITY.UPDATE:
			event = i18next.t('loc:Updated')
			break
		case ACTIVITY.ARCHIVE:
			event = i18next.t('loc:Archived')
			break
		default:
			break
	}
	return event
}

export const formatDate = (date?: Date | string | number | Dayjs) => {
	if (!date) {
		return '-'
	}

	if (!dayjs(date, undefined, true).isValid()) {
		return '-'
	}

	return dayjs(date).format('DD.MM.YYYY')
}

export const formatDateTime = (date?: Date | number | string | Dayjs) => {
	if (!date) {
		return '-'
	}

	if (!dayjs(date, undefined, true).isValid()) {
		return '-'
	}

	return dayjs(date).format('DD.MM.YYYY HH:mm')
}

export const formatTime = (date?: Date | number | string | Dayjs) => {
	if (!date) {
		return '-'
	}

	if (!dayjs(date, undefined, true).isValid()) {
		return '-'
	}

	return dayjs(date).format('HH:mm')
}

export const hasUpperCaseLetter = (password: string): boolean => {
	if (password?.length > 0 && /(?=.*[A-Z])/.test(password)) {
		return true
	}

	return false
}

export const hasLowerCaseLetter = (password: string): boolean => {
	if (/(?=.*[a-z])/.test(password)) {
		return true
	}

	return false
}

export const hasNumber = (password: string): boolean => {
	if (/(?=.*\d)/.test(password)) {
		return true
	}

	return false
}

export const isStrongEnough = (password: string): boolean => {
	if (hasLowerCaseLetter(password) && hasUpperCaseLetter(password) && hasNumber(password) && password?.length >= 8) {
		return true
	}

	return false
}

export const createSlug = (value: string, separator = '-', lower = true) => {
	if (value) {
		return slugify(value, {
			replacement: separator,
			lower
		})
	}
	return ''
}

export const fromStringToFloat = (string: string | number | null | undefined): number | null => {
	let result
	if (string && isString(string)) {
		result = parseFloat(replace(string, ',', '.').replace(' ', ''))
	} else if (string) {
		result = Number(string)
	} else {
		result = null
	}

	return result
}

/**
 * Returns null - e.g. input was cleared
 *
 * Returns NaN - e.g. input value is "asdf"
 */
export const transformNumberFieldValue = (rawValue: number | string | undefined | null, min?: number, max?: number, precision?: number, notNullValue?: boolean) => {
	let result = null
	const value = typeof rawValue === 'string' ? fromStringToFloat(rawValue) : rawValue
	if (!value && notNullValue) {
		result = min
	}
	if (isNumber(value) && isFinite(value)) {
		if (isNumber(min) && value < min) {
			result = min
		} else if (isNumber(max) && value > max) {
			result = max
		} else if (isNumber(min) && isNumber(max) && value >= min && value <= max) {
			result = value
		}
	} else if (Number.isNaN(value)) {
		result = NaN
	}

	if (isFinite(result) && isNumber(precision)) {
		result = round(result as number, precision)
	}

	return result
}

export const removeDiacritics = (str: string): string => {
	return str.normalize('NFD').replace(/\p{Diacritic}/gu, '')
}

export const validationRequired = (value: string) => !value && i18next.t('loc:This field is required')
export const validationString = (maxLength: number) => (value: string) =>
	get(value, 'length') > maxLength && i18next.t('loc:Max. number of characters is {{max}}', { max: maxLength })
export const validationNumberMin = (min: number) => (value: any) => isFinite(value) && value < min && i18next.t('loc:Enter at least {{min}}', { min })
export const validationNumberMax = (max: number) => (value: any) => isFinite(value) && value > max && i18next.t('loc:Enter at most {{max}}', { max: floor(max, 2) })

export const isValidCommaSeparatedEmailList = (emails: string) => {
	if (!emails) {
		return false
	}
	const splitedEmails = emails?.split(',')
	const isValid = splitedEmails.every((item) => {
		if (
			/^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(item.trim())
		) {
			return true
		}
		return false
	})
	return isValid
}

export const validationCommaSeparatedEmailList = (value: string) => !isValidCommaSeparatedEmailList(value) && i18next.t('loc:Wrong format')

export const validateEmail = (value: string) =>
	value && !/^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)
		? i18next.t('loc:Invalid e-mail address')
		: undefined

export const handleCreateSubFolder = (projectID: number | undefined, folderID: number, projectName: string | undefined, dispatch: Dispatch<any>) => {
	dispatch(
		change(FORM.SUB_FOLDER_CREATE_FORM, 'opened', {
			projectID,
			mainFolderID: folderID,
			projectName: projectName || '-'
		})
	)
}

export const handleCreatePlan = (folderID: number, projectCodeAttributes: any, projectName: string | undefined, dispatch: Dispatch<any>) => {
	if (projectCodeAttributes?.length !== 0) {
		dispatch(
			initialize(FORM.PLAN_CREATE_FORM, {
				projectCodeAttributes,
				type: CODE_TYPE.STATES,
				isCodeSet: true
			})
		)
	} else {
		dispatch(
			initialize(FORM.PLAN_CREATE_FORM, {
				projectCodeAttributes: [],
				type: CODE_TYPE.FREE_TEXT,
				isCodeSet: false
			})
		)
	}
	dispatch(
		change(FORM.PLAN_CREATE_FORM, 'opened', {
			folderID,
			projectName: projectName || '-'
		})
	)
}
