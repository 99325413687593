import React from 'react'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Row, Col } from 'antd'
import { WrappedFieldArrayProps, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { validationRequired, validationString, validationNumberMin, validationNumberMax } from '../../../../utils/helpers'

import InputField from '../../../../atoms/InputField'
import InputNumberField from '../../../../atoms/InputNumberField'

const stringMax100 = validationString(100)
const numberMin1 = validationNumberMin(1)
const numberMax10 = validationNumberMax(10)
const labelHeight = { marginTop: 26 }
type Props = WrappedFieldArrayProps<any>

const StatusArrayField = (props: Props) => {
	const { t } = useTranslation()

	return props?.fields?.map((option, index, fields) => {
		let action
		if (index === 0) {
			action = (
				<Button
					type={'primary'}
					className={'extd-btn'}
					style={labelHeight}
					icon={<PlusOutlined />}
					disabled={props?.fields?.length === 10}
					onClick={() =>
						fields.push({
							option: null,
							priority: null
						})
					}
				/>
			)
		} else {
			action = <Button type={'default'} className={'extd-btn'} icon={<CloseOutlined />} onClick={() => fields.remove(index)} />
		}

		return (
			<Row key={index} gutter={[16, 16]} className={'mt-6'}>
				<Col span={11} className={'ml-6'}>
					<Field
						component={InputField}
						name={`${option}.option`}
						placeholder={t('loc:Option name')}
						size={'large'}
						label={index === 0 ? t('loc:Option name') : null}
						required
						validate={[validationRequired, stringMax100]}
					/>
				</Col>
				<Col span={6}>
					<Field
						component={InputNumberField}
						name={`${option}.priority`}
						placeholder={t('loc:Priority')}
						size={'large'}
						label={index === 0 ? t('loc:Priority') : null}
						required
						validate={[validationRequired, numberMax10, numberMin1]}
					/>
				</Col>
				<Col>{action}</Col>
			</Row>
		)
	})
}

export default StatusArrayField
