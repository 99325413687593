import React from 'react'
import { useTranslation } from 'react-i18next'
import { find, indexOf } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button } from 'antd'
import { arrayRemove, formValueSelector } from 'redux-form'

import { translateProjectUserRole } from '../../../utils/helpers'
import { FORM, PROJECT_USER_ROLE } from '../../../utils/enums'
import { RootState } from '../../../reducers'
import { ReactComponent as Delete } from '../../../assets/icons/icon-delete.svg'

const ProjectParticipants = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const selector = formValueSelector(FORM.PROJECT_FORM)
	const users = useSelector((state: RootState) => selector(state, 'participants'))

	const handleDelete = (userID: number) => {
		const elem = find(users, (user) => user?.id === userID)
		const index = indexOf(users, elem)
		dispatch(arrayRemove(FORM.PROJECT_FORM, 'participants', index))
	}

	const columns = [
		{
			key: 'name',
			title: t('loc:Name'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (data: any) => `${data?.name} ${data?.surname}`
		},
		{
			key: 'email',
			dataIndex: 'email',
			title: t('loc:Email'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true
		},
		{
			// Je to status view, role je tu to aby data boli syncnute z BE nazvami.
			key: 'role',
			dataIndex: 'role',
			title: t('loc:Status view'),
			width: 100,
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (role: PROJECT_USER_ROLE) => translateProjectUserRole(role)
		},
		{
			key: 'action',
			width: 50,
			render: (data: any) => <Button type={'link'} onClick={() => handleDelete(data?.id)} icon={<Delete />} />
		}
	]

	return <Table size={'small'} className={'extd-table'} rowKey={'id'} dataSource={users} columns={columns} pagination={false} />
}

export default ProjectParticipants
