import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IPlansPayload, IPlansActions } from './plansActions'
import { RESET_STORE } from '../generalTypes'
import { PLANS } from './plansTypes'

export const initState = {
	plans: {
		plans: null,
		pagination: null,
		search: null,
		onlyActual: false,
		fromDate: undefined,
		toDate: undefined,
		isLoading: false,
		isFailure: false
	} as IPlansPayload & ILoadingAndFailure
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (state = initState, action: IPlansActions) => {
	switch (action.type) {
		case PLANS.PLANS_LOAD_START:
			return {
				...state,
				plans: {
					...state.plans,
					isLoading: true
				}
			}
		case PLANS.PLANS_LOAD_FAIL:
			return {
				...state,
				plans: {
					...initState.plans,
					isFailure: true
				}
			}
		case PLANS.PLANS_LOAD_DONE:
			return {
				...state,
				plans: {
					...initState.plans,
					plans: action.payload.plans,
					pagination: action.payload.pagination,
					search: action.payload.search,
					onlyActual: action.payload.onlyActual,
					fromDate: action.payload.fromDate,
					toDate: action.payload.toDate
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
