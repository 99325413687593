import React from 'react'
/* eslint-disable import/no-cycle */
import { forEach, isEmpty, lowerCase } from 'lodash'
import { notification } from 'antd'

import { IErrorMessage } from '../types/interfaces'
import { NOTIFICATION_TYPE } from './enums'
import { translateMessageType } from './helpers'
import { ReactComponent as Success } from '../assets/icons/icon-notification-done.svg'
import { ReactComponent as Warning } from '../assets/icons/icon-notification-warning.svg'
import { ReactComponent as Info } from '../assets/icons/icon-notification-info.svg'
import { ReactComponent as Error } from '../assets/icons/icon-notification-error.svg'

// NOTE: helpere ktore potrebuju pracovat s JSX.Element cize potrebuju aby bol importnuty react a mali priponu jsx/tsx lebo TS nedovoli aby React component bol js/ts neda sa to ignorovat
// Tento wrapper bude len exportovat funkcie ktore pracuju s nejkaym JSX.Elemetnom (eg: <NotifCloseIcon />)

const showNotifications = (messages: IErrorMessage[], typeNotification: NOTIFICATION_TYPE) => {
	if (!isEmpty(messages) && typeNotification === NOTIFICATION_TYPE.NOTIFICATION) {
		forEach(messages, (message) => {
			let notif
			let icon
			let color
			switch (lowerCase(message.type)) {
				case 'info':
					notif = notification.info
					icon = <Info />
					color = 'text-blue-600'
					break
				case 'warning':
					notif = notification.warning
					icon = <Warning />
					color = 'text-warning-medium'
					break
				case 'success':
					notif = notification.success
					icon = <Success />
					color = 'text-success-medium'
					break
				case 'error':
					notif = notification.error
					icon = <Error />
					color = 'text-error-medium'
					break
				default:
					notif = notification.info
					icon = <Info />
					color = 'text-blue-600'
					break
			}
			notif({
				icon,
				message: <span className={color}>{translateMessageType(message.type)}</span>,
				description: message.message
			})
		})
	}
}

export default showNotifications
