import React from 'react'
import { useSelector } from 'react-redux'
import { FieldArray, getFormError, InjectedFormProps, reduxForm } from 'redux-form'
import { Form, Row, Col } from 'antd'
import { RootState } from '../../../../reducers'
import { ILabelInValue } from '../../../../types/interfaces'
import { FORM } from '../../../../utils/enums'
import FolderTabs from '../../components/FolderTabs'

// eslint-disable-next-line import/no-cycle
import validate from './validateEditMainFolderStatusesForm'

type Tab = {
	id: number
	name: string
	statusView: ILabelInValue
	existingID: number | undefined
}

export interface IEditMainFolderStatusesForm {
	folderID: number
	tabs: Tab[]
}

type ComponentProps = {}

type Props = InjectedFormProps<IEditMainFolderStatusesForm, ComponentProps> & ComponentProps
const selectorError = getFormError(FORM.MAIN_FOLDER_EDIT_STATUSES_FORM)

const EditMainFolderStatusesForm = (props: Props) => {
	const { handleSubmit } = props

	const formErrorMessage = useSelector((state: RootState) => selectorError(state))

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Row>
				<Col span={24}>
					<div className={'h-7 flex items-center mt-0 text-error-medium cursor-default'}>{formErrorMessage}</div>
					<FieldArray component={FolderTabs} name={'tabs'} formName={FORM.MAIN_FOLDER_EDIT_STATUSES_FORM} />
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IEditMainFolderStatusesForm, ComponentProps>({
	form: FORM.MAIN_FOLDER_EDIT_STATUSES_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(EditMainFolderStatusesForm)

export default form
