/* eslint-disable import/no-cycle */
import { MENU_TYPE, PATH_TYPE } from './menuTypes'
import { LEFT_MENU_ITEM, MENU_KEYS, TOP_MENU_ITEM } from '../../utils/enums'
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'

interface IMenu {
	type: MENU_TYPE
	menuKey: MENU_KEYS
	payload: IMenuPayload
}

interface IPath {
	type: PATH_TYPE
	payload: IPathPayload
}

export interface IMenuPayload {
	menuItem: TOP_MENU_ITEM | LEFT_MENU_ITEM | null
}

export interface IPathPayload {
	project: {
		ID: number
		name: string
	} | null
	folder: {
		ID: number
		name: string
	} | null
	subFolder: {
		ID: number
		name: string
	} | null
	planStateDocument: {
		defaultOption: {
			key: number
			label: string
		}
		planStateOptions: {
			key: number
			label: string
		}[]
		planName: string
		planID: number
	} | null
	selectedTreeNodeKey: string | null
}

export type IMenuActions = IResetStore | IMenu | IPath

export const setTopMenu =
	(menuItem: TOP_MENU_ITEM): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: MENU_TYPE.MENU_CHANGE, menuKey: MENU_KEYS.TOP_MENU, payload: { menuItem } })
	}

export const setLeftMenu =
	(menuItem: LEFT_MENU_ITEM): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: MENU_TYPE.MENU_CHANGE, menuKey: MENU_KEYS.LEFT_MENU, payload: { menuItem } })
	}

export const clearProjectSider = (): ThunkResult<void> => async (dispatch) => {
	dispatch({ type: PATH_TYPE.PROJECT_SIDER_CLEAR })
}

export const setProjectSiderToProject =
	(projectID: number, projectName: string): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: PATH_TYPE.PROJECT_SIDER_CHANGE_TO_PROJECT, payload: { project: { ID: projectID, name: projectName } } })
	}

export const setProjectSiderToFolder =
	(projectID: number, projectName: string, folderID: number, folderName: string): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({
			type: PATH_TYPE.PROJECT_SIDER_CHANGE_TO_FOLDER,
			payload: { project: { ID: projectID, name: projectName }, folder: { ID: folderID, name: folderName } }
		})
	}

export const setProjectSiderToSubFolder =
	(projectID: number, projectName: string, folderID: number, folderName: string, subFolderID: number, subFolderName: string): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({
			type: PATH_TYPE.PROJECT_SIDER_CHANGE_TO_SUBFOLDER,
			payload: { project: { ID: projectID, name: projectName }, folder: { ID: folderID, name: folderName }, subFolder: { ID: subFolderID, name: subFolderName } }
		})
	}

export const setProjectSiderToPlanStateDocument =
	(planStateDocument: { planStateOptions: any; defaultOption: any; planName: string; planID: number } | null): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({
			type: PATH_TYPE.PROJECT_SIDER_CHANGE_TO_PLAN,
			payload: { planStateDocument }
		})
	}
