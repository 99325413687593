import i18next from 'i18next'
import { FormErrors } from 'redux-form'
import { IDatesFilter } from '../../../types/interfaces'

/**
 * @param {IDatesFilter} values
 */

export default (values: IDatesFilter) => {
	const errors: FormErrors<IDatesFilter> = {}

	if (values.dateFrom && values.dateTo) {
		if (values?.dateFrom > values.dateTo) {
			// eslint-disable-next-line no-underscore-dangle
			errors._error = i18next.t('loc:Date to cannot be before Date from')
		}
	}

	return errors
}
