import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Form, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { FORM, USER_ROLE } from '../../../../utils/enums'

// eslint-disable-next-line import/no-cycle
import validate from './validateEditUserForm'
import InputField from '../../../../atoms/InputField'
import SelectField from '../../../../atoms/SelectField'
import { translateUserRole } from '../../../../utils/helpers'

export interface IEditUserForm {
	name: string
	surname: string
	phone: string
	permission: USER_ROLE
	company: string
	companyRole: string
	note: string
}

type ComponentProps = {}

type Props = InjectedFormProps<IEditUserForm, ComponentProps> & ComponentProps

const EditUserForm = (props: Props) => {
	const { handleSubmit } = props
	const { t } = useTranslation()

	const options = map(USER_ROLE, (item) => {
		return { label: translateUserRole(item), value: item, key: item }
	})

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Row gutter={16}>
				<Col span={12}>
					<Field name={'name'} component={InputField} label={t('loc:Name')} placeholder={t('loc:Enter name')} required />
				</Col>
				<Col span={12}>
					<Field name={'surname'} component={InputField} label={t('loc:Surname')} placeholder={t('loc:Enter surname')} required />
				</Col>
				<Col span={24}>
					<Field name={'permission'} component={SelectField} options={options} label={t('loc:Role')} placeholder={t('loc:Choose role')} required />
					<Field name={'phone'} component={InputField} label={t('loc:Phone')} placeholder={t('loc:Enter phone')} />
					<Field name={'company'} component={InputField} label={t('loc:Company')} placeholder={t('loc:Enter company name')} />
					<Field name={'companyRole'} component={InputField} label={t('loc:Position')} placeholder={t('loc:e.g. an engineer, a quality inspector')} />
					<Field name={'note'} component={InputField} label={t('loc:Notes')} placeholder={t('loc:Additional relevant info about you')} />
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IEditUserForm, ComponentProps>({
	form: FORM.EDIT_USER_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(EditUserForm)

export default form
