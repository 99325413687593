import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteProps, Redirect } from 'react-router-dom'
import { isLoggedIn } from '../utils/auth'

import BaseRoute from './BaseRoute'

type Props = RouteProps & {
	layout: any
	component: any
}

const PublicRoute: FC<Props> = (props) => {
	const { t } = useTranslation()
	if (isLoggedIn()) {
		return <Redirect to={t('paths:index')} />
	}

	return <BaseRoute {...props} />
}

export default PublicRoute
