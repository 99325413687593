import { Form, Row, Col, Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import FileUploadField from '../../../../atoms/FileUploadField'
import InputField from '../../../../atoms/InputField'
import { FORM } from '../../../../utils/enums'
import { validateEmail, validationRequired } from '../../../../utils/helpers'
// eslint-disable-next-line import/no-cycle
import validate from './validateCompanyForm'

export interface ICompanyForm {
	company: string
	logo: any
	address: string
	city: string
	postalcode: string
	country: string
	contactname: string
	contactsurname: string
	contactemail: string
	contactphone: string
	web: string
}

type ComponentProps = {}

type Props = InjectedFormProps<ICompanyForm, ComponentProps> & ComponentProps

const CompanyForm = (props: Props) => {
	const { handleSubmit, submitting } = props

	const { t } = useTranslation()

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit} style={{ width: '100%' }}>
			<Row>
				<span className={'heading-3'}>{t('loc:Company')}</span>
			</Row>
			<div className={'flex justify-center items-center'}>
				<Field
					name={'logo'}
					component={FileUploadField}
					label={t('loc:Company image')}
					isPreview={true}
					accept={'image/*'}
					maxCount={1}
					maxSize={5000000}
					exludeFileTypes={['image/svg+xml']}
					action={'api/v1/settings/company/logo'}
				/>
			</div>
			<Row gutter={16}>
				<Col span={24}>
					<Field name={'company'} component={InputField} label={t('loc:Company name')} placeholder={t('loc:Enter company name')} required validate={validationRequired} />
				</Col>
				<Col span={24}>
					<Field name={'address'} component={InputField} label={t('loc:Company adress')} placeholder={t('loc:Enter company adress')} />
				</Col>
				<Col span={24}>
					<Field name={'city'} component={InputField} label={t('loc:Company city')} placeholder={t('loc:Enter company city')} />
				</Col>
				<Col span={24}>
					<Field name={'country'} component={InputField} label={t('loc:Company country')} placeholder={t('loc:Enter company country')} />
				</Col>
				<Col span={24}>
					<Field name={'postalcode'} component={InputField} label={t('loc:Company postal code')} placeholder={t('loc:XXXXX')} />
				</Col>
				<Col span={24}>
					<Field name={'web'} component={InputField} label={t('loc:Company web')} placeholder={t('loc:Enter company web')} />
				</Col>
			</Row>
			<Row>
				<span className={'heading-3'}>{t('loc:Contact person')}</span>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Field name={'contactname'} component={InputField} label={t('loc:Contacts name')} placeholder={t('loc:Enter name')} />
				</Col>
				<Col span={24}>
					<Field name={'contactsurname'} component={InputField} label={t('loc:Contacts surname')} placeholder={t('loc:Enter surname')} />
				</Col>
				<Col span={24}>
					<Field name={'contactemail'} component={InputField} label={t('loc:Contacts email')} placeholder={t('loc:Enter email')} validate={validateEmail} />
				</Col>
				<Col span={24}>
					<Field name={'contactphone'} component={InputField} label={t('loc:Contacts phone')} placeholder={t('loc:00XX...')} />
				</Col>
			</Row>
			<Button type={'primary'} className={'extd-btn w-52 mb-6'} htmlType={'submit'} disabled={submitting} loading={submitting}>
				{t('loc:Save')}
			</Button>
		</Form>
	)
}

const form = reduxForm<ICompanyForm, ComponentProps>({
	form: FORM.SETTINGS_COMPANY_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(CompanyForm)

export default form
