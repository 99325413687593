import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ACTION_TYPE, TREE_NODE_TYPE } from '../../../../utils/enums'

import { ReactComponent as PlansImage } from '../../../../assets/images/image-plan.svg'
import { ReactComponent as FoldersImage } from '../../../../assets/images/image-folder.svg'
import usePermissionCheck from '../../../../utils/permissionCheck'
import { handleCreatePlan, handleCreateSubFolder } from '../../../../utils/helpers'

type Props = {
	type: TREE_NODE_TYPE
	projectID: number | undefined
	folderID: number
	projectName: string | undefined
	projectCodeAttributes: any
}

const PlansTableEmptyState = ({ type, projectID, folderID, projectName, projectCodeAttributes }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const permissedActions = usePermissionCheck()

	if (type === TREE_NODE_TYPE.MAIN_FOLDER) {
		return (
			<div className={'pb-2 pt-28 flex flex-row justify-center'}>
				<div className={'flex flex-col items-center mr-4'}>
					<FoldersImage />
					<Button
						type={'primary'}
						className={'extd-btn h-10 w-56 mt-4'}
						icon={<PlusOutlined />}
						onClick={() => handleCreateSubFolder(projectID, folderID, projectName, dispatch)}
						disabled={!permissedActions?.includes(ACTION_TYPE.FOLDER_CREATE)}
					>
						{t('loc:Create sub-folder')}
					</Button>
				</div>
				<div className={'flex flex-col items-center'}>
					<PlansImage />
					<Button
						type={'primary'}
						className={'extd-btn h-10 w-56 mt-4'}
						icon={<PlusOutlined />}
						onClick={() => handleCreatePlan(folderID, projectCodeAttributes, projectName, dispatch)}
						disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_CREATE)}
					>
						{t('loc:Create new plan')}
					</Button>
				</div>
			</div>
		)
	}
	return (
		<div className={'pb-2 pt-28 flex flex-col items-center'}>
			<PlansImage />
			<Button
				type={'primary'}
				className={'extd-btn h-10 w-56 mt-4'}
				icon={<PlusOutlined />}
				onClick={() => handleCreatePlan(folderID, projectCodeAttributes, projectName, dispatch)}
				disabled={!permissedActions?.includes(ACTION_TYPE.PLAN_CREATE)}
			>
				{t('loc:Create new plan')}
			</Button>
		</div>
	)
}

export default PlansTableEmptyState
