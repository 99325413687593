/* eslint-disable import/no-cycle */
import { ThunkResult } from '../index'
import { FOLDERS } from './foldersTypes'
import { IResetStore } from '../generalTypes'
import { Paths } from '../../types/api'
import { IResponsePagination } from '../../types/interfaces'
import { getReq } from '../../utils/requests'

export interface IFoldersPayload {
	folders: Paths.GetApiV1Folders.Responses.$200['folders'] | null
	pagination: IResponsePagination | null
	search: string | null
}

interface IGetFolders {
	type: FOLDERS
	payload: IFoldersPayload
}

export type IFoldersActions = IResetStore | IGetFolders

export const getFolders =
	(projectID: number, page: number | null | undefined, search: string | null, limit: Paths.GetApiV1Folders.Parameters.Limit): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: FOLDERS.FOLDERS_LOAD_START })
		try {
			const { data } = await getReq('/api/v1/folders/', { projectID, limit, page: page || 1, search }, undefined, undefined, undefined, true)

			dispatch({ type: FOLDERS.FOLDERS_LOAD_DONE, payload: { search, ...data } })
		} catch (e: any) {
			dispatch({ type: FOLDERS.FOLDERS_LOAD_FAIL })
		}
	}
