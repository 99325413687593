import { FormErrors } from 'redux-form'
import i18next from 'i18next'

// eslint-disable-next-line import/no-cycle
import { IResetPasswordForm } from './ResetPasswordForm'

/**
 * @param {IResetPasswordForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IResetPasswordForm> = {}

	if (!values?.password) {
		errors.password = i18next.t('loc:This field is required')
	}

	if (!values?.confirmPassword) {
		errors.confirmPassword = i18next.t('loc:This field is required')
	} else if (values?.password && values.password !== values.confirmPassword) {
		errors.confirmPassword = i18next.t('loc:Passwords do not match')
	}

	return errors
}
