import React from 'react'
import { Field, FieldArray, getFormError, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'

import { useSelector } from 'react-redux'
import InputField from '../../../atoms/InputField'
import { FORM } from '../../../utils/enums'

// eslint-disable-next-line import/no-cycle
import validate from './validateMainFolderCreateForm'
import FolderTabs from './FolderTabs'
import { ILabelInValue } from '../../../types/interfaces'
import { RootState } from '../../../reducers'

type Tab = {
	id: number
	name: string
	statusView: ILabelInValue
}
export interface IMainFolderCreateForm {
	opened?: {
		projectID: number
		projectName: string
	} | null
	selectedTabKey: number | string
	name: string
	tabs: Tab[]
}

type ComponentProps = {}

type Props = InjectedFormProps<IMainFolderCreateForm, ComponentProps> & ComponentProps
const selectorError = getFormError(FORM.MAIN_FOLDER_CREATE_FORM)

const MainFolderCreateForm = (props: Props) => {
	const { handleSubmit } = props
	const { t } = useTranslation()

	const formErrorMessage = useSelector((state: RootState) => selectorError(state))

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Row>
				<Col span={24}>
					<h4 className={'text-l-bold cursor-default'}>{t('loc:Folder Name')}</h4>
				</Col>
				<Col span={24} className={'mt-4'}>
					<Field name={'name'} component={InputField} placeholder={t('loc:Enter folder name')} required />
				</Col>
				<Col>
					<h4 className={'text-l-bold cursor-default'}>{t('loc:Define status timeline for plans in the folder')}</h4>
				</Col>
				<Col span={24}>
					<div className={'h-7 flex items-center mt-0 text-error-medium cursor-default'}>{formErrorMessage}</div>
					<FieldArray component={FolderTabs} name={'tabs'} formName={FORM.MAIN_FOLDER_CREATE_FORM} />
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IMainFolderCreateForm, ComponentProps>({
	form: FORM.MAIN_FOLDER_CREATE_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(MainFolderCreateForm)

export default form
