import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import { map } from 'lodash'

import InputField from '../../../../atoms/InputField'
import SelectField from '../../../../atoms/SelectField'
import { FORM, USER_ROLE } from '../../../../utils/enums'

// eslint-disable-next-line import/no-cycle
import validate from './validateInviteUserForm'
import { translateUserRole } from '../../../../utils/helpers'

export interface IInviteUserForm {
	email: string
	permission: USER_ROLE
}

type ComponentProps = {}

type Props = InjectedFormProps<IInviteUserForm, ComponentProps> & ComponentProps

const InviteUserForm = (props: Props) => {
	const { handleSubmit } = props
	const { t } = useTranslation()

	const options = map(USER_ROLE, (item) => {
		return { label: translateUserRole(item), value: item, key: item }
	})

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Field name={'email'} component={InputField} label={t('loc:Email')} placeholder={t('loc:Emails separated by comma')} required />
			<Field name={'permission'} component={SelectField} label={t('loc:Role')} placeholder={t('loc:Choose role')} options={options} required />
		</Form>
	)
}

const form = reduxForm<IInviteUserForm, ComponentProps>({
	form: FORM.INVITE_USER_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(InviteUserForm)

export default form
