import React from 'react'
import { Button, Form, Row, Col, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { FORM } from '../../../../utils/enums'
// eslint-disable-next-line import/no-cycle
import validate from './validateChangePasswordForm'
import { logOutUser } from '../../../../reducers/authUser/authUserActions'
import { isStrongEnough } from '../../../../utils/helpers'
import { renderTooltip } from '../../../NewPasswordPage/components/NewPasswordForm'
import { RootState } from '../../../../reducers'
import InputPasswordField from '../../../../atoms/InputPasswordField'

export interface IChangePasswordForm {
	actualPassword: string
	newPassword: string
	repeatedPassword: string
}

type ComponentProps = {}

type Props = InjectedFormProps<IChangePasswordForm, ComponentProps> & ComponentProps

const ChangePasswordForm = (props: Props) => {
	const { handleSubmit, submitting } = props

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const selector = formValueSelector(FORM.SETTINGS_CHANGE_PASSWORD_FORM)

	const newPasswordValue = useSelector((state: RootState) => selector(state, 'newPassword'))

	const canSubmit = isStrongEnough(newPasswordValue) && !submitting

	const showTooltip = !canSubmit && newPasswordValue?.length > 0

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit} style={{ width: '100%' }}>
			<Row className={'mb-4'}>
				<span className={'heading-3'}>{t('loc:Change password')}</span>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Field name={'actualPassword'} component={InputPasswordField} label={t('loc:Actual password')} required />
				</Col>
				<Col span={24} style={{ marginTop: '-8px' }}>
					<Button
						type={'text'}
						className={'extd-btn p-0 hover:bg-white'}
						onClick={() => {
							dispatch(logOutUser(true, t('paths:forgot-password')))
						}}
					>
						<span className={'text-m-regular text-blue-600 m-0'}>{t('loc:Forgot password?')}</span>
					</Button>
				</Col>
				<Col span={24} className={'mt-16'}>
					<Tooltip visible={showTooltip} placement={'topRight'} title={renderTooltip(newPasswordValue)}>
						<Field name={'newPassword'} component={InputPasswordField} label={t('loc:New password')} required />
					</Tooltip>
				</Col>
				<Col span={24}>
					<Field name={'repeatedPassword'} component={InputPasswordField} label={t('loc:Repeat new password')} required />
				</Col>
			</Row>
			<Button type={'primary'} className={'extd-btn w-52'} htmlType={'submit'} disabled={!canSubmit} loading={submitting}>
				{t('loc:Submit')}
			</Button>
		</Form>
	)
}

const form = reduxForm<IChangePasswordForm, ComponentProps>({
	form: FORM.SETTINGS_CHANGE_PASSWORD_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate
})(ChangePasswordForm)

export default form
