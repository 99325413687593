import React, { useCallback, useRef, useState } from 'react'
import { WrappedFieldProps } from 'redux-form'

import { get, trimStart } from 'lodash'

import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { TextAreaRef } from 'antd/lib/input/TextArea'

type Props = WrappedFieldProps &
	TextAreaProps &
	FormItemLabelProps & {
		focusRow?: number
	}

const TextAreaField = (props: Props) => {
	const { input, placeholder, rows, autoSize, maxLength, focusRow } = props

	const [autoSizeState, setSutoSizeState] = useState(undefined as { minRows?: number; maxRows?: number } | undefined)

	const ref = useRef(null as TextAreaRef | null)

	const parseValue = (value: any) => trimStart(value) || null

	const onChange = useCallback(
		(e: any) => {
			if (input.onChange) {
				const val = parseValue(get(e, 'target.value'))
				input.onChange(val as any, e.target.name)
			}
		},
		[input]
	)

	const onFocus = useCallback(
		(e) => {
			if (input.onFocus) {
				input.onFocus(e)
			}
			if (focusRow) {
				setSutoSizeState({ minRows: focusRow, maxRows: 10 })
				ref?.current?.resizableTextArea?.resizeOnNextFrame()
			}
		},
		[focusRow, input, setSutoSizeState]
	)

	const onBlur = useCallback(
		(e) => {
			if (input.onBlur) {
				const val = parseValue(get(e, 'target.value'))
				input.onBlur(val as any, e.target.name)
			}
			if (focusRow) {
				setSutoSizeState({ minRows: 1, maxRows: 10 })
				ref?.current?.resizableTextArea?.resizeOnNextFrame()
			}
		},
		[focusRow, input, setSutoSizeState]
	)

	return (
		<Input.TextArea
			{...input}
			onFocus={onFocus}
			onBlur={onBlur}
			onChange={onChange}
			value={input.value}
			placeholder={placeholder}
			className={'extd-input'}
			rows={rows}
			autoSize={autoSizeState || autoSize}
			maxLength={maxLength}
			ref={ref}
		/>
	)
}

export default TextAreaField
