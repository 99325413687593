import { FolderAddOutlined } from '@ant-design/icons'
import { Button, Col, Row, Upload, Typography } from 'antd'
import { debounce, find, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { arrayInsert, arrayPush, arrayRemove, change, Field, FieldArray, formValueSelector, getFormError, isDirty, WrappedFieldArrayProps, WrappedFieldProps } from 'redux-form'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { ReactSortable } from 'react-sortablejs'
import { RcFile } from 'antd/es/upload/interface'
import SelectField, { selectFieldFilterOption } from '../../../atoms/SelectField'
import { RootState } from '../../../reducers'
import { ILabelInValue } from '../../../types/interfaces'
import { FORM, MSG_TYPE, NOTIFICATION_TYPE, UPLOAD_ERROR_TYPE } from '../../../utils/enums'
import { getReq } from '../../../utils/requests'

import { ReactComponent as ArrowIcon } from '../../../assets/icons/icon-breadcrumb-arrow.svg'
import { ReactComponent as DoneIcon } from '../../../assets/icons/icon-notification-done.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/icon-notification-warning-red.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon-delete.svg'
import { ReactComponent as ProjectIcon } from '../../../assets/icons/icon-input-project.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-close.svg'
import { ReactComponent as DocumentImage } from '../../../assets/images/image-document-small.svg'
import { ReactComponent as Drag } from '../../../assets/icons/icon-drag.svg'
import { getAccessToken } from '../../../utils/auth'
import showNotifications from '../../../utils/tsxHelpers'

const { Dragger } = Upload
export interface PathFieldValue {
	project: ILabelInValue | null
	folder: ILabelInValue | null
	subFolder: ILabelInValue | null
	plan: ILabelInValue | null
	planState: ILabelInValue | null
}

export interface FileFieldValue {
	id: number
	name: string
}

export interface PlanStateFieldValue {
	route: PathFieldValue | null
	files: FileFieldValue[]
}

const form = FORM.UPLOAD_DOCUMENTS
const selector = formValueSelector(form)

type PlanFileFieldProps = WrappedFieldArrayProps<FileFieldValue>

const FilesField = (param: PlanFileFieldProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const selectorError = getFormError(FORM.UPLOAD_DOCUMENTS)
	const formErrorMessage: string = useSelector((state: RootState) => selectorError(state)) as string

	const isFormDirty = useSelector((state: RootState) => isDirty(form)(state))

	const [fileList, setFileList] = useState<any[]>([])
	const files = param?.fields.getAll()
	const name = param?.fields?.name

	const getErrorMessage = (): string | undefined => {
		if (!formErrorMessage || !isFormDirty) {
			return undefined
		}
		const errorArray = formErrorMessage.split('-')
		const pathFieldIndex = parseInt(name?.substring(name.indexOf('[') + 1, name.lastIndexOf(']')), 10)

		const errorText = errorArray.find(
			(errorItem) =>
				parseInt(errorItem?.substring(errorItem.indexOf('[') + 1, errorItem.lastIndexOf(']')), 10) === pathFieldIndex && errorItem.includes(UPLOAD_ERROR_TYPE.FILE)
		)

		if (errorText) {
			return errorText?.substring(errorText.indexOf(' ') + 1)
		}

		return undefined
	}

	const deleteFile = (index: number) => {
		dispatch(arrayRemove(form, name, index))
	}

	const handleUploadChange = async (info: UploadChangeParam<UploadFile<any>>) => {
		if (info.file.status === 'error') {
			showNotifications(
				[
					{
						type: MSG_TYPE.ERROR,
						message: t('loc:Upload failed, remove the file and try again.')
					}
				],
				NOTIFICATION_TYPE.NOTIFICATION
			)
		}
		if (info.file.status === 'done' || info.file.status === 'success') {
			const newFile = { id: info.file.response?.file?.id, name: info.file.response?.file?.name }
			setFileList([...info.fileList.filter((file) => file.uid !== info.file.uid)])
			if (find(files, (file) => file.id === newFile.id)) {
				showNotifications(
					[
						{
							type: MSG_TYPE.ERROR,
							message: t('loc:This file has already been uploaded.')
						}
					],
					NOTIFICATION_TYPE.NOTIFICATION
				)
			} else dispatch(arrayInsert(form, name, 0, newFile))
		} else {
			setFileList(info.fileList)
		}
	}

	const getDraggerStyle = () => {
		if (fileList?.length > 0 || files?.length > 0) {
			if (fileList.length + files.length < 4) {
				return { marginLeft: '50%', width: '50%', minHeight: 172, zIndex: 6 }
			}
			return { marginLeft: '50%', width: '50%', minHeight: 44 * (fileList.length + files.length) - 4, zIndex: 6 }
		}
		return { minHeight: 172, zIndex: 6 }
	}

	const getSortableStyle = () => {
		if (fileList.length + files.length === 0) {
			return { top: -172, width: '100%', minHeight: 172 }
		}
		if (fileList.length + files.length < 4) {
			return { top: -172 + 44 * fileList.length, width: '100%', minHeight: 172 }
		}
		return {
			top: -172 + 44 * fileList.length - 44 * (fileList.length + files.length - 4),
			width: '100%',
			minHeight: 44 * (fileList.length + files.length)
		}
	}

	const getMaxUploadHeight = () => {
		if (fileList.length + files.length < 4) {
			return { maxHeight: 242 }
		}
		return { maxHeight: 242 + 44 * (fileList.length + files.length - 4) }
	}

	const items = map(files, (item, index) => {
		return (
			<div className={'h-10 mb-1 flex justify-between sortable bg-gray-100 pt-2 pr-2 pb-2 rounded'} style={{ width: 'calc(50% - 16px)' }} key={item.id}>
				<div className={'flex flex-row'} style={{ maxWidth: '90%' }}>
					<div className={'handle-on'}>
						<Drag />
					</div>
					<div>
						<ProjectIcon />
					</div>
					<Typography.Text className={'ml-1'} ellipsis>
						{item.name}
					</Typography.Text>
				</div>
				<div>
					<Button className={'w-6 h-6 p-0'} style={{ top: '-4px', right: '-4px' }} type={'text'} onClick={() => deleteFile(index)}>
						<CloseIcon style={{ position: 'absolute', top: '0px', left: '0px' }} />
					</Button>
				</div>
			</div>
		)
	})

	const debounceNotification = debounce(() => {
		showNotifications(
			[
				{
					type: MSG_TYPE.ERROR,
					message: t(`loc:Max number of uploaded files is 10`)
				}
			],
			NOTIFICATION_TYPE.NOTIFICATION
		)
	}, 100)

	return (
		<>
			<div className={'flex flex-col extd-drag-upload'} style={getMaxUploadHeight()}>
				<span className={'heading-4 mb-2 cursor-default'}>{t('loc:2. Upload documents')}</span>
				<span className={'cursor-default'}>{t(`loc:Drag'n'Drop files from your computer that you wish to upload into specified destination.`)}</span>
				<Row className={'mt-4'}>
					<Col span={24}>
						<Dragger
							accept={undefined}
							headers={{
								Authorization: `Bearer ${getAccessToken()}`
							}}
							iconRender={() => {
								return <ProjectIcon className={'mt-1'} />
							}}
							action={'api/v1/documents'}
							maxCount={10}
							multiple
							fileList={fileList}
							beforeUpload={(file: RcFile, FileList: RcFile[]) => {
								if (FileList.length + fileList.length + files.length > 10) {
									debounceNotification()
									return Upload.LIST_IGNORE
								}
								return true
							}}
							onDrop={(e) => e.dataTransfer.files}
							onChange={handleUploadChange}
							className={'bg-blue-100'}
							style={getDraggerStyle()}
						>
							<div className={'flex flex-row justify-center items-center p-2'}>
								<DocumentImage className={'mr-4'} />
								<div className={'flex flex-col'}>
									<span className={'text-m-regular text-blue-600'}>{t('loc:Drag and drop documents here or')}</span>
									<Button type={'primary'} className={'extd-btn mt-4'}>
										{t('loc:Browse files')}
									</Button>
								</div>
							</div>
						</Dragger>
					</Col>
				</Row>
				<ReactSortable
					list={files}
					className={'relative'}
					handle={'.handle-on'}
					style={getSortableStyle()}
					setList={(newState) => {
						dispatch(change(form, name, newState))
					}}
					onEnd={() => {
						document.body.classList.remove('grabbing')
					}}
					onStart={() => {
						document.body.classList.add('grabbing')
					}}
					group={'groupName'}
					forceFallback={true}
				>
					{items}
				</ReactSortable>
			</div>
			<div className={'mt-4'}>
				<span className={'text-error-medium cursor-default'}>{getErrorMessage()}</span>
			</div>
		</>
	)
}

type PathFieldProps = WrappedFieldProps

const PathField = (param: PathFieldProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const selectorError = getFormError(FORM.UPLOAD_DOCUMENTS)
	const formErrorMessage: string = useSelector((state: RootState) => selectorError(state)) as string

	const isFormDirty = useSelector((state: RootState) => isDirty(form)(state))

	const name = param?.input?.name

	const getErrorMessage = (): string | undefined => {
		if (!formErrorMessage || !isFormDirty) {
			return undefined
		}
		const errorArray = formErrorMessage.split('-')
		const pathFieldIndex = parseInt(name?.substring(name.indexOf('[') + 1, name.lastIndexOf(']')), 10)

		const errorText = errorArray.find(
			(errorItem) =>
				parseInt(errorItem?.substring(errorItem.indexOf('[') + 1, errorItem.lastIndexOf(']')), 10) === pathFieldIndex && errorItem.includes(`]${UPLOAD_ERROR_TYPE.PATH}`)
		)

		if (errorText) {
			return errorText?.substring(errorText.indexOf(' ') + 1)
		}

		return undefined
	}

	const projectsSider = useSelector((state: RootState) => state?.projectsStore?.projectsSider)
	const projectValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.project`))

	const [foldersOptions, setFoldersOptions] = useState<any[]>([])
	const folderValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.folder`))

	const [subFoldersOptions, setSubFoldersOptions] = useState<any[]>([])
	const subFolderValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.subFolder`))

	const [planOptions, setPlanOptions] = useState<any[]>([])
	const planValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.plan`))

	const [planStateOptions, setPlanStateOptions] = useState<any[]>([])
	const planStateValue: ILabelInValue = useSelector((state: RootState) => selector(state, `${name}.planState`))

	const planStatesValues: any[] = useSelector((state: RootState) => selector(state, 'planStates'))

	const projectOptions = map(projectsSider?.originalData, (project) => ({
		label: project.name,
		key: project.id,
		value: project.id
	}))

	useEffect(() => {
		const currProj = find(projectsSider?.originalData, (project) => project.id === projectValue?.value)
		if (currProj) {
			const newFolderOptions = map(currProj?.folders, (folder) => ({
				label: folder.name,
				key: folder.id,
				value: folder.id
			}))
			setFoldersOptions(newFolderOptions)
			if (folderValue && !find(newFolderOptions, (option) => folderValue.value === option.value)) {
				dispatch(change(form, `${name}.folder`, null))
				dispatch(change(form, `${name}.subFolder`, null))
				dispatch(change(form, `${name}.plan`, null))
				dispatch(change(form, `${name}.planState`, null))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectValue, projectsSider?.originalData])

	useEffect(() => {
		if (!projectValue) {
			dispatch(change(form, `${name}.folder`, null))
			dispatch(change(form, `${name}.subFolder`, null))
			dispatch(change(form, `${name}.plan`, null))
			dispatch(change(form, `${name}.planState`, null))
		} else if (!folderValue) {
			dispatch(change(form, `${name}.subFolder`, null))
			dispatch(change(form, `${name}.plan`, null))
			dispatch(change(form, `${name}.planState`, null))
		} else {
			const currProj = find(projectsSider?.originalData, (project) => project.id === projectValue?.value)
			if (currProj) {
				const currFolder = find(currProj?.folders, (folder) => folder.id === folderValue?.value)
				if (currFolder) {
					const newSubFolderOptions = map(currFolder?.folders, (folder) => ({
						label: folder.name,
						key: folder.id,
						value: folder.id
					}))
					setSubFoldersOptions(newSubFolderOptions)
					if (subFolderValue && !find(newSubFolderOptions, (option) => subFolderValue.value === option.value)) {
						dispatch(change(form, `${name}.subFolder`, null))
						dispatch(change(form, `${name}.plan`, null))
						dispatch(change(form, `${name}.planState`, null))
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectsSider?.originalData, projectValue, folderValue])

	useEffect(() => {
		const getPlanOptions = async (folderID: number) => {
			const data = await getReq('/api/v1/plans/', { folderID, limit: 1000 })
			const newPlanOptions = map(data?.data?.plans, (plan) => ({
				label: plan.name,
				key: plan.id,
				value: plan.id
			}))
			setPlanOptions(newPlanOptions)
			if (planValue && !find(newPlanOptions, (option) => planValue.value === option.value)) {
				dispatch(change(form, `${name}.plan`, null))
				dispatch(change(form, `${name}.planState`, null))
			}
			if (planValue) {
				const currPlan = find(data?.data?.plans, (plan) => plan.id === planValue?.value)
				if (currPlan) {
					const newPlanStateOptions = map(currPlan?.planStates, (planStates) => ({
						label: planStates?.name,
						key: planStates?.id,
						value: planStates?.id
					}))

					setPlanStateOptions(newPlanStateOptions)
				}
			}
		}

		if (subFolderValue) getPlanOptions(subFolderValue?.value)
		else if (folderValue) getPlanOptions(folderValue?.value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folderValue, subFolderValue, planValue])

	const deletePathField = () => {
		const index = parseInt(name.substring(name.indexOf('[') + 1, name.lastIndexOf(']')), 10)
		dispatch(arrayRemove(form, 'planStates', index))
	}

	return (
		<div className={'mb-6'}>
			<div className={'flex flex-row justify-between'}>
				<div className={'flex flex-col cursor-default mb-4'}>
					<span className={'heading-4 mb-2'}>{t('loc:1. Specify destination')}</span>
					<span>{t('loc:Specify final destination for documents.')}</span>
				</div>
				{planStatesValues?.length > 1 && (
					<div className={'flex items-center'}>
						<Button type={'text'} danger className={'extd-btn'} style={{ width: '104px' }} icon={<DeleteIcon />} onClick={deletePathField}>
							{t('loc:Remove')}
						</Button>
					</div>
				)}
			</div>
			<div className={'flex flex-row items-center'}>
				<div>{planStateValue ? <DoneIcon className={'mr-2 ml-2'} /> : <WarningIcon className={'mr-2 ml-2'} style={{ fill: 'green' }} />}</div>
				<div className={'w-40'}>
					<Field
						name={`${name}.project`}
						component={SelectField}
						allowClear={true}
						placeholder={t('loc:Choose project')}
						label={t('loc:Project')}
						labelInValue
						options={projectOptions}
					/>
				</div>
				{projectValue && (
					<>
						<ArrowIcon />
						<div className={'w-40'}>
							<Field
								name={`${name}.folder`}
								component={SelectField}
								allowClear={true}
								placeholder={t('loc:Choose folder')}
								label={t('loc:Folder')}
								labelInValue
								options={foldersOptions}
							/>
						</div>
					</>
				)}
				{folderValue && subFoldersOptions?.length !== 0 && (
					<>
						<ArrowIcon />
						<div className={'w-40'}>
							<Field
								name={`${name}.subFolder`}
								label={t('loc:Subfolder')}
								placeholder={t('loc:Choose subfolder')}
								component={SelectField}
								allowClear={true}
								labelInValue
								options={subFoldersOptions}
							/>
						</div>
					</>
				)}
				{folderValue && (
					<>
						<ArrowIcon />
						<div className={'w-80'}>
							<Field
								name={`${name}.plan`}
								allowClear={true}
								label={t('loc:Plan')}
								placeholder={t('loc:Choose plan')}
								component={SelectField}
								showSearch
								filterOption={selectFieldFilterOption}
								labelInValue
								options={planOptions}
							/>
						</div>
					</>
				)}
				{planValue && (
					<>
						<ArrowIcon />
						<div className={'w-40'}>
							<Field
								name={`${name}.planState`}
								label={t('loc:PlanState')}
								placeholder={t('loc:Choose plan state')}
								component={SelectField}
								allowClear={true}
								labelInValue
								options={planStateOptions}
							/>
						</div>
					</>
				)}
			</div>
			<span className={'text-error-medium cursor-default'} style={{ position: 'relative', top: '-16px' }}>
				{getErrorMessage()}
			</span>
		</div>
	)
}

type PlanStateFieldProps = WrappedFieldArrayProps<PlanStateFieldProps>

const PlanStateField = (param: PlanStateFieldProps) => {
	return param?.fields?.map((name, index: number) => {
		return (
			<div className={'mb-4 p-4 bg-white'} key={index}>
				<Field name={`${name}.route` as string} component={PathField as any} />
				<FieldArray name={`${name}.files` as string} component={FilesField as any} />
			</div>
		)
	})
}

const PlanStateFieldArray = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const createNewPlanStateField = () => {
		const newPlanStateField: PlanStateFieldValue = {
			route: {
				project: null,
				folder: null,
				subFolder: null,
				plan: null,
				planState: null
			},
			files: []
		}
		dispatch(arrayPush(form, 'planStates', newPlanStateField))
	}

	return (
		<>
			<FieldArray name={'planStates'} component={PlanStateField as any} />
			<Row>
				<Col flex={'auto'} className={'bg-white flex justify-center'}>
					<Button type={'default'} className={'extd-btn m-3'} onClick={createNewPlanStateField} icon={<FolderAddOutlined />}>
						{t('loc:Add new upload destination')}
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default PlanStateFieldArray
