import React, { useCallback } from 'react'
import { Form, Input } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { trimStart } from 'lodash'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { formFieldID } from '../utils/helpers'

const { Item } = Form

type Props = WrappedFieldProps & InputProps & FormItemLabelProps

const InputPasswordField = (props: Props) => {
	const {
		input,
		size,
		placeholder,
		label,
		required,
		type,
		prefix,
		disabled,
		style,
		meta: { form, error, touched },
		maxLength
	} = props
	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? e.target.value : null
			input.onChange(trimStart(val as string))
		},
		[input]
	)

	const onBlur = useCallback(
		async (e) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? e.target.value : null
			// NOTE: wait until redux-form "BLUR" action is finished
			await input.onBlur(val)
		},
		[input]
	)

	const onFocus = useCallback(
		async (e) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? e.target.value : null
			if (input.onFocus) {
				input.onFocus(val)
			}
		},
		[input]
	)

	return (
		<Item label={label} required={required} style={style} help={touched && error} validateStatus={error && touched ? 'error' : undefined}>
			<Input.Password
				{...input}
				id={formFieldID(form, input.name)}
				onChange={onChange}
				onBlur={onBlur}
				size={size || 'large'}
				onFocus={onFocus}
				value={input.value}
				placeholder={placeholder}
				className={'extd-password-input'}
				type={type || 'text'}
				prefix={prefix}
				disabled={disabled}
				maxLength={maxLength}
				iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
			/>
		</Item>
	)
}

export default React.memo(InputPasswordField)
