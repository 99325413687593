import { isEmail } from 'lodash-checkit'
import { FormErrors } from 'redux-form'
import i18next from 'i18next'

// eslint-disable-next-line import/no-cycle
import { IInviteForm } from './InviteForm'

/**
 * @param {ILoginForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IInviteForm> = {}

	if (!values?.email) {
		errors.email = i18next.t('loc:This field is required')
	} else if (values.email && !isEmail(values.email)) {
		errors.email = i18next.t('loc:Email format is incorrect')
	} else if (values.email?.length > 255) {
		errors.email = i18next.t('loc:Max. number of characters is {{max}}', { max: 255 })
	}

	return errors
}
