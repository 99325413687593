import { RESET_STORE } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { IUserActions, IAuthUserPayload } from './authUserActions'
import { ILoadingAndFailure } from '../../types/interfaces'
import { AUTH_USER } from './authUserTypes'

export const initState = {
	authUser: {
		originalData: null,
		company: null,
		companyLogo: null,
		isLoading: false,
		isFailure: false
	} as IAuthUserPayload & ILoadingAndFailure
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (state = initState, action: IUserActions) => {
	switch (action.type) {
		case AUTH_USER.AUTH_USER_LOAD_START:
			return {
				...state,
				authUser: {
					...state.authUser,
					isLoading: true
				}
			}
		case AUTH_USER.AUTH_USER_LOAD_FAIL:
			return {
				...state,
				authUser: {
					...initState.authUser,
					isFailure: true
				}
			}
		case AUTH_USER.AUTH_USER_LOAD_DONE:
			return {
				...state,
				authUser: {
					...initState.authUser,
					originalData: action.payload.originalData,
					company: action.payload.company,
					companyLogo: action.payload.companyLogo
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}

// eslint-disable-next-line default-param-last
