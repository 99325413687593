import { FormErrors } from 'redux-form'

// eslint-disable-next-line import/no-cycle
import { IBulkUploadForm } from './BulkUploadForm'

/**
 * @param {IBulkUploadForm} values
 */
export default (values: any) => {
	const errors: FormErrors<IBulkUploadForm> = {}

	if (!values?.selectStatusAttributes?.find((value: any) => !!value?.value)) {
		// eslint-disable-next-line no-underscore-dangle
		errors._error = 'error'
	}

	return errors
}
