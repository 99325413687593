import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IProjectsPayload, IProjectsActions, IProjectPayload } from './projectsActions'
import { RESET_STORE } from '../generalTypes'
import { PROJECTS, PROJECTS_SIDER, PROJECT } from './projectsTypes'

export const initState = {
	projects: {
		originalData: null,
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IProjectsPayload & ILoadingAndFailure,
	projectsSider: {
		originalData: null,
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IProjectsPayload & ILoadingAndFailure,
	project: {
		originalData: null,
		isLoading: false,
		isFailure: false
	} as IProjectPayload & ILoadingAndFailure
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (state = initState, action: IProjectsActions) => {
	switch (action.type) {
		case PROJECTS.PROJECTS_LOAD_START:
			return {
				...state,
				projects: {
					...state.projects,
					isLoading: true
				}
			}
		case PROJECTS.PROJECTS_LOAD_FAIL:
			return {
				...state,
				projects: {
					...initState.projects,
					isFailure: true
				}
			}
		case PROJECTS.PROJECTS_LOAD_DONE:
			return {
				...state,
				projects: {
					...initState.projects,
					originalData: action.payload.originalData,
					pagination: action.payload.pagination
				}
			}
		case PROJECT.PROJECT_LOAD_START:
			return {
				...state,
				project: {
					...state.project,
					isLoading: true
				}
			}
		case PROJECT.PROJECT_LOAD_FAIL:
			return {
				...state,
				project: {
					...initState.project,
					isFailure: true
				}
			}
		case PROJECT.PROJECT_LOAD_DONE:
			return {
				...state,
				project: {
					...initState.project,
					originalData: action.payload.originalData
				}
			}
		case PROJECTS_SIDER.PROJECTS_SIDER_LOAD_START:
			return {
				...state,
				projectsSider: {
					...state.projectsSider,
					isLoading: true
				}
			}
		case PROJECTS_SIDER.PROJECTS_SIDER_LOAD_FAIL:
			return {
				...state,
				projectsSider: {
					...initState.projectsSider,
					isFailure: true
				}
			}
		case PROJECTS_SIDER.PROJECTS_SIDER_LOAD_DONE:
			return {
				...state,
				projectsSider: {
					...initState.projectsSider,
					originalData: action.payload.originalData,
					pagination: null
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
