import React from 'react'
import { map } from 'lodash'
import { ItemInterface, ReactSortable } from 'react-sortablejs'
import cx from 'classnames'
import { Button, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { ReactComponent as Drag } from '../assets/icons/icon-drag.svg'
import { TAB_TYPE } from '../utils/enums'

type Props<TabData> = {
	selectedKey: number | string
	onClick: (tabKey: number | string) => void
	setList: (reorderedTabs: ItemInterface[]) => void
	tabsList: TabData[]
	tabType: TAB_TYPE
	disabled: boolean
	addButtonDisabled: boolean
	onAddButtonClick: () => void
}

const DraggableTabs = <TabData extends ItemInterface>(props: Props<TabData>) => {
	const { tabsList, selectedKey, onClick, setList, tabType, disabled, addButtonDisabled, onAddButtonClick } = props
	const { t } = useTranslation()

	const tabContent = (item: TabData) => {
		const isActive = item.id === selectedKey
		if (tabType === TAB_TYPE.PROJECT) {
			return (
				<div>
					<div
						className={cx('text-m-regular mr-4', { 'text-blue-600': isActive })}
						onClick={() => onClick(item.id)}
						// Focusovanie dragovatelnych tabov neriesime
						role='none'
					>
						{item.title}
					</div>
					<Typography.Text ellipsis className='text-s-regular text-zinc-400' onClick={() => onClick(item.id)} style={{ maxWidth: 150 }}>
						{item.name || '-'}
					</Typography.Text>
				</div>
			)
		}
		if (tabType === TAB_TYPE.FOLDER) {
			return (
				<div>
					<div
						className={'text-s-regular text-zinc-400 mr-4'}
						onClick={() => onClick(item.id)}
						// Focusovanie dragovatelnych tabov neriesime
						role='none'
					>
						{`${t('loc:Status')} ${item.id}`}
					</div>
					<Typography.Text ellipsis className={cx('text-m-regular', { 'text-blue-600': isActive })} onClick={() => onClick(item.id)} style={{ maxWidth: 150 }}>
						{item.name || '-'}
					</Typography.Text>
				</div>
			)
		}

		return <div />
	}

	const items = map(tabsList, (item) => {
		const isActive = item.id === selectedKey
		return (
			<div key={item.id} className={cx('extd-tab pt-1 pl-2 pr-2 pb-2', { 'bg-zinc-100': isActive })} role={'none'}>
				<div className={disabled ? 'handle' : 'handle handle-on'}>
					<Drag />
				</div>
				{tabContent(item)}
			</div>
		)
	})

	return (
		<ReactSortable list={tabsList} setList={setList} direction={'horizontal'} handle={'.handle-on'} filter={'.filtered'}>
			{items}
			<Button type={'primary'} className={'extd-btn filtered relative'} disabled={addButtonDisabled} onClick={onAddButtonClick} icon={<PlusOutlined />} />
		</ReactSortable>
	)
}

export default DraggableTabs
