import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import { initialize } from 'redux-form'
import { logOutUser } from '../../reducers/authUser/authUserActions'
import { RootState } from '../../reducers'
import { history } from '../../utils/history'
import { getReq } from '../../utils/requests'
import { FORM } from '../../utils/enums'

const ProfileDropdown = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const authUser = useSelector((state: RootState) => state?.userStore?.authUser?.originalData)

	const handleLogout = () => {
		dispatch(logOutUser(true))
	}

	const handleOpenSettings = async () => {
		if (authUser?.id) {
			try {
				const data = await getReq('/api/v1/users/{userID}', { userID: authUser?.id })

				const initData = {
					name: data.data.user?.name,
					surname: data.data.user?.surname,
					email: data.data.user?.email,
					phone: data.data.user?.phone,
					company: data.data.user?.company,
					companyRole: data.data.user?.companyRole,
					note: data.data.user?.note
				}

				dispatch(initialize(FORM.SETTINGS_CONTACTS_FORM, initData))

				history.push(t('paths:settings'))
			} catch (e: any) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}
	}

	const dropdownMenu = (
		<Menu>
			<Menu.Item key={'settings'} onClick={handleOpenSettings}>
				{t('loc:Settings')}
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key={'logout'} onClick={handleLogout}>
				{t('loc:Log out')}
			</Menu.Item>
		</Menu>
	)

	const user = (
		<div className={'cursor-pointer'}>
			<span className={'text-m-regular'}>{`${authUser?.name} ${authUser?.surname}`}</span>
			<DownOutlined className='ml-2' />
		</div>
	)

	return (
		<Dropdown className='mr-5' overlay={dropdownMenu}>
			{user}
		</Dropdown>
	)
}

export default ProfileDropdown
