import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { InboxOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, TablePaginationConfig, Table, Row, Col, Pagination, Tooltip, Typography } from 'antd'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { change } from 'redux-form'
import { RootState } from '../../../reducers'
import { getProjects, getProjectsSider } from '../../../reducers/projects/projectsActions'
import { history } from '../../../utils/history'
import { ACTION_TYPE, DEFAULT_PAGE_SIZE, FORM, PAGINATION } from '../../../utils/enums'
import { formatDateTime } from '../../../utils/helpers'
import { Paths } from '../../../types/api'
import { ReactComponent as ProjectImage } from '../../../assets/images/image-project.svg'
import usePermissionCheck from '../../../utils/permissionCheck'
import { setProjectSiderToProject } from '../../../reducers/menu/menuActions'
import ConfirmModal from '../../../components/ConfirmModal'
import { deleteReq } from '../../../utils/requests'
import ProjectsTableActions from './components/ProjectTableActions'
import SearchFilter from '../components/SearchFilter'
import { ISearchFilter } from '../../../types/interfaces'

const ProjectsTable = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const projects = useSelector((state: RootState) => state?.projectsStore?.projects)

	const [modalState, setModalState] = useState<{ visible: boolean; projectID: number | undefined }>({ visible: false, projectID: undefined })

	const [, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam
	})

	const permissedActions = usePermissionCheck()

	const [search, setSearch] = useState('')

	const pagination = {
		current: projects?.pagination?.page,
		total: projects?.pagination?.totalCount,
		pageSize: projects?.pagination?.limit,
		showSizeChanger: true,
		pageSizeOptions: PAGINATION.pageSizeOptions,
		className: 'extd-pagination'
	}

	// after 1st render
	useEffect(() => {
		dispatch(getProjects(1, search, pagination?.pageSize || DEFAULT_PAGE_SIZE))
		dispatch(change(FORM.SEARCH_FILTER, 'search', null))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const searchProjects = (values: ISearchFilter) => {
		dispatch(getProjects(1, values?.search, pagination?.pageSize || DEFAULT_PAGE_SIZE))
		setSearch(values?.search)
	}

	const handleCreateProjectClick = () => {
		history.push(t('paths:projects/create'))
	}

	const columns = [
		{
			key: 'name',
			dataIndex: 'name',
			title: t('loc:Project name'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (name: any) => (
				<div>
					<Tooltip placement={'topLeft'} title={name} overlayStyle={{ maxWidth: '500px' }}>
						<InboxOutlined className={'text-blue-600 mr-2'} />
						<Typography.Text ellipsis className={'text-m-medium'} style={{ width: 'calc(100% - 25px)' }}>
							{name}
						</Typography.Text>
					</Tooltip>
				</div>
			)
		},
		{
			key: 'updatedby',
			dataIndex: 'lastUpdate',
			title: t('loc:Updated By'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (lastUpdate: any) => `${lastUpdate?.user?.name} ${lastUpdate?.user?.surname}`
		},
		{
			key: 'lastModified',
			dataIndex: 'lastUpdate',
			title: t('loc:Last modified'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (lastUpdate: any) => formatDateTime(lastUpdate?.datetime)
		},
		{
			key: 'actions',
			width: 50,
			render: (data: any) => <ProjectsTableActions projectID={data?.id} setModalState={setModalState} />
		}
	]

	const handleTableChange = useCallback(
		(paginationConfig: TablePaginationConfig) => {
			dispatch(getProjects(paginationConfig?.current, search, paginationConfig?.pageSize as Paths.GetApiV1Projects.Parameters.Limit))
		},
		[dispatch, search]
	)

	const handlePaginationChange = (page: number, pageSize: number) => {
		dispatch(getProjects(page, search, pageSize as Paths.GetApiV1Projects.Parameters.Limit))
	}

	const onRow = (record: Paths.GetApiV1ProjectsProjectId.Responses.$200['project']) => ({
		onClick: () => {
			dispatch(setProjectSiderToProject(record?.id, record?.name))
			setQuery({
				projectID: record?.id,
				projectName: record?.name,
				folderID: undefined,
				folderName: undefined,
				subFolderID: undefined,
				subFolderName: undefined,
				planID: undefined,
				planName: undefined,
				planStateID: undefined,
				planStateName: undefined
			})
		}
	})

	const confirmDelete = async () => {
		if (modalState.projectID) {
			try {
				await deleteReq('/api/v1/projects/{projectID}', { projectID: modalState.projectID })
				dispatch(getProjectsSider())
				dispatch(getProjects(pagination?.current, search, pagination?.pageSize || DEFAULT_PAGE_SIZE))
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}
		setModalState({ visible: false, projectID: undefined })
	}

	const renderEmptyState = (
		<div className={'pb-2 pt-28 flex flex-col items-center'}>
			<ProjectImage />
			<Button
				type={'primary'}
				className={'extd-btn h-10 w-56 mt-4'}
				icon={<PlusOutlined />}
				onClick={handleCreateProjectClick}
				disabled={!permissedActions?.includes(ACTION_TYPE.PROJECT_CREATE)}
			>
				{t('loc:New project')}
			</Button>
		</div>
	)

	const projectsTable = (
		<Table
			size={'small'}
			className={'extd-table mt-4 mb-28'}
			style={{ cursor: 'pointer' }}
			columns={columns}
			rowKey={'id'}
			loading={projects?.isLoading}
			dataSource={(projects?.originalData as any) || []}
			pagination={false}
			onRow={onRow}
			onChange={handleTableChange}
			locale={{
				emptyText: t('loc:No projects found')
			}}
		/>
	)

	return (
		<div>
			<Row justify={'space-between'} className={'pt-4'}>
				<Col span={10} style={{ minWidth: '144px' }}>
					<SearchFilter onSubmit={searchProjects} placeholder={t('loc:Search project')} />
				</Col>
				<Col>
					<Button
						type={'default'}
						className={'extd-btn h-10 w-36 mr-2'}
						icon={<UploadOutlined />}
						onClick={() => history.push(t('paths:upload-documents'))}
						disabled={!permissedActions?.includes(ACTION_TYPE.UPLOAD)}
					>
						{t('loc:Upload files')}
					</Button>
					<Button
						type={'primary'}
						className={'extd-btn h-10 w-36'}
						icon={<PlusOutlined />}
						onClick={handleCreateProjectClick}
						disabled={!permissedActions?.includes(ACTION_TYPE.PROJECT_CREATE)}
					>
						{t('loc:New project')}
					</Button>
				</Col>
			</Row>
			{!projects?.isLoading && projects?.originalData?.length === 0 && !search ? renderEmptyState : projectsTable}
			<div className={'extd-layout-footer'}>
				<Pagination
					size={'small'}
					className={'extd-pagination'}
					showSizeChanger={true}
					pageSizeOptions={PAGINATION.pageSizeOptions}
					current={pagination?.current}
					total={pagination?.total}
					showTotal={(total, range) => `${range[0]}-${range[1]} ${t('loc:of')} ${total} ${t('loc:items')}`}
					pageSize={pagination?.pageSize || 0}
					onChange={handlePaginationChange}
				/>
			</div>
			<ConfirmModal
				visible={modalState.visible === true}
				text={t('loc:Delete project?')}
				confirmButtonText={t('loc:Confirm')}
				cancelButtonText={t('loc:Cancel')}
				confirmButtonClassName={'extd-btn-danger'}
				onCancel={() => setModalState({ visible: false, projectID: undefined })}
				onSubmit={confirmDelete}
			/>
		</div>
	)
}

export default ProjectsTable
