import React, { PureComponent } from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Form, Space, Row, Tooltip, Col } from 'antd'
import { withTranslation, WithTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import i18next from 'i18next'
import { RootState } from '../../../reducers'
import { hasUpperCaseLetter, hasLowerCaseLetter, hasNumber, isStrongEnough } from '../../../utils/helpers'
import { ReactComponent as Planshelf } from '../../../assets/images/image-planshelf.svg'
import InputPasswordField from '../../../atoms/InputPasswordField'

import { FORM } from '../../../utils/enums'
// eslint-disable-next-line import/no-cycle
import validateNewPasswordForm from './validateNewPasswordForm'

const mapStateToProps = (state: RootState) => ({
	formValues: getFormValues(FORM.CREATE_PASSWORD)(state)
})

const connector = connect(mapStateToProps)

export interface INewPasswordForm {
	password: string
	confirmPassword: string
}

type PropsFromRedux = ConnectedProps<typeof connector>

export type ComponentProps = WithTranslation & PropsFromRedux

type Props = InjectedFormProps<INewPasswordForm, ComponentProps> & ComponentProps

export const renderTooltip = (password: string) => {
	return (
		<Space direction={'vertical'}>
			<span>{i18next.t('loc:At least')}:</span>
			<ul>
				<li>{`${i18next.t('loc:8 characters')} ${password?.length >= 8 ? '✔' : ''}`}</li>
				<li>{`${i18next.t('loc:1 lowercase letter')} ${hasLowerCaseLetter(password) ? '✔' : ''}`}</li>
				<li>{`${i18next.t('loc:1 uppercase letter')} ${hasUpperCaseLetter(password) ? '✔' : ''}`}</li>
				<li>{`${i18next.t('loc:1 number')} ${hasNumber(password) ? '✔' : ''}`}</li>
			</ul>
		</Space>
	)
}

class NewPasswordForm extends PureComponent<Props> {
	render() {
		const { t, handleSubmit, submitting } = this.props
		const { formValues } = this.props as any

		const canSubmit = isStrongEnough(formValues?.password) && !submitting

		const showTooltip = !canSubmit && formValues?.password?.length > 0

		return (
			<Form layout={'vertical'} className={'mt-20'} onSubmitCapture={handleSubmit}>
				<Row gutter={[16, 16]} justify={'center'}>
					<Col span={14}>
						<Planshelf className={'mb-10'} />
					</Col>
					<Col span={14}>
						<h3 className={'heading-3 mb-0'}>{t('loc:Welcome')}</h3>
						<span className={'text-m-regular text-zinc-600'}>{t('loc:Set password for your account.')}</span>
					</Col>
					<Col span={14} className={'mt-2'}>
						<Tooltip visible={showTooltip} placement={'right'} title={renderTooltip(formValues?.password)}>
							<Field
								component={InputPasswordField}
								label={t('loc:Password')}
								placeholder={t('loc:Enter password')}
								type={'password'}
								size={'large'}
								name={'password'}
								required
							/>
						</Tooltip>
					</Col>
				</Row>
				<Row gutter={[16, 16]} justify={'center'}>
					<Col span={14}>
						<Field
							component={InputPasswordField}
							label={t('loc:Password again')}
							placeholder={t('loc:Enter password again')}
							type={'password'}
							size={'large'}
							name={'confirmPassword'}
							required
						/>
					</Col>
					<Col span={14}>
						<Button type={'primary'} block className={'extd-btn'} size={'large'} htmlType={'submit'} disabled={!canSubmit} loading={submitting}>
							{t('loc:Log in')}
						</Button>
					</Col>
				</Row>
			</Form>
		)
	}
}

const form = reduxForm<INewPasswordForm, ComponentProps>({
	form: FORM.CREATE_PASSWORD,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateNewPasswordForm
})(NewPasswordForm)

export default withTranslation()(compose(connector)(form))
