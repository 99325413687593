import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Result, Row, Col } from 'antd'
import { ReactComponent as Planshelf } from '../../assets/images/image-planshelf.svg'
import { ReactComponent as Illustration404 } from '../../assets/images/image-404.svg'

type Props = {}

const NotFoundPage: FC<Props> = () => {
	const { t } = useTranslation()

	return (
		<Row justify={'center'} className={'mt-20'}>
			<Col span={14}>
				<Planshelf className={'mb-10'} />
			</Col>
			<Col span={14}>
				<Result
					title={<span className={'heading-4 text-zinc-500'}>{'404'}</span>}
					icon={<Illustration404 />}
					subTitle={<span className={'text-m-regular text-zinc-400'}>{t('loc:This page was not found.')}</span>}
					extra={<Link to={t('paths:index') as string}>{<span className={'text-m-regular text-blue-600'}>{t('loc:Back to homepage')}</span>}</Link>}
				/>
			</Col>
		</Row>
	)
}

export default NotFoundPage
