import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Menu, Dropdown, Button, Row, Col, Input, Pagination, TablePaginationConfig, Tag } from 'antd'

import { CheckCircleOutlined, ClockCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { initialize } from 'redux-form'
import { translateUserRole } from '../../../utils/helpers'
import { ACTION_TYPE, DEFAULT_PAGE_SIZE, FORM, PAGINATION, USER_ROLE } from '../../../utils/enums'
import { RootState } from '../../../reducers'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon-delete.svg'
import { getUser, getUsers } from '../../../reducers/users/usersActions'
import { ReactComponent as EditIcon } from '../../../assets/icons/icon-edit.svg'
import { ReactComponent as DotsIcon } from '../../../assets/icons/icon-table-actions.svg'
import ConfirmModal from '../../../components/ConfirmModal'
import { ReactComponent as FoldersImage } from '../../../assets/images/image-folder.svg'
import { Paths } from '../../../types/api'
import InviteUserModal from './components/InviteUserModal'
import { deleteReq, patchReq } from '../../../utils/requests'
import EditUserModal from './components/EditUserModal'
import { IEditUserForm } from './components/EditUserForm'
import UserDetailModal from './components/UserDetailModal'
import usePermissionCheck from '../../../utils/permissionCheck'
import { reloadUserData } from '../../../reducers/authUser/authUserActions'

const minDropdownWidth = { minWidth: 150 }

type Props = {
	userID: number
	setModalState: (value: { visible: MODAL_TYPE | undefined; userID: number | undefined }) => void
}

enum MODAL_TYPE {
	DELETE,
	INVITE,
	EDIT,
	DETAIL
}

enum ACTION_KEY {
	EDIT = 'EDIT',
	DELETE = 'DELETE'
}

const USER_STATE = {
	ACTIVE: 'ACTIVE',
	PENDING_INVITATION: 'PENDING_INVITATION'
}

const UsersTableActions = (props: Props) => {
	const { userID, setModalState } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const permissedActions = usePermissionCheck()

	const handleEditClick = async (e: any) => {
		e.domEvent.stopPropagation()

		const data = await dispatch(getUser(userID))

		dispatch(initialize(FORM.EDIT_USER_FORM, data))
		setModalState({ visible: MODAL_TYPE.EDIT, userID })
	}

	const dropdownMenu = (
		<Menu>
			<Menu.Item key={ACTION_KEY.EDIT} icon={<EditIcon />} onClick={(e) => handleEditClick(e)} disabled={!permissedActions?.includes(ACTION_TYPE.TEAM_MATES)}>
				{t('loc:Edit')}
			</Menu.Item>
			<Menu.Item
				key={ACTION_KEY.DELETE}
				icon={<DeleteIcon />}
				className={permissedActions?.includes(ACTION_TYPE.TEAM_MATES) ? 'text-error-medium' : ''}
				onClick={(e) => {
					e.domEvent.stopPropagation()
					setModalState({ visible: MODAL_TYPE.DELETE, userID })
				}}
				disabled={!permissedActions?.includes(ACTION_TYPE.TEAM_MATES)}
			>
				{t('loc:Delete')}
			</Menu.Item>
		</Menu>
	)

	return (
		<Dropdown overlay={dropdownMenu} overlayStyle={minDropdownWidth} trigger={['click']}>
			<Button
				type={'text'}
				className={'extd-table-dropdown flex justify-center'}
				style={{ padding: '0' }}
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				<DotsIcon />
			</Button>
		</Dropdown>
	)
}

const debounced = debounce((searchTerm: string, limit: number | undefined, searchUsers: (value: string) => void) => {
	if (limit) {
		searchUsers(searchTerm)
	}
}, 300)

const UsersTable = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const users = useSelector((state: RootState) => state?.usersStore?.users)
	const authUser = useSelector((state: RootState) => state?.userStore?.authUser?.originalData)
	const [modalState, setModalState] = useState<{ visible: MODAL_TYPE | undefined; userID: number | undefined }>({ visible: undefined, userID: undefined })

	const [search, setSearch] = useState('')
	const [inputSearchValue, setInputSearchValue] = useState('')
	const selectedProject = useSelector((state: RootState) => state?.menuStore?.path?.project)

	const pagination = {
		current: users?.pagination?.page,
		total: users?.pagination?.totalCount,
		pageSize: users?.pagination?.limit,
		showSizeChanger: true,
		pageSizeOptions: PAGINATION.pageSizeOptions,
		className: 'extd-pagination'
	}

	useEffect(() => {
		dispatch(getUsers(pagination?.pageSize || DEFAULT_PAGE_SIZE, 1, '', selectedProject?.ID || undefined))
		setInputSearchValue('')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProject])

	const handlePaginationChange = (page: number, pageSize: number) => {
		dispatch(getUsers(pageSize as Paths.GetApiV1Users.Parameters.Limit, page, search, selectedProject?.ID || undefined))
	}

	const handleTableChange = useCallback(
		(paginationConfig: TablePaginationConfig) => {
			dispatch(
				getUsers(
					paginationConfig?.pageSize as Paths.GetApiV1Users.Parameters.Limit,
					paginationConfig?.current as Paths.GetApiV1Users.Parameters.Page,
					search,
					selectedProject?.ID || undefined
				)
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	)

	const searchUsers = (searchTerm: string) => {
		dispatch(getUsers(pagination?.pageSize || DEFAULT_PAGE_SIZE, 1, searchTerm, selectedProject?.ID || undefined))
		setSearch(searchTerm)
	}

	const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		debounced(event.target.value, users?.pagination?.limit, searchUsers)
		setInputSearchValue(event.target.value)
	}

	const handleInviteClick = () => {
		dispatch(initialize(FORM.INVITE_USER_FORM, { email: '' }))
		setModalState({ visible: MODAL_TYPE.INVITE, userID: undefined })
	}

	const confirmInvite = async () => {
		dispatch(getUsers(pagination?.pageSize || DEFAULT_PAGE_SIZE, pagination?.current, search, selectedProject?.ID || undefined))
		setModalState({ visible: undefined, userID: undefined })
	}

	const confirmDelete = async () => {
		if (modalState.userID) {
			try {
				await deleteReq('/api/v1/users/{userID}', { userID: modalState.userID })
				dispatch(getUsers(pagination?.pageSize || DEFAULT_PAGE_SIZE, pagination?.current, search, selectedProject?.ID || undefined))
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e)
			}
			setModalState({ visible: undefined, userID: undefined })
		}
	}

	const handleEditUser = async (values: IEditUserForm) => {
		if (modalState.userID) {
			try {
				const reqBody = {
					name: values?.name,
					surname: values?.surname,
					permission: values?.permission,
					note: values?.note,
					phone: values?.phone,
					company: values?.company,
					companyRole: values?.companyRole
				}
				await patchReq('/api/v1/users/{userID}', { userID: modalState.userID }, reqBody)
				dispatch(getUsers(pagination?.pageSize || DEFAULT_PAGE_SIZE, pagination?.current, search, selectedProject?.ID || undefined))

				if (modalState.userID === authUser?.id) {
					dispatch(reloadUserData(authUser?.id))
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e)
			}
			setModalState({ visible: undefined, userID: undefined })
		}
	}

	const onRow = (record: Paths.GetApiV1UsersUserId.Responses.$200['user']) => ({
		onClick: async () => {
			await dispatch(getUser(record?.id))
			setModalState({ visible: MODAL_TYPE.DETAIL, userID: record?.id })
		}
	})

	const renderUserState = (state: string) => {
		switch (state) {
			case USER_STATE.PENDING_INVITATION:
				return (
					<div className={'text-warning-medium flex'}>
						<ClockCircleOutlined className={'text-warning-medium text-lg mr-2'} />
						{t('loc:Waiting')}
					</div>
				)
			case USER_STATE.ACTIVE:
				return (
					<div className={'text-success-medium flex'}>
						<CheckCircleOutlined className={'text-success-medium text-lg mr-2'} />
						{t('loc:Approved')}
					</div>
				)
			default:
				return '-'
		}
	}

	const switchToEdit = async () => {
		const data = await dispatch(getUser(modalState.userID || 0))

		dispatch(initialize(FORM.EDIT_USER_FORM, data))
		setModalState({ visible: MODAL_TYPE.EDIT, userID: modalState.userID })
	}

	const columns = [
		{
			key: 'email',
			dataIndex: 'email',
			title: t('loc:Email'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true
		},
		{
			key: 'name',
			title: t('loc:Name'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (data: any) => `${data?.name || ''} ${data?.surname || ''}`
		},
		{
			key: 'position',
			dataIndex: 'position',
			title: t('loc:Position'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true
		},
		{
			key: 'company',
			dataIndex: 'company',
			title: t('loc:Company'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true
		},
		{
			key: 'projectCount',
			dataIndex: 'projectCount',
			title: t('loc:Projects'),
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			width: 80,
			render: (count: number) => (count ? <Tag>{`(${count})`}</Tag> : null)
		},
		{
			key: 'permission',
			dataIndex: 'permission',
			title: t('loc:Role'),
			width: 120,
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (role: USER_ROLE) => translateUserRole(role)
		},
		{
			key: 'state',
			dataIndex: 'state',
			title: t('loc:Invitation'),
			width: 100,
			sorter: false,
			showSorterTooltip: false,
			ellipsis: true,
			render: (state: string) => renderUserState(state)
		},
		{
			key: 'actions',
			width: 50,
			render: (data: any) => <UsersTableActions userID={data?.id} setModalState={setModalState} />
		}
	]

	const renderEmptyState = (
		<div className={'pb-2 pt-28 flex flex-col items-center'}>
			<FoldersImage />
			<Button type={'primary'} className={'extd-btn h-10 w-56 mt-4'} icon={<PlusOutlined />} onClick={handleInviteClick}>
				{t('loc:Invite user')}
			</Button>
		</div>
	)

	const foldersTable = (
		<Table
			size={'small'}
			className={'extd-table mt-4'}
			columns={columns}
			rowKey={'id'}
			onRow={onRow}
			loading={users?.isLoading}
			dataSource={(users?.originalData as any) || []}
			pagination={false}
			onChange={handleTableChange}
			locale={{
				emptyText: t('loc:No users found')
			}}
		/>
	)
	return (
		<div>
			<Row justify={'space-between'} className={'pt-4'}>
				<Col span={10} style={{ minWidth: '144px' }}>
					<Input
						allowClear
						placeholder={t('loc:Search people by name, position, company...')}
						onChange={onSearchChange}
						className={'extd-input'}
						type={'text'}
						size={'large'}
						value={inputSearchValue}
						suffix={<SearchOutlined />}
						maxLength={100}
					/>
				</Col>
				<Col>
					<Button type={'primary'} className={'extd-btn h-10'} icon={<PlusOutlined />} onClick={handleInviteClick}>
						{t('loc:Invite user')}
					</Button>
				</Col>
			</Row>
			{!users?.isLoading && users?.originalData?.length === 0 && !search ? renderEmptyState : foldersTable}
			<div className={'extd-layout-footer'}>
				<Pagination
					size={'small'}
					className={'extd-pagination'}
					showSizeChanger={true}
					pageSizeOptions={PAGINATION.pageSizeOptions}
					current={pagination?.current}
					total={pagination?.total}
					showTotal={(total, range) => `${range[0]}-${range[1]} ${t('loc:of')} ${total} ${t('loc:items')}`}
					pageSize={pagination?.pageSize || 0}
					onChange={handlePaginationChange}
				/>
			</div>
			<InviteUserModal
				visible={modalState.visible === MODAL_TYPE.INVITE}
				onCancel={() => setModalState({ visible: undefined, userID: undefined })}
				onSubmit={confirmInvite}
			/>
			<UserDetailModal openEdit={switchToEdit} visible={modalState.visible === MODAL_TYPE.DETAIL} onCancel={() => setModalState({ visible: undefined, userID: undefined })} />
			<ConfirmModal
				visible={modalState.visible === MODAL_TYPE.DELETE}
				text={t('loc:Delete user?')}
				confirmButtonText={t('loc:Confirm')}
				cancelButtonText={t('loc:Cancel')}
				confirmButtonClassName={'extd-btn-danger'}
				onCancel={() => setModalState({ visible: undefined, userID: undefined })}
				onSubmit={confirmDelete}
			/>
			{modalState.visible === MODAL_TYPE.EDIT && (
				<EditUserModal
					onSubmit={handleEditUser}
					onClose={() => setModalState({ visible: undefined, userID: undefined })}
					visible={modalState.visible === MODAL_TYPE.EDIT}
				/>
			)}
		</div>
	)
}

export default UsersTable
