import React, { useCallback, useEffect, useState } from 'react'
import { TablePaginationConfig, Table, Row, Col, Pagination, Typography, Tooltip } from 'antd'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import dayjs from 'dayjs'
import { change, formValueSelector, getFormValues } from 'redux-form'
import { RootState } from '../../../reducers'
import { getPlans } from '../../../reducers/plans/plansActions'
import { Paths } from '../../../types/api'
import { ACTION_TYPE, DEFAULT_PAGE_SIZE, PAGINATION, TREE_NODE_TYPE, MODAL_TYPE_PLANS, FORM, PROJECT_CODE_TYPE } from '../../../utils/enums'
import { formatDateTime } from '../../../utils/helpers'
import { getProject, IProjectPayload } from '../../../reducers/projects/projectsActions'
import EditMainFolderStatusesModal from './components/EditMainFolderStatusesModal'
import { postReq } from '../../../utils/requests'
import PlanStatesTable from '../PlanStates/PlanStatesTable'
import { setProjectSiderToPlanStateDocument, setProjectSiderToSubFolder } from '../../../reducers/menu/menuActions'
import usePermissionCheck from '../../../utils/permissionCheck'
import { getSelectedPlans, selectPlanFiles, deselectPlanFiles, getPartlySelectedPlans } from './components/PlanDownloadHandler'
import { IDownloadItem, setDownloadList } from '../../../reducers/dowload/downloadActions'
import DuplicateResponseModal from './components/DuplicateResponseModal'
import { IDatesFilter, IFailedPlan, IFailedPlans } from '../../../types/interfaces'
import PlansTableActions from './components/PlansTableActions'
import PlansTableEmptyState from './components/PlansTableEmptyState'
import PlansTableButtonRow from './components/PlansTableButtonRow'
import PlansTableFilterRow from './components/PlansTableFilterRow'
import DraggerHeader from './components/upload/DraggerHeader'
import DraggerCell from './components/upload/DraggerCell'
import ChooseFirstIndexModal from './components/ChooseFirstIndexModal'

type Props = {
	projectID?: number
	folderID: number
	type: TREE_NODE_TYPE
}

const selectorSearch = formValueSelector(FORM.PLANS_SEARCH_FILTER)
const selectorDates = getFormValues(FORM.DATES_FILTER)

const PlansTable = (props: Props) => {
	const { projectID, folderID, type } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const plans = useSelector((state: RootState) => state?.plansStore?.plans)
	const menu = useSelector((state: RootState) => state?.menuStore)

	const scrollWidth = 970 + (plans?.plans?.at(0)?.planStates?.length || 0) * 175

	const [search, setSearch] = useState('')
	// same name as BE.
	const [onlyActual, setOnlyActual] = useState(false)
	const [projectData, setProjectData] = useState<IProjectPayload | null>(null)

	const [modalState, setModalState] = useState<MODAL_TYPE_PLANS | undefined>()
	const [chooseIndexModalState, setChooseIndexModalState] = useState<{ visible: boolean; increaseStatus: boolean }>({ visible: false, increaseStatus: false })
	const [failedDuplicatedPlans, setFailedDuplicatedPlans] = useState<undefined | IFailedPlans>(undefined)

	const downloadList = useSelector((state: RootState) => state?.downloadStore?.download?.data)
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
	const [draggingPlanStateID, setDraggingPlanStateID] = useState<undefined | number>(undefined)

	// To show values in dropdown button.
	const [datesValues, setDatesValues] = useState<{ dateFrom: dayjs.Dayjs | undefined; dateTo: dayjs.Dayjs | undefined }>({ dateFrom: undefined, dateTo: undefined })

	const permissedActions = usePermissionCheck()

	const [query, setQuery] = useQueryParams({
		projectID: NumberParam,
		projectName: StringParam,
		folderID: NumberParam,
		folderName: StringParam,
		subFolderID: NumberParam,
		subFolderName: StringParam,
		planID: NumberParam,
		planName: StringParam,
		planStateID: NumberParam,
		planStateName: StringParam
	})

	const formSearch = useSelector((state: RootState) => selectorSearch(state, 'search'))
	const formDates: Partial<IDatesFilter> = useSelector(selectorDates)

	useEffect(() => {
		const getPlansPayload = async () => {
			const plansPayload = await dispatch(
				getPlans(
					folderID,
					1,
					formSearch || '',
					plans?.onlyActual || false,
					formDates?.dateFrom ? `${dayjs(formDates?.dateFrom).valueOf()}` : undefined,
					formDates?.dateTo ? `${dayjs(formDates?.dateTo).valueOf()}` : undefined,
					plans?.pagination?.limit || DEFAULT_PAGE_SIZE
				)
			)
			if (formSearch) {
				setSearch(formSearch)
			}
			if (formDates?.dateFrom || formDates?.dateTo) {
				setDatesValues({
					dateFrom: formDates?.dateFrom,
					dateTo: formDates?.dateTo
				})
			}
			setOnlyActual(plans?.onlyActual || false)

			if (query?.planID && (!menu?.path?.planStateDocument?.planID || menu?.path?.planStateDocument?.planID !== query?.planID)) {
				const selectedPlan = plansPayload?.plans?.filter((plan) => plan?.id === query.planID)?.at(0)
				const planStateOptions = map(selectedPlan?.planStates, (state) => ({
					key: state?.id,
					label: state?.name
				})).filter((item) => item?.key !== query.planStateID)

				dispatch(
					setProjectSiderToPlanStateDocument({
						planStateOptions,
						defaultOption: {
							key: query.planStateID,
							label: query.planStateName
						},
						planName: query.planName || '',
						planID: query.planID
					})
				)
			} else if (!query?.planID && menu?.path?.planStateDocument?.defaultOption?.key) {
				setQuery({
					planID: menu?.path?.planStateDocument?.planID,
					planName: menu?.path?.planStateDocument?.planName,
					planStateID: menu?.path?.planStateDocument?.defaultOption?.key,
					planStateName: menu?.path?.planStateDocument?.defaultOption?.label
				})
			}
		}

		getPlansPayload()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folderID, menu?.path?.planStateDocument?.planID])

	useEffect(() => {
		if (plans?.plans?.length) setSelectedRowKeys(getSelectedPlans(downloadList, plans, type, folderID))
		// Toto je super shitty practice, neviem ale ako to fixnut.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadList, folderID, plans?.plans])

	useEffect(() => {
		const fetchProject = async () => {
			if (projectID) {
				const project = await dispatch(getProject(projectID))
				setProjectData(project)
			}
		}

		fetchProject()
	}, [projectID, dispatch])

	const handleBulkDuplicate = async (increaseStatus?: boolean, index?: string | number) => {
		try {
			const requestBody = {
				planIDs: selectedRowKeys,
				increaseStatus,
				index
			}

			const { data } = await postReq('/api/v1/plans/duplicate', undefined, requestBody as any)

			if (data?.duplicatedPlans?.length !== selectedRowKeys?.length) {
				const plansWithoutGeneratedName: IFailedPlan[] = data?.plansWithoutGeneratedName
				const alreadyExistingPlans: IFailedPlan[] = data?.alreadyExistingPlans
				const maxIndexPlans: IFailedPlan[] = data?.maxIndexPlans
				const noStatusPlans: IFailedPlan[] = data?.noStatusPlans
				const maxStatusPlans: IFailedPlan[] = data?.maxStatusPlans

				setFailedDuplicatedPlans({ plansWithoutGeneratedName, alreadyExistingPlans, maxIndexPlans, noStatusPlans, maxStatusPlans })
				setModalState(MODAL_TYPE_PLANS.DUPLICATE_RESPONSE)
			}

			dispatch(setDownloadList(deselectPlanFiles(downloadList, selectedRowKeys)))
			dispatch(getPlans(folderID, 1, search, onlyActual, plans?.fromDate, plans?.toDate, plans?.pagination?.limit || DEFAULT_PAGE_SIZE))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	}

	const checkIfBulkDuplicatePlansMissingIndex = (increaseStatus: boolean) => {
		let isMissingIndex = false
		selectedRowKeys.forEach((key) => {
			const planName = plans?.plans?.find((plan) => plan?.id === key)?.name
			const splitPlanName = planName?.split('_')
			projectData?.originalData?.projectCodeAttributes.forEach((code: any, index: number) => {
				if (code?.type === PROJECT_CODE_TYPE.INDEX && splitPlanName?.at(index) === '-' && !isMissingIndex) {
					isMissingIndex = true
				}
			})
		})

		if (isMissingIndex) {
			setChooseIndexModalState({ visible: true, increaseStatus })
		} else {
			handleBulkDuplicate(increaseStatus)
		}
	}

	const searchPlans = (searchTerm: string) => {
		dispatch(getPlans(folderID, 1, searchTerm, onlyActual, plans?.fromDate, plans?.toDate, plans?.pagination?.limit || DEFAULT_PAGE_SIZE))
		setSearch(searchTerm)
	}

	const shouldHide = (index: number) => {
		const plansLength = plans.plans?.at(0)?.planStates?.length
		if (plansLength === undefined) {
			return true
		}
		if (plansLength > index) {
			return false
		}
		return true
	}

	const getPlanStateName = (index: number) => {
		const planStateName = plans.plans?.at(0)?.planStates?.at(index)?.name
		if (!planStateName) return '-'
		return planStateName
	}

	const openDocuments = (data: any, index: number) => {
		const planStateOptions = map(data?.planStates, (state) => ({
			key: state?.id,
			label: state?.name
		})).filter((item) => item?.key !== data?.planStates?.at(index)?.id)

		const defaultOption = {
			key: data?.planStates?.at(index)?.id,
			label: getPlanStateName(index)
		}

		if (data?.folder?.id !== folderID && menu?.path.project?.ID && menu?.path.project.name && menu?.path.folder?.ID && menu?.path.folder?.name) {
			dispatch(
				setProjectSiderToSubFolder(
					menu?.path?.project?.ID || 0,
					menu?.path?.project?.name || '',
					menu?.path?.folder?.ID || 0,
					menu?.path?.folder?.name,
					data?.folder?.id,
					data?.folder?.name
				)
			)
		}

		dispatch(setProjectSiderToPlanStateDocument({ planStateOptions, defaultOption, planName: data?.name, planID: data?.id }))
		if (data?.folder?.id !== folderID && menu?.path.project?.ID && menu?.path.project.name && menu?.path.folder?.ID && menu?.path.folder?.name) {
			setQuery({
				projectID: menu?.path?.project?.ID,
				projectName: menu?.path?.project?.name,
				folderID: menu?.path?.folder?.ID,
				folderName: menu?.path?.folder?.name,
				subFolderID: data?.folder?.id,
				subFolderName: data?.folder?.name,
				planID: data?.id,
				planName: data?.name,
				planStateID: defaultOption?.key,
				planStateName: defaultOption?.label
			})
		} else setQuery({ planID: data?.id, planName: data?.name, planStateID: defaultOption?.key, planStateName: defaultOption?.label })
	}

	const pagination = {
		current: plans?.pagination?.page,
		total: plans?.pagination?.totalCount,
		pageSize: plans?.pagination?.limit,
		showSizeChanger: true,
		pageSizeOptions: PAGINATION.pageSizeOptions,
		className: 'extd-pagination'
	}

	const getPlanStateTitle = (index: number) => {
		if (!permissedActions?.includes(ACTION_TYPE.UPLOAD) || projectData?.originalData?.projectCodeAttributes?.length === 0) {
			return getPlanStateName(index)
		}
		return (
			<DraggerHeader
				subFolderID={menu?.path?.subFolder?.ID ? folderID : undefined}
				folderID={menu?.path?.folder?.ID}
				name={getPlanStateName(index)}
				projectCodeAttributes={projectData?.originalData?.projectCodeAttributes}
			/>
		)
	}

	const refreshPlans = () => {
		dispatch(getPlans(folderID, 1, search, onlyActual, plans?.fromDate, plans?.toDate, plans?.pagination?.limit || DEFAULT_PAGE_SIZE))
	}

	const isClearShown = !!(datesValues?.dateFrom || datesValues?.dateTo || onlyActual || search)

	const getColumns = (): any => {
		const firstColumns = [
			{
				key: 'name',
				title: t('loc:Plans name'),
				sorter: false,
				showSorterTooltip: false,
				width: '300px',
				fixed: 'left' as const,
				render: (data: any) => {
					return (
						<Row>
							<Tooltip placement={'topLeft'} title={data?.name} overlayStyle={{ maxWidth: '500px' }}>
								<Col style={{ width: 'calc(100% - 25px)' }}>
									<Typography.Text ellipsis>{data?.name}</Typography.Text>
								</Col>
							</Tooltip>
							<Col flex={'25px'}>
								<PlansTableActions
									folderID={folderID}
									planID={data.id}
									planName={data?.name}
									projectName={projectData?.originalData?.name || '-'}
									projectCodeAttributes={projectData?.originalData?.projectCodeAttributes}
									page={pagination?.current || 1}
									search={search}
									onlyActual={onlyActual}
									dateFromValue={plans?.fromDate}
									dateToValue={plans?.toDate}
									limit={pagination?.pageSize || 25}
								/>
							</Col>
						</Row>
					)
				}
			},
			{
				key: 'description',
				dataIndex: ['description'],
				title: t('loc:Description'),
				sorter: false,
				showSorterTooltip: false,
				ellipsis: true,
				width: '350px',
				render: (description: any) => (
					<Tooltip placement={'top'} title={description}>
						<span>{description}</span>
					</Tooltip>
				)
			},
			{
				key: 'lastUpdate',
				dataIndex: ['lastUpdate'],
				title: t('loc:Last modified at'),
				sorter: false,
				showSorterTooltip: false,
				ellipsis: true,
				width: '140px',
				render: (data: any) => formatDateTime(data?.datetime)
			}
		]

		const planStateColumns = map(Array.from(Array(10).keys()), (index) => {
			return {
				key: `planStates_${index}`,
				title: getPlanStateTitle(index),
				sorter: false,
				showSorterTooltip: false,
				hidden: shouldHide(index),
				width: '200px',
				render: (data: any) => (
					<DraggerCell
						index={index}
						planData={data}
						openDocuments={(plansData: any, plansIndex: number) => openDocuments(plansData, plansIndex)}
						refreshPlans={refreshPlans}
						type={type}
						folderID={folderID}
						draggingPlanStateID={draggingPlanStateID}
						setDraggingPlanStateID={setDraggingPlanStateID}
					/>
				)
			}
		})

		return firstColumns.concat(planStateColumns as any).filter((col: any) => !col.hidden)
	}

	const handleTableChange = useCallback(
		(paginationConfig: TablePaginationConfig) => {
			dispatch(
				getPlans(
					folderID,
					paginationConfig?.current,
					search,
					onlyActual,
					plans?.fromDate,
					plans?.toDate,
					paginationConfig?.pageSize as Paths.GetApiV1Plans.Parameters.Limit
				)
			)
		},
		[dispatch, search, folderID, onlyActual, plans?.fromDate, plans?.toDate]
	)

	const getRowSelectionSelectedKeys = () => {
		const partlySelected = getPartlySelectedPlans(downloadList, plans, type, folderID)
		const fullySelected: number[] = []
		selectedRowKeys?.forEach((key) => {
			if (!partlySelected.includes(key)) fullySelected.push(key)
		})
		return fullySelected
	}

	const onSelectChange = async (newSelectedRowKeys: any) => {
		// aby bol select plynuly a necakal na BE response.
		setSelectedRowKeys(newSelectedRowKeys)

		const deselectedIds = selectedRowKeys?.filter((id: number) => !newSelectedRowKeys?.includes(id))
		const newlySelectedIds = newSelectedRowKeys?.filter((id: number) => !getRowSelectionSelectedKeys()?.includes(id))
		let newDownloadList: IDownloadItem[] = downloadList

		if (newlySelectedIds?.length) {
			try {
				const reqBody = {
					planIDs: newlySelectedIds
				}

				const params = {
					fromDate: plans?.fromDate,
					toDate: plans?.toDate
				}

				const data = await postReq('/api/v1/plans/documents', params, reqBody as any)
				newDownloadList = selectPlanFiles(newDownloadList, menu?.path, data?.data)
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}
		if (deselectedIds?.length) {
			newDownloadList = deselectPlanFiles(newDownloadList, deselectedIds)
		}
		dispatch(setDownloadList(newDownloadList))
	}

	const rowSelection = {
		selectedRowKeys: getRowSelectionSelectedKeys(),
		getCheckboxProps: (record: any) => {
			return {
				indeterminate: getPartlySelectedPlans(downloadList, plans, type, folderID)?.includes(record?.id)
			}
		},
		onChange: onSelectChange,
		indeterminate: true
	}

	const handlePaginationChange = (page: number, pageSize: number) => {
		dispatch(getPlans(folderID, page, search, onlyActual, plans?.fromDate, plans?.toDate, pageSize as Paths.GetApiV1Plans.Parameters.Limit))
	}

	const handleResetFilters = () => {
		dispatch(change(FORM.DATES_FILTER, 'dateFrom', undefined))
		dispatch(change(FORM.DATES_FILTER, 'dateTo', undefined))
		setDatesValues({
			dateFrom: undefined,
			dateTo: undefined
		})
		setOnlyActual(false)
		if (!formSearch || formSearch !== '') {
			dispatch(change(FORM.PLANS_SEARCH_FILTER, 'search', ''))
		}
		if ((onlyActual || datesValues.dateFrom || datesValues.dateTo) && !formSearch && formSearch !== '') {
			dispatch(getPlans(folderID, 1, '', false, undefined, undefined, plans?.pagination?.limit || DEFAULT_PAGE_SIZE))
		}
	}

	const plansTable = (
		<div className={'mb-28'}>
			<Table
				size={'small'}
				className={'extd-table'}
				columns={getColumns()}
				rowSelection={rowSelection}
				rowKey={'id'}
				loading={plans?.isLoading}
				dataSource={plans?.plans || []}
				pagination={false}
				onChange={handleTableChange}
				scroll={{ x: scrollWidth }}
				sticky
				locale={{
					emptyText: t('loc:No plans found')
				}}
			/>
		</div>
	)

	const planTable = (
		<div>
			<PlansTableButtonRow
				type={type}
				searchPlans={searchPlans}
				handleBulkDuplicate={checkIfBulkDuplicatePlansMissingIndex}
				resetFilters={handleResetFilters}
				duplicateDisabled={selectedRowKeys?.length === 0 || !selectedRowKeys?.length}
				projectID={projectID}
				folderID={folderID}
				projectName={projectData?.originalData?.name}
				projectCodeAttributes={projectData?.originalData?.projectCodeAttributes}
				isClearShown={isClearShown}
			/>
			<PlansTableFilterRow
				type={type}
				folderID={folderID}
				projectCodeAttributes={projectData?.originalData?.projectCodeAttributes}
				setModalState={(modalType: MODAL_TYPE_PLANS) => setModalState(modalType)}
				setOnlyActual={(value: boolean) => {
					dispatch(getPlans(folderID, 1, search, !onlyActual, plans?.fromDate, plans?.toDate, plans?.pagination?.limit || DEFAULT_PAGE_SIZE))
					setOnlyActual(value)
				}}
				onlyActual={onlyActual}
				setDateValues={(dateFrom: dayjs.Dayjs | undefined, dateTo: dayjs.Dayjs | undefined) => {
					setDatesValues({
						dateFrom: dateFrom ? (dayjs(dateFrom).format('DD.MM.YYYY') as any) : undefined,
						dateTo: dateTo ? (dayjs(dateTo).format('DD.MM.YYYY') as any) : undefined
					})

					dispatch(
						getPlans(
							folderID,
							1,
							search,
							onlyActual,
							dateFrom ? `${dayjs(dateFrom).valueOf()}` : undefined,
							dateTo ? `${dayjs(dateTo).valueOf()}` : undefined,
							plans?.pagination?.limit || DEFAULT_PAGE_SIZE
						)
					)
				}}
				dateValues={datesValues}
			/>
			{!plans?.isLoading && plans?.plans?.length === 0 && !search && !onlyActual && !plans.fromDate && !plans.toDate ? (
				<PlansTableEmptyState
					type={type}
					projectID={projectID}
					folderID={folderID}
					projectName={projectData?.originalData?.name}
					projectCodeAttributes={projectData?.originalData?.projectCodeAttributes}
				/>
			) : (
				plansTable
			)}
			<div className={'extd-layout-footer'}>
				<Pagination
					size={'small'}
					className={'extd-pagination'}
					showSizeChanger={true}
					pageSizeOptions={PAGINATION.pageSizeOptions}
					current={pagination?.current}
					total={pagination?.total}
					showTotal={(total, range) => `${range[0]}-${range[1]} ${t('loc:of')} ${total} ${t('loc:items')}`}
					pageSize={pagination?.pageSize || 0}
					onChange={handlePaginationChange}
				/>
			</div>
			<EditMainFolderStatusesModal
				onClose={() => setModalState(undefined)}
				projectName={projectData?.originalData?.name || '-'}
				visible={modalState === MODAL_TYPE_PLANS.EDIT}
			/>
			{modalState === MODAL_TYPE_PLANS.DUPLICATE_RESPONSE && (
				<DuplicateResponseModal
					onCancel={() => {
						setModalState(undefined)
						setFailedDuplicatedPlans(undefined)
					}}
					visible={modalState === MODAL_TYPE_PLANS.DUPLICATE_RESPONSE}
					failedDuplicatedPlans={failedDuplicatedPlans}
				/>
			)}
			{chooseIndexModalState.visible && (
				<ChooseFirstIndexModal
					onClose={() => setChooseIndexModalState({ visible: false, increaseStatus: false })}
					visible={chooseIndexModalState.visible}
					increaseStatus={chooseIndexModalState?.increaseStatus}
					refetchPlans={(newIndex?: string | number) => handleBulkDuplicate(chooseIndexModalState.increaseStatus, newIndex)}
				/>
			)}
		</div>
	)
	return menu?.path?.planStateDocument?.defaultOption?.key ? <PlanStatesTable /> : planTable
}

export default PlansTable
